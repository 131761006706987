import { Col, PageHeader, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resumenComprobanteShowModalComprobantes,
  resumenesComprobanteStartLoad,
} from "../../../../actions/resumencomprobante";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { ComprobantesModal } from "./ComprobantesModal";
import { ResumenSearchFormTable } from "./ResumenSearchFormTable";
import { ResumenTable } from "./ResumenTable";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";

export const ResumenList = () => {
  const dispatch = useDispatch();
  const { resumenesComprobante } = useSelector(
    (state) => state.resumencomprobante
  );
  const handleReLoad = () => {
    dispatch(resumenesComprobanteStartLoad());
  };
  const handleNewResumen = () => {
    dispatch(resumenComprobanteShowModalComprobantes());
  };
  useEffect(() => {
    dispatch(resumenesComprobanteStartLoad());
  }, []);

  return (
    <PageHeaderUi
      title="Resúmenes"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key={"actualizar"}
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleReLoad}
        />,
        <ButtonUi
          key={"nuevo"}
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNewResumen}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ResumenSearchFormTable />
            <ResumenTable resumenes={resumenesComprobante} />
            <ComprobantesModal />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export default ResumenList;
