import { Col, Form, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClienteForm } from "./ClienteForm";
import {
  clienteClearActive,
  clienteStartAdd,
  clienteStartUpdate,
} from "../../../actions/cliente";
import { FormUi } from "../../ui/FormUi";
import { uiShowForm } from "../../../actions/ui";
import dayjs from "dayjs";

const initialValues = (data = {}) => ({
  id: data?.id || dayjs().valueOf(),
  tipodocumento: data?.tipodocumento || null,
  nrodocumento: data?.nrodocumento || null,
  nombrecompleto: data?.nombrecompleto || null,
  nombres: data?.nombres || null,
  apepaterno: data?.apepaterno || null,
  apematerno: data?.apematerno || null,
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  direccion: data?.direccion || null,
  telefono: data?.telefono || null,
  email: data?.email || null,
  state: data ? data?.state : true,
});

export const ClienteScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeCliente } = useSelector((state) => state.cliente);

  const handleSave = (values) => {
    if (activeCliente) {
      dispatch(clienteStartUpdate(values));
    } else {
      dispatch(clienteStartAdd(values));
    }
    dispatch(clienteClearActive());
    dispatch(uiShowForm(false));
  };

  return (
    <Row>
      <Col sm={24} md={24} lg={24} xl={18}>
        <FormUi
          form={form}
          name="FormClientes"
          layout="vertical"
          autoComplete="off"
          handleSubmit={handleSave}
          initialValues={initialValues(activeCliente)}
        >
          <ClienteForm />
        </FormUi>
      </Col>
    </Row>
  );
};
