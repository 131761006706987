import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_maquinas_select } from "../../../actions/produccion";

export const MaquinaSelect = ({ ...props }) => {
  const [maquinas, setMaquinas] = useState([]);
  const OnLoadDatos = async () => {
    const datos = await get_maquinas_select();
    setMaquinas(datos);
  };
  useEffect(() => {
    OnLoadDatos();
  }, []);

  return <SelectUi options={maquinas} {...props} />;
};
