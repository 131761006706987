import { toast } from "react-toastify";

const messageLoading = (content = null) =>
  toast.loading(content || "Cargando...");
const messageSuccess = (content, duration = null) =>
  toast.success(content, { autoClose: duration || 2000 });
const messageError = (content, duration = null) =>
  toast.error(content, { autoClose: duration || 3500 });
const messageSuccessLoading = (key, content, duration = null) =>
  toast.update(key, {
    render: content,
    type: "success",
    isLoading: false,
    autoClose: duration || 2500,
  });
const messageInfo = (content, duration = null) =>
  toast.info(content, { autoClose: duration || 2500 });
const messageErrorLoading = (key, content, duration = null) =>
  toast.update(key, {
    render: content,
    type: "error",
    isLoading: false,
    autoClose: duration || 3500,
  });
const DisplayMessageErrors = (errors, keyError, keymsg = null) => {
  for (let [key, value] of Object.entries(errors)) {
    if (typeof value === "object") {
      DisplayMessageErrors(value, key, keymsg);
    } else {
      if (keymsg) {
        return messageErrorLoading(keymsg, `${keyError} - ${errors}`);
      } else {
        messageErrorLoading(keymsg, `${keyError} - ${errors}`);
      }
    }
  }
};
const validaMessagesErrors = (error, key = null) => {
  const errors = error.response;
  if (errors.status === 500) {
    if (key === null) messageError(`${errors.statusText} - ${error.message}`);
    else messageErrorLoading(key, errors.statusText);
  } else if (errors.status === 400) {
    const dataErrors = errors?.data;
    const keyError = Object.keys(dataErrors)[0];
    const valueError = Object.values(dataErrors)[0];
    if (typeof valueError === "object") {
      DisplayMessageErrors(dataErrors, keyError, key);
    } else {
      if (key) return messageErrorLoading(key, `${keyError} - ${valueError}`);
      else return toast.error(`${valueError}`);
    }
  } else {
    messageError(errors.statusText);
  }
};
export {
  messageSuccess,
  messageError,
  messageLoading,
  messageInfo,
  messageSuccessLoading,
  messageErrorLoading,
  validaMessagesErrors,
};
