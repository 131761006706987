import { Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clienteClearActive,
  clienteStartAdd,
  clienteStartUpdate,
} from "../../../../actions/cliente";
import { uiShowModal } from "../../../../actions/ui";
import { ClienteForm } from "./ClienteForm";
import { ModalUi } from "./../../../ui/ModalUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  TipoButtonConstants,
  TiposClientes,
} from "../../../../types/typesConstants";
import { FormUi } from "../../../ui/FormUi";

const initialValues = (data = {}) => ({
  id: data?.id || "",
  tipocliente: data?.tipocliente || TiposClientes.CLIENTE,
  tipodocumento: data?.tipodocumento || null,
  nrodocumento: data?.nrodocumento || null,
  nombrecompleto: data?.nombrecompleto || "",
  nombres: data?.nombres || "",
  apepaterno: data?.apepaterno || "",
  apematerno: data?.apematerno || "",
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  direccion: data?.direccion || "",
  telefono: data?.telefono || "",
  email: data?.email || "",
  clienteid: data?.clienteid || "",
  state: data?.state || true,
});

export const ClienteModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeCliente } = useSelector((state) => state.cliente);
  const { visibleModal } = useSelector((state) => state.ui);

  useEffect(() => {
    if (visibleModal) {
      if (activeCliente === null) {
        form.setFieldsValue({ ...initialValues() });
      } else {
        form.setFieldsValue({ ...activeCliente });
      }
    }
  }, [activeCliente, visibleModal]);
  const handleCancel = () => {
    dispatch(clienteClearActive());
    dispatch(uiShowModal(false));
  };
  const handleSave = (values, _e) => {
    const valuesData = {
      id: values.clienteid,
      persona: { ...values },
      state: values.state,
      tipocliente: values.tipocliente,
    };
    if (activeCliente) {
      dispatch(clienteStartUpdate(valuesData));
    } else {
      dispatch(clienteStartAdd(valuesData));
    }
    handleCancel();
  };

  return (
    <ModalUi
      title={
        <>
          Cliente
          {activeCliente ? <small> Editar</small> : <small> Nuevo</small>}
        </>
      }
      open={visibleModal}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          form="FormClientes"
          key="submit"
          htmlType="submit"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
    >
      <FormUi
        form={form}
        id="FormClientes"
        name="FormClientes"
        layout="vertical"
        autoComplete="off"
        handleSubmit={handleSave}
        initialValues={initialValues(activeCliente)}
      >
        <ClienteForm />
      </FormUi>
    </ModalUi>
  );
};
