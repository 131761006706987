import { Col, DatePicker, Form, Row, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import {
  TipoButtonConstants,
  TipoFiltroBusquedaComprobanteConstants,
} from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { FormUi } from "../../../ui/FormUi";
const { Option } = Select;

const initialValues = {
  tipofiltro: TipoFiltroBusquedaComprobanteConstants.FECHA_EMISION,
  fechaemision: dayjs(),
};
// const validationSchema = Yup.object().shape({
//   fechaemision: Yup.string().when("tipofiltro", {
//     is: TipoFiltroBusquedaComprobanteConstants.FECHA_EMISION,
//     then: Yup.string()
//       .required("La Fecha de emisión es obligatorio")
//       .nullable(),
//   }),
// });
export const ResumenSearchFormTable = () => {
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    const { tipofiltro, fechaemision, cliente, estadocomprobante } = values;
    const _fechaemision = dayjs(fechaemision).format("YYYY-MM-DD");
    // dispatch(
    //   comprobantespagoStartLoad(
    //     tipofiltro,
    //     _fechaemision,
    //     cliente,
    //     estadocomprobante
    //   )
    // );
  };
  return (
    <FormUi
      name="advanced_search"
      className="ant-advanced-search-form"
      labelCol={{
        span: 5,
      }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item name="tipofiltro" label="Filtrar por">
            <Select name="tipofiltro" placeholder="Filtrar por">
              <Option key="1" value="FEMI">
                Fecha de Emisión
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12} md={8} lg={4} offset={1}>
          <Form.Item name="fechaemision">
            <DatePicker name="fechaemision" format={"DD-MM-YYYY"} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
    </FormUi>
  );
};
