import React from "react";
import { EstadoProgramacionTejido } from "../../../../types/typesConstants";
import { Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const EstadoProgramacionTag = ({ estado, estado_nombre = "NUEVO" }) => {
  if (estado === EstadoProgramacionTejido.PROCESO) {
    return (
      <Tag icon={<SyncOutlined spin />} color="processing">
        {estado_nombre}
      </Tag>
    );
  } else if (estado === EstadoProgramacionTejido.CUMPLIDO) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="success">
        {estado_nombre}
      </Tag>
    );
  } else if (estado === EstadoProgramacionTejido.CANCELADO) {
    return (
      <Tag icon={<CloseCircleOutlined />} color="error">
        {estado_nombre}
      </Tag>
    );
  } else {
    return (
      <Tag icon={<PlusCircleOutlined />} color="cyan">
        {estado_nombre}
      </Tag>
    );
  }
};
