import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_locales } from "../../../actions/local";

export const LocalSelect = ({ ...props }) => {
  const [locales, setLocales] = useState([]);
  const OnLoadLocales = async () => {
    const datos = await get_locales();
    setLocales(datos);
  };
  useEffect(() => {
    OnLoadLocales();
  }, []);

  return <SelectUi data={locales} {...props} />;
};
