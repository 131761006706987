export const types = {
  productosInventarioLoaded: "[Inventario] Productos Inventario Loaded",
  inventarioSetActive: "[Inventario] Inventario de producto Set Active",
  inventarioClearActive: "[Inventario] Inventario de producto Clear Active",
  inventarioInserted: "[Inventario] Inventario de producto Inserted",
  inventarioUpdated: "[Inventario] Inventario de producto Updated",
  inventariosInserted: "[Inventario] Inventarios de producto Inserted",
  inventariosUpdated: "[Inventario] Inventarios de producto Updated",
  movimientoEntradaAddNew: "[Inventario] Movimiento Entrada Add New",
  inventarioShowModalEgreso: "[Inventario] Show or Close Modal Egreso",
  inventarioShowModalIngreso: "[Inventario] Show or Close Modal Ingreso",
  inventarioShowModalRemover: "[Inventario] Show or Close Modal Remover",
  inventarioShowFormInput: "[Inventario] Show or Close Form Input movements",
  inventarioShowFormOutput: "[Inventario] Show or Close Form Output movements",
  inventarioShowFormRemove: "[Inventario] Show or Close Form Remove movements",
  inventarioSetQuantitySelectSeries: "[Inventario] Set Quantity Select Series",
  inventarioShowFormTransfer:
    "[Inventario] Show or Close Form Transfer movements",
  movimientoTrasladoInventarioInserted:
    "[MovimientosTrasladoInventario] Movimiento Traslado Inventario Inserted",
  movimientoSetActive:
    "[MovimientosInventario] Movimiento de producto Set Active",
  movimientoClearActive:
    "[MovimientosInventario] Movimiento de producto Clear Active",
  movimientoShowModalDetalle:
    "[MovimientosInventario] Show or Close Modal Detalle Movimiento",
  comprobantesMovimientoLoaded:
    "[ComprobanteMovimiento] Comprobantes Movimiento Loaded",
  comprobanteMovimientoInserted:
    "[ComprobanteMovimiento] Comprobantes Movimiento Inserted",
  comprobanteMovimientoUpdated:
    "[ComprobanteMovimiento] Comprobantes Movimiento Updated",
  comprobanteMovimientoClearActive:
    "[ComprobanteMovimiento] Comprobante Movimiento Clear Active",
  comprobanteMovimientoSetActive:
    "[ComprobanteMovimiento]  Comprobante Movimiento Set Active",

  detalleComprobanteMovimientoSetActive:
    "[ComprobanteMovimiento] Detalle Comprobante Movimiento Set Active",
  detalleComprobanteMovimientoClearActive:
    "[ComprobanteMovimiento] Detalle Comprobante Movimiento Clear Active",
  comprobantesMovimientoInitialize:
    "[ComprobanteMovimiento] Comprobantes Movimiento Initialize",
};
