import { Col, Row, Form, DatePicker, Space } from "antd";
import React from "react";
import dayjs from "dayjs";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { movimientosTrasladoStartLoading } from "../../../actions/inventario";
import { useDispatch } from "react-redux";
import { FormUi } from "../../ui/FormUi";
const initialValues = {
  fechaemision: dayjs(),
};
export const MovimientoTrasladoSearchForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    const fechaemision = dayjs(values?.fechaemision).format("YYYY-MM-DD");
    dispatch(movimientosTrasladoStartLoading(fechaemision));
    localStorage.setItem("fechaemision", fechaemision);
  };

  return (
    <FormUi
      name="FormMovimientoTraslado"
      form={form}
      layout="horizontal"
      autoComplete="off"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      className="ant-advanced-search-form"
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Space wrap>
            <Form.Item
              name="fechaemision"
              label="Fecha de Emisión"
              rules={[
                {
                  required: true,
                  message: "La Fecha de emisión es obligatorio",
                },
              ]}
            >
              <DatePicker name="fechaemision" format={"DD-MM-YYYY"} />
            </Form.Item>
            <div style={{ paddingBottom: "14px" }}>
              <ButtonUi
                htmlType="submit"
                typeUi={TipoButtonConstants.Filtrar}
              />
            </div>
          </Space>
        </Col>
      </Row>
    </FormUi>
  );
};
