import { Col, Form, Row } from "antd";
import React from "react";
import { DetalleSeriesTable } from "./DetalleSeriesTable";

export const DetalleEntradaSerieForm = ({ cantidad }) => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={20} lg={16} xl={16}>
        <Form.List
          name="series"
          rules={[
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (value.length !== cantidad)
                  return Promise.reject(
                    new Error(
                      "Las series deben ser igual a la cantidad ingresada"
                    )
                  );
              },
            }),
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <DetalleSeriesTable
                data={fields}
                handleDelete={remove}
                handleAdd={add}
              />
              <Form.Item className="ui-errorlist">
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};
