import { DatePicker, Form, Input, Space, Tooltip } from "antd";
import React from "react";
import { TableUi } from "../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";

const columns = (props) => {
  const { handleEliminar, handleAgregar } = props;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Serie",
      dataIndex: "numero_serie",
      key: "numero_serie",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "numero_serie"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "El número de serie es obligatorio",
            },
          ]}
        >
          <Input
            placeholder="Ingrese el número de serie"
            onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      ),
    },
    {
      title: "Fecha fabricación",
      dataIndex: "fecha_fabricacion",
      key: "fecha_fabricacion",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "fecha_fabricacion"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "La fecha de fabricación es obligatorio",
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      ),
    },

    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, field) => (
        <Space size="middle">
          <Tooltip title="Agregar">
            <ButtonUi
              typeUi={TipoButtonConstants.AgregarTable}
              onClick={() => handleAgregar()}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleEliminar(field)}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};

export const DetalleSeriesTable = ({ data, handleDelete, handleAdd }) => {
  const handleAgregar = () => {
    handleAdd({ numero_serie: "", fecha_fabricacion: null });
  };
  const handleEliminar = (field) => {
    handleDelete(field?.name);
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEliminar, handleAgregar })}
      size="small"
    />
  );
};
