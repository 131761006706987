import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  empleadoSetActive,
  empleadosStartLoad,
  empleadoStartDelete,
} from "../../../actions/empleado";
import { uiShowForm } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import dayjs from "dayjs";
import { TableSearchBasicUi } from "./../../ui/TableSearchBasicUi";

const columns = ({ filteredColumn, handleEditar, handleEliminar }) => [
  {
    title: "N°",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombrecompleto",
    key: "nombrecompleto",
    filteredValue: [filteredColumn],
    onFilter: (value, record) =>
      String(record?.nombrecompleto)
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      String(record?.nrodocumento)
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      String(record?.direccion).toLowerCase().includes(value.toLowerCase()),
  },

  {
    title: "T.Documento",
    dataIndex: "nombretipodocumento",
    key: "nombretipodocumento",
  },
  {
    title: "N°Documento",
    dataIndex: "nrodocumento",
    key: "nrodocumento",
  },
  {
    title: "Fec.Nacimiento",
    dataIndex: "fechanacimiento",
    key: "fechanacimiento",
    render: (text, record, index) => (
      <>{dayjs(text).format(DateFormats.frontend)}</>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Telefono",
    dataIndex: "telefono",
    key: "telefono",
  },
  {
    title: "Dirección",
    dataIndex: "direccion",
    key: "direccion",
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <Space size="middle">
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          htmlType="button"
          onClick={() => handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          onClick={() => handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const EmpleadoTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const handleEditar = (data) => {
    dispatch(empleadoSetActive(data));
    dispatch(uiShowForm());
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el empleado seleccionado?",
      `${item.nombres}, ${item.apepaterno} ${item.apematerno}`,
      (isConfirmed) => {
        if (isConfirmed) {
          const itemDelete = { id: item?.empleadoid };
          dispatch(empleadoStartDelete(itemDelete));
        }
      }
    );
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({
        filteredColumn: searchText,
        handleEditar,
        handleEliminar,
      })}
      rowKey="id"
      bordered
      data={data}
      onSearch={onSearch}
      loading={loading}
      size="small"
    />
  );
};
