import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../ui/ModalUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { Form } from "antd";
import { FormUi } from "../../ui/FormUi";
import { ItemDetalleForm } from "./ItemDetalleForm";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { detallecomprobantecompraClearActive } from "../../../actions/compra";

const initialValues = (data = {}) => ({
  id: data?.id || new dayjs().valueOf(),
  item: data?.item || null,
  descripcion: data?.descripcion || null,
  tipoafectacionigv: data?.tipoafectacionigv || null,
  tipoafectacionigv_nombre: data?.tipoafectacionigv_nombre || null,
  almacen: data?.almacen || null,
  almacen_nombre: data?.almacen_nombre || null,
  cantidad: parseInt(data?.cantidad) || 1,
  preciounitario: data?.preciounitario || null,
  valorunitario: data?.valorunitario || null,
  valorigvunitario: data?.valorigvunitario || 0,
  porcentajedisplay: data?.porcentajedisplay || 0,
  porcentajeimpuesto: data?.porcentajeimpuesto || 0,
  valorigv: data?.valorigv || 0,
  unidadmedida: data?.unidadmedida || null,
  unidadmedida_nombre: data?.unidadmedida_nombre || null,
  descuentos: data?.descuentos || 0,
  grupotipoafectacionigv: data?.grupotipoafectacionigv || "Ope. Gravada",
  disabledafectacionigv: data?.disabledafectacionigv || false,
});
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({
  form,
  open,
  activeDetalleComprobanteCompra,
}) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    form.resetFields();
    if (!open && prevOpen) {
      dispatch(detallecomprobantecompraClearActive());
    } else {
      //Abrir Modal
      if (activeDetalleComprobanteCompra) {
        //entro editar
        form.setFieldsValue({
          ...activeDetalleComprobanteCompra,
          cantidad: parseInt(activeDetalleComprobanteCompra.cantidad),
        });
      } else {
        //entro nuevo
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const ItemDetalleModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();

  const { activeDetalleComprobanteCompra } = useSelector(
    (state) => state.compra
  );
  const { activeEmpleado } = useSelector((state) => state.auth);
  useResetFormOnCloseModal({ form, open, activeDetalleComprobanteCompra });
  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        porcentajedisplay:
          "IGV " +
          parseFloat(activeEmpleado?.empresa?.porcentajeigv).toFixed(0) +
          " %",
        porcentajeimpuesto: activeEmpleado?.empresa?.porcentajeigv,
      });
    }
  }, [form, open]);
  return (
    <ModalUi
      title="Busqueda de producto/servicio"
      open={open}
      onCancel={onCancelModal}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="ItemDetalleForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="ItemDetalleForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeDetalleComprobanteCompra)}
      >
        <ItemDetalleForm />
      </FormUi>
    </ModalUi>
  );
};
