import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_terminalescajero } from "../../../actions/terminalcajero";

export const TerminalCajeroSelect = ({ ...props }) => {
  const [terminalescajero, setTerminalescajero] = useState([]);
  const OnLoadTerminalesCajero = async () => {
    const datos = await get_terminalescajero();
    setTerminalescajero(datos);
  };
  useEffect(() => {
    OnLoadTerminalesCajero();
  }, []);

  return <SelectUi data={terminalescajero} {...props} />;
};
