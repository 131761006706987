import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msg";
const router = {
  colores: "colores/",
};
export const coloresStartLoad = () => {
  return async (dispatch) => {
    dispatch(colorLoading());
    try {
      const resp = await axiosConToken.get(router.colores);
      const data = resp.data;
      dispatch(colorLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(colorLoading(false));
    }
  };
};
export const colorStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(router.colores, data);
      const { msg, color } = resp.data;
      dispatch(colorAdded(color));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const colorStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${router.colores}${data.id}/`,
        data
      );
      const { msg, color } = resp.data;
      dispatch(colorUpdated(color));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const colorStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`colores/${data.id}/`);
      const { msg } = resp.data;
      dispatch(colorDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const colorLoaded = (colores) => ({
  type: types.coloresLoaded,
  payload: colores,
});
const colorAdded = (color) => ({ type: types.colorAdded, payload: color });
const colorUpdated = (color) => ({
  type: types.colorUpdated,
  payload: color,
});
const colorDeleted = (color) => ({
  type: types.colorDeleted,
  payload: color,
});
export const colorSetActive = (color) => ({
  type: types.colorSetActive,
  payload: color,
});
export const colorClearActive = () => ({ type: types.colorClearActive });
export const colorLoading = (loading = true) => ({
  type: types.colorLoading,
  payload: loading,
});

export const get_colores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("colores/get_colores");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_prefijoSunat = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${router.colores}get_prefijosunat/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
