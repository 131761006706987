import { types } from "../types/typesGenerales";

const initialState = {
  clientes: [],
  activeCliente: null,
  loading: false,
};
export const clienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.clienteLoaded:
      return { ...state, clientes: action.payload };
    case types.clienteAdded:
      return {
        ...state,
        clientes: [...state.clientes, action.payload],
      };
    case types.clienteSetActive:
      return {
        ...state,
        activeCliente: action.payload,
      };
    case types.clienteClearActive:
      return {
        ...state,
        activeCliente: null,
      };
    case types.clienteUpdated:
      return {
        ...state,
        clientes: state.clientes.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.clienteDeleted:
      return {
        ...state,
        clientes: state.clientes.filter((e) => e.id !== action.payload.id),
        activeCliente: null,
      };
    case types.clienteLoading:
      return { ...state, loading: action.payload };
    case types.clienteLogout:
      return { ...initialState };
    default:
      return state;
  }
};
