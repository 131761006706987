import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { InventarioProductoTable } from "./InventarioProductoTable";
import { InventarioSeachForm } from "./InventarioSeachForm";

export const InventarioSearch = () => {
  const { inventariosProducto } = useSelector((state) => state.inventario);
  const { loading } = useSelector((state) => state.ui);
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <InventarioSeachForm />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <InventarioProductoTable
            data={inventariosProducto}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};
