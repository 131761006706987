import React, { useEffect, useState } from "react";
import { get_tipocomprobantepagos_by_terminalcajero } from "../../../../actions/comprobante";
import { SelectUi } from "../../../ui/SelectUi";

export const TipoComprobanteSelect = ({ terminalcajero = 0, ...props }) => {
  const [tipocomprobantes, setTipocomprobantes] = useState([]);
  useEffect(() => {
    const OnLoadTipoComprobantes = async (terminalcajero) => {
      const datos = await get_tipocomprobantepagos_by_terminalcajero(
        terminalcajero
      );
      setTipocomprobantes(datos);
    };
    OnLoadTipoComprobantes(terminalcajero);
    return () => {
      setTipocomprobantes([]);
    };
  }, [terminalcajero]);

  return <SelectUi data={tipocomprobantes} {...props} />;
};
