import React, { useState } from "react";
import { Form } from "antd";
import { TableSearchBasicUi } from "./../../../../ui/TableSearchBasicUi";
const columns_table = (acciones) => [
  {
    title: "Item producido",
    dataIndex: "descripcion",
    key: "descripcion",
    fixed: "left",
    width: "40%",
    filteredValue: [acciones?.filteredColumn],
    onFilter: (value, record) =>
      String(record.partida).toLowerCase().includes(value.toLowerCase()) ||
      String(record.descripcion).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Almacén destino",
    dataIndex: "almacen_nombre",
    key: "almacen_nombre",
    width: "20%",
  },
  {
    title: "Partida",
    dataIndex: "partida",
    key: "partida",
    align: "center",
  },

  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    align: "center",
  },
  {
    title: "Peso (KG)",
    dataIndex: "pesoneto",
    key: "pesoneto",
  },
];

export const SearchFormProgramacionTintoreriaTable = ({ data, loading }) => {
  const form = Form.useFormInstance();
  const [searchText, setSearchText] = useState("");

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      form.setFieldValue("detalleprogramaciontintoreria", selectedRows);
    },
  };
  const onSearch = (value, e) => {
    e.preventDefault();
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      rowSelection={rowSelection}
      columns={columns_table({ filteredColumn: searchText })}
      rowKey="id"
      dataSource={data}
      onSearch={onSearch}
      size="small"
    />
  );
};
