import { Dropdown } from "antd";
import React from "react";
import { ButtonUi } from "./ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";

export const ActionsDropdownUi = ({ menu }) => {
  return (
    <Dropdown
      menu={menu}
      placement="bottomRight"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <ButtonUi
        typeUi={TipoButtonConstants.OpcionesTable}
        displayText={false}
      />
    </Dropdown>
  );
};
