import { Button, Col, Form, Row, Steps, Tabs, message, theme } from "antd";
import React, { useState } from "react";
import { CardUi } from "../../../ui/CardUi";
import { UnorderedListOutlined } from "@ant-design/icons";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { TareaProduccionDrawer } from "./modal/TareaProduccionDrawer";
import { ProcesoProduccionDetalleTable } from "./ProcesoProduccionDetalleTable";
import { FinalizarTareaDrawer } from "./modal/FinalizarTarea/FinalizarTareaDrawer";

const items = [
  {
    label: "Union de hombros",
    key: "unionhombros",
    children: "First-content",
  },
  {
    label: "Pegado de cuellos",
    key: "pegadocuellos",
    children: "Second-content",
  },
  {
    label: "Tapete de cuellos",
    key: "tapete",
    children: "Last-content",
  },
];
export const TareasProduccionSteps = () => {
  const form = Form.useFormInstance();
  const [open, setOpen] = useState(false);
  const [openDrawerFinalizar, setOpenDrawerFinalizar] = useState(false);
  const detallerutaproduccion = Form.useWatch("detallerutaproduccion", form);
  const detalleprocesoproduccion = Form.useWatch(
    "detalleprocesoproduccion",
    form
  );

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const handleOpenFinalizarDrawer = () => setOpenDrawerFinalizar(true);
  const handleCloseFinalizarDrawer = () => setOpenDrawerFinalizar(false);
  return (
    <CardUi
      title={
        <span>
          <UnorderedListOutlined /> Detalle proceso producción
        </span>
      }
      size="small"
      extra={
        detallerutaproduccion?.length > 0 && (
          <ButtonUi
            typeUi={TipoButtonConstants.AgregarTable}
            onClick={handleOpenModal}
          />
        )
      }
    >
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.List
            name="detalleprocesoproduccion"
            rules={[
              {
                validator: async (_, value) => {
                  if (value < 1)
                    return Promise.reject(
                      new Error(
                        "Ingrese al menos un item en el detalle de proceso de producción"
                      )
                    );
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                <ProcesoProduccionDetalleTable
                  detalleprocesoproduccion={detalleprocesoproduccion}
                  handleDelete={remove}
                  handleOpenDrawerTarea={handleOpenModal}
                  handleOpenDrawerFinalizar={handleOpenFinalizarDrawer}
                />
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <TareaProduccionDrawer
        detallerutaproduccion={detallerutaproduccion}
        open={open}
        onCancelModal={handleCloseModal}
      />
      <FinalizarTareaDrawer
        open={openDrawerFinalizar}
        onCancelDrawer={handleCloseFinalizarDrawer}
      />
    </CardUi>
  );
};
