import { Form } from "antd";
import React, { useState } from "react";
import { SalidaForm } from "./SalidaForm";
import {
  movimientoSalidaStartAdd,
  showFormOutputMovements,
} from "../../../../actions/inventario";
import { useDispatch, useSelector } from "react-redux";
import { FormUi } from "../../../ui/FormUi";
import { DetalleSalidaModal } from "./DetalleSalidaModal";

const initialValues = {
  almacenorigen: null,
  inventario: null,
  cantidad: "",
  tipomovimiento: null,
  series: [],
  lotes: [],
  manejalotes: false,
  manejaseries: false,
  unidadmedida: null,
};

export const SalidaScreen = () => {
  const [form] = Form.useForm();
  const { manejalotes, manejaseries, cantidad } = form.getFieldsValue();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleSave = (values) => {
    const valuesLotes = values?.lotes
      ?.filter((e) => e.cantidad_ingresada !== null)
      .map((e) => ({
        ...e,
        cantidad: e.cantidad_ingresada,
      }));
    const valuesData = {
      empresa: activeEmpleado?.empresa?.id,
      almacenorigen: values?.almacenorigen,
      almacendestino: values?.almacenorigen,
      tipomovimiento: values?.tipomovimiento,
      detallescomprobantemovimiento: [
        {
          inventario: values?.inventario,
          cantidad: values?.cantidad,
          unidadmedida: values?.unidadmedida,
          lotes: valuesLotes,
          series: values?.series?.filter((item) => item.selected === true),
        },
      ],
    };
    dispatch(movimientoSalidaStartAdd(valuesData));
    dispatch(showFormOutputMovements(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === "FormDetalleLoteSerieSalida") {
          const { FormMovimientoSalida } = forms;
          FormMovimientoSalida.setFieldValue("lotes", values?.lotes);
          FormMovimientoSalida.setFieldValue("series", values?.series);
        }
        setOpen(false);
      }}
    >
      <FormUi
        name="FormMovimientoSalida"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <SalidaForm onOpenModal={showModal} />
      </FormUi>
      <DetalleSalidaModal
        series={form.getFieldValue("series")}
        lotes={form.getFieldValue("lotes")}
        manejalotes={manejalotes}
        manejaseries={manejaseries}
        cantidad={cantidad}
        open={open}
        onCancel={hideModal}
      />
    </Form.Provider>
  );
};
