import React, { useState } from "react";
import { TableUi } from "../../../ui/TableUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Form, InputNumber, Space, Tag, Tooltip } from "antd";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch } from "react-redux";
import { productocarritoSetActive } from "../../../../actions/venta";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DetalleSalidaModal } from "./modal/DetalleSalidaModal";
const columns = (acciones) => {
  const { handleAdd, OnChangeCantidad, cantidadField, validCantidad } =
    acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Stock",
      dataIndex: "stockactual",
      key: "stockactual",
      align: "center",
      render: (text, record, index) => {
        const _color =
          record?.stockactual >= record?.stockminimo
            ? "green"
            : record?.stockactual === 0
            ? "red"
            : "gold";
        return (
          <Tag color={_color}>
            <b> {parseFloat(record?.stockactual).toFixed(2)}</b>
          </Tag>
        );
      },
    },
    {
      title: "Precio",
      dataIndex: "precioventa",
      key: "precioventa",
      align: "right",
      render: (text, record, index) => <>{parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (text, record, index) => {
        const valida = validCantidad === index ? false : true;
        const cantidad =
          cantidadField?.index === index ? cantidadField?.cantidad : null;
        return (
          <Form.Item
            validateStatus={valida ? "validating" : "error"}
            help={!valida ? "Ingrese cantidad valida" : ""}
            style={{ marginBottom: "0px" }}
          >
            <InputNumber
              size="small"
              value={cantidad}
              onChange={(value) => OnChangeCantidad(value, index)}
              min={1}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "accciones",
      key: "accciones",
      render: (text, record, index) => (
        <>
          <Space size="middle">
            <Tooltip title="Agregar">
              <ButtonUi
                type="primary"
                size="small"
                typeUi={TipoButtonConstants.CustomButton}
                icon={<PlusCircleOutlined />}
                onClick={() => handleAdd(record, index)}
                text="Añadir"
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
};
export const ProductosTable = ({ productos, loading }) => {
  const form = Form.useFormInstance();
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [cantidadField, setCantidadField] = useState({
    cantidad: "",
    index: -1,
  });
  const [validCantidad, setValidCantidad] = useState(-1);
  const [itemInventario, setItemInventario] = useState(null);
  const OnChangeCantidad = (value, index) =>
    setCantidadField({ cantidad: value, index });
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    setCantidadField({
      cantidad: "",
      index: -1,
    });
  };
  const handleAdd = (producto, index) => {
    const cantidad =
      cantidadField?.index === index ? cantidadField?.cantidad : null;
    if (cantidad === "" || cantidad === null) setValidCantidad(index);
    else {
      //Validado
      setValidCantidad(-1);

      let productoscarrito = detallecomprobante || [];
      const bExiste =
        productoscarrito?.findIndex((item) => item?.id === producto?.id) === -1
          ? false
          : true;
      if (!bExiste) {
        if (producto?.manejalotes || producto?.manejaseries) {
          setItemInventario(producto);
          showModal();
        } else {
          dispatch(
            productocarritoSetActive({
              ...producto,
              cantidad,
              totalventa: parseFloat(
                parseFloat(cantidad * producto.precioventa).toFixed(2)
              ),
            })
          );
          setCantidadField({
            cantidad: null,
            index: -1,
          });
        }
      }
    }
  };
  return (
    <>
      <TableUi
        rowKey="id"
        size="small"
        scroll={{ x: 550 }}
        columns={columns({
          OnChangeCantidad,
          validCantidad,
          cantidadField,
          handleAdd,
        })}
        data={productos}
        loading={loading}
      />
      <DetalleSalidaModal
        cantidad={cantidadField?.cantidad}
        open={open}
        onCancel={hideModal}
        producto={itemInventario}
      />
    </>
  );
};
