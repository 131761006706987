export const types = {
  empleadoLoaded: "[empleado] empleados Loaded",
  empleadoSetActive: "[empleado] empleado Set Active",
  empleadoClearActive: "[empleado] empleado Clear Active",
  empleadoAdded: "[empleado] empleado added",
  empleadoUpdated: "[empleado] empleado updated",
  empleadoDeleted: "[empleado] empleado deleted",
  empleadoLogout: "[empleado] empleado logout",
  empleadoLoading: "[empleado] empleado loading",
};
