import { types } from "../types/typesGenerales";

const initialState = {
  colores: [],
  activeColor: null,
  loading: false,
};

export const colorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.coloresLoaded:
      return { ...state, colores: action.payload };
    case types.colorAdded:
      return {
        ...state,
        colores: [action.payload, ...state.colores],
      };
    case types.colorSetActive:
      return {
        ...state,
        activeColor: action.payload,
      };
    case types.colorClearActive:
      return {
        ...state,
        activeColor: null,
      };
    case types.colorUpdated:
      return {
        ...state,
        colores: state.colores.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.colorDeleted:
      return {
        ...state,
        colores: state.colores.filter((e) => e.id !== action.payload.id),
        activeColor: null,
      };
    case types.colorLoading:
      return { ...state, loading: action.payload };
    case types.colorLogout:
      return { ...initialState };
    default:
      return state;
  }
};
