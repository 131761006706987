import React from "react";
import { ProveedorPrincipalTabContent } from "./ProveedorPrincipalTabContent";
import { ProveedorDireccionTabContent } from "./ProveedorDireccionTabContent";

export const ProveedorForm = () => {
  return (
    <>
      <ProveedorPrincipalTabContent />
      <ProveedorDireccionTabContent />
    </>
  );
};
