import React from "react";
import { TabsUi } from "./../../ui/TabsUi";
import { ListaPrecioSucursalDetalle } from "./ListaPrecioSucursalDetalle";
import { ListaPrecioItemsTab } from "./ListaPrecioItemsTab";

const items = [
  {
    key: "item",
    label: "Items",
    children: <ListaPrecioItemsTab />,
  },
  {
    key: "sucursal",
    label: "Sucursales",
    children: <ListaPrecioSucursalDetalle />,
  },
];
export const ListaPrecioTabForm = () => {
  return <TabsUi data={items} />;
};
