import React, { useEffect, useState } from "react";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { Col, Row, Space } from "antd";
import { MovimientosCajaTable } from "./MovimientosCajaTable";
import { useDispatch, useSelector } from "react-redux";
import {
  cajaAbiertaSetActive,
  movimientosCajaStartLoad,
} from "../../../../actions/caja";
import { MovimientoCajaDrawer } from "./drawer/MovimientoCajaDrawer";
import { useNavigate } from "react-router-dom";

export const MovimientosCajaList = () => {
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { movimientosCaja } = useSelector((state) => state.caja);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [cajaabierta, setCajaabierta] = useState(
    JSON.parse(localStorage.getItem("caja")) || null
  );
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const OnLoadMovimientos = () => {
    dispatch(movimientosCajaStartLoad(activeEmpleado?.id));
  };
  useEffect(() => {
    OnLoadMovimientos();
  }, []);

  useEffect(() => {
    if (cajaabierta) {
      dispatch(cajaAbiertaSetActive(cajaabierta));
    } else {
      navigate("/caja");
    }
    return () => {
      setCajaabierta(null);
    };
  }, [cajaabierta]);

  const handleReload = () => {
    OnLoadMovimientos();
  };
  //   const handleNuevoMovimiento = () => {showModal()};
  return (
    <>
      <PageHeaderUi
        title="Gastos e Ingresos"
        subTitle="Listado"
        extra={[
          <Space key="options" wrap>
            <ButtonUi
              key="reload"
              typeUi={TipoButtonConstants.Actualizar}
              onClick={handleReload}
            />
            <ButtonUi
              key="nuevo"
              typeUi={TipoButtonConstants.Nuevo}
              onClick={showModal}
            />
          </Space>,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <Row>
            <Col span={24}>
              <MovimientosCajaTable
                handleOpen={showModal}
                movimientoscaja={movimientosCaja}
              />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
      <MovimientoCajaDrawer open={open} handleClose={hideModal} />
    </>
  );
};
export default MovimientosCajaList;
