import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../../actions/ui";
import { ClienteTable } from "./ClienteTable";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { clientesStartLoad } from "../../../actions/cliente";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ClienteScreen } from "./ClienteScreen";

export const ClienteListScreen = () => {
  const dispatch = useDispatch();
  const { clientes, loading } = useSelector((state) => state.cliente);
  const handleNuevo = () => {
    dispatch(uiShowForm());
  };
  const handleActualizar = () => {
    dispatch(clientesStartLoad());
  };
  useEffect(() => {
    dispatch(clientesStartLoad());
  }, []);
  return (
    <PageHeaderUi
      title="Clientes"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
          key="Nuevo"
        />,
      ]}
    >
      <ClienteTable data={clientes} loading={loading} />
    </PageHeaderUi>
  );
};
export const ClienteList = () => {
  const { visibleForm } = useSelector((state) => state.ui);
  if (visibleForm) {
    return <ClienteScreen />;
  } else {
    return <ClienteListScreen />;
  }
};
export default ClienteList;
