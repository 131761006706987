import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlmacenEmpleadoSelect } from "../reusable/AlmacenEmpleadoSelect";
import { AlmacenSelect } from "../reusable/AlmacenSelect";

import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { MovimientoTrasladoFormTable } from "./MovimientoTrasladoFormTable";
import { uiShowForm } from "../../../actions/ui";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";

export const MovimientoTrasladoForm = ({ onOpenModal }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const detallescomprobantemovimiento = Form.useWatch(
    "detallescomprobantemovimiento",
    form
  );
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const handleAdd = () => {
    form
      .validateFields(["almacenorigen"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const HandleCloseForm = () => {
    dispatch(uiShowForm(false));
  };
  return (
    <>
      <Card
        title="Movimiento Traslado"
        size="small"
        extra={
          <Space wrap>
            <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>
              Guardar
            </Button>
            <Tooltip title="Salir">
              <Button
                type="primary"
                danger
                icon={<CloseSquareOutlined />}
                onClick={HandleCloseForm}
              >
                Salir
              </Button>
            </Tooltip>
          </Space>
        }
      >
        <FormItemHiddenUi name="manejalotes" />
        <FormItemHiddenUi name="manejaseries" />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              name="almacenorigen"
              label="Almacén origen"
              rules={[
                { required: true, message: "Seleccione almacen de origen" },
              ]}
            >
              <AlmacenEmpleadoSelect
                empleado={activeEmpleado?.id}
                placeholder="Seleccione almacén origen"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              name="almacendestino"
              label="Almacén destino"
              rules={[
                { required: true, message: "Seleccione almacen destino" },
              ]}
            >
              <AlmacenSelect placeholder="Seleccione almacen destino" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Space>
              <Form.Item name="tieneobservacion" valuePropName="checked">
                <Switch />
              </Form.Item>
              Añadir observación
            </Space>
          </Col>
        </Row>
        {tieneobservacion && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item name="observacion" className="mb-1">
                <Input.TextArea
                  rows={2}
                  placeholder="Ingrese Observación"
                  maxLength={150}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Divider orientation="left" style={{ margin: "6px 0px" }}>
          <b>Detalle de productos</b>
        </Divider>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="detallescomprobantemovimiento"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value < 1)
                      return Promise.reject(
                        new Error(
                          "Ingrese al menos un item en el detalle de movimiento"
                        )
                      );
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <MovimientoTrasladoFormTable
                    detallecomprobantemovimiento={detallescomprobantemovimiento}
                    handleDelete={remove}
                    OpenModal={onOpenModal}
                    title={() => (
                      <ButtonUi
                        typeUi={TipoButtonConstants.AgregarTable}
                        onClick={() => handleAdd()}
                      />
                    )}
                  />
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Card>
    </>
  );
};
