export const types = {
  productoLoaded: "[producto] Productos Loaded",
  productoStatusLoading: "[producto] Productos Status Loading",
  productoSetActive: "[producto] Producto Set Active",
  productoClearActive: "[producto] Producto Clear Active",
  productoStartAddNew: "[producto] Start Producto Add New",
  productoAddNew: "[producto] Producto Add New",
  productoUpdated: "[producto] Producto updated",
  productoDeleted: "[producto] Producto deleted",
  productoPreviewSave: "[producto] Producto preview saved",

  productoShowModalListaPrecios:
    "[producto] Producto show modal lista de precios",

  productoLogout: "[producto] Producto logout",

  productoAtributosAdd: "[producto] Producto Atributos Add",
  productoAtributoSetActive: "[producto] Producto Atributo Set Active",
  productoAtributoClearActive: "[producto] Producto Atributo Clear Active",
  productoVarianteSetActive: "[producto] Producto Variante SetActive",
  productoVarianteClearActive: "[producto] Producto Variante Clear Active",
};
