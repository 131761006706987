import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesProgramacion";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msgproduccion";
const routes = {
  maquina: "produccion/maquina/",
  tareaproduccion: "produccion/tareaproduccion/",
  rutaproduccion: "produccion/rutaproduccion/",
  ordenproduccion: "produccion/ordenproduccion/",
  procesoproduccion: "produccion/procesoproduccion/",
  programaciontejido: "produccion/programaciontejido/",
  programaciontintoreria: "produccion/programaciontintoreria/",
  movimientotraslado: "movimientotraslado/",
  consolidadotela: "produccion/consolidadotela/",
};
//TODO: MAQUINAS
export const maquinaStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.maquina);
      const body = resp.data;
      dispatch(maquinaLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const maquinaStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.maquina, data);
      const body = resp.data;
      const { msg, maquina } = body;
      dispatch(maquinaInserted(maquina));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const maquinaStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.maquina}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, maquina } = body;
      dispatch(maquinaUpdated(maquina));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const maquinaStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`${routes.maquina}${data.id}/`);
      const { msg } = resp.data;
      dispatch(maquinaDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const maquinaLoaded = (data) => ({
  type: types.maquinasLoaded,
  payload: data,
});
const maquinaInserted = (maquina) => ({
  type: types.maquinasInserted,
  payload: maquina,
});
const maquinaUpdated = (maquina) => ({
  type: types.maquinasUpdated,
  payload: maquina,
});
const maquinaDeleted = (maquina) => ({
  type: types.maquinasDeleted,
  payload: maquina,
});
export const maquinaSetActive = (maquina) => ({
  type: types.maquinasSetActive,
  payload: maquina,
});
export const maquinaClearActive = () => ({
  type: types.maquinasClearActive,
});
//TODO: TAREAS DE PRODUCCION
export const tareaProduccionStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.tareaproduccion);
      const body = resp.data;
      dispatch(tareaProduccionLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const tareaProduccionStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.tareaproduccion, data);
      const body = resp.data;
      const { msg, tareaproduccion } = body;
      dispatch(tareaProduccionInserted(tareaproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const tareaProduccionStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.tareaproduccion}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, tareaproduccion } = body;
      dispatch(tareaProduccionUpdated(tareaproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const tareaProduccionStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.tareaproduccion}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(tareaProduccionDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const tareaProduccionLoaded = (data) => ({
  type: types.tareasProduccionLoaded,
  payload: data,
});
const tareaProduccionInserted = (tareaproduccion) => ({
  type: types.tareasProduccionInserted,
  payload: tareaproduccion,
});
const tareaProduccionUpdated = (tareaproduccion) => ({
  type: types.tareasProduccionUpdated,
  payload: tareaproduccion,
});
const tareaProduccionDeleted = (tareaproduccion) => ({
  type: types.tareasProduccionDeleted,
  payload: tareaproduccion,
});
export const tareaProduccionSetActive = (tareaproduccion) => ({
  type: types.tareasProduccionSetActive,
  payload: tareaproduccion,
});
export const tareaProduccionClearActive = () => ({
  type: types.tareasProduccionClearActive,
});

//TODO: RUTAS DE PRODUCCION
export const rutaProduccionStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.rutaproduccion);
      const body = resp.data;
      dispatch(rutaProduccionLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const rutaProduccionStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.rutaproduccion, data);
      const body = resp.data;
      const { msg, rutaproduccion } = body;
      dispatch(rutaProduccionInserted(rutaproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const rutaProduccionStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.rutaproduccion}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, rutaproduccion } = body;
      dispatch(rutaProduccionUpdated(rutaproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const rutaProduccionStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.rutaproduccion}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(rutaProduccionDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const rutaProduccionLoaded = (data) => ({
  type: types.rutasProduccionLoaded,
  payload: data,
});
const rutaProduccionInserted = (rutaproduccion) => ({
  type: types.rutasProduccionInserted,
  payload: rutaproduccion,
});
const rutaProduccionUpdated = (rutaproduccion) => ({
  type: types.rutasProduccionUpdated,
  payload: rutaproduccion,
});
const rutaProduccionDeleted = (rutaproduccion) => ({
  type: types.rutasProduccionDeleted,
  payload: rutaproduccion,
});
export const rutaProduccionSetActive = (rutaproduccion) => ({
  type: types.rutasProduccionSetActive,
  payload: rutaproduccion,
});
export const rutaProduccionClearActive = () => ({
  type: types.rutasProduccionClearActive,
});
export const showFormRutaProduccion = (visible = true) => ({
  type: types.rutasProduccionShowForm,
  payload: visible,
});

//TODO: ORDENES DE PRODUCCION
export const ordenProduccionStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.ordenproduccion);
      const body = resp.data;
      dispatch(ordenProduccionLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const ordenProduccionStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.ordenproduccion, data);
      const body = resp.data;
      const { msg, ordenproduccion } = body;
      dispatch(ordenProduccionInserted(ordenproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const ordenProduccionStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.ordenproduccion}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, ordenproduccion } = body;
      dispatch(ordenProduccionUpdated(ordenproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const ordenProduccionStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.ordenproduccion}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(ordenProduccionDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const ordenProduccionLoaded = (data) => ({
  type: types.ordenesProduccionLoaded,
  payload: data,
});
const ordenProduccionInserted = (ordenproduccion) => ({
  type: types.ordenesProduccionInserted,
  payload: ordenproduccion,
});
const ordenProduccionUpdated = (ordenproduccion) => ({
  type: types.ordenesProduccionUpdated,
  payload: ordenproduccion,
});
const ordenProduccionDeleted = (ordenproduccion) => ({
  type: types.ordenesProduccionDeleted,
  payload: ordenproduccion,
});
export const ordenProduccionSetActive = (ordenproduccion) => ({
  type: types.ordenesProduccionSetActive,
  payload: ordenproduccion,
});
export const ordenProduccionClearActive = () => ({
  type: types.ordenesProduccionClearActive,
});
export const showFormOrdenProduccion = (visible = true) => ({
  type: types.ordenesProduccionShowForm,
  payload: visible,
});

//TODO: PROCESOS PRODUCCION

export const procesoProduccionStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.procesoproduccion);
      const body = resp.data;
      dispatch(procesoProduccionLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const procesoProduccionStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.procesoproduccion, data);
      const body = resp.data;
      const { msg, procesoproduccion } = body;
      dispatch(procesoProduccionInserted(procesoproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const procesoProduccionStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.procesoproduccion}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, procesoproduccion } = body;
      dispatch(procesoProduccionUpdated(procesoproduccion));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const procesoProduccionStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.procesoproduccion}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(procesoProduccionDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const procesoProduccionLoaded = (data) => ({
  type: types.procesosProduccionLoaded,
  payload: data,
});
const procesoProduccionInserted = (procesoproduccion) => ({
  type: types.procesosProduccionInserted,
  payload: procesoproduccion,
});
const procesoProduccionUpdated = (procesoproduccion) => ({
  type: types.procesosProduccionUpdated,
  payload: procesoproduccion,
});
const procesoProduccionDeleted = (procesoproduccion) => ({
  type: types.procesosProduccionDeleted,
  payload: procesoproduccion,
});
export const procesoProduccionSetActive = (procesoproduccion) => ({
  type: types.procesosProduccionSetActive,
  payload: procesoproduccion,
});
export const procesoProduccionClearActive = () => ({
  type: types.procesosProduccionClearActive,
});
export const showFormProcesoProduccion = (visible = true) => ({
  type: types.procesosProduccionShowForm,
  payload: visible,
});
export const detalleProcesoProduccionSetActive = (detalle) => ({
  type: types.detalleProcesoProduccionSetActive,
  payload: detalle,
});
export const detalleProcesoProduccionClearActive = () => ({
  type: types.detalleProcesoProduccionClearActive,
});
export const programacionStatusLoading = (loading = true) => ({
  type: types.dataLoading,
  payload: loading,
});
export const programacionTejidoStartLoading = () => {
  return async (dispatch) => {
    dispatch(programacionStatusLoading());
    try {
      const resp = await axiosConToken.get(routes.programaciontejido);
      const body = resp.data;
      dispatch(programacionTejidoLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(programacionStatusLoading(false));
    }
  };
};
export const programacionTejidoStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.programaciontejido, data);
      const body = resp.data;
      const { msg, programaciontejido } = body;
      dispatch(programacionTejidoInserted(programaciontejido));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const programacionTejidoStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.programaciontejido}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, programaciontejido } = body;
      dispatch(programacionTejidoUpdated(programaciontejido));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const programacionTejidoStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.programaciontejido}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(programacionTejidoDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};

export const programacionTintoreriaStartLoading = () => {
  return async (dispatch) => {
    dispatch(programacionStatusLoading());
    try {
      const resp = await axiosConToken.get(routes.programaciontintoreria);
      const body = resp.data;
      dispatch(programacionTintoreriaLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(programacionStatusLoading(false));
    }
  };
};
export const programacionTintoreriaStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.programaciontintoreria,
        data
      );
      const body = resp.data;
      const { msg, programaciontintoreria } = body;
      dispatch(programacionTintoreriaInserted(programaciontintoreria));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const programacionTintoreriaStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.programaciontintoreria}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, programaciontintoreria } = body;
      dispatch(programacionTintoreriaUpdated(programaciontintoreria));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const programacionTintoreriaStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.programaciontintoreria}${data.id}/`
      );
      const { msg } = resp.data;
      dispatch(programacionTintoreriaDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};

const programacionTejidoLoaded = (programaciones) => ({
  type: types.programacionTejidoLoaded,
  payload: programaciones,
});
const programacionTejidoInserted = (programaciontejido) => ({
  type: types.programacionTejidoInserted,
  payload: programaciontejido,
});
const programacionTejidoUpdated = (programaciontejido) => ({
  type: types.programacionTejidoUpdated,
  payload: programaciontejido,
});
const programacionTejidoDeleted = (programaciontejido) => ({
  type: types.programacionTejidoDeleted,
  payload: programaciontejido,
});
export const detalleProgramacionTejidoSetActive = (detalle) => ({
  type: types.detalleProgramacionTejidoSetActive,
  payload: detalle,
});
export const detalleProgramacionTejidoClearActive = () => ({
  type: types.detalleProgramacionTejidoClearActive,
});
export const programacionTejidoSetActive = (programaciontejido) => ({
  type: types.programacionTejidoSetActive,
  payload: programaciontejido,
});
export const programacionTejidoClearActive = () => ({
  type: types.programacionTejidoClearActive,
});

export const showFormProgramacionTejido = (visible = true) => ({
  type: types.produccionShowFormProgramacionTejido,
  payload: visible,
});

const programacionTintoreriaLoaded = (programaciones) => ({
  type: types.programacionTintoreriaLoaded,
  payload: programaciones,
});
const programacionTintoreriaInserted = (programacion) => ({
  type: types.programacionTintoreriaInserted,
  payload: programacion,
});
const programacionTintoreriaUpdated = (programacion) => ({
  type: types.programacionTintoreriaUpdated,
  payload: programacion,
});
const programacionTintoreriaDeleted = (programacion) => ({
  type: types.programacionTintoreriaDeleted,
  payload: programacion,
});
export const programacionTintoreriaSetActive = (programacion) => ({
  type: types.programacionTintoreriaSetActive,
  payload: programacion,
});
export const programacionTintoreriaClearActive = () => ({
  type: types.programacionTintoreriaClearActive,
});
export const detalleProgramacionTintoreriaSetActive = (detalle) => ({
  type: types.detalleProgramacionTintoreriaSetActive,
  payload: detalle,
});
export const detalleProgramacionTintoreriaClearActive = () => ({
  type: types.detalleProgramacionTintoreriaClearActive,
});
export const showFormProgramacionTintoreria = (visible = true) => ({
  type: types.produccionShowFormProgramacionTintoreria,
  payload: visible,
});
export const consolidadoTelaStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.consolidadotela, data);
      const body = resp.data;
      const { msg, programaciontintoreria } = body;
      // dispatch(consolidadoTelaInserted(consolidadotela));
      dispatch(programacionTintoreriaUpdated(programaciontintoreria));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const consolidadoTelaStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.consolidadotela}${data.id}/`,
        data
      );
      const body = resp.data;
      const { msg, programaciontintoreria } = body;
      // dispatch(consolidadoTelaUpdated(consolidadotela));
      dispatch(programacionTintoreriaUpdated(programaciontintoreria));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const consolidadoTelaInserted = (consolidado) => ({
  type: types.consolidadoTelaInserted,
  payload: consolidado,
});
const consolidadoTelaUpdated = (consolidado) => ({
  type: types.consolidadoTelaUpdated,
  payload: consolidado,
});
export const consolidadoTelaSetActive = (consolidado) => ({
  type: types.consolidadoTelaSetActive,
  payload: consolidado,
});
export const consolidadoTelaClearActive = () => ({
  type: types.consolidadoTelaClearActive,
});
export const showFormConsolidadoTela = (visible = true) => ({
  type: types.produccionShowFormConsolidadoTela,
  payload: visible,
});
//TODO: Implement extra functions
// ASYNC FUNCTIONS
export const get_estados_programacion_tejido = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_estados_programaciontejido/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipostelas = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_tipostelas/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_colores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_colores/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_tipo_tenido_tela = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_tipotenidotela/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_ancho_tela = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_anchotela/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_pdf_programacion_tejido = async (programaciontejido) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_pdf_programacion_tejido/`,
      {
        responseType: "blob",
        params: { programaciontejido },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_detallecomprobantemovimientos_produccion = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontejido}get_detallecomprobantemovimientos_produccion/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_pdf_programacion_tintoreria = async (
  programaciontintoreria
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontintoreria}get_pdf_programacion_tintoreria/`,
      {
        responseType: "blob",
        params: { programaciontintoreria },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const search_programaciontejido_by_filter = async (filter) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontintoreria}search_programaciontejido_by_filter/`,
      { params: { filter } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const search_comprobantecompras_by_filter = async (filter) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontintoreria}search_comprobantecompras_by_filter/`,
      { params: { filter } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const list_detalleprogramaciontintoreria_by_programaciontintoreria =
  async (programaciontintoreria) => {
    let datos = [];
    try {
      const resp = await axiosConToken.get(
        `${routes.programaciontintoreria}list_detalleprogramaciontintoreria_by_programaciontintoreria/`,
        { params: { programaciontintoreria } }
      );
      datos = resp.data;
    } catch (error) {
      validaMessagesErrors(error.response);
    }
    return datos;
  };
export const get_maquinas_select = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.maquina}get_maquinas_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_tareasproduccion = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.rutaproduccion}get_tareasproduccion/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_items_a_producir = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.ordenproduccion}get_items_a_producir/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_estados = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.ordenproduccion}get_estados/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_unidadesmedida = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.ordenproduccion}get_unidadesmedida/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_ordenesproduccion = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.procesoproduccion}get_ordenesproduccion/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_proveedores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.procesoproduccion}get_proveedores/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_empleados = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.procesoproduccion}get_empleados/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_unidades_medida = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.procesoproduccion}get_unidadesmedida/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_estados_procesoproduccion = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.procesoproduccion}get_estados/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const list_detalleprogramacionestintorerias_cumplidos = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.consolidadotela}list_detalleprogramacionestintorerias_cumplidos/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_solo_almacenes = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.programaciontintoreria}get_solo_almacenes/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const search_items_by_param = async (param) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.consolidadotela}search_items_by_param/`,
      {
        params: { param },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_pdf_consolidado_tela = async (consolidadotela) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.consolidadotela}get_pdf_consolidacion_tela/`,
      {
        responseType: "blob",
        params: { consolidadotela },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
