import { axiosConToken } from "../helpers/axios";

import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { types } from "../types/typesListaPrecio";

const key = "msglistaprecioes";
const route = {
  listaprecios: "listaprecios/",
};

export const listapreciosStartLoad = (filtros) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get("listaprecios", {
        params: { ...filtros },
      });
      const data = resp.data;
      dispatch(listaprecioLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const listaprecioStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post("listaprecios/", data);
      const { msg, listaprecio } = resp.data;
      dispatch(listaprecioAdded(listaprecio));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const listaprecioStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(`listaprecios/${data.id}/`, data);
      const { msg, listaprecio } = resp.data;
      dispatch(listaprecioUpdated(listaprecio));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const listaprecioStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`listaprecios/${data.id}/`);
      const { msg } = resp.data;
      dispatch(listaprecioDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const listaprecioLoaded = (listaprecioes) => ({
  type: types.listapreciosLoaded,
  payload: listaprecioes,
});
const listaprecioAdded = (listaprecio) => ({
  type: types.listaprecioAdded,
  payload: listaprecio,
});
const listaprecioUpdated = (listaprecio) => ({
  type: types.listaprecioUpdated,
  payload: listaprecio,
});
const listaprecioDeleted = (listaprecio) => ({
  type: types.listaprecioDeleted,
  payload: listaprecio,
});
export const listaprecioSetActive = (listaprecio) => ({
  type: types.listaprecioSetActive,
  payload: listaprecio,
});
export const listaprecioClearActive = () => ({
  type: types.listaprecioClearActive,
});
export const reglaListaprecioSetActive = (reglalistaprecio) => ({
  type: types.reglaListaprecioSetActive,
  payload: reglalistaprecio,
});
export const reglaListaprecioClearActive = () => ({
  type: types.reglaListaprecioClearActive,
});
// Functions Async POS

export const get_tipos_calculo = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("listaprecios/get_tipos_calculo/");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_campos_aplicar = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("listaprecios/get_campos_aplicar/");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_items = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${route.listaprecios}get_items/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_sucursales = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${route.listaprecios}get_sucursales/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
