import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { authStartLogout } from "../actions/auth";

export const PrivateRoute = ({ isAllowed, children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  localStorage.setItem("lastPath", location.pathname);

  if (!isAllowed)
    return <Navigate to="/login" state={{ from: location }} replace />;
  else {
    const tokens = localStorage.getItem("authTokens") || null;
    if (!tokens) dispatch(authStartLogout());

    return children;
  }
};
