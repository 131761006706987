import React, { useEffect, useState } from "react";
import { getProvincias } from "../../../helpers/loadGenerales";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const ProvinciaSelect = ({ departamento, ...props }) => {
  const [provincias, setProvincias] = useState([]);
  const OnLoadProvincias = async (departamento) => {
    const datos = await getProvincias(departamento);
    setProvincias(datos);
  };
  useEffect(() => {
    OnLoadProvincias(departamento);
  }, [departamento]);
  return <SearchBasicSelectUI options={provincias} {...props} />;
};
