export const types = {
  guiaremisionesLoaded: "[guiaremision] guiaremisiones Loaded",
  guiaremisionSetActive: "[guiaremision] guiaremision Set Active",
  guiaremisionClearActive: "[guiaremision] guiaremision Clear Active",
  guiaremisionAdded: "[guiaremision] guiaremision added",
  guiaremisionUpdated: "[guiaremision] guiaremision updated",
  guiaremisionDeleted: "[guiaremision] guiaremision deleted",
  guiaremisionLogout: "[guiaremision] guiaremision logout",
  guiaremisionShowForm: "[guiaremision] show Form guiaremision",
  guiaremisionShowModal: "[guiaremision] show Modal guiaremision",
  guiaremisionSetAlmacen: "[guiaremision] set Almacen guiaremision",
};
