import { Col, Row, Form, Input } from "antd";
import React from "react";
import { DepartamentoSelect } from "../ubigeos/DepartamentoSelect";
import { DistritoSelect } from "../ubigeos/DistritoSelect";
import { ProvinciaSelect } from "../ubigeos/ProvinciaSelect";
import { TipoLocalSelect } from "./TipoLocalSelect";

export const LocalForm = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const departamento = Form.useWatch("departamento", form);
  const provincia = Form.useWatch("provincia", form);
  const changeDepartamento = () => {
    form.setFieldValue("provincia", null);
    form.setFieldValue("ubigeodireccion", null);
  };
  const changeProvincia = () => {
    form.setFieldValue("ubigeodireccion", null);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item
            name="descripcion"
            label="Descripción"
            rules={[
              { required: true, message: "La descripción es obligatorio" },
            ]}
          >
            <Input maxLength={100} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="telefono"
            label="Telefono/Movil"
            rules={[{ required: true, message: "El telefono es obligatorio" }]}
          >
            <Input maxLength={10} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "El email es obligatorio" },
              { type: "email", message: "Ingrese un email válido" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="direccion"
            label="Dirección"
            rules={[{ required: true, message: "La dirección es obligatoria" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="tipolocal"
            label="Tipos locales"
            rules={[
              { required: true, message: "El tipo de local es obligatorio" },
            ]}
          >
            <TipoLocalSelect
              style={{ width: "100%" }}
              placeholder="[-Seleccione los tipos-]"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item
            name="departamento"
            label="Departamento"
            rules={[
              { required: true, message: "El departamento es obligatorio" },
            ]}
          >
            <DepartamentoSelect
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={changeDepartamento}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item
            name="provincia"
            label="Provincia"
            rules={[{ required: true, message: "La provincia es obligatoria" }]}
          >
            <ProvinciaSelect
              departamento={departamento}
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={changeProvincia}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item
            name="ubigeodireccion"
            label="Distrito"
            rules={[{ required: true, message: "El distrito es obligatorio" }]}
          >
            <DistritoSelect
              provincia={provincia}
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
