export const types = {
  dataLoading: "[Produccion] Programaciones data Loading",
  programacionTejidoLoaded: "[Tejido] Programaciones Tejido Loaded",
  produccionShowFormProgramacionTejido:
    "[Tejido] Show or Close Form Programaciones Tejido",
  programacionTejidoSetActive: "[Tejido] Programación Tejido Set Active",
  programacionTejidoClearActive: "[Tejido] Programación Tejido Clear Active",
  programacionTejidoInserted: "[Tejido] Programación Tejido Inserted",
  programacionTejidoUpdated: "[Tejido] Programación Tejido Updated",
  programacionTejidoDeleted: "[Tejido] Programación Tejido Deleted",
  programacionTejidoLogout: "[Tejido] Programación Tejido Logout",
  programacionTintoreriaLoaded: "[Tejido] Programaciones Tintoreria Loaded",
  detalleProgramacionTejidoSetActive:
    "[Tejido] detalle Programación Tejido Set Active",
  detalleProgramacionTejidoClearActive:
    "[Tejido] detalle Programación Tejido Clear Active",
  produccionShowFormProgramacionTintoreria:
    "[Tejido] Show or Close Form Programaciones Tintoreria",
  programacionTintoreriaSetActive:
    "[Tintoreria] Programación Tintoreria Set Active",
  programacionTintoreriaClearActive:
    "[Tintoreria] Programación Tintoreria Clear Active",
  detalleProgramacionTintoreriaSetActive:
    "[Tejido] detalle Programación Tintoreria Set Active",
  detalleProgramacionTintoreriaClearActive:
    "[Tejido] detalle Programación Tintoreria Clear Active",
  programacionTintoreriaInserted: "[Tejido] Programación Tintoreria Inserted",
  programacionTintoreriaUpdated: "[Tejido] Programación Tintoreria Updated",
  programacionTintoreriaDeleted: "[Tejido] Programación Tintoreria Deleted",
  programacionTintoreriaLogout: "[Tejido] Programación Tintoreria Logout",

  produccionShowFormConsolidadoTela:
    "[Consolidado] Show or Close Form Consolidado Tela",
  consolidadoTelaSetActive: "[Consolidado] Consolidado Tela Set Active",
  consolidadoTelaClearActive: "[Consolidado] Consolidado Tela Clear Active",
  consolidadoTelaInserted: "[Consolidado] Consolidado Tela Inserted",
  consolidadoTelaUpdated: "[Consolidado] Consolidado Tela Updated",
  consolidadoTelaDeleted: "[Consolidado] Consolidado Tela Deleted",
  consolidadoTelaLogout: "[Consolidado] Consolidado Tela Logout",
  consolidadoTelaLoaded: "[Consolidado] Consolidados Tela Loaded",
  detalleConsolidadoTelaSetActive:
    "[Consolidado] detalle Consolidado tela Set Active",
  detalleConsolidadoTelaClearActive:
    "[Consolidado] detalle Consolidado tela Clear Active",

  maquinasLoaded: "[Maquinas] Maquinas Loaded",
  maquinasSetActive: "[Maquinas] Maquinas Set Active",
  maquinasClearActive: "[Maquinas] Maquinas Clear Active",
  maquinasInserted: "[Maquinas] Maquinas Inserted",
  maquinasUpdated: "[Maquinas] Maquinas Updated",
  maquinasDeleted: "[Maquinas] Maquinas Deleted",
  maquinasLogout: "[Maquinas] Maquinas Logout",

  tareasProduccionLoaded: "[TareasProduccion] Tareas Produccion Loaded",
  tareasProduccionSetActive: "[TareasProduccion] Tareas Produccion Set Active",
  tareasProduccionClearActive:
    "[TareasProduccion] Tareas Produccion Clear Active",
  tareasProduccionInserted: "[TareasProduccion] Tareas Produccion Inserted",
  tareasProduccionUpdated: "[TareasProduccion] Tareas Produccion Updated",
  tareasProduccionDeleted: "[TareasProduccion] Tareas Produccion Deleted",
  tareasProduccionLogout: "[TareasProduccion] Tareas Produccion Logout",

  rutasProduccionLoaded: "[RutasProduccion] Rutas Produccion Loaded",
  rutasProduccionShowForm:
    "[RutasProduccion] Show or Close Form Rutas produccion",
  rutasProduccionSetActive: "[RutasProduccion] Rutas Produccion Set Active",
  rutasProduccionClearActive: "[RutasProduccion] Rutas Produccion Clear Active",
  rutasProduccionInserted: "[RutasProduccion] Rutas Produccion Inserted",
  rutasProduccionUpdated: "[RutasProduccion] Rutas Produccion Updated",
  rutasProduccionDeleted: "[RutasProduccion] Rutas Produccion Deleted",
  rutasProduccionLogout: "[RutasProduccion] Rutas Produccion Logout",

  ordenesProduccionLoaded: "[OrdenesProduccion] Ordenes Produccion Loaded",
  ordenesProduccionShowForm:
    "[OrdenesProduccion] Show or Close Form Ordenes produccion",
  ordenesProduccionSetActive:
    "[OrdenesProduccion] Ordenes Produccion Set Active",
  ordenesProduccionClearActive:
    "[OrdenesProduccion] Ordenes Produccion Clear Active",
  ordenesProduccionInserted: "[OrdenesProduccion]Ordeness Produccion Inserted",
  ordenesProduccionUpdated: "[OrdenesProduccion] Ordenes Produccion Updated",
  ordenesProduccionDeleted: "[OrdenesProduccion] Ordenes Produccion Deleted",
  ordenesProduccionLogout: "[OrdenesProduccion] Ordenes Produccion Logout",

  procesosProduccionLoaded: "[ProcesosProduccion] Procesos Produccion Loaded",
  procesosProduccionShowForm:
    "[ProcesosProduccion] Show or Close Form Procesos produccion",
  procesosProduccionSetActive:
    "[ProcesosProduccion] Procesos Produccion Set Active",
  procesosProduccionClearActive:
    "[ProcesosProduccion] Procesos Produccion Clear Active",
  detalleProcesoProduccionSetActive:
    "[ProcesosProduccion] detalle Proceso Produccion Set Active",
  detalleProcesoProduccionClearActive:
    "[ProcesosProduccion] detalle Proceso Produccion Clear Active",
  procesosProduccionInserted:
    "[ProcesosProduccion] Procesos Produccion Inserted",
  procesosProduccionUpdated: "[ProcesosProduccion] Procesos Produccion Updated",
  procesosProduccionDeleted: "[ProcesosProduccion] Procesos Produccion Deleted",
  procesosProduccionLogout: "[ProcesosProduccion] Procesos Produccion Logout",
};
