import React, { useEffect, useRef, useState } from "react";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Form } from "antd";
import { ModalUi } from "../../../../ui/ModalUi";
import { FormUi } from "../../../../ui/FormUi";
import { NaturalezaPagoForm } from "./NaturalezaPagoForm";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  naturalezaPagoCajaSelectUpdate,
  naturalezaPagoCajaStartAdd,
  naturalezaPagoCajaStartUpdate,
} from "../../../../../actions/caja";
const initialValues = { id: dayjs().valueOf(), nombre: null };
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const NaturalezaPagoModal = ({ open, handleCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeNaturalezaPagoCaja } = useSelector((state) => state.caja);
  useResetFormOnCloseModal({ form, open });
  const handleSave = (values) => {
    if (activeNaturalezaPagoCaja) {
      dispatch(naturalezaPagoCajaStartUpdate(values));
    } else {
      dispatch(naturalezaPagoCajaStartAdd(values));
    }
    handleCancel();
    dispatch(naturalezaPagoCajaSelectUpdate());
  };

  return (
    <ModalUi
      title="Añadir categoria pago"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormNaturalezaPago"
        />,
      ]}
    >
      <FormUi
        name="FormNaturalezaPago"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <NaturalezaPagoForm />
      </FormUi>
    </ModalUi>
  );
};
