import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { getTiposItems } from "../../../actions/producto";

export const TiposItemSelect = ({ ...props }) => {
  const [tipositems, setTipositems] = useState([]);

  useEffect(() => {
    const TiposItemsLoad = async () => {
      const datos = await getTiposItems();
      setTipositems(datos);
    };
    TiposItemsLoad();
    return () => {
      setTipositems([]);
    };
  }, []);

  return <SelectUi data={tipositems} {...props} />;
};
