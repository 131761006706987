import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { TableUi } from "../../../ui/TableUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch } from "react-redux";
import { setQuantitySelectSeries } from "../../../../actions/inventario";

const columns = (props) => {
  const { form } = props;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Serie",
      dataIndex: "numero_serie",
      key: "numero_serie",
      render: (text, record, i) => (
        <>{form.getFieldValue(["series", record?.name, "numero_serie"])}</>
      ),
    },
    {
      title: "Fecha fabricación",
      dataIndex: "fecha_fabricacion",
      key: "fecha_fabricacion",
      render: (text, record, i) => (
        <>{form.getFieldValue(["series", record?.name, "fecha_fabricacion"])}</>
      ),
    },
  ];
};
export const DetalleSeriesTable = ({ data, openModal }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const series = Form.useWatch("series", form);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    series.forEach((item, index) => {
      if (newSelectedRowKeys.includes(index)) {
        item.selected = true;
      } else item.selected = false;
    });
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(
      setQuantitySelectSeries(
        series?.filter((item) => item.selected === true).length
      )
    );
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    const indexSelected = [];
    if (openModal) {
      series?.forEach((item, index) => {
        if (item.selected === true) {
          indexSelected.push(index);
        }
      });
      setSelectedRowKeys(indexSelected);
    } else {
      dispatch(setQuantitySelectSeries(0));
    }
  }, [openModal]);
  return (
    <TableUi
      rowSelection={{ ...rowSelection }}
      data={data}
      columns={columns({ form })}
      size="small"
    />
  );
};
