import { Col, Descriptions, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { DetalleSalidaSerieTable } from "./DetalleSalidaSerieTable";
import { useSelector } from "react-redux";
const items = (props) => {
  const { cantidad_despacho, cantidad } = props;
  return [
    {
      key: "1",
      label: "Cantidad despacho",
      children: parseFloat(cantidad_despacho).toFixed(2),
    },
    {
      key: "2",
      label: "Cantidad ingresada",
      children: parseFloat(cantidad).toFixed(2),
    },
  ];
};
export const DetalleSalidaSerieForm = ({ cantidad, openModal }) => {
  const { quantitySelectedSeries } = useSelector((state) => state.inventario);
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={20} lg={16} xl={16}>
        <Form.List
          name="series"
          rules={[
            {
              validator: async (_, value) => {
                const cantSeleccionada = value?.filter(
                  (item) => item.selected === true
                ).length;
                if (cantSeleccionada !== cantidad)
                  return Promise.reject(
                    new Error(
                      "Las series seleccionadas deben ser igual a la cantidad ingresada"
                    )
                  );
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <DetalleSalidaSerieTable data={fields} openModal={openModal} />
              <Form.Item className="ui-errorlist">
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Descriptions
          title="Resumen de cantidades"
          bordered
          column={1}
          items={items({
            cantidad_despacho: cantidad,
            cantidad: quantitySelectedSeries,
          })}
          size="middle"
        />
      </Col>
    </Row>
  );
};
