import React from "react";
import { Input, Button, Col, Image, Row, Form } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import logo from "../../static/img/LogoAleman.jpg";
import "./LoginScreen.scss";
import { useDispatch } from "react-redux";
import { authStartLogin } from "../../actions/auth";
import { FormUi } from "./../ui/FormUi";

const inititalValues = {
  email: "45737030",
  password: "45737030",
};

export const LoginScreen = () => {
  const distpatch = useDispatch();
  // const navigate = useNavigate();
  const onSubmit = (values) => {
    const lastPath = localStorage.getItem("lastPath") || "/";
    const { email, password } = values;
    distpatch(authStartLogin(email, password));
    // navigate(lastPath, { replace: true });
  };
  return (
    <div className="login">
      <div className="login__content">
        <span className="login__logo">
          <Image preview={false} src={logo}></Image>
        </span>
        <span className="login__title">Login</span>
        <FormUi
          initialValues={inititalValues}
          className="login-form"
          handleSubmit={onSubmit}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "El email es obligatorio" }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "El password es obligatorio" }]}
          >
            <Input.Password
              size="large"
              type="password"
              autoComplete="off"
              prefix={<LockOutlined className="site-form-item-icon" />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              placeholder="Password"
            />
          </Form.Item>
          <Row>
            <Col span={16} offset={8}>
              <Button type="primary" htmlType="submit" shape="round">
                Ingresar
              </Button>
            </Col>
          </Row>
        </FormUi>
      </div>
    </div>
  );
};
