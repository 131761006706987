import React, { useEffect, useState } from "react";
import { get_unidadesmedida } from "../../../actions/comprobante";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const UnidadMedidaSelect = ({ ...restProps }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const data = await get_unidadesmedida();
      setOptions(data);
    };
    OnLoad();
    return () => {
      setOptions([]);
    };
  }, []);

  return <SearchBasicSelectUI data={options} {...restProps} />;
};
