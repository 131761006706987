import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uiCollapsedSidebar } from "../../actions/ui";
import { Link } from "react-router-dom";
import { LogoMenu } from "./Siderbar/LogoMenu";
import routes from "../../routers/routes";

const getMenuItems = (routesFilter, parentId = null) => {
  let Menus_Item = [];
  routesFilter
    .filter((x) => x.parentId === parentId && x.isVisible === true)
    .forEach((item) => {
      let haveChildrens =
        routesFilter.filter(
          (x) => x.parentId === item.id && x.isVisible === true
        ).length > 0
          ? true
          : false;
      const childrens = haveChildrens
        ? getMenuItems(routesFilter, item.id)
        : undefined;
      const MenuItem = {
        key: item.path,
        icon: item.icon,
        children: childrens,
        label: item.isLink ? <Link to={item.to}>{item.Name}</Link> : item.Name,
        type: undefined,
      };
      Menus_Item = [...Menus_Item, MenuItem];
    });
  return Menus_Item;
};
export const SiderbarAll = () => {
  const { Sider } = Layout;
  const dispatch = useDispatch();
  const { activeUsuario: user, activeEmpleado } = useSelector(
    (state) => state.auth
  );
  const { collapsedSidebar } = useSelector((state) => state.ui);
  const [MenuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let routesFilter = routes;
    if (activeEmpleado) {
      if (!user?.is_superuser) {
        //Es empleado y un usuario con perfiles
        let aRoutesFilter = [];
        user?.groups.forEach((x) => {
          const valuesData = routes.filter((i) => i.roles.includes(x.name));
          const valuesNewAdd = valuesData.filter(
            (x) => !aRoutesFilter.some((item) => item.id === x.id)
          );
          aRoutesFilter = aRoutesFilter.concat(valuesNewAdd);
        });
        routesFilter = aRoutesFilter.sort(
          (a, b) => Number(a.id) - Number(b.id)
        );
        setMenuItems(getMenuItems(routesFilter));
      } else {
        //Es un empleado y superusuario
        setMenuItems(getMenuItems(routesFilter));
      }
    } else {
      const initialConfigRoutes = routes?.filter(
        (x) => x?.initialConfig === true
      );
      setMenuItems(getMenuItems(initialConfigRoutes));
    }
  }, []);

  const onCollapsed = (broken) => {
    dispatch(uiCollapsedSidebar(broken));
  };

  return (
    <Sider
      className="site-layout-background"
      collapsible
      collapsed={collapsedSidebar}
      trigger={null}
      breakpoint="xs"
      onBreakpoint={(broken) => {
        onCollapsed(broken);
      }}
      onCollapse={(collapse) => {
        onCollapsed(collapse);
      }}
    >
      <LogoMenu />
      <Menu
        defaultSelectedKeys={[""]}
        mode="inline"
        theme="dark"
        items={MenuItems}
      />
    </Sider>
  );
};
