import React, { useState } from "react";
import { TableUi } from "./../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useDispatch } from "react-redux";
import {
  comprobantecompraSetActive,
  comprobantecompraStartDelete,
  get_pdf_comprobantecompra,
  showFormComprobanteCompra,
} from "../../../actions/compra";
import { open_file } from "../../../helpers/utils";
import { ItemDetallePreviewModal } from "../modal/ItemDetallePreviewModal";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { comprobanteMovimientoSetActive } from "../../../actions/inventario";

const handleMenuOpt = (e, record, acciones) => {
  const {
    handleEditar,
    handleMovimientoEntrada,
    handleEliminar,
    handlePrintPDF,
  } = acciones;

  switch (e.key) {
    case "pdfcomprobantecompra":
      handlePrintPDF(record);
      break;
    case "editar":
      handleEditar(record);
      break;
    case "eliminar":
      handleEliminar(record);
      break;
    case "copiarmovimiento":
      handleMovimientoEntrada(record);
      break;
    default:
      break;
  }
};

const items = (data) => {
  const _exist_item_inventario =
    data?.detallescomprobantecompra?.some((x) => x.esinventariado === true) &&
    !data?.comprobantemovimiento;

  let itemsMenu = [
    {
      key: "pdfcomprobantecompra",
      label: "Imprimir",
      icon: <FilePdfOutlined />,
    },
  ];
  if (_exist_item_inventario) {
    itemsMenu = [
      ...itemsMenu,
      {
        key: "copiarmovimiento",
        label: "Crear mov. inventario",
        icon: <CopyOutlined />,
      },
      {
        key: "editar",
        label: "Editar",
        icon: <EditOutlined />,
      },
      {
        key: "eliminar",
        label: "Eliminar",
        icon: <DeleteOutlined />,
      },
    ];
  }
  return itemsMenu;
};

const menuProps = (record, acciones) => ({
  items: items(record),
  onClick: (e) => handleMenuOpt(e, record, acciones),
});

const BtnGroupComprobante = ({ comprobante, acciones }) => {
  return (
    <>
      <Dropdown menu={menuProps(comprobante, acciones)} trigger={["click"]}>
        <Button className="ml-2">
          <MoreOutlined />
        </Button>
      </Dropdown>
    </>
  );
};

const columns = (acciones) => {
  const { handleShowItems } = acciones;

  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Fecha Vencimiento",
      dataIndex: "fechavencimiento",
      key: "fechavencimiento",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor_nombre",
      key: "proveedor_nombre",
    },
    {
      title: "Comprobante",
      dataIndex: "nrocomprobante",
      key: "nrocomprobante",
      render: (text, record) => (
        <>
          <h5
            style={{
              margin: 0,
            }}
          >
            {record.nrocomprobante}
          </h5>
          <h6
            style={{
              margin: 0,
            }}
          >
            {record.tipocomprobante_nombre}
          </h6>
        </>
      ),
      align: "center",
    },
    {
      title: "Forma pago",
      dataIndex: "formapago_nombre",
      key: "formapago_nombre",
      align: "center",
    },

    {
      title: "Productos",
      dataIndex: "btnproductos",
      key: "btnproductos",
      render: (text, record) => (
        <ButtonUi
          typeUi={TipoButtonConstants.CustomButton}
          icon={<FileSearchOutlined />}
          displayText={false}
          onClick={() => handleShowItems(record)}
        />
      ),
      align: "center",
    },
    {
      title: "Moneda",
      dataIndex: "moneda_prefijosunat",
      key: "moneda_prefijosunat",
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "montototal",
      key: "montototal",
      align: "right",
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <BtnGroupComprobante comprobante={record} acciones={acciones} />
      ),
    },
  ];
};

export const ComprobanteCompraTable = ({ comprobantes }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleEditar = (data) => {
    dispatch(comprobantecompraSetActive(data));
    dispatch(showFormComprobanteCompra());
  };
  const handleEliminar = (data) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el comprobante seleccionado?",
      `${data.nrocomprobante}`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(comprobantecompraStartDelete(data));
        }
      }
    );
  };
  const handlePrintPDF = async (comprobante) => {
    const file = await get_pdf_comprobantecompra(comprobante?.id);

    let name_file = `${comprobante?.proveedor_nrodocumento}-${comprobante?.seriecomprobante}-${comprobante?.nrocomprobante}`;
    open_file(file, name_file, "application/pdf");
  };
  const handleShowItems = (data) => {
    setItems(data?.detallescomprobantecompra);
    showModal();
  };
  const handleMovimientoEntrada = (data) => {
    localStorage.setItem("ComprobanteCompraForm", true);
    const valueData = {
      id: null,
      comprobantecompra: data?.id,
      detallescomprobantemovimiento: data?.detallescomprobantecompra?.map(
        (x, index) => ({
          id: dayjs().valueOf() + index,
          item: x.item,
          item_nombre: x.item_nombre,
          unidadmedida: x.unidadmedida,
          unidadmedida_nombre: x.unidadmedida_nombre,
          cantidad: parseFloat(x.cantidad),
          manejalotes: x.manejalotes,
          manejaseries: x.manejaseries,
          esinventariado: x.esinventariado,
          costo: parseFloat(x.preciounitario),
          moneda: data.moneda,
        })
      ),
    };
    dispatch(comprobanteMovimientoSetActive(valueData));
    return navigate("/inventario/movimientoentrada", { replace: true });
  };
  return (
    <>
      <TableUi
        data={comprobantes}
        columns={columns({
          handleEditar,
          handleEliminar,
          handleMovimientoEntrada,
          handleShowItems,
          handlePrintPDF,
        })}
        rowKey="id"
        size="small"
      />
      <ItemDetallePreviewModal items={items} open={open} onCancel={hideModal} />
    </>
  );
};
