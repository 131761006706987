import React, { useEffect, useRef } from "react";
import { Form } from "antd";
import { ItemDetalleForm } from "./ItemDetalleForm";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { ModalUi } from "../../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { FormUi } from "../../../../ui/FormUi";
import { detalleComprobanteMovimientoClearActive } from "../../../../../actions/inventario";

const initialValues = (data = {}) => ({
  id: data?.id || new dayjs().valueOf(),
  item: data?.item || null,
  item_nombre: data?.item_nombre || null,
  manejalotes: data?.manejalotes || null,
  manejaseries: data?.manejaseries || null,
  almacen: data?.almacen || null,
  almacen_nombre: data?.almacen_nombre || null,
  cantidad: parseInt(data?.cantidad) || 1,
  unidadmedida: data?.unidadmedida || null,
  unidadmedida_nombre: data?.unidadmedida_nombre || null,
  costo: data?.costo || null,
  moneda: data?.moneda || null,
  series: data?.series || [],
  lotes: data?.lotes || [],
});
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({
  form,
  open,
  activeDetalleComprobanteMovimiento,
}) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    form.resetFields();
    if (!open && prevOpen) {
      dispatch(detalleComprobanteMovimientoClearActive());
    } else {
      //Abrir Modal
      if (activeDetalleComprobanteMovimiento) {
        //entro editar
        form.setFieldsValue({
          ...activeDetalleComprobanteMovimiento,
          cantidad: parseFloat(activeDetalleComprobanteMovimiento.cantidad),
        });
      } else {
        //entro nuevo
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const ItemDetalleModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();

  const { activeDetalleComprobanteMovimiento } = useSelector(
    (state) => state.inventario
  );
  useResetFormOnCloseModal({ form, open, activeDetalleComprobanteMovimiento });
  const handleSubmit = () => {
    onCancelModal();
  };
  return (
    <ModalUi
      title="Busqueda de producto/servicio"
      open={open}
      onCancel={onCancelModal}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="ItemDetalleForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="ItemDetalleForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeDetalleComprobanteMovimiento)}
        handleSubmit={handleSubmit}
      >
        <ItemDetalleForm />
      </FormUi>
    </ModalUi>
  );
};
