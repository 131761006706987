import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  movimientoRemoverStartAdd,
  showModalRemove,
} from "../../../actions/inventario";
import { RemoveForm } from "./RemoveForm";
import { FormUi } from "../../ui/FormUi";

const initialValues = (data = {}) => ({
  inventario: data?.id || "",
  producto: data?.producto || "",
  nombreproducto: data?.nombreproducto || "",
  almacenorigen: data?.almacen || "",
  nombrealmacen: data?.nombrealmacen || "",
  stockactual: data?.stockactual || "",
  cantidad: data?.cantidad || "",
});

// const validationSchema = Yup.object().shape({
//   almacenorigen: Yup.string().required("El almacén es obligatorio"),
//   inventario: Yup.string().required("El producto es obligatorio"),
//   cantidad: Yup.number()
//     .typeError("Ingrese un número válido")
//     .required("La cantidad es obligatorio")
//     .min(0, "Ingrese cantidad mayor a 0"),
// });

export const RemoveModal = () => {
  const dispatch = useDispatch();
  const { inventarioActive, visibleModalRemove } = useSelector(
    (state) => state.inventario
  );
  const handleSave = (values) => {
    const valuesData = {
      almacendestino: values.almacenorigen,
      detallemovimiento: [
        { inventario: values.inventario, cantidad: values.cantidad },
      ],
      ...values,
    };
    dispatch(movimientoRemoverStartAdd(valuesData));
    handleCancel();
  };
  const handleCancel = () => {
    dispatch(showModalRemove(false));
  };
  return (
    <Modal
      title="Retirar Item de almacén"
      centered
      maskClosable={false}
      destroyOnClose
      open={visibleModalRemove}
      onOk={handleSave}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <Button
          key="back"
          danger
          icon={<CloseOutlined />}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          form="FormRetirarAlmacen"
          key="submit"
          htmlType="submit"
          type="primary"
          icon={<SaveOutlined />}
        >
          Guardar
        </Button>,
      ]}
    >
      <FormUi
        id="FormRetirarAlmacen"
        name="FormRetirarAlmacen"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(inventarioActive)}
        onSubmit={handleSave}
      >
        <RemoveForm />
      </FormUi>
    </Modal>
  );
};
