import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_unidadesmedida } from "../../../actions/produccion";
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
export const UnidadMedidaSelect = ({ ...props }) => {
  const [unidadesmedida, setUnidadesmedida] = useState([]);
  useEffect(() => {
    const OnLoadData = async () => {
      const datos = await get_unidadesmedida();
      setUnidadesmedida(datos);
    };
    OnLoadData();
  }, []);

  return (
    <SelectUi
      data={unidadesmedida}
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
      {...props}
    />
  );
};
