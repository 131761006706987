import { useEffect, useState } from "react";
import { get_tipodocumentosidentidad } from "../../../../actions/empleado";
import { SelectUi } from "../../../ui/SelectUi";

export const TipoDocumentoSelect = ({ ...props }) => {
  const [tipodocumentos, setTipodocumentos] = useState([]);
  const OnLoadTipoDocumentos = async () => {
    const datos = await get_tipodocumentosidentidad();
    setTipodocumentos(datos);
  };
  useEffect(() => {
    OnLoadTipoDocumentos();
  }, []);
  return <SelectUi data={tipodocumentos} {...props} />;
};
