import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  procesoProduccionStartLoad,
  showFormProcesoProduccion,
} from "../../../../actions/produccion";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ProcesoProduccionTable } from "./ProcesoProduccionTable";
import { ProcesoProduccionScreen } from "./ProcesoProduccionScreen";

const ProcesoProduccionListScreen = () => {
  const dispatch = useDispatch();
  const { procesosproduccion } = useSelector((state) => state.produccion);
  useEffect(() => {
    dispatch(procesoProduccionStartLoad());
  }, [dispatch]);
  const handleNuevo = () => {
    dispatch(showFormProcesoProduccion(true));
  };
  const handleActualizar = () => {
    dispatch(procesoProduccionStartLoad());
  };
  return (
    <PageHeaderUi
      title="Procesos producción"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
          key="Nuevo"
        />,
      ]}
    >
      <ProcesoProduccionTable data={procesosproduccion} />
    </PageHeaderUi>
  );
};
export const ProcesoProduccionList = () => {
  const { visibleFormProcesoProduccion } = useSelector(
    (state) => state.produccion
  );
  if (visibleFormProcesoProduccion) {
    return <ProcesoProduccionScreen />;
  } else {
    return <ProcesoProduccionListScreen />;
  }
};
export default ProcesoProduccionList;
