import React, { useState } from "react";
import { TableUi } from "../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ZoomInOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { detalleComprobanteMovimientoSetActive } from "../../../actions/inventario";
import { DetalleLoteSeriePreviewModal } from "./modal/DetalleLoteSeriePreviewModal";

const columns_table = (props) => {
  const { handleDelete, handlePreview } = props;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Item",
      dataIndex: "item_nombre",
      key: "item_nombre",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "center",
      render: (text, record, index) => <> {parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "U.Medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Detalle",
      dataIndex: "detalle",
      key: "detalle",
      render: (text, record, index) => (
        <>
          {record?.manejalotes && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              text="Lotes"
              icon={<ZoomInOutlined />}
              onClick={() => handlePreview(record)}
            />
          )}
          {record?.manejaseries && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              text="Series"
              icon={<ZoomInOutlined />}
              onClick={() => handlePreview(record)}
            />
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record, index) => (
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          size="small"
          onClick={
            () => handleDelete(index) // remove a friend from the list
          }
        />
      ),
    },
  ];
};

export const MovimientoTrasladoFormTable = ({
  detallecomprobantemovimiento,
  handleDelete,
  title,
}) => {
  const [openPreview, setOpenPreview] = useState(false);
  const dispatch = useDispatch();
  // const showModalPreview = () => setOpenPreview(true);
  const hideModalPreview = () => setOpenPreview(false);
  const handlePreview = (record) => {
    dispatch(detalleComprobanteMovimientoSetActive(record));
    setOpenPreview(true);
  };
  return (
    <>
      <TableUi
        columns={columns_table({ handleDelete, handlePreview })}
        rowKey="id"
        size="small"
        pagination={false}
        data={detallecomprobantemovimiento}
        title={title}
      />
      <DetalleLoteSeriePreviewModal
        open={openPreview}
        onCancelModal={hideModalPreview}
      />
    </>
  );
};
