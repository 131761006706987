import React, { useEffect, useState } from "react";
import { getVendedores } from "../../../helpers/loadVentas";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const VendedorSelect = ({ name, ...props }) => {
  const [proveedores, SetProveedores] = useState([]);
  const OnLoadVendedores = async () => {
    const datos = await getVendedores();
    SetProveedores(datos);
  };
  useEffect(() => {
    OnLoadVendedores();
  }, []);

  return <SearchBasicSelectUI data={proveedores} {...props} />;
};
