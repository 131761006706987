import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../../ui/ModalUi";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
import { FormUi } from "../../../../../ui/FormUi";
import { SearchComprobanteCompraForm } from "./SearchComprobanteCompraForm";
import { Form } from "antd";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  comprobantecompra: data?.comprobantecompra || null,
  detallecomprobantecompra: data?.detallecomprobantecompra || null,
});
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const SearchComprobanteCompraModal = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });
  const handleSave = (values) => {
    onCancel();
  };
  return (
    <ModalUi
      title="Comprobantes compra"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormComprobanteCompra"
        />,
      ]}
    >
      <FormUi
        name="FormComprobanteCompra"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues()}
        handleSubmit={handleSave}
      >
        <SearchComprobanteCompraForm />
      </FormUi>
    </ModalUi>
  );
};
