import React, { useEffect } from "react";
import { ModalUi } from "./../../../ui/ModalUi";
import { useDispatch, useSelector } from "react-redux";
import {
  usuarioClearActive,
  usuarioStartAdd,
  usuarioStartUpdate,
} from "../../../../actions/usuario";
import { uiShowModal } from "../../../../actions/ui";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { UsuarioForm } from "./UsuarioForm";
import { FormUi } from "../../../ui/FormUi";
import { Form } from "antd";

const initialValues = (data = {}) => ({
  id: data?.id || "",
  username: data?.username || "",
  password: data?.username || "",
  first_name: data?.first_name || "",
  last_name: data?.last_name || "",
  email: data?.email || "",
  is_superuser: data?.is_superuser || false,
  is_staff: data?.is_staff || false,
  is_active: data?.is_active || false,
});

export const UsuarioModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeUsuario } = useSelector((state) => state.auth);
  const { visibleModal } = useSelector((state) => state.ui);
  useEffect(() => {
    if (!activeUsuario) {
      form.setFieldsValue({ ...initialValues() });
    } else {
      form.setFieldsValue({ ...activeUsuario });
    }
  }, [activeUsuario]);
  const handleCancel = () => {
    dispatch(usuarioClearActive());
    dispatch(uiShowModal(false));
  };
  const handleSave = (values) => {
    if (activeUsuario) {
      dispatch(usuarioStartUpdate(values));
    } else {
      dispatch(usuarioStartAdd(values));
    }
    handleCancel();
  };
  return (
    <ModalUi
      title={
        <>
          Usuario
          {activeUsuario ? <small> Editar</small> : <small> Nuevo</small>}
        </>
      }
      open={visibleModal}
      onCancel={handleCancel}
      width="70%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Guardar}
          form="FormUsuario"
          key="submit"
          htmlType="submit"
        />,
      ]}
      forceRender
    >
      <FormUi
        form={form}
        name="FormUsuario"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeUsuario)}
        handleSubmit={handleSave}
      >
        <UsuarioForm />
      </FormUi>
    </ModalUi>
  );
};
