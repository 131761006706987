import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input } from "antd";
import { TipoDocumentoSelect } from "../../configuraciones/TipoDocumentoSelect";
import { TipoDocumentoPersonaConstants } from "../../../types/typesConstants";
import { getDatosPersona } from "../../../helpers/loadGenerales";
import { SwitchUi } from "../../ui/SwitchUi";
import { toast } from "react-toastify";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
export const ClientePrincipalPart = () => {
  const form = Form.useFormInstance();
  const tipodocumento = Form.useWatch("tipodocumento", form);
  const [visible, setVisible] = useState(false);
  const [maxlength, setMaxlength] = useState(25);
  const { setFieldValue } = form;
  const clearFieldsSearch = () => {
    setFieldValue("nombrecompleto", null);
    setFieldValue("nombres", null);
    setFieldValue("apepaterno", null);
    setFieldValue("apematerno", null);
  };
  const ChangeTipoDocumento = (tipodocumento) => {
    const itipodocumento = parseInt(tipodocumento);
    switch (itipodocumento) {
      case parseInt(TipoDocumentoPersonaConstants.Ruc):
        setVisible(true);
        setMaxlength(11);
        break;
      case parseInt(TipoDocumentoPersonaConstants.Dni):
        setMaxlength(8);
        setVisible(false);
        break;
      default:
        setVisible(false);
        setMaxlength(25);
        break;
    }
  };
  const onHandleChangeTipoDocumento = (tipodocumento) => {
    clearFieldsSearch();
    ChangeTipoDocumento(tipodocumento);
  };
  const onChangePersona = (e) => {
    setFieldValue("nrodocumento", e.target.value);
  };
  const searchPersona = async (numerodocumento, e) => {
    e.preventDefault();
    if (!!tipodocumento && !!numerodocumento) {
      const datosPersona = await getDatosPersona(
        tipodocumento,
        numerodocumento
      );
      if (datosPersona) {
        const itipodocumento = parseInt(tipodocumento);
        switch (itipodocumento) {
          case parseInt(TipoDocumentoPersonaConstants.Ruc):
            setFieldValue("nombrecompleto", datosPersona?.nombre);
            setFieldValue("nombres", null);
            setFieldValue("apepaterno", null);
            setFieldValue("apematerno", null);
            break;
          case parseInt(TipoDocumentoPersonaConstants.Dni):
            setFieldValue("nombrecompleto", datosPersona?.nombre);
            setFieldValue("nombres", datosPersona?.nombres);
            setFieldValue("apepaterno", datosPersona?.apellidoPaterno);
            setFieldValue("apematerno", datosPersona?.apellidoMaterno);
            break;
          default:
            clearFieldsSearch();
            break;
        }
      } else {
        toast.info("No existe datos con el filtro ingresado");
      }
    } else {
      toast.info("Seleccione o ingrese el tipo y número de documento");
    }
  };
  useEffect(() => {
    const _tipodocumento = form.getFieldValue("tipodocumento");
    ChangeTipoDocumento(_tipodocumento);
  }, []);

  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            label="Tipo Documento"
            name="tipodocumento"
            className="mb-1"
            rules={[
              {
                required: true,
                message: "El tipo de documento es obligatorio",
              },
            ]}
          >
            <TipoDocumentoSelect
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              onChange={onHandleChangeTipoDocumento}
              // disabled={values?.tipodocumento != ""}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Número"
            name="nrodocumento"
            className="mb-1"
            rules={[
              {
                required: true,
                message: "El número documento es obligatorio",
              },
            ]}
          >
            <Input.Search
              placeholder="Buscar número"
              allowClear
              showCount
              maxLength={maxlength}
              enterButton
              onSearch={searchPersona}
              onChange={onChangePersona}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <Form.Item
            name="state"
            label="¿Está activo?"
            className="mb-1"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      {visible && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Form.Item name="nombrecompleto" label="Nombre completo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}
      {!visible && (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item name="nombres" label="Nombres">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="apepaterno" label="Ape.Paterno">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="apematerno" label="Ape.Materno">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
