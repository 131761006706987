import React, { useEffect, useState } from "react";
import { CardUi } from "../../ui/CardUi";
import { MonedaTable } from "./MonedaTable";
import { useDispatch, useSelector } from "react-redux";
import { monedaStartLoad } from "../../../actions/moneda";
import { MonedaModal } from "./MonedaModal";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { Space } from "antd";

export const MonedaCard = () => {
  const dispatch = useDispatch();
  const { monedas, loading } = useSelector((state) => state.moneda);
  const [open, setOpen] = useState(false);
  const OnLoad = () => dispatch(monedaStartLoad());
  useEffect(() => {
    OnLoad();
  }, []);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <CardUi
      loading={loading}
      size="small"
      title="Moneda"
      extra={
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.Actualizar}
            size="small"
            onClick={OnLoad}
          />
          <ButtonUi
            typeUi={TipoButtonConstants.Nuevo}
            size="small"
            onClick={handleOpenModal}
          />
        </Space>
      }
    >
      <MonedaTable
        data={monedas}
        loading={loading}
        handleOpenModal={handleOpenModal}
      />
      <MonedaModal open={open} onCancelModal={handleCloseModal} />
    </CardUi>
  );
};
