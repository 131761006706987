import React from "react";
import {
  Row,
  Col,
  Tooltip,
  DatePicker,
  Collapse,
  Space,
  Form,
  Input,
} from "antd";
import { ClienteSelect } from "../reusable/ClienteSelect";
import { MonedaSelect } from "../reusable/MonedaSelect";
import { CotizacionFormDetalle } from "./CotizacionFormDetalle";
import { VendedorSelect } from "../reusable/VendedorSelect";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { CardUi } from "../../ui/CardUi";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
const { TextArea } = Input;
const { Panel } = Collapse;

export const CotizacionForm = ({ handleCloseForm }) => {
  return (
    <>
      <CardUi
        title="Cotización"
        size="small"
        extra={
          <Space wrap>
            <ButtonUi
              typeUi={TipoButtonConstants.Guardar}
              htmlType="submit"
              type="primary"
            />
            <Tooltip title="Salir">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={handleCloseForm}
              />
            </Tooltip>
          </Space>
        }
      >
        <FormItemHiddenUi name="id" />
        <FormItemHiddenUi name="montosubtotal" />
        <FormItemHiddenUi name="montoigvtotal" />
        <FormItemHiddenUi name="montototal" />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              name="cliente"
              label="Cliente"
              rules={[{ required: true, message: "El cliente es obligatorio" }]}
            >
              <ClienteSelect placeholder="Seleccione cliente" allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4} xl={4}>
            <Form.Item name="fechaemision" label="Fecha emisión">
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4} xl={4}>
            <Form.Item name="fechaentrega" label="Fecha entrega">
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4} xl={4}>
            <Form.Item name="fechavalidez" label="Fecha Válidez">
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={12} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              name="vendedor"
              label="Vendedor"
              rules={[
                { required: true, message: "El vendedor es obligatorio" },
              ]}
            >
              <VendedorSelect placeholder="Seleccione vendedor" allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Form.Item
              name="moneda"
              label="Moneda"
              rules={[{ required: true, message: "La moneda es obligatoria" }]}
            >
              <MonedaSelect placeholder="Seleccione moneda" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Collapse accordion>
          <Panel header="Información adicional">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item name="contacto" label="Contacto" className="mb-1">
                  <Input placeholder="Ingrese nombre contacto" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="telefono"
                  label="Telefono / Movil"
                  className="mb-1"
                >
                  <Input placeholder="Ingrese telefono / movil" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="observacion"
                  label="Observación"
                  className="mb-1"
                >
                  <TextArea
                    placeholder="Ingrese observación"
                    rows={2}
                    maxLength={1000}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Form.Item
                  name="inforeference"
                  label="Información referencial"
                  className="mb-1"
                >
                  <Input placeholder="Información referencial (opcional)" />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <CotizacionFormDetalle />
      </CardUi>
    </>
  );
};
