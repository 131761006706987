import { Form } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalUi } from "../../../ui/ModalUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { FormUi } from "../../../ui/FormUi";
import { ProveedorForm } from "./ProveedorForm";
import {
  proveedorClearActive,
  proveedorStartAdd,
  proveedorStartUpdate,
} from "../../../../actions/proveedor";
import dayjs from "dayjs";

const initialValues = (data = {}) => ({
  id: data?.id || dayjs().valueOf(),
  tipodocumento: data?.tipodocumento || null,
  nrodocumento: data?.nrodocumento || null,
  nombrecompleto: data?.nombrecompleto || null,
  nombres: data?.nombres || null,
  apepaterno: data?.apepaterno || null,
  apematerno: data?.apematerno || null,
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  direccion: data?.direccion || null,
  telefono: data?.telefono || null,
  email: data?.email || null,
  state: data?.state || true,
});
const useResetFormOnCloseModal = ({ form, open, activeProveedor }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    } else {
      if (!activeProveedor) form.setFieldsValue({ ...initialValues() });
      else form.setFieldsValue({ ...activeProveedor });
    }
  }, [form, prevOpen, open]);
};
export const ProveedorModal = ({ open, OnClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeProveedor } = useSelector((state) => state.proveedor);
  useResetFormOnCloseModal({ form, open, activeProveedor });

  const handleCancel = () => {
    dispatch(proveedorClearActive());
    OnClose();
  };
  const handleSave = (values) => {
    if (activeProveedor) {
      dispatch(proveedorStartUpdate(values));
    } else {
      dispatch(proveedorStartAdd(values));
    }
    handleCancel();
  };

  return (
    <ModalUi
      title={
        <>
          Proveedor
          {activeProveedor ? <small> Editar</small> : <small> Nuevo</small>}
        </>
      }
      open={open}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          form="FormProveedores"
          key="submit"
          htmlType="submit"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
      forceRender
    >
      <FormUi
        form={form}
        name="FormProveedores"
        layout="vertical"
        autoComplete="off"
        handleSubmit={handleSave}
        initialValues={initialValues()}
      >
        <ProveedorForm />
      </FormUi>
    </ModalUi>
  );
};
