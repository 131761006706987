import { DatePicker, Form, InputNumber, Space, Tooltip } from "antd";
import React from "react";
import { TableUi } from "../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { ListaPrecioSelect } from "../reusable/ListaPrecioSelect";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { MonedaSelect } from "../reusable/MonedaSelect";

const columns = (props) => {
  const { handleEliminar } = props;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Lista precio",
      dataIndex: "listaprecio",
      key: "listaprecio",
      width: "30%",
      render: (text, field, index) => (
        <>
          <Form.Item
            name={[field.name, "listaprecio"]}
            rules={[
              { required: true, message: "La lista de precio es obligatorio" },
            ]}
            style={{ marginBottom: 0, width: "90%" }}
          >
            <ListaPrecioSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      render: (text, field) => (
        <Form.Item
          name={[field.name, "precio"]}
          rules={[{ required: true, message: "El precio es obligatorio" }]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber placeholder="Ingrese precio" style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: "Moneda",
      dataIndex: "moneda",
      key: "moneda",
      width: "20%",
      render: (text, field) => (
        <Form.Item
          name={[field.name, "moneda"]}
          rules={[{ required: true, message: "La moneda es obligatorio" }]}
          style={{ marginBottom: 0 }}
        >
          <MonedaSelect placeholder="[-Seleccione-]" />
        </Form.Item>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, field) => {
        return (
          <Space size="middle">
            <Tooltip title="Eliminar">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                onClick={() => handleEliminar(field)}
                displayText={false}
                ghost
                size="small"
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const ListaPreciosTabContentTable = ({ data, handleDelete, title }) => {
  const handleEliminar = (field) => {
    handleDelete(field?.name);
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEliminar })}
      size="small"
      title={title}
    />
  );
};
