import { Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
export const RemoveForm = ({ ...props }) => {
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item name="nombreproducto" label="Producto">
            <Input name="nombreproducto" readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item name="stockactual" label="Stock Actual">
            <InputNumber
              name="stockactual"
              placeholder="Ingrese cantidad"
              readOnly
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="nombrealmacen" label="Almacén origen">
            <Input name="nombrealmacen" readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item name="cantidad" label="Cantidad">
            <InputNumber
              name="cantidad"
              placeholder="Ingrese cantidad"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
