export const types = {
  comprobantecompraLoaded: "[comprobantecompra] Comprobantes compra Loaded",
  comprobantecompraSetActive:
    "[comprobantecompra] Comprobantes comprao Set Active",
  comprobantecompraClearActive:
    "[comprobantecompra] Comprobantes compra Clear Active",
  comprobantecompraAdded: "[comprobantecompra] Comprobantes compra added",
  comprobantecompraUpdated: "[comprobantecompra] Comprobantes compra updated",
  comprobantecompraDeleted: "[comprobantecompra] Comprobantes compra deleted",
  comprobantecompraLogout: "[comprobantecompra] Comprobantes compra logout",
  comprobantecompraShowForm:
    "[comprobantecompra] Show or Close Form Comprobantes compra",
  detallecomprobantecompraSetActive:
    "[comprobantecompra] Detalle Comprobantes compra Set Active",
  detallecomprobantecompraClearActive:
    "[comprobantecompra] Detalle Comprobantes compra Clear Active",
};
