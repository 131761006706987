import React from "react";
import { Grid } from "antd";
const { useBreakpoint } = Grid;
export const LogoMenu = (LogoBreackPoint) => {
  const screens = useBreakpoint();
  //   console.log(screens);
  const prueba = Object.entries(screens).filter((screen) => !!screen[1]);
  //   console.log(prueba);
  return <div className="logo-vertical"></div>;
};
