import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Form, Upload } from "antd";
import { ComprobanteReportLayoutUi } from "./ComprobanteReportLayoutUi";
import { to_Base64 } from "../../../helpers/utils";
const { Dragger } = Upload;

export const ComprobanteCompraFileUpload = () => {
  const form = Form.useFormInstance();
  const archivo = Form.useWatch("archivo", form);
  const propsUpload = {
    maxCount: 1,
    accept: "application/pdf",
    beforeUpload: () => {
      return false;
    },
    onChange: async ({ file, fileList: newFileList }) => {
      if (file?.status !== "removed") {
        const is_valid = file.type === "application/pdf" ? true : false;
        if (is_valid) {
          const _archivo = await to_Base64(newFileList?.[0].originFileObj);
          form.setFieldValue("archivo", _archivo);
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <>
      {archivo ? (
        <ComprobanteReportLayoutUi fileUrl={archivo} />
      ) : (
        <Dragger key="Fileupload" {...propsUpload}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga clic o arrastre el archivo a esta área para cargarlo
          </p>
        </Dragger>
      )}
    </>
  );
};
