import { Col, Form, InputNumber, Row } from "antd";

import React, { useState } from "react";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { UnidadMedidaSelect } from "../../../../generales/unidadesmedida/UnidadMedidaSelect";
import { InputSearchDropdown } from "../../../reusable/InputSearchDropdown";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { DetalleSeriesLotesModal } from "../../../modal/DetalleSeriesLotesModal";

export const ItemDetalleForm = () => {
  const form = Form.useFormInstance();
  const manejalotes = Form.useWatch("manejalotes", form);
  const manejaseries = Form.useWatch("manejaseries", form);
  const cantidad = Form.useWatch("cantidad", form);
  const series = Form.useWatch("series", form);
  const lotes = Form.useWatch("lotes", form);
  const [open, setOpen] = useState(false);

  const onChangeUnidadMedida = (value, field) => {
    form.setFieldsValue({ unidadmedida_nombre: field?.prefijo });
  };
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const validaBeforeOpen = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        handleOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="item" />
      <FormItemHiddenUi name="almacen_nombre" />
      <FormItemHiddenUi name="unidadmedida_nombre" />
      <FormItemHiddenUi name="esinventariado" />
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <FormItemHiddenUi name="costo" />
      <FormItemHiddenUi name="moneda" />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <InputSearchDropdown />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={5}>
          <Form.Item
            name="cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              {
                type: "number",
                min: 0.01,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} precision={2} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            name="unidadmedida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect
              placeholder="[-Unidad medida-]"
              onChange={onChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
        {manejalotes && (
          <Col xs={24} sm={24} md={12} lg={5} xl={4}>
            <ButtonUi
              typeUi={TipoButtonConstants.Agregar}
              text="Lotes"
              onClick={validaBeforeOpen}
            />
          </Col>
        )}
        {manejaseries && (
          <Col xs={24} sm={24} md={12} lg={5} xl={4}>
            <ButtonUi
              typeUi={TipoButtonConstants.Agregar}
              text="Series"
              onClick={validaBeforeOpen}
            />
          </Col>
        )}
      </Row>
      {manejaseries && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="series"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (manejaseries) {
                      const cantSeleccionada = value?.filter(
                        (item) => item.selected === true
                      ).length;
                      if (cantSeleccionada !== getFieldValue("cantidad"))
                        return Promise.reject(
                          new Error(
                            "Las series deben ser igual a la cantidad ingresada"
                          )
                        );
                    }
                  },
                }),
              ]}
            >
              {(fields, options, { errors }) => (
                <>
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      {manejalotes && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="lotes"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (manejalotes) {
                      let suma = value?.reduce(
                        (prev, curr) => Number(prev) + Number(curr?.cantidad),
                        0
                      );
                      if (suma !== getFieldValue("cantidad"))
                        return Promise.reject(
                          new Error(
                            "La cantidad de los lotes deben ser igual a la cantidad ingresada"
                          )
                        );
                    }
                  },
                }),
              ]}
            >
              {(fields, options, { errors }) => (
                <>
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      <DetalleSeriesLotesModal
        manejalotes={manejalotes}
        manejaseries={manejaseries}
        cantidad={cantidad}
        series={series}
        lotes={lotes}
        open={open}
        onCancel={handleCloseModal}
      />
    </>
  );
};
