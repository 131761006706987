import React from "react";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { Col, Form, Input, Row } from "antd";

export const TareaProduccionForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[
              {
                required: true,
                message: "Campo obligatorio",
              },
            ]}
            required={false}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="descripcion"
            label="Descripción"
            rules={[
              {
                required: true,
                message: "Campo obligatorio",
              },
            ]}
            required={false}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
