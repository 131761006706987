import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../../ui/ModalUi";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
import { FormUi } from "../../../../../ui/FormUi";
import { Form } from "antd";
import { SearchFormProgramacionTejidoForm } from "./SearchFormProgramacionTejidoForm";
import { useSelector } from "react-redux";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  programaciontejido: data?.programaciontejido || null,
  detalleprogramaciontejido: data?.detalleprogramaciontejido || null,
});
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const SearchFormProgramacionTejidoModal = ({ open, onCancel }) => {
  const [form] = Form.useForm();

  const { activeDetalleProgramacionTintoreria } = useSelector(
    (state) => state.produccion
  );
  useResetFormOnCloseModal({ form, open });
  const handleSave = (values) => {
    onCancel();
  };
  return (
    <ModalUi
      title="Programaciones tejido"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormDetalle"
        />,
      ]}
    >
      <FormUi
        name="FormSearchProgramacionTejido"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeDetalleProgramacionTintoreria)}
        handleSubmit={handleSave}
      >
        <SearchFormProgramacionTejidoForm />
      </FormUi>
    </ModalUi>
  );
};
