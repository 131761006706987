import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SearchFormProgramacionTintoreriaTable } from "./SearchFormProgramacionTejidoTable";
import { list_detalleprogramaciontintoreria_by_programaciontintoreria } from "../../../../../actions/produccion";

export const SearchFormProgramacionTintoreriaForm = ({
  open,
  programaciontintoreria,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) {
      const OnLoadDataDetalle = async (programaciontintoreria) => {
        const datos =
          (await list_detalleprogramaciontintoreria_by_programaciontintoreria(
            programaciontintoreria
          )) || [];
        setData(datos);
      };
      setLoading(true);
      OnLoadDataDetalle(programaciontintoreria);
      setLoading(false);
    }
  }, [programaciontintoreria, open]);
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="detalleprogramaciontintoreria"
            rules={[
              {
                required: true,
                message:
                  "Seleccione al menos un elementro del detalle de programación de tintoreria",
              },
            ]}
          >
            <SearchFormProgramacionTintoreriaTable
              data={data}
              loading={loading}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
