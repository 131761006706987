import { Col, ColorPicker, Form, Input, Row } from "antd";
import React from "react";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";

export const ColorForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="codigohexadecimal"
            label="Color Hex."
            rules={[
              {
                required: true,
                message: "El color hexadecimal es obligatorio",
              },
            ]}
          >
            <ColorPicker format="hex" mode="single" showText allowClear />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
