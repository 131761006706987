import React from "react";
import dayjs from "dayjs";
import { CierreCajaForm } from "./CierreCajaForm";
import { useDispatch, useSelector } from "react-redux";
import {
  arqueoCajaStartAdd,
  arqueoCajaStartUpdate,
  cierreCajaClearActive,
  cierreCajaSetCurrentStep,
} from "../../../../actions/caja";
import { Form, Space, Tooltip, message } from "antd";
import { CardUi } from "../../../ui/CardUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { FormUi } from "../../../ui/FormUi";
import { showConfirm } from "../../../../helpers/confirmModal";

const initialValues = (data = {}) => ({
  id: data?.id || dayjs().valueOf(),
  fechacierre: dayjs(data?.fechacierre) || dayjs(),
  montoteorico: data?.montoteorico || 0,
  montoreal: data?.montoreal || 0,
  montodescuadre: data?.montodescuadre || 0,
  descuadre: data?.descuadre || 0,
  arqueador: data?.arqueador || null,
  observacion: data?.observacion || null,
  moneda: data?.moneda || null,
  detallearqueocaja: data?.detallearqueocaja || [],
});
export const CierreCajaScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeCajaAbierta, activeDetalleArqueoCaja, currentStep } =
    useSelector((state) => state.caja);

  const HandleCloseForm = () => {
    dispatch(cierreCajaClearActive());
    window.history.back();
  };
  const handleProviderSubmit = (name, { values, forms }) => {
    const { FormCierreCaja } = forms;
    if (name === "FormArqueocaja") {
      const detallearqueocaja =
        FormCierreCaja.getFieldValue("detallearqueocaja") || [];
      const _existe = detallearqueocaja?.filter(
        (x) => x.denominacionmoneda === values?.denominacionmoneda
      )?.length;
      if (_existe < 1)
        if (activeDetalleArqueoCaja) {
          FormCierreCaja.setFieldValue(
            "detallearqueocaja",
            detallearqueocaja?.map((e) => (e.id === values.id ? values : e))
          );
        } else
          FormCierreCaja.setFieldsValue({
            detallearqueocaja: [
              ...detallearqueocaja,
              { ...values, id: dayjs().valueOf() },
            ],
          });
      else message.error("el item ya existe en el detalle del arqueo de caja");
    } else if (name === "FormCierreCaja") {
      showConfirm("Esta seguro de cerrar la caja?", "", (isConfirmed) => {
        if (isConfirmed) {
          const valuesData = {
            ...values,
            aperturacaja: activeCajaAbierta?.id,
            fechacierre: dayjs(values?.fechacierre).format("YYYY-MM-DD"),
            empresa: activeEmpleado?.empresa,
          };
          if (activeCajaAbierta?.arqueocaja) {
            //Editar
            dispatch(arqueoCajaStartUpdate(valuesData));
          } else {
            //Agregar
            dispatch(arqueoCajaStartAdd(valuesData));
          }
          dispatch(
            cierreCajaSetCurrentStep({
              ...currentStep,
              current: currentStep?.current + 1,
            })
          );
        }
      });
    }
  };
  return (
    <Form.Provider onFormFinish={handleProviderSubmit}>
      <FormUi
        name="FormCierreCaja"
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={initialValues(activeCajaAbierta?.arqueocaja)}
      >
        <CardUi
          title="Cierre Caja"
          size="small"
          extra={
            <Space wrap>
              <Tooltip title="Salir">
                <ButtonUi
                  typeUi={TipoButtonConstants.Cancelar}
                  onClick={HandleCloseForm}
                />
              </Tooltip>
            </Space>
          }
        >
          <CierreCajaForm />
        </CardUi>
      </FormUi>
    </Form.Provider>
  );
};

export default CierreCajaScreen;
