export const types = {
  authLogin: "[auth] Login",
  authStartRegister: "[auth] Start Register",
  authStartTokenRenew: "[auth] Start token renew",
  authEmpleadoSetActive: "[auth] Empleado Set Active",
  authUsuariosLoaded: "[usuario] usuarios Loaded",

  authUsuarioChecking: "[usuario] Auth Usuario checking",

  authUsuarioSetActive: "[usuario] usuario Set Active",
  authUsuarioClearActive: "[usuario] usuario Clear Active",

  authUsuarioAdded: "[usuario] usuario added",
  authUsuarioUpdated: "[usuario] usuario updated",
  authUsuarioDeleted: "[usuario] usuario deleted",
  authUsuarioLogout: "[usuario] usuario logout",
};
