import React from "react";
import { Col, Form, Input, Row } from "antd";
import { FormItemHiddenUi } from "./../../../ui/FormItemHiddenUi";
import { SwitchUi } from "../../../ui/SwitchUi";

export const UsuarioForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Nombre de Usuario"
            name="username"
            rules={[
              {
                required: true,
                message: "El nombre de usuario es obligatorio",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="¿Está activo?"
            name="is_active"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "El password es obligatorio",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Nombre"
            name="first_name"
            rules={[
              {
                required: true,
                message: "El nombre es obligatorio",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label="Apellidos" name="last_name">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "El email es obligatorio",
              },
              {
                type: "email",
                message: "El email es obligatorio",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Form.Item
            label="¿Es super usuario?"
            name="is_superuser"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Form.Item
            label="¿Es empleado?"
            name="is_staff"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
