import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Form } from "antd";
import { FormUi } from "../../../ui/FormUi";
import { ItemDetalleForm } from "./ItemDetalleForm";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { movimientoClearActive } from "../../../../actions/inventario";

const initialValues = (data = {}) => ({
  id: data?.id || new dayjs().valueOf(),
  inventario: data?.inventario || null,
  item_nombre: data?.item_nombre || null,
  cantidad: parseInt(data?.cantidad) || 1,
  unidadmedida: data?.unidadmedida || null,
  unidadmedida_nombre: data?.unidadmedida_nombre || null,
  almacen: data?.almacen || null,
  series: data?.series || [],
  lotes: data?.lotes || [],
});
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({
  form,
  open,
  activeDetalleComprobanteCompra,
  almacen,
}) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(movimientoClearActive());
      form.resetFields();
    } else {
      //Abrir Modal
      if (activeDetalleComprobanteCompra) {
        form.setFieldsValue({
          ...activeDetalleComprobanteCompra,
          cantidad: parseInt(activeDetalleComprobanteCompra.cantidad),
        });
      } else {
        form.setFieldsValue({ id: new dayjs().valueOf(), almacen: almacen });
      }
    }
  }, [form, prevOpen, open]);
};
export const ItemDetalleModal = ({ open, onCancelModal, almacen }) => {
  const [form] = Form.useForm();

  const { activeDetalleComprobanteCompra } = useSelector(
    (state) => state.compra
  );
  useResetFormOnCloseModal({
    form,
    open,
    activeDetalleComprobanteCompra,
    almacen,
  });

  return (
    <ModalUi
      title="Busqueda de producto/servicio"
      open={open}
      onCancel={onCancelModal}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="ItemDetalleForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="ItemDetalleForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeDetalleComprobanteCompra)}
      >
        <ItemDetalleForm />
      </FormUi>
    </ModalUi>
  );
};
