import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const routes = { clientes: "clientes/" };

export const clientesStartLoad = () => {
  return async (dispatch) => {
    dispatch(clienteLoading());
    try {
      const resp = await axiosConToken.get(routes.clientes);
      const data = resp.data;
      dispatch(clientesLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    } finally {
      dispatch(clienteLoading(false));
    }
  };
};
export const clienteStartAdd = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.clientes, data);
      const { msg, cliente } = resp.data;
      dispatch(clienteAdded(cliente));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const clienteStartUpdate = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.clientes}${data.id}/`,
        data
      );
      const { msg, cliente } = resp.data;
      dispatch(clienteUpdated(cliente));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const clienteStartDelete = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(`${routes.clientes}${data.id}/`);
      const { msg, cliente } = resp.data;
      dispatch(clienteUpdated(cliente));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
const clientesLoaded = (clientes) => ({
  type: types.clienteLoaded,
  payload: clientes,
});
const clienteAdded = (cliente) => ({
  type: types.clienteAdded,
  payload: cliente,
});
const clienteUpdated = (cliente) => ({
  type: types.clienteUpdated,
  payload: cliente,
});
const clienteDeleted = (cliente) => ({
  type: types.clienteDeleted,
  payload: cliente,
});
export const clienteSetActive = (cliente) => ({
  type: types.clienteSetActive,
  payload: cliente,
});
export const clienteClearActive = () => ({ type: types.clienteClearActive });
export const clienteLoading = (loading = true) => ({
  type: types.proveedorLoading,
  payload: loading,
});
//TODO: Implement extra functions
// ASYNC FUNCTIONS
export const get_clientes_by_tipocliente = async (tipocliente) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.clientes}get_clientes_by_tipocliente/`,
      { params: { tipocliente } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_proveedores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`clientes/get_proveedores/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
