import React from "react";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterFilled,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MinusOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { TipoButtonConstants } from "../../types/typesConstants";
import { Tooltip } from "antd";
import { Button } from "antd";

export const ButtonUi = ({
  typeUi,
  icon = null,
  text = null,
  displayText = true,
  ...props
}) => {
  const LoadButton = (typeUi) => {
    switch (typeUi) {
      case TipoButtonConstants.Nuevo:
        return (
          <Button type="primary" {...props}>
            <PlusOutlined />
            {displayText && "Nuevo"}
          </Button>
        );
      case TipoButtonConstants.Actualizar:
        return (
          <Button type="success" {...props}>
            <ReloadOutlined />
            {displayText && "Actualizar"}
          </Button>
        );
      case TipoButtonConstants.Guardar:
        return (
          <Button type="primary" {...props}>
            <SaveOutlined />
            {displayText && (text == null ? "Guardar" : text)}
          </Button>
        );
      case TipoButtonConstants.Eliminar:
        return (
          <Button danger {...props}>
            <DeleteOutlined />
            {displayText && "Eliminar"}
          </Button>
        );
      case TipoButtonConstants.Cancelar:
        return (
          <Button danger {...props}>
            <CloseOutlined />
            {displayText && "Cancelar"}
          </Button>
        );
      case TipoButtonConstants.Filtrar:
        return (
          <Button type="default" {...props} className="ant-btn-info">
            <FilterFilled />
            {displayText && (text == null ? "Filtrar" : text)}
          </Button>
        );
      case TipoButtonConstants.Buscar:
        return (
          <Button type="primary" {...props}>
            <SearchOutlined />
            {displayText && (text == null ? "Buscar" : text)}
          </Button>
        );
      case TipoButtonConstants.Seleccionar:
        return (
          <Button type="primary" {...props}>
            <MenuUnfoldOutlined />
            {displayText && "Seleccionar"}
          </Button>
        );
      case TipoButtonConstants.Agregar:
        return (
          <Button type="success" {...props}>
            <PlusOutlined />
            {displayText && (text == null ? "Agregar" : text)}
          </Button>
        );
      case TipoButtonConstants.Disminuir:
        return (
          <Button type="primary" danger {...props}>
            <MinusOutlined />
            {displayText && (text == null ? "Quitar" : text)}
          </Button>
        );
      case TipoButtonConstants.AgregarTable:
        return (
          <Button type="default" {...props} className="ant-btn-success">
            <PlusOutlined />
            {displayText && "Agregar"}
          </Button>
        );
      case TipoButtonConstants.EditarTable:
        return (
          <Button type="default" {...props}>
            <EditOutlined />
            {displayText && "Editar"}
          </Button>
        );
      case TipoButtonConstants.EliminarTable:
        return (
          <Tooltip title="Eliminar">
            <Button danger {...props}>
              <DeleteOutlined />
              {displayText && "Eliminar"}
            </Button>
          </Tooltip>
        );
      case TipoButtonConstants.CustomButton:
        return (
          <Button {...props}>
            {icon}
            {displayText && (text == null ? "Agregar" : text)}
          </Button>
        );
      case TipoButtonConstants.Iniciar:
        return (
          <Button type="primary" {...props}>
            <PlayCircleOutlined />
            {displayText && "Iniciar"}
          </Button>
        );
      case TipoButtonConstants.Salir:
        return (
          <Button type="primary" danger {...props}>
            <LogoutOutlined />
            {displayText && "Salir"}
          </Button>
        );
      case TipoButtonConstants.OpcionesTable:
        return (
          <Button type="text" {...props}>
            <MoreOutlined />
            {displayText && "Opciones"}
          </Button>
        );
      default:
        return <></>;
    }
  };
  return <>{LoadButton(typeUi)}</>;
};
