import React, { useEffect, useState } from "react";
import { getTiposDocumentoIdentidad } from "../../helpers/loadConfiguraciones";
import { SelectUi } from "../ui/SelectUi";

export const TipoDocumentoSelect = ({ ...props }) => {
  const [tipodocumentos, setTipodocumentos] = useState([]);
  const OnLoadTipoDocumentos = async () => {
    const datos = await getTiposDocumentoIdentidad();
    setTipodocumentos(datos);
  };
  useEffect(() => {
    OnLoadTipoDocumentos();
  }, []);
  return <SelectUi data={tipodocumentos} {...props} />;
};
