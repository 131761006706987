import { Col, Row, Form, Input, InputNumber } from "antd";
import React from "react";
import { ImageUploadUi } from "../../ui/ImageUploadUi";
import { SwitchUi } from "../../ui/SwitchUi";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { MonedasSelect } from "../monedas/MonedasSelect";
import { DepartamentoSelect } from "../ubigeos/DepartamentoSelect";
import { ProvinciaSelect } from "../ubigeos/ProvinciaSelect";
import { DistritoSelect } from "../ubigeos/DistritoSelect";

export const EmpresaGeneralTab = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const esemisorelectronico = Form.useWatch("esemisorelectronico", form);
  const logo = Form.useWatch("logo", form);
  const rubrica = Form.useWatch("rubrica", form);
  const icono = Form.useWatch("icono", form);
  const departamento = Form.useWatch("departamento", form);
  const provincia = Form.useWatch("provincia", form);
  const changeDepartamento = () => {
    form.setFieldValue("provincia", null);
    form.setFieldValue("ubigeodireccion", null);
  };
  const changeProvincia = () => {
    form.setFieldValue("ubigeodireccion", null);
  };
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Numero RUC"
            name="numeroruc"
            className="mb-1"
            rules={[
              { required: true, message: "El número de ruc es obligatorio" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} maxLength="11" min={0} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16} xl={16}>
          <Form.Item
            label="Nombre"
            name="nombre"
            className="mb-1"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="nombrecomercial"
            label="Nombre comercial"
            className="mb-1"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            name="numerodetraccion"
            label="N° Detracción"
            className="mb-1"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="direccion"
            label="Dirección"
            className="mb-1"
            rules={[{ required: true, message: "La dirección es obligatorio" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            name="moneda"
            label="Moneda"
            className="mb-1"
            rules={[{ required: true, message: "La moneda es obligatoria" }]}
          >
            <MonedasSelect placeholder="Seleccione moneda" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item
            name="departamento"
            label="Departamento"
            rules={[
              { required: true, message: "El departamento es obligatorio" },
            ]}
          >
            <DepartamentoSelect
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={changeDepartamento}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item
            name="provincia"
            label="Provincia"
            rules={[{ required: true, message: "La provincia es obligatoria" }]}
          >
            <ProvinciaSelect
              departamento={departamento}
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={changeProvincia}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <Form.Item
            name="ubigeodireccion"
            label="Distrito"
            rules={[{ required: true, message: "El distrito es obligatorio" }]}
          >
            <DistritoSelect
              provincia={provincia}
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item label="¿Es emisor electrónico?" name="esemisorelectronico">
            <SwitchUi
              name="esemisorelectronico"
              checked={esemisorelectronico}
              onChange={(value) => setFieldValue("esemisorelectronico", value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item
            name="porcentajeigv"
            label="Impuesto (IGV, IVA)"
            className="mb-1"
            rules={[
              { required: true, message: "El porcentaje IGV es obligatorio" },
              { type: "number", message: "Ingrese un número válido" },
            ]}
          >
            <InputNumber
              style={{ width: "80%" }}
              addonAfter="%"
              precision={2}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="logo" label="Logo" className="mb-1">
            <ImageUploadUi name="logo" filelist={logo} form={form} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="rubrica" label="Rubrica" className="mb-1">
            <ImageUploadUi name="rubrica" filelist={rubrica} form={form} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="icono" label="Icono" className="mb-1">
            <ImageUploadUi name="icono" filelist={icono} form={form} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
