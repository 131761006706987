import React, { useEffect, useState } from "react";
import { Col, Descriptions, Row } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { configDataGraphits } from "../../helpers/utils";

ChartJS.register(ArcElement, Tooltip, Legend);

export const NotasVentaCardContent = () => {
  const { dataMontosNotasVenta } = useSelector((state) => state.dashboard);

  const [data, setData] = useState(
    configDataGraphits("S/ por medio de pagos", [], [])
  );
  const [totalGraphit, setTotalGraphit] = useState(0);
  useEffect(() => {
    let gLabels = [];
    let gData = [];
    let SumTotal = 0;
    dataMontosNotasVenta?.forEach((item) => {
      gLabels.push(item.mediopago);
      gData.push(item.monto);
      SumTotal += parseFloat(item.monto);
    });
    const result = configDataGraphits("S/ por medio de pagos", gLabels, gData);
    setData(result);
    setTotalGraphit(SumTotal.toFixed(2));
  }, [dataMontosNotasVenta]);

  return (
    <>
      <Row>
        <Col>
          <Doughnut data={data} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Total">
              {`S/ ${totalGraphit}`}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};
