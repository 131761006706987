import React, { useState } from "react";
import { TableUi } from "../../ui/TableUi";
import { Space, Tag, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import dayjs from "dayjs";
import {
  CloseOutlined,
  CloseSquareOutlined,
  EyeOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DetalleMovimientoPreviewModal } from "../reusable/DetalleMovimientoPreviewModal";
import { showDeleteConfirm } from "./../../../helpers/confirmModal";
import { useDispatch } from "react-redux";
import { comprobanteMovimientoStartAnulado } from "../../../actions/inventario";

const Tag_TipoMovimiento = (record) => {
  switch (record?.tipocomprobantemovimiento) {
    case "ENTRA":
      return (
        <span>
          <Tag color="success">
            <b>
              <PlusOutlined /> {record?.tipocomprobantemovimiento_nombre}
            </b>
          </Tag>
          <h6 style={{ margin: "4px 0px 0px 0px" }}>
            {record?.tipomovimiento_nombre}
          </h6>
        </span>
      );
    case "SALIDA":
      return (
        <span>
          <Tag color="error">
            <b>
              <MinusOutlined /> {record?.tipocomprobantemovimiento_nombre}
            </b>
          </Tag>
          <h6 style={{ margin: "4px 0px 0px 0px" }}>
            {record?.tipomovimiento_nombre}
          </h6>
        </span>
      );
    default:
      return (
        <span>
          <Tag color="error">
            <b>
              <CloseOutlined /> {record?.tipocomprobantemovimiento_nombre}
            </b>
          </Tag>
          <h6 style={{ margin: "4px 0px 0px 0px" }}>
            {record?.tipomovimiento_nombre}
          </h6>
        </span>
      );
  }
};
const columns = (acciones) => {
  const { handleShowDetail, handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
      render: (text, record, i) => <>{i + 1}</>,
    },
    {
      title: "Fecha-Hora",
      dataIndex: "fechahora",
      key: "fechahora",
      align: "center",
      render: (text, record, i) => (
        <>{dayjs(text).format(DateFormats.DatetimeFrontEnd)}</>
      ),
    },
    {
      title: "T.Movimiento",
      dataIndex: "tipocomprobantemovimiento_nombre",
      key: "tipocomprobantemovimiento_nombre",
      render: (text, record, i) => Tag_TipoMovimiento(record),
    },
    {
      title: "Comprobante",
      dataIndex: "comprobantemovimiento",
      key: "comprobantemovimiento",
      align: "center",
    },
    {
      title: "Almacén",
      dataIndex: "almacen_nombre",
      key: "almacen_nombre",
      align: "center",
    },
    {
      title: "Comprobante Asoc.",
      dataIndex: "comprobanteasociado",
      key: "comprobanteasociado",
      align: "center",
      render: (text, record) => {
        return (
          <>
            {record?.tipocomprobanteasociado && (
              <span>
                {record?.comprobanteasociado}
                <h6 style={{ margin: "0px" }}>
                  {record?.tipocomprobanteasociado}
                </h6>
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Detalle",
      dataIndex: "cantidaditems",
      key: "cantidaditems",
      align: "center",
      render: (text, record, i) => (
        <>
          <ButtonUi
            typeUi={TipoButtonConstants.CustomButton}
            icon={<EyeOutlined />}
            text={record?.detallescomprobantemovimiento?.length}
            size="small"
            onClick={() => handleShowDetail(record)}
          />
        </>
      ),
    },
    {
      title: "Obs.",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (value, record) => (
        <>
          <Space wrap>
            <Tooltip title="Anular">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                displayText={false}
                size="small"
                onClick={() => handleEliminar(record)}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
};
export const MovimientosTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [dataDetalle, setDataDetalle] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleHideModal = () => setOpen(false);
  const handleEliminar = (data) => {
    showDeleteConfirm(
      "¿Está seguro de anular el comprobante?",
      data?.comprobantemovimiento,
      (confirm) => {
        if (confirm) {
          dispatch(comprobanteMovimientoStartAnulado(data?.id));
        }
      }
    );
  };
  const handleShowDetail = (data) => {
    setDataDetalle(data?.detallescomprobantemovimiento);
    handleOpenModal();
  };
  return (
    <>
      <TableUi
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns({ handleEliminar, handleShowDetail })}
        data={data}
      />
      <DetalleMovimientoPreviewModal
        dataDetalle={dataDetalle}
        open={open}
        handleCancelModal={handleHideModal}
      />
    </>
  );
};
