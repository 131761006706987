import React from "react";
import { useDispatch } from "react-redux";
import {
  ordenProduccionSetActive,
  ordenProduccionStartDelete,
  showFormOrdenProduccion,
} from "../../../../actions/produccion";
import { showDeleteConfirm } from "../../../../helpers/confirmModal";
import { TableUi } from "../../../ui/TableUi";
import {
  EstadoOrdenProduccion,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { Space, Tag } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const TagEstado = ({ data }) => {
  switch (data?.estado) {
    case EstadoOrdenProduccion.CUMPLIDO:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {data?.estado}
        </Tag>
      );

    case EstadoOrdenProduccion.CANCELADO:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {data?.estado}
        </Tag>
      );
    case EstadoOrdenProduccion.PLANEADO:
      return (
        <Tag icon={<CalendarOutlined />} color="default">
          {data?.estado}
        </Tag>
      );
    default:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="processing">
          {data?.estado}
        </Tag>
      );
  }
};

const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Item",
      dataIndex: "item_nombre",
      key: "item_nombre",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Unidad Medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Fec.Hora inicio",
      dataIndex: "fechahorainicio",
      key: "fechahorainicio",
      render: (text) => <>{dayjs(text).format("DD/MM/YYYY HH:mm:ss")}</>,
    },
    {
      title: "Fec.Hora fin",
      dataIndex: "fechahorafin",
      key: "fechahorafin",
      render: (text) => <>{dayjs(text).format("DD/MM/YYYY HH:mm:ss")}</>,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (text, record) => <TagEstado data={{ ...record }} />,
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <Space wrap>
          <ButtonUi
            key="Editar"
            displayText={false}
            typeUi={TipoButtonConstants.EditarTable}
            onClick={() => handleEditar(record)}
          />
          <ButtonUi
            key="Eliminar"
            displayText={false}
            typeUi={TipoButtonConstants.EliminarTable}
            onClick={() => handleEliminar(record)}
          />
        </Space>
      ),
    },
  ];
};
export const OrdenProduccionTable = ({ data }) => {
  const dispatch = useDispatch();
  const handleEditar = (record) => {
    dispatch(ordenProduccionSetActive(record));
    dispatch(showFormOrdenProduccion(true));
  };
  const handleEliminar = (record) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la ruta de programación?",
      record.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(ordenProduccionStartDelete(record));
        }
      }
    );
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEditar, handleEliminar })}
      rowKey="id"
      size="small"
    />
  );
};
