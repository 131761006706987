import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { search_items_by_param } from "../../../actions/inventario";

export const InputSearchDropdown = () => {
  const form = Form.useFormInstance();
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await search_items_by_param(value);
      const options = data.map((item) => ({
        label: item?.label,
        key: item?.key,
      }));
      setItems(options);
      setItemsData(data);
    }
  };
  const onClick = ({ key }) => {
    const itemSelect = itemsData?.find((x) => x.key === parseInt(key));
    form.setFieldsValue({
      item_nombre: itemSelect?.label,
      item: key,
      manejalotes: itemSelect?.manejalotes,
      manejaseries: itemSelect?.manejaseries,
      tipoafectacionigv: itemSelect?.tipoafectacionigv,
      grupotipoafectacionigv: itemSelect?.grupotipoafectacionigv,
      unidadmedida: itemSelect?.unidadmedida,
      unidadmedida_nombre: itemSelect?.unidadmedida_nombre,
    });
  };
  const handleChangeSearch = () => {
    form.setFieldsValue({
      item: null,
      manejalotes: null,
      manejaseries: null,
      tipoafectacionigv: null,
      grupotipoafectacionigv: null,
      unidadmedida: null,
      unidadmedida_nombre: null,
    });
  };
  return (
    <Dropdown menu={{ items, onClick }}>
      <Form.Item name="item_nombre">
        <Input.Search
          placeholder="Buscar producto / servicio"
          allowClear
          enterButton
          onSearch={handleSearch}
          onChange={handleChangeSearch}
        />
      </Form.Item>
    </Dropdown>
  );
};
