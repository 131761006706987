import { Form, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { get_monedas } from "../../../actions/comprobante";

export const MonedaRadio = ({ ...props }) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const [monedas, setMonedas] = useState([]);
  useEffect(() => {
    const OnLoadData = async () => {
      const datos = await get_monedas();
      setMonedas(datos);
      const OptionDefault =
        datos.filter((x) => x.pordefecto === true)[0] || datos?.[0];
      setFieldValue("moneda", OptionDefault?.value || null);
      setFieldValue("prefijo", OptionDefault?.prefijo || null);
    };
    OnLoadData();
  }, []);
  useEffect(() => {
    const moneda = monedas.filter((x) => x.value === props?.value)?.[0];
    setFieldValue("prefijo", moneda?.prefijo || null);
  }, [props?.value]);

  return (
    <Radio.Group
      options={monedas}
      buttonStyle="solid"
      optionType="button"
      {...props}
      // onChange={OnChangeMoneda}
    />
  );
};
