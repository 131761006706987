import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { FormUi } from "../../../ui/FormUi";
import { Form } from "antd";
import dayjs from "dayjs";
import { MaquinaForm } from "./MaquinaForm";
import { useDispatch, useSelector } from "react-redux";
import { maquinaClearActive } from "../../../../actions/produccion";
import { ButtonUi } from "../../../ui/ButtonUi";
const initialValues = {
  id: new dayjs().valueOf(),
  nombre: null,
  descripcion: null,
  fechaadquisicion: dayjs(),
};
const useResetFormOnCloseModal = ({ form, open, activeMaquina }) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(maquinaClearActive());
      form.resetFields();
    } else {
      //Abrir Modal
      if (activeMaquina) {
        form.setFieldsValue({
          ...activeMaquina,
          fechaadquisicion: dayjs(activeMaquina?.fechaadquisicion),
        });
      } else {
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const MaquinaModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();
  const { activeMaquina } = useSelector((state) => state.produccion);
  useResetFormOnCloseModal({ form, open, activeMaquina });
  return (
    <ModalUi
      title="Maquina"
      open={open}
      onCancel={onCancelModal}
      width="40%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="MaquinaForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="MaquinaForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
      >
        <MaquinaForm />
      </FormUi>
    </ModalUi>
  );
};
