import { Col, Form, Input, InputNumber, Row, Space, Switch } from "antd";
import React, { useState } from "react";
import { ColorSelect } from "../../reusable/ColorSelect";
import { TipoTenidoTelaSelect } from "../../reusable/TipoTenidoTelaSelect";
import { useDispatch } from "react-redux";
import { detalleProgramacionTintoreriaSetActive } from "../../../../../actions/produccion";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { SearchComprobanteCompraModal } from "./modal/SearchComprobanteCompraModal";
import { MonedaSelect } from "../../../../ventas/reusable/MonedaSelect";
import { AlmacenSelect } from "../../../reusable/AlmacenSelect";
import { InputSearchDropdown } from "./ItemSeachDropdown";

export const DetalleProgramacionTabItem = ({
  field,
  handleOpenModal,
  disabledControl,
}) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [openModalComprobante, setOpenModalComprobante] = useState(false);
  const detalleprogramaciontintoreria = Form.useWatch(
    "detalleprogramaciontintoreria",
    form
  );
  const estado = Form.useWatch(
    ["detalleprogramaciontintoreria", field?.name, "estado"],
    form
  );
  const handleOpenModalComprobante = () => setOpenModalComprobante(true);
  const handleHideModalComprobante = () => setOpenModalComprobante(false);
  const handleEditar = (row, e) => {
    e.preventDefault();
    dispatch(detalleProgramacionTintoreriaSetActive(row));
    handleOpenModal();
  };
  const handleSelectComprobanteCompra = (row, e) => {
    e.preventDefault();
    dispatch(detalleProgramacionTintoreriaSetActive(row));
    handleOpenModalComprobante();
  };
  return (
    <>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 24 }]}
        style={{ marginBottom: "10px" }}
      >
        <FormItemHiddenUi name={[field.name, "id"]} />
        <FormItemHiddenUi name={[field.name, "item"]} />
        <Col xs={24} sm={24} md={16} lg={14} xl={12}>
          <Space.Compact style={{ width: "100%" }}>
            <Form.Item
              name={[field.name, "detalleprogramaciontejido_nombre"]}
              label="Programación tejido"
              rules={[
                { required: true, message: "El tipo de tela es obligatorio" },
              ]}
              style={{ marginBottom: 0, width: "100%" }}
            >
              <Input.Search
                readOnly
                placeholder="Buscar programación tintoreria"
                allowClear
                enterButton
                onSearch={(_, e) =>
                  handleEditar(detalleprogramaciontintoreria[field?.name], e)
                }
                disabled={disabledControl}
              />
            </Form.Item>
          </Space.Compact>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            name={[field.name, "color"]}
            label="Color"
            rules={[{ required: true, message: "El color es obligatorio" }]}
            style={{ marginBottom: 0, width: "100%" }}
          >
            <ColorSelect
              placeholder="[-Seleccione-]"
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={4}>
          <Form.Item
            label="Tipo teñido"
            name={[field.name, "tipotenidotela"]}
            rules={[
              {
                required: true,
                message: "El tipo de teñido de tela es obligatorio",
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <TipoTenidoTelaSelect
              style={{ width: "100%" }}
              placeholder="[-Seleccione-]"
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 24 }]}
        style={{ marginBottom: "10px" }}
      >
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Cantidad rollos"
            name={[field.name, "cantidadrollos"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estado,
                message: "La cantidad real es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese cantidad de rollos"
              style={{ width: "100%" }}
              precision={2}
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Peso bruto (Kg)"
            name={[field.name, "pesobruto"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estado,
                message: "La peso bruto es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese peso bruto"
              style={{ width: "100%" }}
              precision={2}
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Peso neto (Kg)"
            name={[field.name, "pesoneto"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estado,
                message: "La peso neto es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese peso neto"
              style={{ width: "100%" }}
              precision={2}
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            name={[field.name, "estado"]}
            style={{ marginBottom: 0 }}
            label="¿Se cumplió?"
            valuePropName="checked"
          >
            <Switch
              checkedChildren="SI"
              unCheckedChildren="NO"
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Form.Item
            name={[field.name, "almacen"]}
            label="Almacén"
            rules={[{ required: estado, message: "El almacén es obligatorio" }]}
          >
            <AlmacenSelect
              placeholder="[-Seleccione-]"
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={16}>
          <InputSearchDropdown
            field={field}
            name={[field.name, "item_nombre"]}
            label="Producto/Servicio referencia"
            placeholder="Buscar producto / servicio"
            disabled={disabledControl}
          />
        </Col>
      </Row>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={12}>
          <Space.Compact style={{ width: "100%" }}>
            <Form.Item
              name={[field.name, "detallecomprobantecompra_nombre"]}
              label="Comprobante compra"
              rules={[
                {
                  required: estado,
                  message: "El comprobante de compra es obligatorio",
                },
              ]}
              style={{ marginBottom: 0, width: "100%" }}
            >
              <Input.Search
                readOnly
                placeholder="Buscar comprobante compra"
                allowClear
                enterButton
                onSearch={(_, e) =>
                  handleSelectComprobanteCompra(
                    detalleprogramaciontintoreria[field?.name],
                    e
                  )
                }
                disabled={disabledControl}
              />
            </Form.Item>
          </Space.Compact>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Precio"
            name={[field.name, "precio"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estado,
                message: "El precio es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese precio"
              style={{ width: "100%" }}
              precision={2}
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Moneda"
            name={[field.name, "moneda"]}
            rules={[
              {
                required: estado,
                message: "La moneda es obligatoria",
              },
            ]}
          >
            <MonedaSelect
              placeholder="[-Seleccione-]"
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Tipo de cambio"
            name={[field.name, "tipocambio"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estado,
                message: "El tipo de cambio es obligatoria",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese tipo de cambio"
              style={{ width: "100%" }}
              precision={4}
              disabled={disabledControl}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 24 }]}
        style={{ marginBottom: "10px" }}
      >
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            label="Partida"
            name={[field.name, "partida"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: estado,
                message: "La partida es obligatoria",
              },
            ]}
          >
            <Input placeholder="Ingrese partida" disabled={disabledControl} />
          </Form.Item>
        </Col>
      </Row>
      <SearchComprobanteCompraModal
        open={openModalComprobante}
        onCancel={handleHideModalComprobante}
      />
    </>
  );
};
