import { Col, Row } from "antd";
import React from "react";
import { ListaPrecioItemDetalle } from "./ListaPrecioItemDetalle";

export const ListaPrecioItemsTab = () => {
  return (
    <Row>
      <Col xs="24" sm="24" md="24" lg="24" xl="24">
        <ListaPrecioItemDetalle />
      </Col>
    </Row>
  );
};
