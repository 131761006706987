import { message } from "antd";
import { axiosConToken } from "./axios";
const getEmpleados = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("terminalcajero/get_empleados/");
    datos = resp.data;
  } catch (error) {
    message.error(error.response);
  }
  return datos;
};

export { getEmpleados };
