import React from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { CardUi } from "../../ui/CardUi";
import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { TipoComprobanteSelect } from "../reusable/TipoComprobanteSelect";
import { MonedasSelect } from "../../generales/monedas/MonedasSelect";
import { ComprobanteCompraFormTable } from "./ComprobanteCompraFormTable";
import { MediosPagoComprobanteCompra } from "./MediosPagoComprobanteCompra";
import { ProveedorSearchSelect } from "../../generales/clientes/reusable/ProveedorSearchSelect";
import { ComprobanteCompraFileUpload } from "./ComprobanteCompraFileUpload";

export const ComprobanteCompraForm = ({ onOpenModal, handleCancelForm }) => {
  const form = Form.useFormInstance();
  const detallescomprobantecompra = Form.useWatch(
    "detallescomprobantecompra",
    form
  );
  const tienepagos = Form.useWatch("tienepagos", form);
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const proveedor_nombre = Form.useWatch("proveedor_nombre", form);
  const archivo = Form.useWatch("archivo", form);

  const handleNuevoDetalle = () => onOpenModal();

  const handleDeleteFileUpload = () => {
    form.setFieldValue("archivo", null);
  };

  return (
    <CardUi
      key="cardComprobante"
      title="Comprobante compra"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancelForm}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormComprobanteCompra"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <Row gutter={[8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <FormItemHiddenUi name="id" />
          <FormItemHiddenUi name="proveedor_nombre" />
          <FormItemHiddenUi name="montototalgravadas" />
          <FormItemHiddenUi name="montototalexoneradas" />
          <FormItemHiddenUi name="montototalinafectas" />
          <FormItemHiddenUi name="montoigvtotal" />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={7} xl={6}>
              <Form.Item
                name="tipocomprobante"
                label="Tipo comprobante"
                rules={[
                  {
                    required: true,
                    message: "Seleccione el tipo de comprobante",
                  },
                ]}
              >
                <TipoComprobanteSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="nrocomprobante"
                label="Número"
                rules={[
                  { required: true, message: "El número es obligatorio" },
                ]}
              >
                <Input
                  placeholder="Ingrese número"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={5} xl={5}>
              <Form.Item
                name="fechaemision"
                label="Fecha Emisión"
                rules={[
                  {
                    required: true,
                    message: "La fecha de emisión es obligatoria",
                  },
                ]}
                required={false}
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={5} xl={5}>
              <Form.Item
                name="fechavencimiento"
                label="Fecha Vencimiento"
                rules={[
                  {
                    required: true,
                    message: "La fecha de vencimiento es obligatoria",
                  },
                ]}
                required={false}
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="proveedor"
                label="Proveedor"
                rules={[
                  {
                    required: true,
                    message: "El proveedor es obligatorio",
                  },
                ]}
                required={false}
              >
                <ProveedorSearchSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={5}>
              <Form.Item
                name="moneda"
                label="Moneda"
                rules={[
                  {
                    required: true,
                    message: "La moneda es obligatoria",
                  },
                ]}
                required={false}
              >
                <MonedasSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={5} xl={5}>
              <Form.Item
                name="tipocambio"
                label="Tipo cambio"
                rules={[
                  {
                    required: true,
                    message: "Campo obligatorio",
                  },
                ]}
                required={false}
              >
                <InputNumber
                  precision={4}
                  style={{ width: "100%" }}
                  placeholder="Ingrese tipo de cambio"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Space>
                <Form.Item name="tienepagos" valuePropName="checked">
                  <Switch />
                </Form.Item>
                ¿Desea agregar pagos a esta compra?
              </Space>

              {tienepagos && <MediosPagoComprobanteCompra />}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Space>
                <Form.Item name="tieneobservacion" valuePropName="checked">
                  <Switch />
                </Form.Item>
                Añadir observación
              </Space>
            </Col>
          </Row>
          {tieneobservacion && (
            <Row>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Form.Item name="observacion">
                  <Input.TextArea rows={2} placeholder="Observaciones" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                title={
                  <span>
                    <UnorderedListOutlined /> Detalle comprobante compra
                  </span>
                }
                size="small"
                type="inner"
              >
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ButtonUi
                      typeUi={TipoButtonConstants.AgregarTable}
                      onClick={handleNuevoDetalle}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.List
                      name="detallescomprobantecompra"
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (value < 1)
                              return Promise.reject(
                                new Error(
                                  "Ingrese al menos un item en el detalle de compra"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          <ComprobanteCompraFormTable
                            detallescomprobantecompra={
                              detallescomprobantecompra
                            }
                            handleDelete={remove}
                            handleOpenModal={onOpenModal}
                          />
                          <Form.Item className="ui-errorlist">
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <FormItemHiddenUi name="archivo" />
          <CardUi
            key="archivo"
            type="inner"
            title="Archivo"
            size="small"
            className="DragDropComprobanteCompra"
            extra={
              archivo?.length > 0 && [
                <Button
                  key="1"
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={handleDeleteFileUpload}
                >
                  Eliminar archivo
                </Button>,
              ]
            }
          >
            <ComprobanteCompraFileUpload />
          </CardUi>
        </Col>
      </Row>
    </CardUi>
  );
};
