import React, { useEffect, useState } from "react";
import { get_estados_procesoproduccion } from "../../../../actions/produccion";
import { SelectUi } from "../../../ui/SelectUi";

export const EstadoProcesoProduccion = ({ ...props }) => {
  const [estados, setEstados] = useState([]);
  const OnLoadDatos = async () => {
    const datos = await get_estados_procesoproduccion();
    setEstados(datos);
  };
  useEffect(() => {
    OnLoadDatos();
  }, []);

  return <SelectUi data={estados} {...props} />;
};
