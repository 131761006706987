import { Form, message } from "antd";
import React, { useState } from "react";
import { searchProduct_by_store_and_name } from "../../../actions/inventario";
import { SearchSelectUI } from "../../ui/SearchSelectUI";

export const ProductoSearchSelect = ({ almacen }) => {
  const form = Form.useFormInstance();
  const [products, setProducts] = useState([]);

  const handleSearch = async (value) => {
    if (almacen && value) {
      const data = await searchProduct_by_store_and_name(almacen, value);
      if (data) {
        setProducts(data);
      }
    } else {
      message.error("Seleccione el almacen y/o ingrese el item a buscar");
    }
  };

  const OnChange = (value, field) => {
    form.setFieldsValue({
      inventario: value || null,
      item_nombre: field?.label || null,
      unidadmedida: field?.unidadmedida || null,
      unidadmedida_nombre: field?.unidadmedida_nombre || null,
      stockactual: field?.stockactual || 0,
      manejalotes: field?.manejalotes || null,
      manejaseries: field?.manejaseries || null,
      lotes: field?.lotes || [],
      series: field?.series || [],
    });
  };

  return (
    <SearchSelectUI
      handleSearch={handleSearch}
      onChange={OnChange}
      data={products}
      allowClear
      placeholder="Buscar Producto"
    />
  );
};
