import { Space, Tag } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { empresaSetActive, empresaStartDelete } from "../../../actions/empresa";
import { uiShowForm } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TableUi } from "../../ui/TableUi";

const columns = (acciones) => [
  {
    title: "N°",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
  },
  {
    title: "RUC",
    dataIndex: "numeroruc",
    key: "numeroruc",
  },
  {
    title: "Dirección",
    dataIndex: "direccion",
    key: "direccion",
  },
  {
    title: "N° detraccion",
    dataIndex: "numerodetraccion",
    key: "numerodetraccion",
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
    render: (text, record) =>
      text === true ? (
        <Tag color="green">Activo</Tag>
      ) : (
        <Tag color="red">Inactivo</Tag>
      ),
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <Space size="middle">
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          onClick={() => acciones.handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          onClick={() => acciones.handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const EmpresasTable = ({ data }) => {
  const dispatch = useDispatch();
  const handleEditar = (data) => {
    dispatch(empresaSetActive(data));
    dispatch(uiShowForm());
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la empresa seleccionado?",
      `${item.nombre}`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(empresaStartDelete(item));
        }
      }
    );
  };

  return (
    <TableUi
      columns={columns({ handleEditar, handleEliminar })}
      rowKey="id"
      bordered
      dataSource={data}
      size="small"
    />
  );
};
