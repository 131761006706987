import React from "react";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TableUi } from "../../../ui/TableUi";

const columns = (acciones) => {
  const { handleDeleteRow } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Tipo Comprobante",
      dataIndex: "tipocomprobante_nombre",
      key: "tipocomprobante_nombre",
    },
    {
      title: "N° Comprobante",
      dataIndex: "comprobante",
      key: "comprobante",
    },
    {
      title: "Moneda",
      dataIndex: "moneda_nombre",
      key: "moneda_nombre",
    },
    {
      title: "Total Op.Gravadas",
      dataIndex: "montototalgravadas",
      key: "montototalgravadas",
    },
    {
      title: "Total Op.Gravadas",
      dataIndex: "montototalgravadas",
      key: "montototalgravadas",
    },
    {
      title: "IGV",
      dataIndex: "montoigvtotal",
      key: "montoigvtotal",
    },
    {
      title: "Total",
      dataIndex: "montototal",
      key: "montototal",
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record, index) => (
        <ButtonUi
          key={`${TipoButtonConstants.Eliminar}${index}`}
          typeUi={TipoButtonConstants.Eliminar}
          onClick={() => handleDeleteRow(record)}
          displayText={false}
        />
      ),
    },
  ];
};
export const ComprobantesModalTable = ({ name, data, ...props }) => {
  const { values, setFieldValue } = props;

  const handleDeleteRow = (comprobante) => {
    let comprobantes = values?.detalleresumencomprobante;
    comprobantes = comprobantes.filter((e) => e.id !== comprobante.id);
    setFieldValue("detalleresumencomprobante", comprobantes);
  };
  return (
    <TableUi
      rowKey="id"
      name={name}
      columns={columns({ handleDeleteRow })}
      data={data}
      {...props}
    />
  );
};
