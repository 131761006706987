import React from "react";
import { TableUi } from "../../ui/TableUi";
import { Form, Input, InputNumber, Space, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TipoOperacionSelect } from "../reusable/TipoOperacionSelect";
import { TipoEmisionSelect } from "../reusable/TipoEmisionSelect";
import { TipoComprobanteSelect } from "../reusable/TipoComprobanteSelect";
import { SwitchUi } from "./../../ui/SwitchUi";

const columns = (props) => {
  const { handleEliminar, form } = props;
  const { setFieldValue } = form;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Tipo operación",
      dataIndex: "tipooperacion",
      key: "tipooperacion",
      render: (text, field, index) => (
        <>
          <Form.Item
            name={[field.name, "tipooperacion"]}
            rules={[
              { required: true, message: "La lista de precio es obligatorio" },
            ]}
            style={{ marginBottom: 0, width: "90%" }}
          >
            <TipoOperacionSelect
              placeholder="[-Seleccione-]"
              onChange={(value) => {
                setFieldValue(
                  ["detalleterminal", field.name, "tipocomprobante"],
                  null
                );
              }}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Tipo Emisión",
      dataIndex: "tipoemision",
      key: "tipoemision",
      render: (text, field) => (
        <Form.Item
          name={[field.name, "tipoemision"]}
          rules={[
            { required: true, message: "El tipo de emisión es obligatorio" },
          ]}
          style={{ marginBottom: 0 }}
        >
          <TipoEmisionSelect
            onChange={(value) =>
              setFieldValue(
                ["detalleterminal", field.name, "tipocomprobante"],
                null
              )
            }
            style={{ width: "100%" }}
            placeholder="[-Seleccione-]"
          />
        </Form.Item>
      ),
    },
    {
      title: "Tipo comprobante",
      dataIndex: "tipocomprobante",
      key: "tipocomprobante",
      render: (text, field) => (
        <Form.Item
          name={[field.name, "tipocomprobante"]}
          rules={[
            {
              required: true,
              message: "El tipo de comprobante es obligatorio",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <TipoComprobanteSelect
            placeholder="[-Seleccione-]"
            nametipooperacion={["detalleterminal", field.name, "tipooperacion"]}
            nametipoemision={["detalleterminal", field.name, "tipoemision"]}
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Serie",
      dataIndex: "seriecomprobante",
      key: "seriecomprobante",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "seriecomprobante"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "La serie de comprobante es obligatorio",
            },
          ]}
        >
          <Input placeholder="Ingrese Serie de comprobante" />
        </Form.Item>
      ),
    },
    {
      title: "N° Comprobante Actual",
      dataIndex: "nrocomprobanteactual",
      key: "nrocomprobanteactual",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "nrocomprobanteactual"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "El número de comprobante actual es obligatorio",
            },
          ]}
        >
          <InputNumber
            placeholder="Ingrese N° actual"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Por defecto",
      dataIndex: "tipocomprobantepordefecto",
      key: "tipocomprobantepordefecto",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "tipocomprobantepordefecto"]}
          className="classRowFieldTable"
          valuePropName="checked"
        >
          <SwitchUi />
        </Form.Item>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, field) => (
        <Space size="middle">
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleEliminar(field)}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};
export const TerminalCajeroDetalleTableItem = ({
  data,
  handleDelete,
  title,
}) => {
  const form = Form.useFormInstance();

  const handleEliminar = (field) => {
    handleDelete(field?.name);
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEliminar, form })}
      size="small"
      title={title}
    />
  );
};
