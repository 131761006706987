import { List } from "antd";
import React from "react";
import { ProductoCardItem } from "./ProductoCardItem";
export const ProductosList = ({ productos, loading }) => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 4,
        xxl: 4,
      }}
      dataSource={productos}
      loading={loading}
      renderItem={(item) => (
        <List.Item>
          <ProductoCardItem producto={item} />
        </List.Item>
      )}
      style={{ padding: 10, height: "60vh", overflow: "auto" }}
      pagination={{
        position: "bottom",
        align: "center",
      }}
    />
  );
};
