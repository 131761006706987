import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { comprobantespagoSendSunat } from "../../../../actions/comprobante";

const ButtonsTable = ({ comprobante, acciones }) => {
  const { OnHandleEnviarComprobante } = acciones;
  return (
    <>
      <Button
        with="dashed"
        type="primary"
        onClick={() => OnHandleEnviarComprobante(comprobante)}
        disabled={comprobante.dias_faltantes < 0}
      >
        <SendOutlined /> Enviar
      </Button>
    </>
  );
};
const columns = (acciones) => {
  const TagEstadoMensaje = (record, mensaje) => {
    let tagestado = null;
    if (record?.dias_faltantes < 0) {
      tagestado = (
        <Tag
          key={`t-${record?.id}`}
          icon={<CloseCircleOutlined />}
          color="error"
        >
          {mensaje}
        </Tag>
      );
    } else if (record?.dias_faltantes === 0) {
      tagestado = (
        <Tag
          key={`t-${record?.id}`}
          icon={<ExclamationCircleOutlined />}
          color="warning"
        >
          {mensaje}
        </Tag>
      );
    } else if (record?.dias_faltantes > 0) {
      tagestado = (
        <Tag
          key={`t-${record?.id}`}
          icon={<ClockCircleOutlined />}
          color="default"
        >
          {mensaje}
        </Tag>
      );
    }
    return tagestado;
  };
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "T. Nro.Documento",
      dataIndex: "cliente_nrodocumento",
      key: "cliente_nrodocumento",
      align: "center",
      render: (text, record) => (
        <>
          {text}
          <h5 style={{ margin: 0 }}>{record.cliente_tipodocumento}</h5>
        </>
      ),
    },
    {
      title: "Cliente",
      dataIndex: "cliente_nombre",
      key: "cliente_nombre",
    },
    {
      title: "Tipo comprobante",
      dataIndex: "tipocomprobante_nombre",
      key: "tipocomprobante_nombre",
    },
    {
      title: "Comprobante",
      dataIndex: "comprobante",
      key: "comprobante",
    },
    {
      title: "Días para enviar",
      dataIndex: "mensajes_sunat",
      key: "mensajes_sunat",
      align: "center",
      render: (text, record) => <>{TagEstadoMensaje(record, text)}</>,
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <ButtonsTable comprobante={record} acciones={acciones} />
      ),
    },
  ];
};
export const ComprobantePagoNoEnviadoTable = ({ comprobantes }) => {
  const dispatch = useDispatch();
  const OnHandleEnviarComprobante = (comprobante) => {
    dispatch(comprobantespagoSendSunat(comprobante));
  };
  return (
    <Table
      columns={columns({
        OnHandleEnviarComprobante,
      })}
      dataSource={comprobantes}
      rowKey="id"
      size="small"
    />
  );
};
