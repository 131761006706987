import React, { useEffect, useState } from "react";
import { getUnidadesMedida_SelectOptions } from "../../../actions/producto";
import { SelectUi } from "../../ui/SelectUi";

export const UnidadMedidaSelect = ({ ...props }) => {
  const [datos, setDatos] = useState([]);
  const OnLoad = async () => {
    const data = await getUnidadesMedida_SelectOptions();
    setDatos(data);
  };
  useEffect(() => {
    OnLoad();
  }, []);

  return <SelectUi data={datos} {...props}></SelectUi>;
};
