import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get_clientes_by_tipocomprobante } from "../../../../actions/comprobante";
import { SelectUi } from "../../../ui/SelectUi";

export const ClienteSelect = ({ tipocomprobante, ...props }) => {
  const [clientes, setClientes] = useState([]);
  const { visibleModal } = useSelector((state) => state.ui);
  const OnLoadClientes = async () => {
    const datos = await get_clientes_by_tipocomprobante(tipocomprobante);
    setClientes(datos);
  };
  useEffect(() => {
    OnLoadClientes(tipocomprobante);
  }, [tipocomprobante]);

  useEffect(() => {
    if (visibleModal === false) {
      OnLoadClientes();
    }
  }, [visibleModal]);

  return <SelectUi data={clientes} {...props} />;
};
