import React, { useEffect, useState } from "react";
import { get_items_a_producir } from "../../../actions/produccion";
import { SearchSelectUI } from "../../ui/SearchSelectUI";

export const ItemSelect = ({ ...props }) => {
  const [items, setItems] = useState([]);
  const OnLoadDatos = async () => {
    const datos = await get_items_a_producir();
    setItems(datos);
  };
  useEffect(() => {
    OnLoadDatos();
  }, []);

  return <SearchSelectUI options={items} {...props} />;
};
