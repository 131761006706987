import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Row } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AlmacenEmpleadoSelect } from "../reusable/AlmacenEmpleadoSelect";

import { InputSearchDropdown } from "../reusable/ItemSearchDropdown";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { UnidadMedidaSelect } from "../reusable/UnidadMedidaSelect";
export const CotizacionProductoModalForm = () => {
  const form = Form.useFormInstance();
  const cantidad = Form.useWatch("cantidad", form);
  const preciounitario = Form.useWatch("preciounitario", form);
  const almacen = Form.useWatch("almacen", form);
  const { setFieldsValue } = form;
  const { activeEmpleado } = useSelector((state) => state.auth);
  const handleChangeAmount = (operator) => {
    let amount = cantidad;
    if (operator === "minus") {
      amount -= 1;
    } else {
      amount += 1;
    }
    setFieldsValue({ cantidad: amount });
  };

  useEffect(() => {
    const total = cantidad * preciounitario;
    const igv = (activeEmpleado?.empresa_porcentajeigv || 0) / 100;
    let valorigv = igv * preciounitario;
    let valorunitario = preciounitario - valorigv;
    let subtotalventa = cantidad * valorunitario;

    valorigv = parseFloat(valorigv.toFixed(2));
    valorunitario = parseFloat(valorunitario.toFixed(2));
    subtotalventa = parseFloat(subtotalventa.toFixed(2));
    setFieldsValue({
      valorunitario,
      subtotalventa,
      valorigv,
      totalventa: total,
    });
  }, [
    cantidad,
    preciounitario,
    setFieldsValue,
    activeEmpleado?.empresa_porcentajeigv,
  ]);

  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="item" />
      <FormItemHiddenUi name="unidadmedida_nombre" />
      <FormItemHiddenUi name="valorunitario" />
      <FormItemHiddenUi name="subtotalventa" />
      <FormItemHiddenUi name="valorigv" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="almacen"
            label="Almacén"
            rules={[{ required: true, message: "El almacén es obligatorio" }]}
          >
            <AlmacenEmpleadoSelect
              empleado={activeEmpleado?.id}
              placeholder="Seleccione almacén"
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={20} xl={18}>
          <InputSearchDropdown
            placeholder="Buscar Producto"
            almacen={almacen}
            allowClear
            label="Producto/Servicio referencia"
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={5}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[{ required: true, message: "La cantidad es obligatoria" }]}
          >
            <InputNumber
              addonBefore={
                <div
                  onClick={() => handleChangeAmount("minus")}
                  style={{ cursor: "pointer" }}
                >
                  <MinusOutlined />
                </div>
              }
              addonAfter={
                <div
                  onClick={() => handleChangeAmount("plus")}
                  style={{ cursor: "pointer" }}
                >
                  <PlusOutlined />
                </div>
              }
              placeholder="Ingrese cantidad"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={5}>
          <Form.Item
            name="unidadmedida"
            label="Unidad medida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Form.Item
            name="preciounitario"
            label="Precio unitario"
            rules={[
              { required: true, message: "El precio unitario es obligatoria" },
            ]}
          >
            <InputNumber
              name="preciounitario"
              placeholder="Ingrese precio"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Form.Item name="totalventa" label="Total venta">
            <InputNumber
              name="totalventa"
              style={{ width: "100%" }}
              precision={2}
              readOnly
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
