import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { Form } from "antd";
import { FormUi } from "../../../ui/FormUi";
import { DetalleSerieForm } from "./DetalleSerieForm";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";

const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
const initialValues = { series: [], lotes: [] };
export const DetalleSerieModal = ({
  open,
  onCancelModal,
  cantidad,
  series,
}) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });
  useEffect(() => {
    if (open) {
      const valueSerie = series?.map((item) => ({
        ...item,
        selected: item.selected ? item.selected : false,
      }));

      form.setFieldsValue({
        series: valueSerie,
      });
    }
  }, [form, open]);

  const handleSave = () => {
    onCancelModal();
  };
  return (
    <ModalUi
      title="Detalle producto por lote/serie"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancelModal}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormSerie"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormSerie"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <DetalleSerieForm cantidad={cantidad} openModal={open} />
      </FormUi>
    </ModalUi>
  );
};
