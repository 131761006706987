import React, { useEffect, useState } from "react";
import { get_monedas } from "../../../../actions/caja";
import { SelectUi } from "../../../ui/SelectUi";

export const MonedaSelect = ({ name, ...props }) => {
  const [monedas, SetMonedas] = useState([]);
  const OnLoadMonedas = async () => {
    const datos = await get_monedas();
    SetMonedas(datos);
  };
  useEffect(() => {
    OnLoadMonedas();
  }, []);

  return <SelectUi data={monedas} name={name} {...props} />;
};
