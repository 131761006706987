import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileTextTwoTone,
  FileZipTwoTone,
  MenuUnfoldOutlined,
  SettingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Table, Tag } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  comprobantepagoSetActive,
  comprobantepagoStartXMLCreate,
  file_download_cdr_comprobantepago,
  file_download_xml_comprobantepago,
  get_pdf_boleta_venta,
  get_pdf_factura_venta,
  showModalComprobanteOptionPrint,
} from "../../../actions/comprobante";
import { open_file } from "../../../helpers/utils";
import {
  EstadoComprobantePagoConstants,
  TipoComprobanteSunatConstants,
} from "../../../types/typesConstants";

const handleMenuOpt = (e, record, acciones) => {
  const { OnHandleReCrearXML, OnHandleShowModalComprobanteOptionPrint } =
    acciones;

  switch (e.key) {
    case "RECREAR":
      OnHandleReCrearXML(record?.id);
      break;
    case "OPTION_PRINT":
      OnHandleShowModalComprobanteOptionPrint(record);
      break;
    // Invoice();
    default:
      break;
  }
};

const items = [
  {
    key: "RECREAR",
    label: "Re-Crear XML",
    icon: <FileTextOutlined />,
  },
  {
    key: "OPTION_PRINT",
    label: "Opciones Imprimir",
    icon: <MenuUnfoldOutlined />,
  },
  {
    key: "ANULAR",
    label: "Anular",
    icon: <CloseOutlined />,
  },
];
const menuProps = (record, acciones) => ({
  items,
  onClick: (e) => handleMenuOpt(e, record, acciones),
});
const BtnGroupComprobante = ({ comprobante, acciones }) => {
  const {
    OnHandleDownloadXML,
    OnHandlePDFComprobantePago,
    OnHandleDownloadCDR,
  } = acciones;
  return (
    <>
      <Button
        onClick={() => OnHandleDownloadXML(comprobante)}
        disabled={!comprobante?.comprobante_xml_existe}
      >
        <FileTextTwoTone twoToneColor="#0083ff" /> XML
      </Button>
      <Button
        onClick={() => OnHandlePDFComprobantePago(comprobante)}
        className="ml-1"
      >
        <FilePdfOutlined style={{ color: "#FF0000" }} /> PDF
      </Button>
      <Button
        className="ml-1"
        onClick={() => OnHandleDownloadCDR(comprobante)}
        disabled={!comprobante?.comprobante_cdr_existe}
      >
        <FileZipTwoTone twoToneColor="#00bb2d" /> CDR
      </Button>
      <Dropdown menu={menuProps(comprobante, acciones)} trigger={["click"]}>
        <Button className="ml-2">
          <SettingOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
const columns = (acciones) => {
  const TagEstadoComprobante = (comprobante) => {
    switch (comprobante.estadocomprobante) {
      case EstadoComprobantePagoConstants.ACEPTADO:
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
      case EstadoComprobantePagoConstants.RECHAZADO:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
      case EstadoComprobantePagoConstants.ENVIADO:
        return (
          <Tag icon={<SyncOutlined />} color="default">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
      default:
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="processing">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
    }
  };
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Cliente",
      dataIndex: "cliente_nombre",
      key: "cliente_nombre",
    },
    {
      title: "Comprobante",
      dataIndex: "comprobante",
      key: "comprobante",
    },
    {
      title: "Estado",
      dataIndex: "estadocomprobante",
      key: "estadocomprobante",
      render: (text, record) => <>{TagEstadoComprobante(record)}</>,
    },
    {
      title: "T.Gravado",
      dataIndex: "montosubtotal",
      key: "montosubtotal",
    },
    {
      title: "T.IGV",
      dataIndex: "montoigv",
      key: "montoigv",
      align: "right",
    },
    {
      title: "Total",
      dataIndex: "montototal",
      key: "montototal",
      align: "right",
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <BtnGroupComprobante comprobante={record} acciones={acciones} />
      ),
    },
  ];
};
export const ComprobantePagoTable = ({ comprobantes }) => {
  const dispatch = useDispatch();
  const OnHandleReCrearXML = (comprobante) => {
    dispatch(comprobantepagoStartXMLCreate(comprobante));
  };
  const OnHandleShowModalComprobanteOptionPrint = (comprobante) => {
    dispatch(comprobantepagoSetActive(comprobante));
    dispatch(showModalComprobanteOptionPrint());
  };
  const OnHandlePDFComprobantePago = async (comprobante) => {
    let file = null;
    switch (comprobante.tipocomprobantesunat) {
      case TipoComprobanteSunatConstants.Boleta:
        file = await get_pdf_boleta_venta(comprobante?.id);
        break;
      case TipoComprobanteSunatConstants.Factura:
        file = await get_pdf_factura_venta(comprobante?.id);
        break;
      default:
        file = null;
        break;
    }
    let name_file = comprobante?.nombrearchivocomprobantesunat + ".pdf";
    open_file(file, name_file, "application/pdf");
  };
  const OnHandleDownloadXML = async (comprobante) => {
    const file = await file_download_xml_comprobantepago(comprobante?.id);
    let name_file = comprobante?.nombrearchivocomprobantesunat + ".xml";
    open_file(file, name_file, "application/xml");
  };
  const OnHandleDownloadCDR = async (comprobante) => {
    const file = await file_download_cdr_comprobantepago(comprobante?.id);
    const { type: type_file } = file;
    let name_file = "R-" + comprobante?.nombrearchivocomprobantesunat + ".zip";
    open_file(file, name_file, type_file);
  };
  return (
    <Table
      columns={columns({
        OnHandleReCrearXML,
        OnHandleShowModalComprobanteOptionPrint,
        OnHandlePDFComprobantePago,
        OnHandleDownloadXML,
        OnHandleDownloadCDR,
      })}
      dataSource={comprobantes}
      rowKey="id"
      size="small"
    ></Table>
  );
};
