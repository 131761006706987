import { axiosConToken } from "./axios";
import { validaMessagesErrors } from "./validates";

/*  Movimiento de Entrada */

const getAlmacenesEmpleadoSelect = async (empleado) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "movimientoentrada/get_almacenes_empledo",
      {
        params: { empleado },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
    console.log(error.response);
  }
  return datos;
};
const getTiposMovimientos = async (tipooperacion) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("inventario/tipomovimientos", {
      params: { tipooperacion },
    });
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

const getKardexProductos = async (producto, fechainicio, fechafin) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("inventario/KardexProducto", {
      params: { producto, fechainicio, fechafin },
    });
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

const getProductosAlmacen = async (almacen) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("inventario/getProductosAlmacen", {
      params: { almacen },
    });
    datos = resp.data;
  } catch (error) {
    console.log(error.response);
    validaMessagesErrors(error.response);
  }
  return datos;
};
const getPdfNotaTraslado = async (comprobantemovimiento) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "movimientotraslado/get_pdf_movimiento_traslado",
      {
        responseType: "blob",
        params: { comprobantemovimiento },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
const get_inventories_by_store = async (almacen) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "movimientotraslado/get_inventories_by_store",
      {
        params: { almacen },
      }
    );
    datos = resp.data;
  } catch (error) {
    console.log(error.response);
    validaMessagesErrors(error.response);
  }
  return datos;
};
export {
  getAlmacenesEmpleadoSelect,
  getKardexProductos,
  getTiposMovimientos,
  getProductosAlmacen,
  getPdfNotaTraslado,
  get_inventories_by_store,
};
