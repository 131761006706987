import React from "react";

import { Col, Row } from "antd";
import { ButtonUi } from "../ui/ButtonUi";
import { DateFormats, TipoButtonConstants } from "../../types/typesConstants";
import dayjs from "dayjs";
import { ModalUi } from "../ui/ModalUi";
import { TableUi } from "../ui/TableUi";

const columns_serie = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Serie",
    dataIndex: "numero_serie",
    key: "numero_serie",
  },
  {
    title: "Fecha fabricación",
    dataIndex: "fecha_fabricacion",
    key: "fecha_fabricacion",
  },
];
const columns_lote = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Lote",
    dataIndex: "numero_lote",
    key: "numero_lote",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    align: "center",
    render: (text) => <> {parseFloat(text).toFixed(2)}</>,
  },
  {
    title: "Fecha fabricación",
    dataIndex: "fecha_fabricacion",
    key: "fecha_fabricacion",
    align: "center",
    render: (text, record, index) => (
      <> {dayjs(text).format(DateFormats.frontend)}</>
    ),
  },
  {
    title: "Fecha vencimiento",
    dataIndex: "fecha_vencimiento",
    key: "fecha_vencimiento",
    align: "center",
    render: (text, record, index) => (
      <> {dayjs(text).format(DateFormats.frontend)}</>
    ),
  },
];

export const InventarioLoteSerieModal = ({
  inventario,
  open,
  handleCancelModal,
}) => {
  return (
    <ModalUi
      title={
        inventario?.manejaseries
          ? "Series"
          : inventario?.manejalotes
          ? "Lotes"
          : ""
      }
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCancelModal}
      width="40%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancelModal}
        />,
      ]}
      forceRender
    >
      {inventario?.manejaseries && (
        <Row key="series">
          <Col span="20">
            <TableUi
              data={inventario?.series}
              columns={columns_serie}
              size="small"
              rowKey="id"
            />
          </Col>
        </Row>
      )}
      {inventario?.manejalotes && (
        <Row key="lotes">
          <Col span="24">
            <TableUi
              data={inventario?.lotes}
              columns={columns_lote}
              size="small"
              rowKey="id"
            />
          </Col>
        </Row>
      )}
    </ModalUi>
  );
};
