export const types = {
  terminalcajeroLoaded: "[terminalcajero] Terminales Cajero Loaded",
  terminalcajeroSetActive: "[terminalcajero] Terminales Cajero Set Active",
  terminalcajeroClearActive: "[terminalcajero] Terminales Cajero Clear Active",
  terminalcajeroAdded: "[terminalcajero] Terminales Cajero added",
  terminalcajeroUpdated: "[terminalcajero] Terminales Cajero updated",
  terminalcajeroDeleted: "[terminalcajero] Terminales Cajero deleted",
  terminalcajeroLogout: "[terminalcajero] Terminales Cajero logout",

  cajaLoaded: "[caja] Caja Loaded",
  cajaSetActive: "[caja] Caja Set Active",
  cajaClearActive: "[caja] Caja Clear Active",
  cajaStartAdd: "[caja] Start Caja Add New",
  cajaStartUpdate: "[caja] Start Caja Update",
  cajaStartDelete: "[caja] Start Caja Delete",
  cajaAdded: "[caja] Caja added",
  cajaUpdated: "[caja] Caja updated",
  cajaDeleted: "[caja] Caja deleted",
  cajaLogout: "[caja] Caja logout",

  movimientoCajaLoaded: "[movimientocaja] Movimientos Caja loaded",

  showModalArqueoCaja: "[caja] Show Modal Arqueo Caja",

  arqueoCajaStartAdd: "[caja] Start Arqueo Caja Add New",
  arqueoCajaSetActive: "[caja] Arqueo caja Set Active",

  productocarritoSetActive: "[POS] Producto Carrito Set Active",
  productocarritoClearActive: "[POS] Producto Carrito Clear Active",
  showModalMediosPago: "[ui] Show Modal Medios Pago",
  showModalComprobanteResult: "[ui] Show Modal Comprobante Result",
  showModalStock: "[ui] Show Modal Stock Productos",
  productostockSetActive: "[POS] Producto Stock Set Active",
  productostockClearActive: "[POS] Producto Stock Clear Active",
  comprobantepagoClearActive: "[comprobante] Comprobante Pago Clear Active",

  puntoventaSetResult: "[puntoventa] Punto venta set Result",
  puntoventaSetCurrentStep: "[puntoventa] Punto venta Set Current Step",
  puntoventaLogout: "[puntoventa] Punto venta logout",

  arqueoCajaShowForm: "[arqueo caja] Arqueo Caja Show Form",
};
