import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msg";
const router = {
  monedas: "monedas/",
};
export const monedaStartLoad = () => {
  return async (dispatch) => {
    dispatch(monedaLoading());
    try {
      const resp = await axiosConToken.get(router.monedas);
      const data = resp.data;
      dispatch(monedaLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(monedaLoading(false));
    }
  };
};
export const monedaStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(router.monedas, data);
      const { msg, moneda } = resp.data;
      dispatch(monedaAdded(moneda));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const monedaStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${router.monedas}${data.id}/`,
        data
      );
      const { msg, moneda } = resp.data;
      dispatch(monedaUpdated(moneda));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const monedaStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`monedas/${data.id}/`);
      const { msg } = resp.data;
      dispatch(monedaDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const monedaLoaded = (monedas) => ({
  type: types.monedasLoaded,
  payload: monedas,
});
const monedaAdded = (moneda) => ({ type: types.monedaAdded, payload: moneda });
const monedaUpdated = (moneda) => ({
  type: types.monedaUpdated,
  payload: moneda,
});
const monedaDeleted = (moneda) => ({
  type: types.monedaDeleted,
  payload: moneda,
});
export const monedaSetActive = (moneda) => ({
  type: types.monedaSetActive,
  payload: moneda,
});
export const monedaClearActive = () => ({ type: types.monedaClearActive });
export const monedaLoading = (loading = true) => ({
  type: types.dataLoading,
  payload: loading,
});

export const get_monedas = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("monedas/get_monedas");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_prefijoSunat = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${router.monedas}get_prefijosunat/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
