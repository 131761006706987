import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { EmpleadoVendedorSelect } from "../../reusable/EmpleadoVendedorSelect";
import { FormaPagoSelect } from "../../reusable/FormaPagoSelect";
import { SerieComprontesSelect } from "../../reusable/SerieComprontesSelect";
import { TipoComprobanteSelect } from "./TipoComprobanteSelect";

export const ComprobantePagoForm = () => {
  const form = Form.useFormInstance();
  const tipocomprobante = Form.useWatch("tipocomprobante", form);
  const { activeCajaAbierta } = useSelector((state) => state.venta);
  return (
    <Form name="FormComprobantePago" layout="vertical" className="FormModal">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} md={12} lg={12}>
          <Form.Item name="tipocomprobante" label="Tipo comprobante">
            <TipoComprobanteSelect
              name="tipocomprobante"
              terminalcajero={activeCajaAbierta?.terminalcajero}
              placeholder="Seleccione tipo comprobante"
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={8} lg={8}>
          <Form.Item name="seriecomprobante" label="Serie comprobante">
            <SerieComprontesSelect
              name="seriecomprobante"
              terminalcajero={activeCajaAbierta?.terminalcajero || 0}
              tipocomprobante={tipocomprobante || 0}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} md={20} lg={20}>
          <Form.Item name="observacion" label="Observación">
            <Input.TextArea
              name="observacion"
              cols={3}
              placeholder="Ingrese observación"
            ></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} md={8} lg={6}>
          <Form.Item name="vendedor" label="Vendedor">
            <EmpleadoVendedorSelect
              name="vendedor"
              key="empleados"
              placeholder="Seleccione empleado"
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={8} lg={6}>
          <Form.Item name="formapago" label="Forma pago">
            <FormaPagoSelect
              name="formapago"
              key="formapago"
              placeholder="Seleccione forma pago"
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={8} lg={6}>
          <Form.Item name="fechaemision" label="Fecha Emisión">
            <DatePicker name="fechaemision" format={"DD-MM-YYYY"} />
          </Form.Item>
        </Col>
        <Col sm={12} md={8} lg={6}>
          <Form.Item name="fechavencimiento" label="Fecha Vencimiento">
            <DatePicker name="fechavencimiento" format={"DD-MM-YYYY"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
