import { types } from "../types/typesAuth";
import { axiosSinToken, axiosConToken } from "../helpers/axios";
import { validaMessagesErrors } from "../helpers/validates";
import { toast } from "react-toastify";

export const authStartLogin = (username, password) => {
  return async (dispatch) => {
    try {
      const resp = await axiosSinToken.post("auth/login/", {
        username,
        password,
      });
      const body = resp.data;
      const { authTokens, empleado, usuario } = body;
      localStorage.setItem("authTokens", JSON.stringify(authTokens));
      dispatch(authUsuarioSetActive(usuario));
      dispatch(authEmpleadoSetActive(empleado));
    } catch (error) {
      const resp = error.response;
      if (resp.status === 400) {
        toast.error(resp.data.message);
      } else {
        validaMessagesErrors(error);
      }
    }
  };
};
export const authStartTokenRefreshed = () => {
  return async (dispatch) => {
    try {
      const authTokensActual = JSON.parse(localStorage.getItem("authTokens"));
      const resp = await axiosSinToken.post("auth/token/refresh/", {
        refresh: authTokensActual.refresh,
      });
      const body = resp.data;
      const { authTokens } = body;
      localStorage.setItem("authTokens", JSON.stringify(authTokens));
    } catch (error) {
      const resp = error.response;
      if (resp.status === 400) {
        toast.error(resp.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
};
export const authStartChecking = () => {
  return async (dispatch) => {
    dispatch(authUsuarioCheking());
    try {
      const tokens = localStorage.getItem("authTokens") || null;
      if (tokens) {
        const resp = await axiosConToken.get("auth/me/");
        const { empleado, usuario } = resp.data;
        dispatch(authUsuarioSetActive(usuario));
        dispatch(authEmpleadoSetActive(empleado));
      } else {
        dispatch(authLogout());
      }
    } catch (error) {
      validaMessagesErrors(error);
      dispatch(authLogout());
    } finally {
      dispatch(authUsuarioCheking(false));
    }
  };
};

export const authStartLogout = () => {
  return async (dispatch) => {
    try {
      await axiosConToken.post("auth/logout/");
      localStorage.clear();
    } catch (error) {
      validaMessagesErrors(error);
    } finally {
      dispatch(authLogout());
    }
  };
};
const authUsuarioSetActive = (user) => ({
  type: types.authUsuarioSetActive,
  payload: user,
});

const authUsuarioCheking = (cheking = true) => ({
  type: types.authUsuarioChecking,
  payload: cheking,
});

const authLogout = () => ({
  type: types.authUsuarioLogout,
});

const authEmpleadoSetActive = (empleado) => ({
  type: types.authEmpleadoSetActive,
  payload: empleado,
});
