import React, { useEffect, useState } from "react";
import { getAlmacenesEmpleadoSelect } from "../../../helpers/loadInventarios";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const AlmacenEmpleadoSelect = ({ empleado, ...props }) => {
  const [almacenes, setAlmacenes] = useState([]);
  const OnLoadAlmacenes = async (empleado) => {
    const datos = await getAlmacenesEmpleadoSelect(empleado);
    setAlmacenes(datos);
  };
  useEffect(() => {
    OnLoadAlmacenes(empleado);
  }, [empleado]);

  return <SearchBasicSelectUI data={almacenes} {...props} />;
};
