import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { Image, Space } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch } from "react-redux";
import {
  detalleArqueoCajaSetActive,
  showModalArqueoCaja,
} from "../../../../actions/caja";

const columns = (props) => {
  const { handleEditar, handleDelete } = props;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Denominación",
      dataIndex: "denominacionmoneda_imagen",
      key: "denominacionmoneda_imagen",
      render: (text) => (
        <>
          <Image src={text} width={70} />
        </>
      ),
    },
    {
      title: "Moneda",
      dataIndex: "moneda_nombre",
      key: "moneda_nombre",
      align: "center",
    },
    {
      title: "Valor",
      dataIndex: "valormoneda",
      key: "valormoneda",
      align: "right",
      render: (text, record, index) => <>{parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (text, record, index) => <>{parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "Acciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record, index) => (
        <>
          <Space wrap>
            <ButtonUi
              typeUi={TipoButtonConstants.EditarTable}
              onClick={() => handleEditar(record, index)}
              displayText={false}
              size="small"
            />
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleDelete(index)}
              displayText={false}
              size="small"
            />
          </Space>
        </>
      ),
    },
  ];
};
export const ArqueoCajaDetalleTable = ({
  detallearqueocaja,
  handleDelete,
  onOpenModal,
  title,
}) => {
  const dispatch = useDispatch();
  const handleEditar = (item) => {
    dispatch(detalleArqueoCajaSetActive(item));
    onOpenModal();
  };
  return (
    <TableUi
      name="detallearqueocaja"
      rowKey="id"
      size="small"
      columns={columns({ handleEditar, handleDelete })}
      data={detallearqueocaja}
      pagination={{ pageSize: 25 }}
      title={title}
    />
  );
};
