import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";

export const SearchSelectUI = ({ handleSearch, data, ...restProps }) => {
  return (
    <Select
      showSearch
      defaultActiveFirstOption={false}
      suffixIcon={<SearchOutlined />}
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={null}
      options={data}
      {...restProps}
    />
  );
};
