import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { comprobantespagoNoEnviadosStartLoad } from "../../../../actions/comprobante";
import { ButtonUi } from "../../../ui/ButtonUi";
import { ComprobantePagoNoEnviadoTable } from "./ComprobantePagoNoEnviadoTable";
import { PageHeader } from "@ant-design/pro-layout";

export const ComprobantePagoNoEnviadoList = () => {
  const dispatch = useDispatch();
  const { comprobantespago } = useSelector((state) => state.comprobantepago);
  const LoadComprobantePago = async () => {
    dispatch(comprobantespagoNoEnviadosStartLoad());
  };
  useEffect(() => {
    dispatch(comprobantespagoNoEnviadosStartLoad());
  }, [dispatch]);
  return (
    <PageHeader
      className="site-page-header"
      onBack={() => window.history.back()}
      title="Comprobantes pago"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key={"actualizar"}
          typeUi="2"
          onClick={LoadComprobantePago}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ComprobantePagoNoEnviadoTable comprobantes={comprobantespago} />
          </Col>
        </Row>
      </div>
    </PageHeader>
  );
};

export default ComprobantePagoNoEnviadoList;
