import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListaPrecioScreen } from "./ListaPrecioScreen";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { listapreciosStartLoad } from "../../../actions/listaprecio";
import { ListaPrecioTable } from "./ListaPrecioTable";
import { uiShowForm } from "../../../actions/ui";
import { Col, Row } from "antd";
import { PageHeaderUi } from "../../ui/PageHeaderUi";

const ListaPrecioListScreen = () => {
  const dispatch = useDispatch();
  const { listaprecios } = useSelector((state) => state.listaprecio);
  const handleNuevo = () => {
    dispatch(uiShowForm());
  };
  const handleLoad = () => {
    dispatch(listapreciosStartLoad());
  };
  useEffect(() => {
    dispatch(listapreciosStartLoad());
  }, []);

  return (
    <>
      <PageHeaderUi
        title="Lista Precios"
        subTitle="Listado"
        extra={[
          <ButtonUi
            key="actualizar"
            typeUi={TipoButtonConstants.Actualizar}
            onClick={handleLoad}
          />,
          <ButtonUi
            key="nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleNuevo}
          />,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <Row>
            <Col sm={32} md={32} xl={24}>
              <ListaPrecioTable listaprecios={listaprecios} />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
    </>
  );
};

export const ListaPrecioList = () => {
  const { visibleForm } = useSelector((state) => state.ui);
  if (visibleForm) {
    return <ListaPrecioScreen />;
  } else {
    return <ListaPrecioListScreen />;
  }
};
export default ListaPrecioList;
