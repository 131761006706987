import { Col, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { uiShowDrawer } from "../../../actions/ui";
import { KardexSearch } from "./KardexSearch";
import { PageHeaderUi } from "../../ui/PageHeaderUi";

export const KardexList = () => {
  const dispatch = useDispatch();
  const handleNuevaAperturaCaja = () => {
    dispatch(uiShowDrawer());
  };
  return (
    <>
      <PageHeaderUi title="Kardex" subTitle="Consulta">
        <div style={{ overflow: "hidden" }}>
          <Row gutter={16}>
            <Col sm={32} md={32} xl={24}>
              <KardexSearch />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
    </>
  );
};

export default KardexList;
