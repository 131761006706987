import { Drawer, Form, Space } from "antd";
import React, { useEffect, useRef } from "react";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import dayjs from "dayjs";
import { FormUi } from "../../../../ui/FormUi";
import { TareaProduccionForm } from "./TareaProduccionForm";
import { useDispatch, useSelector } from "react-redux";
import { detalleProcesoProduccionClearActive } from "../../../../../actions/produccion";
const useResetFormOnCloseModal = ({ form, open, activeTarea }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    } else {
      if (activeTarea) {
        form.setFieldsValue({
          ...activeTarea,
          fechahorainicio: dayjs(activeTarea?.fechahorainicio),
          fechahorafin: dayjs(activeTarea?.fechahorainicio) || dayjs(),
        });
      } else {
        form.setFieldsValue({ ...initialValues });
      }
    }
  }, [form, prevOpen, open]);
};
const initialValues = {
  id: dayjs().valueOf(),
  fechahorainicio: dayjs(),
  fechahorafin: null,
  detallerutaproduccion: null,
  maquina: null,
  empleado: null,
  proveedor: null,
  estado: null,
};
export const TareaProduccionDrawer = ({
  detallerutaproduccion,
  open,
  onCancelModal,
}) => {
  const { activeDetalleProcesoProduccion } = useSelector(
    (state) => state.produccion
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useResetFormOnCloseModal({
    form,
    open,
    activeTarea: activeDetalleProcesoProduccion,
  });
  const handleCancel = () => {
    dispatch(detalleProcesoProduccionClearActive());
    onCancelModal();
  };
  const handleSubmit = () => handleCancel();
  // detallerutaproduccion={
  //   activeProcesoProduccion
  //     ? detallerutaproduccion
  //     : detallerutaproduccion?.filter(
  //         (x) =>
  //           !detalleprocesoproduccion.some(
  //             ({ detallerutaproduccion: id2 }) => id2 === x.id
  //           )
  //       )
  // }
  return (
    <Drawer
      title={<span>Iniciar tarea de producción</span>}
      maskClosable={false}
      onClose={handleCancel}
      open={open}
      size="large"
      destroyOnClose
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.Cancelar}
            onClick={handleCancel}
          />
          <ButtonUi
            form="FormTareaProduccion"
            typeUi={TipoButtonConstants.Guardar}
            htmlType="submit"
          />
        </Space>
      }
      forceRender
    >
      <FormUi
        form={form}
        name="FormTareaProduccion"
        layout="vertical"
        autoComplete="off"
        handleSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <TareaProduccionForm detallerutaproduccion={detallerutaproduccion} />
      </FormUi>
    </Drawer>
  );
};
