import React, { useEffect, useState } from "react";
import { getDepartamentos } from "../../../helpers/loadGenerales";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const DepartamentoSelect = ({ ...props }) => {
  const [departamentos, setDepartamentos] = useState([]);
  const OnLoadDepartamentos = async () => {
    const datos = await getDepartamentos();
    setDepartamentos(datos);
  };
  useEffect(() => {
    OnLoadDepartamentos();
  }, []);

  return <SearchBasicSelectUI options={departamentos} {...props} />;
};
