import { types } from "../types/typesGenerales";

const initialState = {
  locales: [],
  activeLocal: null,
};

export const localReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.localLoaded:
      return { ...state, locales: action.payload };
    case types.localAdded:
      return {
        ...state,
        locales: [action.payload, ...state.locales],
      };
    case types.localSetActive:
      return {
        ...state,
        activeLocal: action.payload,
      };
    case types.localClearActive:
      return {
        ...state,
        activeLocal: null,
      };
    case types.localUpdated:
      return {
        ...state,
        locales: state.locales.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.localDeleted:
      return {
        ...state,
        locales: state.locales.filter((e) => e.id !== action.payload.id),
        activeLocal: null,
      };
    case types.localLogout:
      return { ...initialState };
    default:
      return state;
  }
};
