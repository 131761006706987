import { Col, Form, Input, Row } from "antd";
import React from "react";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";

export const NaturalezaPagoForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatoria" }]}
          >
            <Input
              placeholder="Ingrese nombre"
              style={{ width: "100%" }}
              onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
