import { CopyOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import { CardDetails } from "./CardDetails";
import { FilterDashboardForm } from "./FilterDashboardForm";

export const Dashboard = () => {
  return (
    <div>
      <Row gutter={24}>
        <Col span={4}>
          <Card>
            <Statistic
              title="Cantidad CPE emitidos"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3498DB",
              }}
              prefix={<CopyOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Monto total Comprobantes"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<DollarCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Monto total notas de ventas"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<DollarCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Monto total general"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<DollarCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Utilidad neta"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<DollarCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={24} lg={24}>
          <FilterDashboardForm />
        </Col>
      </Row>
      <Row className="mt-2" gutter={16}>
        <CardDetails />
      </Row>
    </div>
  );
};

export default Dashboard;
