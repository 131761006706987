import { types } from "../types/typesComprobantePago";

const initialState = {
  comprobantespago: [],
  cotizaciones: [],
  visibleModal: false,
  activeComprobantePago: null,
  tipomediospago: [],
  mediospago: [],
  visibleModalOptionPrintModal: false,
};
export const comprobantepagoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.tipomediospagoLoaded:
      return { ...state, tipomediospago: action.payload };
    case types.mediospagoLoaded:
      return { ...state, mediospago: action.payload };
    case types.comprobantesLoaded:
      return { ...state, comprobantespago: action.payload };
    case types.cotizacionShowModalItemDetalle:
      return {
        ...state,
        visibleModal: action.payload,
      };
    case types.comprobantepagoAdded:
      return {
        ...state,
        comprobantespago: [action.payload, ...state.comprobantespago],
      };
    case types.comprobantepagoUpdated:
      return {
        ...state,
        comprobantespago: state.comprobantespago.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.comprobantepagoDeleted:
      return {
        ...state,
        comprobantespago: state.comprobantespago.filter(
          (e) => e.id !== action.payload.id
        ),
      };
    case types.comprobantepagoSetActive:
      return {
        ...state,
        activeComprobantePago: action.payload,
      };
    case types.comprobantepagoClearActive:
      return {
        ...state,
        activeComprobantePago: null,
      };
    case types.comprobanteShowModalOptionPrint:
      return {
        ...state,
        visibleModalOptionPrintModal: action.payload,
      };
    case types.comprobantepagoShowModal:
      return {
        ...state,
        visibleModal: action.payload,
      };
    default:
      return state;
  }
};
