import React, { useEffect, useState } from "react";
import { get_price_list_select } from "../../../actions/producto";
import { Select } from "antd";

export const ListaPrecioSelect = ({ ...restProps }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_price_list_select();
      setItems(datos);
    };
    OnLoad();
    return () => {
      setItems([]);
    };
  }, []);

  return <Select options={items} {...restProps} />;
};
