import { Tag } from "antd";
import React, { useState } from "react";
import { TableUi } from "../../ui/TableUi";
const columns_table = [
  {
    title: "Fecha - Hora Transacción",
    dataIndex: "fechahoratransaccion",
    key: "fechahoratransaccion",
  },
  {
    title: "Almacén",
    dataIndex: "almacen",
    key: "almacen",
  },
  {
    title: "Tipo transacción",
    dataIndex: "tipomovimiento",
    key: "tipomovimiento",
  },
  {
    title: "N°Comprobante",
    dataIndex: "nrocomprobantemovimiento",
    key: "nrocomprobantemovimiento",
  },
  {
    title: "Fecha Emisión",
    dataIndex: "fechaemision",
    key: "fechaemision",
    align: "center",
  },
  {
    title: "Datos Adicionales",
    dataIndex: "datosadicionales",
    key: "datosadicionales",
    align: "center",
    render: (value, record) => (
      <>
        {record?.serie && (
          <Tag color="cyan">
            <b>Serie : {record?.numero_serie}</b>
          </Tag>
        )}
        {record?.lote && (
          <Tag color="green">
            <b>Lote : {record?.numero_lote}</b>
          </Tag>
        )}
      </>
    ),
  },
  {
    title: "Entrada",
    dataIndex: "entrada",
    key: "entrada",
    align: "center",
    className: "blue-color",
    render: (value) => <b>{value}</b>,
  },
  {
    title: "Salida",
    dataIndex: "salida",
    key: "salida",
    align: "center",
    className: "red_color",
    render: (value) => <b>{value}</b>,
  },
  {
    title: "Saldo",
    dataIndex: "saldo",
    key: "saldo",
    align: "center",
    render: (value) => <b>{value}</b>,
  },
];
export const KardexTable = ({ kardexproductos }) => {
  return (
    <TableUi
      columns={columns_table}
      rowKey="id"
      data={kardexproductos}
      size="small"
      scroll={{ x: 550 }}
    />
  );
};
