import { Col, Form, Input, Row } from "antd";
import React from "react";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { PrefijoSunatSelect } from "../reusable/PrefijoSunatSelect";
import { SwitchUi } from "../../ui/SwitchUi";

export const MonedaForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="prefijo"
            label="Prefijo"
            rules={[{ required: true, message: "El prefijo es obligatorio" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="prefijosunat"
            label="Prefijo Sunat"
            rules={[
              { required: true, message: "El prefijo sunat es obligatorio" },
            ]}
          >
            <PrefijoSunatSelect placeholder="[Seleccione]" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="pordefecto"
            label="Por defecto"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
