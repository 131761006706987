import { Form } from "antd";
import React from "react";

export const FormUi = ({ initialValues = {}, handleSubmit, ...props }) => {
  return (
    <Form
      scrollToFirstError
      initialValues={initialValues}
      onFinish={handleSubmit}
      className="ui-form"
      {...props}
    ></Form>
  );
};
