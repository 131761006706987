import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowModal } from "../../../actions/ui";
import { LocalesTable } from "./LocalesTable";
import { LocalModal } from "./LocalModal";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { localesStartLoad } from "../../../actions/local";

export const LocalesList = () => {
  const dispatch = useDispatch();
  const { locales } = useSelector((state) => state.local);
  const handleNuevo = () => {
    dispatch(uiShowModal());
  };
  const handleReload = () => {
    dispatch(localesStartLoad());
  };
  useEffect(() => {
    handleReload();
  }, []);

  return (
    <>
      <PageHeaderUi
        title="Establecimientos"
        subTitle="Listado"
        extra={[
          <ButtonUi
            typeUi={TipoButtonConstants.Actualizar}
            key="Actulizar"
            onClick={handleReload}
          />,
          <ButtonUi
            key="Nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleNuevo}
          />,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <Row className="mb-1">
            <Col sm={32} md={32} xl={24}></Col>
          </Row>
          <Row>
            <Col sm={32} md={32} xl={24}>
              <LocalesTable locales={locales} />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
      <LocalModal />
    </>
  );
};
export default LocalesList;
