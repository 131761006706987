import { Col, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { ModalUi } from "../../ui/ModalUi";
import { TableUi } from "../../ui/TableUi";
import { useDispatch, useSelector } from "react-redux";
import { comprobantecompraClearActive } from "../../../actions/compra";

const useResetFormOnCloseModal = ({ open }) => {
  const prevOpenRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(comprobantecompraClearActive());
    }
  }, [prevOpen, open]);
};
const columns = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Descripción",
    dataIndex: "descripcion",
    key: "descripcion",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
  },
  {
    title: "Unidad Medida",
    dataIndex: "unidadmedida_nombre",
    key: "unidadmedida_nombre",
  },
];
export const ItemDetallePreviewModal = ({ items, open, onCancel }) => {
  useResetFormOnCloseModal(open);
  return (
    <ModalUi
      title="Busqueda de producto/servicio"
      open={open}
      onCancel={onCancel}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
      ]}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TableUi
            data={items || []}
            columns={columns}
            rowKey="id"
            size="small"
          />
        </Col>
      </Row>
    </ModalUi>
  );
};
