import { Col, Form, InputNumber, Row, Space, Tooltip } from "antd";
import React from "react";
import { AlmacenEmpleadoSelect } from "../../reusable/AlmacenEmpleadoSelect";
import { useDispatch, useSelector } from "react-redux";
import { ProductoAlmacenSelect } from "../../reusable/ProductoAlmacenSelect";
import { TipoMovimientoSelect } from "../../reusable/TipoMovimientoSelect";
import {
  InventarioConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { CardUi } from "../../../ui/CardUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { showFormOutputMovements } from "../../../../actions/inventario";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { UnidadMedidaSelect } from "../../../generales/unidadesmedida/UnidadMedidaSelect";

export const SalidaForm = ({ onOpenModal }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const almacenorigen = Form.useWatch("almacenorigen", form);
  const manejalotes = Form.useWatch("manejalotes", form);
  const manejaseries = Form.useWatch("manejaseries", form);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const changeProducto = (e, option) => {
    form.setFieldsValue({
      manejalotes: option?.manejalotes,
      manejaseries: option?.manejaseries,
      series: option?.series,
      lotes: option?.lotes,
      unidadmedida: option?.unidadmedida,
    });
  };
  const handleOpenModalSeries = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenModalLotes = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCancel = () => {
    dispatch(showFormOutputMovements(false));
  };
  return (
    <CardUi
      title="Salida de almacén"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormMovimientoSalida"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            name="almacenorigen"
            label="Almacén origen"
            rules={[
              { required: true, message: "Seleccione el almacén origen" },
            ]}
          >
            <AlmacenEmpleadoSelect
              empleado={activeEmpleado?.id}
              name="almacenorigen"
              placeholder="Seleccione almacén"
              onChange={() => form.setFieldValue("inventario", null)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Form.Item
            name="tipomovimiento"
            label="Movimiento"
            rules={[{ required: true, message: "Seleccione el movimiento" }]}
          >
            <TipoMovimientoSelect
              tipooperacion={InventarioConstants.TipoOperacionSalida}
              name="tipomovimiento"
              placeholder="Seleccione movimiento"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="inventario"
            label="Producto"
            rules={[{ required: true, message: "Seleccione el producto" }]}
          >
            <ProductoAlmacenSelect
              almacen={almacenorigen}
              placeholder="Seleccione producto"
              onChange={changeProducto}
            />
          </Form.Item>
        </Col>
        {manejaseries && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar series"
                className="ant-btn-info"
                onClick={handleOpenModalSeries}
              />
            </Form.Item>
          </Col>
        )}
        {manejalotes && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar lotes"
                className="ant-btn-info"
                onClick={handleOpenModalLotes}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={4}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatoria" },
              { type: "number", message: "Ingrese un número válido" },
            ]}
          >
            <InputNumber
              name="cantidad"
              placeholder="Ingrese cantidad"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Form.Item
            name="unidadmedida"
            label="Unidad Medida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Form.List
            name="series"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (manejaseries) {
                    const cantSeleccionada = getFieldValue("series")?.filter(
                      (item) => item.selected === true
                    ).length;
                    if (cantSeleccionada !== getFieldValue("cantidad"))
                      return Promise.reject(
                        new Error(
                          "La cantidad ingresada debe ser igual a la cantidad de despacho"
                        )
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, options, { errors }) => (
              <>
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Form.List
            name="lotes"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (manejalotes) {
                    const suma = value?.reduce(
                      (prev, curr) =>
                        Number(prev) + Number(curr?.cantidad_ingresada),
                      0
                    );
                    if (suma !== getFieldValue("cantidad"))
                      return Promise.reject(
                        new Error(
                          "La cantidad ingresada debe ser igual a la cantidad de despacho"
                        )
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, options, { errors }) => (
              <>
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </CardUi>
  );
};
