import React, { useEffect, useState } from "react";
import { get_tiposfiltros_dashboard } from "../../actions/dashboard";
import { SelectUi } from "../ui/SelectUi";

export const TiposFiltrosSelect = ({ ...props }) => {
  const [tipofiltros, setTipofiltros] = useState([]);
  useEffect(() => {
    const OnLoadtipofiltros_dashboard = async () => {
      const data = await get_tiposfiltros_dashboard();
      setTipofiltros(data);
    };
    OnLoadtipofiltros_dashboard();
  }, []);

  return <SelectUi data={tipofiltros} {...props} />;
};
