import { Form } from "antd";
import React, { useEffect } from "react";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  PaymentMethodType,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { MediosPagoContentItem } from "./MediosPagoContentItem";

const ItemMedioPagoNuevo = {
  id: new Date().getTime(),
  tipomediopago: PaymentMethodType.Efectivo,
  mediopago: null,
  labelmediopago: "",
  nrooperacion: "",
  monto: 0,
};

export const MediosPagoContent = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const mediospago = Form.useWatch("mediospago", form);
  const montototalpagar = Form.useWatch("montototalpagar", form);
  const { setFieldValue } = form;
  useEffect(() => {
    let suma_montos = 0;
    mediospago?.forEach((item) => (suma_montos += item.monto));
    const _montovuelto = suma_montos - montototalpagar;
    setFieldValue("montoingresado", suma_montos);
    setFieldValue(
      "montovuelto",
      parseFloat(parseFloat(_montovuelto).toFixed(2))
    );
  }, [mediospago, montototalpagar]);
  return (
    <>
      <Form.List
        name="mediospago"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error("Ingrese al menos 1 medio de pago")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field) => (
                <MediosPagoContentItem
                  key={field.key}
                  form={form}
                  field={field}
                  handleAdd={add}
                  handleRemove={remove}
                />
              ))}
              {fields.length < 1 && (
                <Form.Item>
                  <ButtonUi
                    typeUi={TipoButtonConstants.Agregar}
                    onClick={() => add(ItemMedioPagoNuevo)}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
