import React, { useState } from "react";
import { Form, Image, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const IsValidFile = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";
  if (!isJpgOrPng) message.error("You can only upload JPG/PNG file!");
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) message.error("Image must smaller than 2MB!");
  return isJpgOrPng && isLt2M;
};

export const ProductoImageUpload = ({ name }) => {
  const [form] = Form.useForm();

  const imagen = Form.useWatch("imagen", form);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(imagen);

  const OnHandleChange = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (typeof file !== "undefined") {
      const isValid = IsValidFile(file);
      if (isValid) {
        getBase64(file, (url) => {
          setLoading(false);
          setImageUrl(url);
          form.setFieldValue(name, url);
        });
      }
    }
  };

  const uploadButton = (
    <div
      className="ui_button_upload ui_button_upload_select_picture_card"
      onClick={() => {
        const button = document.getElementById(name);
        button.click();
      }}
    >
      <div className="ui_button_upload_select_picture_card_display_text">
        {imageUrl ? (
          <Image
            className="ui_button_upload_select_picture_card_image"
            src={imageUrl}
            preview={false}
          />
        ) : (
          <>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            Upload
          </>
        )}
      </div>
    </div>
  );
  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        id={name}
        name={name}
        onChange={(event) => {
          OnHandleChange(event);
        }}
      />
      {uploadButton}
    </>
  );
};
