import React, { useEffect } from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Col, Row } from "antd";
import { ProgramacionTejidoTable } from "./ProgramacionTejidoTable";
import { useDispatch, useSelector } from "react-redux";
import {
  programacionTejidoStartLoading,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { ProgramacionTejidoScreen } from "./ProgramacionTejidoScreen";

const ProgramacionTejidoListScreen = () => {
  const dispatch = useDispatch();
  const { programacionesTejido } = useSelector((state) => state.produccion);
  const loadProgramacionesTejido = () => {
    dispatch(programacionTejidoStartLoading());
  };
  const handleReLoad = () => {
    loadProgramacionesTejido();
  };
  const handleNuevo = () => {
    dispatch(showFormProgramacionTejido());
  };

  return (
    <PageHeaderUi
      title="Programación de Tejido"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="actualizar"
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleReLoad}
        />,
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ProgramacionTejidoTable data={programacionesTejido} />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ProgramacionTejidoList = () => {
  const dispatch = useDispatch();
  const { visibleFormProgramacionTejido } = useSelector(
    (state) => state.produccion
  );
  useEffect(() => {
    dispatch(programacionTejidoStartLoading());
  }, []);
  if (visibleFormProgramacionTejido) {
    return <ProgramacionTejidoScreen />;
  } else {
    return <ProgramacionTejidoListScreen />;
  }
};
export default ProgramacionTejidoList;
