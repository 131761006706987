import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  empresaClearActive,
  empresaStartAdd,
  empresaStartUpdate,
} from "../../../actions/empresa";
import { uiShowForm } from "../../../actions/ui";
import { get_image_base64, to_Base64 } from "../../../helpers/utils";
import { EmpresaForm } from "./EmpresaForm";
import { SoapTiposConstans } from "../../../types/typesConstants";
import { FormUi } from "../../ui/FormUi";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  numeroruc: data?.numeroruc || null,
  nombre: data?.nombre || "",
  nombrecomercial: data?.nombrecomercial || "",
  numerodetraccion: data?.numerodetraccion || "",
  direccion: data?.direccion || "",
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  porcentajeigv: parseFloat(data?.porcentajeigv || 18),
  moneda: data?.moneda || null,
  logo: data?.logo || [],
  rubrica: data?.rubrica || [],
  icono: data?.icono || [],
  soaptipo: data?.soaptipo || SoapTiposConstans.demo,
  soapusuario: data?.soapusuario || null,
  soappassword: data?.soappassword || null,
  esemisorelectronico: data?.esemisorelectronico || false,
  certificadosunat: data?.certificadosunat || [],
  keycertificadosunat: data?.keycertificadosunat || [],
});

export const EmpresaScreen = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { activeEmpresa, empresas } = useSelector((state) => state.empresa);
  const [tabKeyError, setTabKeyError] = useState(null);

  const handleSave = async (values) => {
    setTabKeyError(null);
    let file_logo = get_image_base64(values?.logo);
    let file_rubrica = get_image_base64(values?.rubrica);
    let file_icono = get_image_base64(values?.icono);
    let file_certificado = values?.certificadosunat?.[0]?.url || "";
    if (values?.certificadosunat?.[0]?.originFileObj !== undefined) {
      file_certificado = await to_Base64(
        values?.certificadosunat?.[0]?.originFileObj
      );
    }
    let file_keycertificado = values?.keycertificadosunat?.[0]?.url || "";
    if (values?.keycertificadosunat?.[0]?.originFileObj !== undefined) {
      file_keycertificado = await to_Base64(
        values?.keycertificadosunat?.[0]?.originFileObj
      );
    }
    const valuesData = {
      ...values,
      logo: file_logo,
      rubrica: file_rubrica,
      icono: file_icono,
      certificadosunat: file_certificado,
      keycertificadosunat: file_keycertificado,
    };

    if (activeEmpresa) {
      dispatch(empresaStartUpdate(valuesData));
    } else {
      dispatch(empresaStartAdd(valuesData));
    }
    dispatch(empresaClearActive());
    dispatch(uiShowForm(false));
    if (empresas.length === 1) {
      navigate("/");
    }
  };
  const handleFailed = ({ errorFields }) => {
    if (errorFields.length > 0) {
      const tabActive = errorFields?.[0]?.name?.includes(
        "certificadosunat",
        "keycertificadosunat"
      )
        ? "sunat"
        : "general";
      setTabKeyError(tabActive);
    } else setTabKeyError(null);
  };
  return (
    <FormUi
      name="FormEmpresa"
      form={form}
      layout="vertical"
      autoComplete="off"
      // labelWrap
      // labelAlign="left"
      initialValues={initialValues(activeEmpresa)}
      handleSubmit={handleSave}
      onFinishFailed={handleFailed}
    >
      <EmpresaForm tabKeyError={tabKeyError} />
    </FormUi>
  );
};
