import React, { useEffect, useState } from "react";
import { get_rutasproduccion_select } from "../../../actions/producto";
import { SelectUi } from "../../ui/SelectUi";

export const RutaProduccionSelect = ({ ...props }) => {
  const [rutasProduccion, setRutasProduccion] = useState([]);
  const LoadDatos = async () => {
    const datos = await get_rutasproduccion_select();
    setRutasProduccion(datos);
  };
  useEffect(() => {
    LoadDatos();
  }, []);

  return <SelectUi data={rutasProduccion} {...props} />;
};
