import React, { useEffect, useState } from "react";
import { getTipoTerminal } from "../../../helpers/loadVentas";
import { SelectUi } from "../../ui/SelectUi";

export const TipoTerminalSelect = ({ ...props }) => {
  const [tipoterminales, setTipoterminales] = useState([]);
  const OnLoadTipoTerminal = async () => {
    const datos = await getTipoTerminal();
    setTipoterminales(datos);
  };
  useEffect(() => {
    OnLoadTipoTerminal();
  }, []);

  return <SelectUi data={tipoterminales} {...props} />;
};
