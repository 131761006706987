import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { comprobantespagoStartLoad } from "../../../actions/comprobante";
import { ButtonUi } from "../../ui/ButtonUi";
import { ComprobantePrintModal } from "../reusable/ComprobantePrintModal";
import { ComprobantePagoSearchFormTable } from "./ComprobantePagoSearchFormTable";
import { ComprobantePagoTable } from "./ComprobantePagoTable";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { TipoButtonConstants } from "../../../types/typesConstants";

export const ComprobantePagoList = () => {
  const dispatch = useDispatch();
  const { comprobantespago } = useSelector((state) => state.comprobantepago);
  const handleReLoad = () => {
    dispatch(comprobantespagoStartLoad());
  };
  const handleNewComprobante = () => {};
  useEffect(() => {
    dispatch(comprobantespagoStartLoad());
  }, [dispatch]);
  return (
    <PageHeaderUi
      title="Comprobantes pago"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="actualizar"
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleReLoad}
        />,
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNewComprobante}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ComprobantePagoSearchFormTable />
            <ComprobantePagoTable comprobantes={comprobantespago} />
            <ComprobantePrintModal />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export default ComprobantePagoList;
