import { Descriptions, Form, InputNumber, Typography, theme } from "antd";
import React from "react";
const { Text } = Typography;
export const SummaryOrder = ({ detallecomprobante }) => {
  const form = Form.useFormInstance();
  const prefijo = Form.useWatch("prefijo", form);
  const { token } = theme.useToken();
  const labelStyle = {
    width: "50%",
    fontWeight: "bold",
    justifyContent: "right",
    alignItems: "center",
  };
  const inputStyle = {
    fontSize: 16,
    color: token.colorPrimary,
    fontWeight: "bold",
  };
  return (
    <>
      <Descriptions size="small" column={1} className="summary_order">
        <Descriptions.Item>
          <Text type="secondary">
            {(detallecomprobante?.length || 0) + " productos"}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total" labelStyle={labelStyle}>
          <Form.Item name="montototal" style={{ marginBottom: 0 }}>
            <InputNumber
              bordered={false}
              readOnly={true}
              prefix={prefijo || "S/"}
              style={inputStyle}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Descuento" labelStyle={labelStyle}>
          <Form.Item name="montodescuento" style={{ marginBottom: 0 }}>
            <InputNumber
              bordered={false}
              readOnly={true}
              prefix={prefijo || "S/"}
              style={inputStyle}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Total a pagar" labelStyle={labelStyle}>
          <Form.Item name="montototalpagar" style={{ marginBottom: 0 }}>
            <InputNumber
              bordered={false}
              readOnly={true}
              prefix={prefijo || "S/"}
              style={inputStyle}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Monto ingresado" labelStyle={labelStyle}>
          <Form.Item name="montoingresado" style={{ marginBottom: 0 }}>
            <InputNumber
              bordered={false}
              readOnly={true}
              prefix={prefijo || "S/"}
              style={inputStyle}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Vuelto" labelStyle={labelStyle}>
          <Form.Item name="montovuelto" style={{ marginBottom: 0 }}>
            <InputNumber
              bordered={false}
              readOnly={true}
              prefix={prefijo || "S/"}
              style={inputStyle}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
