import React from "react";
import { CotizacionForm } from "./CotizacionForm";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  cotizacionClearActive,
  cotizacionStartAdd,
  cotizacionStartUpdate,
} from "../../../actions/cotizacion";
import { uiShowForm } from "../../../actions/ui";
import { FormUi } from "./../../ui/FormUi";
import { Form } from "antd";
import { messageError } from "../../../helpers/messageConfig";
const initialValues = (data = {}) => ({
  id: data?.id || dayjs().valueOf(),
  cliente: data?.cliente || null,
  vendedor: data?.vendedor || null,
  fechaemision: dayjs(data?.fechaemision) || dayjs(),
  fechaentrega: dayjs(data?.fechaentrega) || dayjs(),
  fechavalidez: dayjs(data?.fechavalidez) || dayjs(),
  moneda: data?.moneda || null,
  contacto: data?.contacto || null,
  telefonocontacto: data?.telefonocontacto || null,
  inforeference: data?.inforeference || null,
  observacion: data?.observacion || null,
  montosubtotal: parseFloat(data?.montosubtotal) || 0,
  montoigvtotal: parseFloat(data?.montoigvtotal) || 0,
  montototal: parseFloat(data?.montototal) || 0,
  detallecotizacion: data?.detallecotizacion || [],
});

export const CotizacionScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeCotizacion } = useSelector((state) => state.cotizacion);
  const handleSubmit = (values) => {
    const valuesData = {
      ...values,
      fechaemision: dayjs(values?.fechaemision).format("YYYY-MM-DD"),
      fechaentrega: dayjs(values?.fechaentrega).format("YYYY-MM-DD"),
      fechavalidez: dayjs(values?.fechavalidez).format("YYYY-MM-DD"),
      empresa: activeEmpleado?.empresa?.id,
    };
    if (activeCotizacion) {
      dispatch(cotizacionStartUpdate(valuesData));
    } else {
      dispatch(cotizacionStartAdd(valuesData));
    }
    handleCloseForm();
  };
  const handleCloseForm = () => {
    dispatch(cotizacionClearActive());
    dispatch(uiShowForm(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormCotizacion } = forms;
        if (name === "FormProductoCotizacionModal") {
          const detallecotizacion =
            FormCotizacion.getFieldValue("detallecotizacion") || [];
          const _existe = detallecotizacion?.filter(
            (x) => x.item === values?.item
          )?.length;
          if (_existe < 1)
            FormCotizacion.setFieldsValue({
              detallecotizacion: [...detallecotizacion, values],
            });
          else messageError("el item ya existe en el detalle de la cotización");
        }
      }}
    >
      <FormUi
        name="FormCotizacion"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeCotizacion)}
        handleSubmit={handleSubmit}
        form={form}
      >
        <CotizacionForm handleCloseForm={handleCloseForm} />
      </FormUi>
    </Form.Provider>
  );
};
