import { Form, Input } from "antd";
import React from "react";

export const FormItemHiddenUi = ({ name }) => {
  return (
    <Form.Item name={name} hidden={true} noStyle>
      <Input />
    </Form.Item>
  );
};
