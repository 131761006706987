import React, { useEffect, useState } from "react";
import { get_seriecomprobantes_by_terminalcajero_tipocomprobante } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";
import { Form } from "antd";
export const SerieComprontesSelect = ({
  terminalcajero,
  tipocomprobante,
  ...props
}) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const [serieComprobantes, setSerieComprobantes] = useState([]);

  useEffect(() => {
    const OnLoadSerieComprobantes = async (terminalcajero, tipocomprobante) => {
      const datos =
        await get_seriecomprobantes_by_terminalcajero_tipocomprobante(
          terminalcajero,
          tipocomprobante
        );
      setSerieComprobantes(datos);
      setFieldValue("seriecomprobante", datos?.[0]?.value);
    };
    if (
      typeof terminalcajero !== "undefined" &&
      typeof tipocomprobante !== "undefined" &&
      tipocomprobante !== null
    )
      OnLoadSerieComprobantes(terminalcajero, tipocomprobante);
  }, [terminalcajero, tipocomprobante]);

  return <SelectUi data={serieComprobantes} {...props} />;
};
