import { axiosConToken } from "./axios";
import { validaMessagesErrors } from "./validates";

const getAlmacenesSelectOptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("locales/getAlmacenesSelect");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
    console.log(error.response);
  }
  return datos;
};
const getAlmacenesEmpleadoSelectOptions = async (empleado) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("empleados/getAlmacenesSelect", {
      params: { empleado },
    });
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export { getAlmacenesSelectOptions, getAlmacenesEmpleadoSelectOptions };
