import React, { useState } from "react";
import { FormUi } from "../../../ui/FormUi";
import { Form } from "antd";
import { EntradaForm } from "./EntradaForm";
import dayjs from "dayjs";
import { DateFormats } from "../../../../types/typesConstants";
import {
  movimientoEntradaStartAddNew,
  showFormInputMovements,
} from "../../../../actions/inventario";
import { useDispatch, useSelector } from "react-redux";
import { DetalleSeriesLotesModal } from "../../modal/DetalleSeriesLotesModal";

const initialValues = {
  almacenorigen: null,
  item: null,
  cantidad: null,
  costo: null,
  unidadmedida: null,
  tipomovimiento: null,
  series: [],
  lotes: [],
  manejalotes: false,
  manejaseries: false,
};
export const EntradaScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { manejalotes, manejaseries } = form.getFieldsValue();
  const cantidad = Form.useWatch("cantidad", form);
  const [open, setOpen] = useState(false);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const showSeriesModal = () => {
    setOpen(true);
  };
  const hideSeriesModal = () => {
    setOpen(false);
  };
  const handleSave = (values) => {
    const valuesData = {
      empresa: activeEmpleado?.empresa?.id,
      almacenorigen: values?.almacendestino,
      almacendestino: values?.almacendestino,
      tipomovimiento: values?.tipomovimiento,
      detallescomprobantemovimiento: [
        {
          ...values,
          lotes: values?.lotes?.map((item) => ({
            ...item,
            fecha_fabricacion: dayjs(values?.fecha_fabricacion).format(
              DateFormats.backend
            ),
            fecha_vencimiento: dayjs(values?.fecha_vencimiento).format(
              DateFormats.backend
            ),
          })),

          series: values?.series?.map((item) => ({
            ...item,
            fecha_fabricacion: dayjs(item.fecha_fabricacion).format(
              DateFormats.backend
            ),
          })),
        },
      ],
    };
    dispatch(movimientoEntradaStartAddNew(valuesData));
    dispatch(showFormInputMovements(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === "FormDetalleSerieLote") {
          const { FormMovimientoEntrada } = forms;
          FormMovimientoEntrada.setFieldValue("series", values?.series);
          FormMovimientoEntrada.setFieldValue("lotes", values?.lotes);
        }
        setOpen(false);
      }}
    >
      <FormUi
        name="FormMovimientoEntrada"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <EntradaForm onOpenModal={showSeriesModal} />
      </FormUi>
      <DetalleSeriesLotesModal
        series={form.getFieldValue("series")}
        lotes={form.getFieldValue("lotes")}
        manejalotes={manejalotes}
        manejaseries={manejaseries}
        cantidad={cantidad}
        open={open}
        onCancel={hideSeriesModal}
      />
    </Form.Provider>
  );
};
