import React from "react";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { CardUi } from "../../ui/CardUi";
import { Space, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { useDispatch, useSelector } from "react-redux";
import { clienteClearActive } from "../../../actions/cliente";
import { uiShowForm } from "../../../actions/ui";
import { ClientePrincipalPart } from "./ClientePrincipalPart";
import { ClienteDireccionPart } from "./ClienteDireccionPart";

export const ClienteForm = () => {
  const dispatch = useDispatch();
  const { activeCliente } = useSelector((state) => state.cliente);
  const handleCancel = () => {
    dispatch(clienteClearActive());
    dispatch(uiShowForm(false));
  };
  return (
    <CardUi
      title={
        <>
          Cliente
          {activeCliente ? <small> Editar</small> : <small> Nuevo</small>}
        </>
      }
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormClientes"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <ClientePrincipalPart />
      <ClienteDireccionPart />
    </CardUi>
  );
};
