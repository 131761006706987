import { types } from "../types/typesCompra";

const initialState = {
  comprobantesCompra: [],
  activeComprobanteCompra: null,
  activeDetalleComprobanteCompra: null,
  visibleFormComprobanteCompra: false,
};

export const ComprasReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.comprobantecompraLoaded:
      return { ...state, comprobantesCompra: action.payload };
    case types.comprobantecompraAdded:
      return {
        ...state,
        comprobantesCompra: [action.payload, ...state.comprobantesCompra],
      };
    case types.comprobantecompraSetActive:
      return {
        ...state,
        activeComprobanteCompra: action.payload,
      };
    case types.comprobantecompraClearActive:
      return {
        ...state,
        activeComprobanteCompra: null,
      };
    case types.detallecomprobantecompraSetActive:
      return {
        ...state,
        activeDetalleComprobanteCompra: action.payload,
      };
    case types.detallecomprobantecompraClearActive:
      return {
        ...state,
        activeDetalleComprobanteCompra: null,
      };
    case types.comprobantecompraUpdated:
      return {
        ...state,
        comprobantesCompra: state.comprobantesCompra.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.comprobantecompraDeleted:
      return {
        ...state,
        comprobantesCompra: state.comprobantesCompra.filter(
          (e) => e.id !== action.payload.id
        ),
        activeComprobanteCompra: null,
      };
    case types.comprobantecompraShowForm:
      return {
        ...state,
        visibleFormComprobanteCompra: action.payload,
      };
    default:
      return state;
  }
};
