import { Col, Row, DatePicker, Form, Space } from "antd";
import React, { useState } from "react";
import { ProductoSelect } from "../reusable/ProductoSelect";
import { KardexTable } from "./KardexTable";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import dayjs from "dayjs";
import { FormSearchUi } from "../../ui/FormSearchUi";
import { get_inventory_movements_by_filters } from "../../../actions/inventario";
import { AlmacenSelect } from "./../reusable/AlmacenSelect";
const initialValues = {
  item: null,
  fechainicio: dayjs(),
  fechafin: dayjs(),
  almacen: null,
};
export const KardexSearch = () => {
  const [form] = Form.useForm();
  const [kardexproductos, setKardexproductos] = useState([]);
  const [showAdvancedRows, setShowAdvancedRows] = useState(false);
  const searchKardex = async (item, fechainicio, fechafin, almacen = null) => {
    const datos = await get_inventory_movements_by_filters(
      item,
      fechainicio,
      fechafin,
      almacen
    );
    setKardexproductos(datos);
  };
  const handleAdvancedRow = () => {
    setShowAdvancedRows(!showAdvancedRows);
    form.setFieldsValue({ almacen: null });
  };
  const handleSubmit = (values) => {
    const { item, fechainicio, fechafin, almacen } = values;
    searchKardex(
      item,
      dayjs(fechainicio).format(DateFormats.backend),
      dayjs(fechafin).format(DateFormats.backend),
      almacen
    );
  };
  return (
    <>
      <FormSearchUi
        form={form}
        name="FormKardexSearch"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        {showAdvancedRows && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="almacen"
                label="Almacén"
                required={false}
                // rules={[{ required: true, message: "El Item es obligatorio" }]}
              >
                <AlmacenSelect placeholder="Seleccione Almacen" />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              name="item"
              label="Item"
              required={false}
              rules={[{ required: true, message: "El Item es obligatorio" }]}
            >
              <ProductoSelect placeholder="Seleccione Item" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={4}>
            <Form.Item
              name="fechainicio"
              label="Fecha Inicio"
              required={false}
              rules={[
                {
                  required: true,
                  message: "La fecha de inicio es obligatoria",
                },
                {
                  type: "date",
                  message: "Ingrese una fecha válida",
                },
              ]}
            >
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={4}>
            <Form.Item
              name="fechafin"
              label="Fecha Fin"
              required={false}
              rules={[
                {
                  required: true,
                  message: "La fecha de fin es obligatoria",
                },
                {
                  type: "date",
                  message: "Ingrese una fecha válida",
                },
              ]}
            >
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
          <Col xs={6} sm={24} md={4} lg={4} xl={4}>
            <Form.Item label="  ">
              <Space wrap>
                <ButtonUi
                  htmlType="submit"
                  typeUi={TipoButtonConstants.Buscar}
                  text="Consultar"
                />
                <ButtonUi
                  typeUi={TipoButtonConstants.Filtrar}
                  displayText={false}
                  onClick={handleAdvancedRow}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </FormSearchUi>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <KardexTable kardexproductos={kardexproductos} />
        </Col>
      </Row>
    </>
  );
};
