import React from "react";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { Card, Col, Form, Input, Row, Space, Tooltip } from "antd";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { CardUi } from "../../../ui/CardUi";
import { useDispatch } from "react-redux";
import {
  rutaProduccionClearActive,
  showFormRutaProduccion,
} from "../../../../actions/produccion";
import { UnorderedListOutlined } from "@ant-design/icons";
import { RutaProduccionDetalleTable } from "./RutaProduccionDetalleTable";

export const RutaProduccionForm = () => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(rutaProduccionClearActive());
    dispatch(showFormRutaProduccion(false));
  };
  return (
    <CardUi
      title="Ruta Producción"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormRutaProduccion"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={12}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[
              {
                required: true,
                message: "El nombre es obligatorio",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Card
            title={
              <span>
                <UnorderedListOutlined /> Detalle ruta producción
              </span>
            }
            size="small"
          >
            <RutaProduccionDetalleTable />
          </Card>
        </Col>
      </Row>
    </CardUi>
  );
};
