import React, { useEffect, useState } from "react";
import { TableTransferUi } from "../../ui/TableTransferUi";
import { get_items } from "../../../actions/listaprecio";
import { Form } from "antd";

const columns = [
  {
    dataIndex: "title",
    title: "Nombre",
  },
  {
    dataIndex: "subcategoria",
    title: "Categoria",
  },
  {
    dataIndex: "costo",
    title: "Costo",
  },
  {
    dataIndex: "precioventa",
    title: "Precio",
  },
];

export const ListaPrecioItemDetalle = () => {
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_items();
      setData(datos);
    };
    OnLoad();
    return () => {
      setData([]);
    };
  }, []);
  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };
  return (
    <Form.Item
      name="itemlistaprecios"
      rules={[{ required: true, message: "Seleccione al item en el detalle" }]}
    >
      <TableTransferUi
        dataSource={data}
        targetKeys={targetKeys}
        showSearch
        showSelectAll={false}
        onChange={onChange}
        filterOption={(inputValue, item) =>
          item.title.indexOf(inputValue.toUpperCase()) !== -1 ||
          item.subcategoria.indexOf(inputValue.toUpperCase()) !== -1
        }
        leftColumns={columns}
        rightColumns={columns}
      />
    </Form.Item>
  );
};
