import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { CardUi } from "../../../ui/CardUi";
import { UnorderedListOutlined } from "@ant-design/icons";
import { DetalleConsolidadoTelaTable } from "./DetalleConsolidadoTelaTable";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { SearchFormProgramacionTintoreriaModal } from "./modal/SearchFormProgramacionTejidoModal";
export const DetalleConsolidadoTelaContent = () => {
  const form = Form.useFormInstance();
  const programaciontintoreria = Form.useWatch("programaciontintoreria", form);
  const detalleconsolidadotela = Form.useWatch("detalleconsolidadotela", form);

  useEffect(() => {
    let cantidadtotal = 0;
    let pesobrutototal = 0;
    let pesonetototal = 0;
    detalleconsolidadotela?.forEach((item) => {
      cantidadtotal += parseFloat(item.cantidad);
      pesobrutototal += parseFloat(item.pesobruto);
      pesonetototal += parseFloat(item.pesoneto);
    });
    cantidadtotal = parseFloat(cantidadtotal).toFixed(2);
    pesobrutototal = parseFloat(pesobrutototal).toFixed(2);
    pesonetototal = parseFloat(pesonetototal).toFixed(2);
    form.setFieldsValue({ cantidadtotal, pesobrutototal, pesonetototal });
  }, [detalleconsolidadotela]);

  const [openModal, setOpenModal] = useState(false);
  const handleHideModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  return (
    <>
      <Form.List
        name="detalleconsolidadotela"
        rules={[
          {
            validator: async (_, value) => {
              if (!value || value.length < 1)
                return Promise.reject(
                  new Error(
                    "Se debe ingresar al menos 1 item en el detalle del consolidado de tela"
                  )
                );
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <CardUi
              title={
                <span>
                  <UnorderedListOutlined /> Detalle consolidado tela
                </span>
              }
              size="small"
              type="inner"
              extra={
                <ButtonUi
                  typeUi={TipoButtonConstants.Agregar}
                  onClick={handleOpenModal}
                />
              }
            >
              <DetalleConsolidadoTelaTable
                data={detalleconsolidadotela}
                handleAdd={add}
                remove={remove}
              />
              <Form.Item className="ui-errorlist">
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </CardUi>
          </>
        )}
      </Form.List>
      <SearchFormProgramacionTintoreriaModal
        programaciontintoreria={programaciontintoreria}
        open={openModal}
        onCancel={handleHideModal}
      />
    </>
  );
};
