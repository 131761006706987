import { Form } from "antd";
import React, { useState } from "react";
import {
  movimientoTrasladoStartInserted,
  showFormTransferMovements,
} from "../../../../actions/inventario";
import { useDispatch, useSelector } from "react-redux";
import { FormUi } from "../../../ui/FormUi";
import { TrasladoForm } from "./TrasladoForm";
import { DetalleSalidaModal } from "./DetalleSalidaModal";

const initialValues = (data = {}) => ({
  inventario: data?.id || "",
  producto: data?.producto || "",
  nombreproducto: data?.nombreproducto || "",
  almacenorigen: data?.almacen || null,
  nombrealmacen: data?.nombrealmacen || "",
  stockactual: data?.stockactual || "",
  almacendestino: data?.almacendestino || null,
  cantidad: parseFloat(data?.cantidad || 0),
  series: data?.series || [],
  lotes: data?.lotes || [],
  unidadmedida: data?.unidadmedida || null,
  manejalotes: data?.manejalotes || false,
  manejaseries: data?.manejaseries || false,
});

export const TrasladoScreen = () => {
  const [form] = Form.useForm();
  const manejaseries = Form.useWatch("manejaseries", form);
  const manejalotes = Form.useWatch("manejalotes", form);
  const cantidad = Form.useWatch("cantidad", form);
  const { inventarioActive } = useSelector((state) => state.inventario);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleSave = (values) => {
    const valuesLotes = values?.lotes
      ?.filter((e) => e.cantidad_ingresada !== null)
      .map((e) => ({
        ...e,
        cantidad: e.cantidad_ingresada,
      }));
    const valuesData = {
      empresa: activeEmpleado?.empresa?.id,
      ...values,
      detallescomprobantemovimiento: [
        {
          inventario: values?.inventario,
          cantidad: values?.cantidad,
          unidadmedida: values?.unidadmedida,
          lotes: valuesLotes,
          series: values?.series?.filter((item) => item.selected === true),
        },
      ],
    };
    console.log(valuesData);
    dispatch(movimientoTrasladoStartInserted(valuesData));
    dispatch(showFormTransferMovements(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === "FormDetalle") {
          const { FormMovimientoTraslado } = forms;
          FormMovimientoTraslado.setFieldValue("lotes", values?.lotes);
          FormMovimientoTraslado.setFieldValue("series", values?.series);
        }
        setOpen(false);
      }}
    >
      <FormUi
        name="FormMovimientoTraslado"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(inventarioActive)}
        handleSubmit={handleSave}
      >
        <TrasladoForm onOpenModal={showModal} />
      </FormUi>
      <DetalleSalidaModal
        series={form.getFieldValue("series")}
        lotes={form.getFieldValue("lotes")}
        manejalotes={manejalotes}
        manejaseries={manejaseries}
        cantidad={cantidad}
        open={open}
        onCancel={hideModal}
      />
    </Form.Provider>
  );
};
