import { Form } from "antd";
import React, { useState } from "react";
import { InsumosDetalleProgramacionTable } from "./InsumosDetalleProgramacionTable";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { DetalleComprobanteMovimientoModal } from "./modal/DetalleComprobanteMovimientoModal";

export const InsumosDetalleProgramacionList = ({ field }) => {
  const form = Form.useFormInstance();
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const detalleprogramaciontejido = Form.useWatch(
    ["detalleprogramaciontejido", field?.name],
    form
  );
  const insumosdetalleprogramaciontejido = Form.useWatch(
    [
      "detalleprogramaciontejido",
      field?.name,
      "insumosdetalleprogramaciontejido",
    ],
    form
  );
  return (
    <>
      <Form.List
        name={[field.name, "insumosdetalleprogramaciontejido"]}
        rules={[
          {
            validator: async (_, value) => {
              // if (activeProducto !== null) {
              // Editar producto
              if (!value || value.length < 1)
                return Promise.reject(
                  new Error(
                    "Se debe ingresar al menos 1 item en el detalle de programación de tejido"
                  )
                );
              // }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <InsumosDetalleProgramacionTable
              data={insumosdetalleprogramaciontejido}
              handleDelete={remove}
              title={() => (
                <ButtonUi
                  typeUi={TipoButtonConstants.AgregarTable}
                  onClick={handleOpenModal}
                />
              )}
            />
            <Form.Item className="ui-errorlist">
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <DetalleComprobanteMovimientoModal
        detalleprogramaciontejido={detalleprogramaciontejido?.id}
        open={openModal}
        onCancelModal={handleCloseModal}
      />
    </>
  );
};
