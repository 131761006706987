import { Col, Input, Row, Table } from "antd";
import React from "react";
const { Search } = Input;
export const TableSearchBasicUi = ({
  columns,
  data,
  onSearch,
  colSpanSeach = 6,
  displayScroll = false,
  xScroll = 550,
  sizeSearch = "middle",
  ...props
}) => {
  return (
    <Table
      className="table-ui"
      columns={columns}
      dataSource={data}
      bordered
      scroll={displayScroll && { x: xScroll }}
      title={() => (
        <Row justify="end">
          <Col span={colSpanSeach}>
            <Search
              placeholder="Buscar"
              allowClear
              onSearch={onSearch}
              enterButton
              size={sizeSearch}
            />
          </Col>
        </Row>
      )}
      {...props}
    />
  );
};
