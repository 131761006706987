import React from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { CardUi } from "../../../ui/CardUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  DateFormats,
  EstadoConsolidadoTelaConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { DetalleConsolidadoTelaContent } from "./DetalleConsolidadoTelaContent";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";

export const ConsolidadoTelaForm = ({ handleClose }) => {
  const form = Form.useFormInstance();
  const estado = Form.useWatch("estado", form);
  return (
    <CardUi
      title={
        <Space wrap>
          <span>Consolidado tela</span>
          {estado === EstadoConsolidadoTelaConstants.PROCESO ? (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Estado: SIN PROCESAR
            </Tag>
          ) : (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Estado: FINALIZADO
            </Tag>
          )}
        </Space>
      }
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleClose}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormConsolidadoTela"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="estado" />
      <FormItemHiddenUi name="programaciontintoreria" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={5} xl={3}>
          <Form.Item name="codigo" label="Programación Tintoreria">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={3}>
          <Form.Item name="proveedor_ruc" label="Proveedor">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="proveedor_nombre" label=" ">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={5} xl={3}>
          <Form.Item name="cantidadtotal" label="Cantidad total">
            <InputNumber readOnly precision={2} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={3}>
          <Form.Item name="pesobrutototal" label="Peso bruto total">
            <InputNumber readOnly precision={2} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={3}>
          <Form.Item name="pesonetototal" label="Peso neto total">
            <InputNumber readOnly precision={2} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={3}>
          <Form.Item name="fechatintoreria" label="Fecha tintoreria">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={4}>
          <Form.Item
            name="fechaconsolidacion"
            label="Fecha consolidación"
            rules={[
              {
                required: true,
                message: "La fecha de consolidación es obligatoria",
              },
            ]}
          >
            <DatePicker format={DateFormats.frontend} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DetalleConsolidadoTelaContent />
        </Col>
      </Row>
    </CardUi>
  );
};
