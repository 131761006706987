import React from "react";
import { TableUi } from "../../../../ui/TableUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  EstadoProgramacionTejido,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { Space, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import {
  get_pdf_programacion_tejido,
  programacionTejidoSetActive,
  programacionTejidoStartDelete,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { showDeleteConfirm } from "../../../../../helpers/confirmModal";
import { FilePdfOutlined } from "@ant-design/icons";
import { open_pdf_file } from "../../../../../helpers/utils";
import { EstadoProgramacionTag } from "../../reusable/EstadoProgramacionTag";

const columns_table = (acciones) => {
  const { handleEditar, handleEliminar, handlePDFProgramacionTejido } =
    acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor_nombre",
      key: "proveedor_nombre",
      align: "center",
      render: (text, record) => (
        <>
          {text}
          <h5 style={{ margin: 0 }}>{record?.proveedor_ruc}</h5>
        </>
      ),
    },
    {
      title: "Cantidad (Rollos, Cuello,etc)",
      dataIndex: "cantidadtotal",
      key: "cantidadtotal",
    },

    {
      title: "Peso bruto (KG)",
      dataIndex: "pesobrutototal",
      key: "pesobrutototal",
    },
    {
      title: "Peso neto (KG)",
      dataIndex: "pesonetototal",
      key: "pesonetototal",
    },
    {
      title: "Estado",
      dataIndex: "nombreestado",
      key: "nombreestado",
      render: (text, record) => (
        <EstadoProgramacionTag estado={record?.estado} estado_nombre={text} />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Space>
          <ButtonUi
            typeUi={TipoButtonConstants.EditarTable}
            size="small"
            displayText={false}
            onClick={() => {
              handleEditar(record);
            }}
          />
          {record?.estado === EstadoProgramacionTejido.PROCESO && (
            <Tooltip title="Eliminar">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                size="small"
                onClick={() => handleEliminar(record)}
                displayText={false}
                ghost
              />
            </Tooltip>
          )}

          <ButtonUi
            typeUi={TipoButtonConstants.CustomButton}
            onClick={() => handlePDFProgramacionTejido(record)}
            className="ml-1"
            icon={<FilePdfOutlined style={{ color: "#FF0000" }} />}
            displayText={false}
            size="small"
          />
        </Space>
      ),
    },
  ];
};
export const ProgramacionTejidoTable = ({ data }) => {
  const dispatch = useDispatch();
  const handleEditar = (programaciontejido) => {
    dispatch(programacionTejidoSetActive(programaciontejido));
    dispatch(showFormProgramacionTejido());
  };
  const handleEliminar = (programaciontejido) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la programación de tejido?",
      programaciontejido.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(programacionTejidoStartDelete(programaciontejido));
        }
      }
    );
  };
  const handlePDFProgramacionTejido = async (programaciontejido) => {
    const file = await get_pdf_programacion_tejido(programaciontejido?.id);
    let name_file = programaciontejido?.nombre + ".pdf";
    open_pdf_file(file, name_file);
  };
  return (
    <TableUi
      columns={columns_table({
        handleEditar,
        handleEliminar,
        handlePDFProgramacionTejido,
      })}
      rowKey="id"
      dataSource={data}
      size="small"
    />
  );
};
