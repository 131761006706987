import React, { useEffect, useState } from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import { TareaProduccionModal } from "./TareaProduccionModal";
import { TareaProduccionTable } from "./TareaProduccionTable";
import {
  tareaProduccionStartAdd,
  tareaProduccionStartLoad,
  tareaProduccionStartUpdate,
} from "../../../../../actions/produccion";
import { Form } from "antd";

export const TareaProduccionList = () => {
  const dispatch = useDispatch();
  const { tareasproduccion, activeTareaProduccion } = useSelector(
    (state) => state.produccion
  );
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const handleActualizar = () => {
    dispatch(tareaProduccionStartLoad());
  };
  useEffect(() => {
    dispatch(tareaProduccionStartLoad());
  }, [dispatch]);
  return (
    <PageHeaderUi
      title="Tareas producción"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={showModal}
          key="Nuevo"
        />,
      ]}
    >
      <TareaProduccionTable data={tareasproduccion} onOpenModal={showModal} />
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "TareaProduccionForm") {
            if (activeTareaProduccion)
              dispatch(tareaProduccionStartUpdate(values));
            else dispatch(tareaProduccionStartAdd(values));

            hideModal();
          }
        }}
      >
        <TareaProduccionModal open={open} onCancelModal={hideModal} />
      </Form.Provider>
    </PageHeaderUi>
  );
};

export default TareaProduccionList;
