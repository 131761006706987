import { Space, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  terminalCajeroSetActive,
  terminalCajeroStartDelete,
} from "../../../actions/terminalcajero";
import { uiShowForm } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TableUi } from "../../ui/TableUi";

const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Local",
      dataIndex: "nombrelocal",
      key: "nombrelocal",
    },
    {
      title: "Tipo terminal",
      dataIndex: "nombretipoterminalcajero",
      key: "nombretipoterminalcajero",
    },
    {
      title: "Acciones",
      dataIndex: "accciones",
      key: "accciones",
      render: (text, record) => (
        <>
          <Space size="middle">
            <Tooltip title="Editar">
              <ButtonUi
                typeUi={TipoButtonConstants.EditarTable}
                onClick={() => handleEditar(record)}
                displayText={false}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                onClick={() => handleEliminar(record)}
                displayText={false}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
};

export const TerminalCajeroTable = ({ terminalescajero, loading }) => {
  const dispatch = useDispatch();

  const handleEditar = (item) => {
    dispatch(terminalCajeroSetActive(item));
    dispatch(uiShowForm());
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el terminal cajero?",
      item.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(terminalCajeroStartDelete(item));
        }
      }
    );
  };

  return (
    <TableUi
      data={terminalescajero}
      rowKey="id"
      size="small"
      scroll={{ x: 550 }}
      columns={columns({ handleEditar, handleEliminar })}
      loading={loading}
    />
  );
};
