import { Col, Form, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { DetalleProgramacionTabItem } from "./DetalleProgramacionTabItem";
import dayjs from "dayjs";
import { EstadoConsolidadoTelaConstants } from "../../../../../types/typesConstants";
const itemDefault = {
  id: new Date().getTime(),
  detalleprogramaciontejido: null,
  detalleprogramaciontejido_nombre: null,
  detallecomprobantecompra: null,
  detallecomprobantecompra_nombre: null,
  color: null,
  cantidad: null,
  pesoneto: null,
  pesobruto: null,
  tipotenidotela: null,
  precio: null,
  unidadmedida: null,
  tipocambio: null,
  partida: null,
  estado: false,
};
export const DetalleProgramacionTab = ({
  fields,
  handleAdd,
  handleRemove,
  handleOpenModal,
}) => {
  const [activeKey, setActiveKey] = useState(0);
  const [items, setItems] = useState([]);
  const form = Form.useFormInstance();
  const detalleprogramaciontintoreria =
    Form.useWatch("detalleprogramaciontintoreria", form) || [];
  const consolidadotela = Form.useWatch("consolidadotela", form) || null;
  useEffect(() => {
    const _items = fields?.map((field, index) => {
      const row_detalleprogramaciontintoreria =
        detalleprogramaciontintoreria[field?.name] || null;
      const row_detalleconsolidadotela =
        consolidadotela?.detalleconsolidadotela?.filter(
          (item) =>
            item.detalleprogramaciontintoreria ===
            row_detalleprogramaciontintoreria?.id
        )?.[0];
      const estado_detalleconsolidadotela =
        row_detalleconsolidadotela?.estado ===
        EstadoConsolidadoTelaConstants.FINALIZADO
          ? false
          : true;
      return {
        label: `Tab ${index + 1}`,
        children: (
          <DetalleProgramacionTabItem
            field={field}
            handleOpenModal={handleOpenModal}
            disabledControl={!estado_detalleconsolidadotela}
          />
        ),
        key: index,
        closable: estado_detalleconsolidadotela,
      };
    });
    setItems(_items);
  }, [fields]);

  const handleChangeTab = (key) => setActiveKey(key);
  const handleAddTab = () => {
    handleAdd({ ...itemDefault, id: dayjs().valueOf() });
    setActiveKey(fields.length);
  };
  const onEdit = (targetKey, action, { handleRemove }) => {
    if (action === "remove") {
      handleRemove(targetKey);
      const newTargetKey = targetKey - 1;
      if (newTargetKey > 0) setActiveKey(newTargetKey);
      else setActiveKey(0);
    } else if (action === "add") {
      handleAddTab();
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Tabs
            onChange={handleChangeTab}
            activeKey={activeKey}
            type="editable-card"
            onEdit={(targetKey, action) =>
              onEdit(targetKey, action, { handleRemove })
            }
            items={items}
            size="small"
            hideAdd={
              consolidadotela?.estado ===
              EstadoConsolidadoTelaConstants.FINALIZADO
                ? true
                : false
            }
          />
        </Col>
      </Row>
    </>
  );
};
