import React, { useEffect, useState } from "react";
import { getSubCategorias_by_categorias_SelectOptions } from "../../../actions/producto";
import { SelectUi } from "../../ui/SelectUi";

export const SubCategoriaProductoSelect = ({ categoria, ...props }) => {
  const [subcategorias, setSubcategorias] = useState([]);
  const SubCategoriasLoad = async (CategoriaId) => {
    if (CategoriaId !== "") {
      const data = await getSubCategorias_by_categorias_SelectOptions(
        CategoriaId
      );
      setSubcategorias(data);
    }
  };
  useEffect(() => {
    SubCategoriasLoad(categoria);
  }, [categoria]);

  return <SelectUi data={subcategorias} {...props} />;
};
