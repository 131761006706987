import React, { useEffect, useState } from "react";
import { get_tiposdescuentos } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";

export const TipoDescuentoSelect = ({ ...props }) => {
  const [descuentos, setDescuentos] = useState([]);

  useEffect(() => {
    const OnLoadMediosPago = async () => {
      const datos = await get_tiposdescuentos();
      setDescuentos(datos);
    };
    OnLoadMediosPago();
  }, []);

  return <SelectUi data={descuentos} {...props} />;
};
