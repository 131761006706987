import { Drawer, Form, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { FormUi } from "../../../../ui/FormUi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { MovimientoCajaForm } from "./MovimientoCajaForm";
import { NaturalezaPagoModal } from "../modal/NaturalezaPagoModal";
import {
  movimientoCajaClearActive,
  movimientoCajaStartAdd,
  movimientoCajaStartUpdate,
} from "../../../../../actions/caja";

const initialValues = {
  id: dayjs().valueOf(),
  fechaemision: dayjs(),
  tipomovimiento: null,
  naturalezapago: null,
  tipodocumentoidentidad: null,
  nrodocumentoidentidad: null,
  nombreacreedor: null,
  monto: null,
  glosa: null,
};
const useResetFormOnCloseModal = ({ form, open, activeMovimientoCaja }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    } else {
      if (activeMovimientoCaja) {
        form.setFieldsValue({
          ...activeMovimientoCaja,
          fechaemision: dayjs(activeMovimientoCaja?.fechaemision),
        });
      } else {
        form.setFieldsValue(initialValues);
      }
    }
  }, [form, prevOpen, open]);
};
export const MovimientoCajaDrawer = ({ open, handleClose }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { activeMovimientoCaja, activeCajaAbierta } = useSelector(
    (state) => state.caja
  );

  useResetFormOnCloseModal({ form, open, activeMovimientoCaja });

  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };
  const hideModal = () => {
    setOpenModal(false);
  };
  const handleCloseDrawer = () => {
    dispatch(movimientoCajaClearActive());
    handleClose();
  };
  const handleSubmit = (values) => {
    const valuesData = {
      ...values,
      fechaemision: dayjs(values?.fechaemision).format(DateFormats.backend),
      aperturacaja: activeCajaAbierta?.id,
    };
    if (activeMovimientoCaja) {
      dispatch(movimientoCajaStartUpdate(valuesData));
    } else {
      dispatch(movimientoCajaStartAdd(valuesData));
    }
    handleClose();
  };
  return (
    <>
      <Drawer
        title="Movimiento Caja"
        size="large"
        onClose={handleCloseDrawer}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space wrap>
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCloseDrawer}
            />
            <ButtonUi
              typeUi={TipoButtonConstants.Guardar}
              htmlType="submit"
              form="FormMovimientoCaja"
            />
          </Space>
        }
        forceRender
      >
        <FormUi
          name="FormMovimientoCaja"
          layout="vertical"
          autoComplete="off"
          form={form}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
        >
          <MovimientoCajaForm handleOpenModal={showModal} />
        </FormUi>
      </Drawer>
      <NaturalezaPagoModal open={openModal} handleCancel={hideModal} />
    </>
  );
};
