import { types } from "../types/typesUi";
const initialState = {
  collapsedSidebar: false,
  visibleForm: false,
  visibleDrawer: false,
  visibleModal: false,
  loading: false,
};
export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uiCollapsedSidebar:
      return {
        ...state,
        collapsedSidebar: action.payload,
      };
    case types.uiShowForm:
      return {
        ...state,
        visibleForm: action.payload,
      };
    case types.uiShowDrawer:
      return {
        ...state,
        visibleDrawer: action.payload,
      };
    case types.uiShowModal:
      return {
        ...state,
        visibleModal: action.payload,
      };
    case types.uiLoading:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
