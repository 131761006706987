import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

export const showDeleteConfirm = (
  title = "¿Está seguro de eliminar?",
  content = "",
  callback
) => {
  confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: "Sí",
    okType: "danger",
    cancelText: "No",
    onOk() {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
  });
};
export const showConfirm = (
  title = "¿Desea continuar con la operación?",
  content = "",
  callback
) => {
  confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: "Sí",
    okType: "danger",
    cancelText: "No",
    onOk() {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
  });
};
