import React, { useState } from "react";
import { get_prefijoSunat } from "../../../actions/moneda";
import { useEffect } from "react";
import { SelectUi } from "../../ui/SelectUi";

export const PrefijoSunatSelect = ({ ...restProps }) => {
  const [options, setOptions] = useState([]);
  const OnLoad = async () => {
    const data = await get_prefijoSunat();
    setOptions(data);
  };
  useEffect(() => {
    OnLoad();

    return () => {
      setOptions([]);
    };
  }, []);

  return <SelectUi data={options} {...restProps} />;
};
