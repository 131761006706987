import { Card, Col } from "antd";
import React, { useEffect } from "react";
import { ComprobantesCardContent } from "./ComprobantesCardContent";
import { NotasVentaCardContent } from "./NotasVentaCardContent";

export const CardDetails = () => {
  return (
    <>
      <Col span={8}>
        <Card title="Notas Venta" size="small">
          <NotasVentaCardContent />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Comprobantes" size="small">
          <ComprobantesCardContent />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Totales" size="small">
          Card content
        </Card>
      </Col>
    </>
  );
};
