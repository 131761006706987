import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { FormUi } from "../../../ui/FormUi";
import { Form } from "antd";
import { DetalleSalidaSerieForm } from "./DetalleSalidaSerieForm";
import { DetalleSalidaLoteForm } from "./DetalleSalidaLoteForm";

const initialValues = { detalle: [] };
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const DetalleSalidaModal = ({
  manejalotes,
  manejaseries,
  lotes,
  series,
  cantidad,
  open,
  onCancel,
}) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        lotes: lotes?.map((obj) => ({
          ...obj,
          cantidad_ingresada: obj.cantidad_ingresada
            ? obj.cantidad_ingresada
            : null,
        })),
        series: series?.map((item) => ({
          ...item,
          selected: item.selected ? item.selected : false,
        })),
      });
    }
  }, [open]);

  const handleSave = () => {
    onCancel();
  };
  return (
    <ModalUi
      title="Detalle producto por lote/serie"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      width={{ sm: "40%", md: "60%" }}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormDetalle"
        />,
      ]}
    >
      <FormUi
        name="FormDetalle"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        {manejalotes && <DetalleSalidaLoteForm cantidad={cantidad} />}
        {manejaseries && (
          <DetalleSalidaSerieForm cantidad={cantidad} openModal={open} />
        )}
      </FormUi>
    </ModalUi>
  );
};
