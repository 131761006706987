import { types } from "../types/typesCaja";

const initialState = {
  movimientosCaja: [],
  naturalezasPagoCaja: [],
  activeMovimientoCaja: null,
  activeNaturalezaPagoCaja: null,
  updateSelectNaturalezaPagoCaja: false,
  activeCajaAbierta: null,
  activeDetalleArqueoCaja: null,
  currentStep: { previewsForm: [], current: 0 },
  dataResult: null,
};
export const cajaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.cajaAbiertaSetActive:
      return {
        ...state,
        activeCajaAbierta: action.payload,
      };
    case types.cierreCajaClearActive:
      return {
        ...state,
        activeDetalleArqueoCaja: null,
        dataResult: null,
        activeArqueoCaja: null,
        currentStep: { previewsForm: [], current: 0 },
      };
    case types.cierreCajaSetCurrentStep:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.cierreCajaSetResult:
      return {
        ...state,
        dataResult: action.payload,
      };
    case types.detalleArqueoCajaSetActive:
      return {
        ...state,
        activeDetalleArqueoCaja: action.payload,
      };
    case types.detalleArqueoCajaClearActive:
      return {
        ...state,
        activeDetalleArqueoCaja: null,
      };
    case types.movimientoCajaLoaded:
      return { movimientosCaja: action.payload };
    case types.movimientoCajaAdded:
      return {
        ...state,
        movimientosCaja: [action.payload, ...state.movimientosCaja],
      };
    case types.movimientoCajaSetActive:
      return {
        ...state,
        activeMovimientoCaja: action.payload,
      };
    case types.movimientoCajaClearActive:
      return {
        ...state,
        activeMovimientoCaja: null,
      };
    case types.movimientoCajaUpdated:
      return {
        ...state,
        movimientosCaja: state.movimientosCaja.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.movimientoCajaDeleted:
      return {
        ...state,
        movimientosCaja: state.movimientosCaja.filter(
          (e) => e.id !== action.payload.id
        ),
        activeMovimientoCaja: null,
      };
    case types.movimientoCajaLogout:
      return { ...state, movimientosCaja: [], activeMovimientoCaja: null };

    case types.naturalezaPagoCajaLoaded:
      return { ...state, naturalezasPagoCaja: action.payload };
    case types.naturalezaPagoCajaAdded:
      return {
        ...state,
        naturalezasPagoCaja: [...state.naturalezasPagoCaja, action.payload],
      };
    case types.naturalezaPagoCajaSetActive:
      return {
        ...state,
        activeNaturalezaPagoCaja: action.payload,
      };
    case types.naturalezaPagoCajaClearActive:
      return {
        ...state,
        activeNaturalezaPagoCaja: null,
      };
    case types.naturalezaPagoCajaUpdated:
      return {
        ...state,
        naturalezasPagoCaja: state.naturalezasPagoCaja.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.naturalezaPagoCajaDeleted:
      return {
        ...state,
        naturalezasPagoCaja: state.naturalezasPagoCaja.filter(
          (e) => e.id !== action.payload.id
        ),
        activeNaturalezaPagoCaja: null,
      };
    case types.naturalezaPagoCajaLogout:
      return {
        ...state,
        naturalezasPagoCaja: [],
        activeNaturalezaPagoCaja: null,
      };
    case types.naturalezaPagoCajaSelectUpdate:
      return {
        ...state,
        updateSelectNaturalezaPagoCaja: action.payload,
      };
    default:
      return state;
  }
};
