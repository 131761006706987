import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { ComprobantePrintModal } from "../../reusable/ComprobantePrintModal";
import { ComprobantePagoModal } from "./ComprobantePagoModal";
import { NotaVentaSeachFormTable } from "./NotaVentaSeachFormTable";
import { NotaVentaTable } from "./NotaVentaTable";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";

export const NotaVentaList = () => {
  const { comprobantespago } = useSelector((state) => state.comprobantepago);
  return (
    <PageHeaderUi title="Notas de venta" subTitle="Listado">
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <NotaVentaSeachFormTable />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={32} md={32} lg={32} xl={32}>
            <NotaVentaTable comprobantes={comprobantespago} />
          </Col>
        </Row>
      </div>
      <ComprobantePagoModal />
      <ComprobantePrintModal />
    </PageHeaderUi>
  );
};
export default NotaVentaList;
