import { Col, Form, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";
import { DetalleComprobanteMovimientoTable } from "./DetalleComprobanteMovimientoTable";
import { FormItemHiddenUi } from "../../../../../ui/FormItemHiddenUi";
import { get_detallecomprobantemovimientos_produccion } from "../../../../../../actions/produccion";
import { MonedaSelect } from "../../../../../ventas/reusable/MonedaSelect";
import { DetalleComprobanteMovimientoSelect } from "./DetalleComprobanteMovimientoSelect";
import dayjs from "dayjs";

export const DetalleComprobanteMovimientoForm = ({ open }) => {
  const form = Form.useFormInstance();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (open) {
      const OnLoad = async () => {
        const datos = await get_detallecomprobantemovimientos_produccion();
        const Options = datos?.map((x) => {
          let lote_or_serie = "";
          if (x.lote != null) lote_or_serie = "Lote :" + x.numero_lote;
          if (x.serie != null) lote_or_serie = "Serie :" + x.numero_serie;
          const _descripcion = `${x.item_nombre} ${lote_or_serie}`;
          return {
            value: x.id,
            label: `${x.nrocomprobantemovimiento} | ${_descripcion} | ${x.cantidad} ${x.unidadmedida_prefijo}`,
            row: { ...x },
          };
        });
        setData(Options);
      };

      OnLoad();
    }
    return () => {
      setData([]);
    };
  }, [open]);
  const handleChangeMoneda = (_, option) => {
    form.setFieldValue("moneda_prefijo", option?.prefijo);
  };
  const handleChangeDetalleComprobanteMovimiento = (_, option) => {
    const row = option?.row;
    form.setFieldsValue({
      id: dayjs().valueOf(),
      descripcion: row?.item_nombre,
      precio: row?.costo || 0,
      moneda: row?.moneda || null,
      moneda_prefijo: row?.moneda_prefijo || null,
      lote: row?.lote,
      numero_lote: row?.numero_lote,
      serie: row?.serie,
      numero_serie: row?.numero_serie,
      unidadmedida: row?.unidadmedida,
      unidadmedida_prefijo: row?.unidadmedida_prefijo,
      cantidad: row?.cantidad,
      nrocomprobantemovimiento: row?.nrocomprobantemovimiento,
    });
  };
  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <FormItemHiddenUi name="id" />
        <FormItemHiddenUi name="detalleprogramaciontejido" />
        <FormItemHiddenUi name="moneda_prefijo" />
        <FormItemHiddenUi name="descripcion" />
        <FormItemHiddenUi name="lote" />
        <FormItemHiddenUi name="numero_lote" />
        <FormItemHiddenUi name="serie" />
        <FormItemHiddenUi name="numero_serie" />
        <FormItemHiddenUi name="unidadmedida" />
        <FormItemHiddenUi name="unidadmedida_prefijo" />
        <FormItemHiddenUi name="cantidad" />
        <FormItemHiddenUi name="nrocomprobantemovimiento" />
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label="Detalle Comprobante movimiento"
            name="detallecomprobantemovimiento"
            rules={[
              {
                required: true,
                message: "El detalle de comprobante movimiento es obligatoria",
              },
            ]}
          >
            <DetalleComprobanteMovimientoSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeDetalleComprobanteMovimiento}
              data={data}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={6}>
          <Form.Item
            name="precio"
            label="Precio"
            rules={[
              {
                required: true,
                message: "El precio es obligatorio",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese precio"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            label="Moneda"
            name="moneda"
            rules={[
              {
                required: true,
                message: "La moneda es obligatoria",
              },
            ]}
          >
            <MonedaSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeMoneda}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={6}>
          <Form.Item
            name="tipocambio"
            label="Tipo cambio"
            rules={[
              {
                required: true,
                message: "El tipo de cambio es obligatorio",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese tipo cambio"
              style={{ width: "100%" }}
              precision={4}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
