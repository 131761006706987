import React, { useEffect, useState } from "react";
import { get_tipocomprobantes_by_tipooperacion_and_tipoemision } from "../../../actions/terminalcajero";
import { SelectUi } from "../../ui/SelectUi";
import { Form } from "antd";

export const TipoComprobanteSelect = ({
  nametipooperacion,
  nametipoemision,
  ...props
}) => {
  const form = Form.useFormInstance();
  const tipooperacion = Form.useWatch(nametipooperacion, form);
  const tipoemision = Form.useWatch(nametipoemision, form);
  const [tipocomprobantes, setTipocomprobantes] = useState([]);
  useEffect(() => {
    const OnLoadTipoComprobantes = async () => {
      const datos = await get_tipocomprobantes_by_tipooperacion_and_tipoemision(
        tipooperacion,
        tipoemision
      );
      setTipocomprobantes(datos);
    };
    if (tipooperacion !== null && tipoemision !== null) {
      OnLoadTipoComprobantes(tipooperacion, tipoemision);
    }
  }, [tipooperacion, tipoemision]);

  return <SelectUi data={tipocomprobantes} {...props} />;
};
