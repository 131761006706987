import React from "react";
import { TableUi } from "../../ui/TableUi";
import { DatePicker, Form, Input, InputNumber, Space, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import dayjs from "dayjs";
const columns = (props) => {
  const { handleEliminar, handleAgregar } = props;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      width: "5%",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Lote",
      dataIndex: "numero_lote",
      key: "numero_lote",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "numero_lote"]}
          className="classRowFieldTable"
        >
          <Input
            placeholder="Ingrese el lote"
            style={{ width: "100%" }}
            onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      ),
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "cantidad"]}
          className="classRowFieldTable"
        >
          <InputNumber
            placeholder="Ingrese la cantidad"
            style={{ width: "100%" }}
            precision={2}
          />
        </Form.Item>
      ),
    },
    {
      title: "Fecha fabricación",
      dataIndex: "fecha_fabricacion",
      key: "fecha_fabricacion",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "fecha_fabricacion"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "La fecha de fabricación es obligatorio",
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      ),
    },
    {
      title: "Fecha vencimiento",
      dataIndex: "fecha_vencimiento",
      key: "fecha_vencimiento",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "fecha_vencimiento"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "La fecha de vencimiento es obligatorio",
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      width: 100,
      render: (text, field) => (
        <Space size="middle">
          <Tooltip title="Agregar">
            <ButtonUi
              typeUi={TipoButtonConstants.AgregarTable}
              onClick={() => handleAgregar()}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleEliminar(field)}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};
export const DetalleEntradaLoteTable = ({ data, handleDelete, handleAdd }) => {
  const handleAgregar = () => {
    handleAdd({
      numero_lote: null,
      cantidad: null,
      fecha_fabricacion: dayjs(),
      fecha_vencimiento: dayjs(),
    });
  };
  const handleEliminar = (field) => {
    handleDelete(field?.name);
  };

  return (
    <TableUi
      data={data}
      columns={columns({ handleAgregar, handleEliminar })}
      size="small"
      // style={{ maxWidth: 500 }}
      scroll={{ x: 550 }}
    />
  );
};
