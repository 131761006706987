export const types = {
  listapreciosLoaded: "[listaprecio] lista precios Loaded",
  listaprecioSetActive: "[listaprecio] lista precio Set Active",
  listaprecioClearActive: "[listaprecio] lista precio Clear Active",
  listaprecioAdded: "[listaprecio] lista precio added",
  listaprecioUpdated: "[listaprecio] lista precio updated",
  listaprecioDeleted: "[listaprecio] lista precio deleted",
  listaprecioLogout: "[listaprecio] lista precio logout",

  reglaListaprecioSetActive: "[listaprecio] regla lista precio Set Active",
  reglaListaprecioClearActive: "[listaprecio] reglalista precio Clear Active",
};
