import { Form, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { empresaClearActive } from "../../../actions/empresa";
import { uiShowForm } from "../../../actions/ui";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { EmpresaGeneralTab } from "./EmpresaGeneralTab";
import { EmpresaSunatTab } from "./EmpresaSunatTab";
import { TabsUi } from "../../ui/TabsUi";
import { labelWithErrorOrNot } from "../../../helpers/utils";
import { CardUi } from "../../ui/CardUi";

const ItemsTab = (props) => {
  const { tabKeyError, esemisorelectronico } = props;
  return [
    {
      key: "general",
      label: labelWithErrorOrNot(tabKeyError, "general", "Datos generales"),
      children: <EmpresaGeneralTab />,
      forceRender: true,
    },
    {
      key: "sunat",
      label: labelWithErrorOrNot(tabKeyError, "sunat", "Entorno sistema Sunat"),
      children: <EmpresaSunatTab />,
      disabled: !esemisorelectronico,
      forceRender: true,
    },
  ];
};

export const EmpresaForm = ({ tabKeyError }) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const esemisorelectronico = Form.useWatch("esemisorelectronico", form);
  const dispatch = useDispatch();
  const { activeEmpresa } = useSelector((state) => state.empresa);
  const [tabKeyActive, setTabKeyActive] = useState("general");
  const OnChangeTab = (tab) => {
    setTabKeyActive(tab);
  };
  useEffect(() => {
    if (tabKeyError !== null) setTabKeyActive(tabKeyError);
  }, [tabKeyError]);
  const handleCancel = () => {
    dispatch(empresaClearActive());
    dispatch(uiShowForm(false));
  };
  return (
    <CardUi
      title={
        <>
          Empresa
          {activeEmpresa ? <small> Editar</small> : <small> Registrar</small>}
        </>
      }
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormEmpresa"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <TabsUi
        data={ItemsTab({ tabKeyError, esemisorelectronico })}
        activeKey={tabKeyActive}
        onChange={OnChangeTab}
        type="card"
      />
    </CardUi>
  );
};
