import { Col, Form, Row } from "antd";
import React from "react";
import { DetalleSalidaSerieTable } from "./DetalleSalidaSerieTable";

export const DetalleSalidaSerieForm = ({ cantidad, openModal }) => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={20} lg={16} xl={16}>
        <Form.List
          name="series"
          rules={[
            {
              validator: async (_, value) => {
                const cantSeleccionada = value?.filter(
                  (item) => item.selected === true
                ).length;
                if (cantSeleccionada !== cantidad)
                  return Promise.reject(
                    new Error(
                      "Las series seleccionadas deben ser igual a la cantidad ingresada"
                    )
                  );
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <DetalleSalidaSerieTable data={fields} openModal={openModal} />
              <Form.Item className="ui-errorlist">
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};
