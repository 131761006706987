import { Col, Descriptions, Row, Form, InputNumber } from "antd";
import React from "react";
import { SwitchUi } from "../../../ui/SwitchUi";
import { TipoDescuentoSelect } from "../../reusable/TipoDescuentoSelect";
import { TypeDiscountConstanst } from "../../../../types/typesConstants";

export const DescuentoContent = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const tienedescuento = Form.useWatch("tienedescuento", form);
  const tipodescuento = Form.useWatch("tipodescuento", form);
  const valordescuento = Form.useWatch("valordescuento", form);
  const montototal = Form.useWatch("montototal", form);
  const prefijo = Form.useWatch("prefijo", form);
  const handleTieneDescuento = (value) => {
    if (!value) {
      setFieldValue("valordescuento", 0);
      setFieldValue("tipodescuento", TypeDiscountConstanst.Monto);
      setFieldValue("descuento", 0);
      setFieldValue("montodescuento", 0);
      setFieldValue("montototalpagar", montototal);
    }
  };
  const calculate_discounts = (value, _tipodescuento) => {
    let montototalpagar = 0;
    let calculodescuento = 0;
    let porcentajeDescuento = 0;
    if (_tipodescuento === TypeDiscountConstanst.Porcentaje) {
      calculodescuento = montototal * (value / 100);
      porcentajeDescuento = value;
    } else {
      calculodescuento = value;
      porcentajeDescuento =
        100 - ((montototal - calculodescuento) / montototal) * 100;
    }
    montototalpagar = montototal - calculodescuento;

    setFieldValue(
      "descuento",
      parseFloat(parseFloat(porcentajeDescuento).toFixed(2))
    );
    setFieldValue(
      "montodescuento",
      parseFloat(parseFloat(calculodescuento).toFixed(2))
    );
    setFieldValue(
      "montototalpagar",
      parseFloat(parseFloat(montototalpagar).toFixed(2))
    );
  };
  const handleChangeTipoDescuento = (value) => {
    calculate_discounts(valordescuento, value);
  };
  const handleChangeDescuento = (value) => {
    calculate_discounts(value, tipodescuento);
  };
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col sm={24} md={24} lg={12}>
        <Descriptions
          title="Descuento"
          size="small"
          className="ui-descriptions"
          column={{
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
        >
          <Descriptions.Item style={{ width: "15%" }}>
            <Form.Item name="tienedescuento" valuePropName="checked">
              <SwitchUi onChange={handleTieneDescuento} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item>
            <Form.Item
              name="tipodescuento"
              style={{
                width: "100%",
                marginBottom: "0px",
              }}
            >
              <TipoDescuentoSelect
                name="tipodescuento"
                disabled={!tienedescuento}
                onChange={handleChangeTipoDescuento}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item>
            <Form.Item
              name="valordescuento"
              style={{
                marginBottom: "0px",
              }}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                addonBefore={
                  tipodescuento === TypeDiscountConstanst.Porcentaje
                    ? "%"
                    : prefijo || "S/"
                }
                precision={2}
                disabled={!tienedescuento}
                onChange={handleChangeDescuento}
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};
