import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import { SearchFormProgramacionTejidoTable } from "./SearchFormProgramacionTejidoTable";
import { SearchSelectUI } from "../../../../../ui/SearchSelectUI";
import { search_programaciontejido_by_filter } from "../../../../../../actions/produccion";
import { FormItemHiddenUi } from "../../../../../ui/FormItemHiddenUi";

export const SearchFormProgramacionTejidoForm = () => {
  const [programaciones, setProgramaciones] = useState([]);
  const [detalleprogramacion, setDetalleprogramacion] = useState([]);
  const onSearch = async (value) => {
    const data = await search_programaciontejido_by_filter(value);
    setProgramaciones(data);
  };
  const handleChange = (value, field) => {
    setDetalleprogramacion(field?.detalleprogramaciontejido);
  };
  return (
    <>
      <FormItemHiddenUi name="detalleprogramaciontejido" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="programaciontejido"
            label="Buscar por código o descripción"
          >
            <SearchSelectUI
              handleSearch={onSearch}
              onChange={handleChange}
              data={programaciones}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <SearchFormProgramacionTejidoTable data={detalleprogramacion} />
        </Col>
      </Row>
    </>
  );
};
