import React from "react";
import { ModalUi } from "../../ui/ModalUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { TableUi } from "../../ui/TableUi";
import { Tag } from "antd";

const columns = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Descripción",
    dataIndex: "item_nombre",
    key: "item_nombre",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
  },
  {
    title: "U.Medida",
    dataIndex: "unidadmedida_nombre",
    key: "unidadmedida_nombre",
  },
  {
    title: "Adicionales",
    dataIndex: "adicionales",
    key: "adicionales",
    render: (text, record) => (
      <>
        {record?.lote && <Tag color="cyan">Lote: {record?.numero_lote}</Tag>}
        {record?.serie && <Tag color="cyan">Serie: {record?.numero_serie}</Tag>}
      </>
    ),
  },
];
export const DetalleMovimientoPreviewModal = ({
  dataDetalle,
  open,
  handleCancelModal,
}) => {
  return (
    <ModalUi
      title="Detalle movimiento"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCancelModal}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancelModal}
        />,
      ]}
      forceRender
    >
      <TableUi columns={columns} size="small" data={dataDetalle} rowKey="id" />
    </ModalUi>
  );
};
