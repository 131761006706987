import React, { useEffect } from "react";
import { EmpleadoTable } from "./EmpleadoTable";
import { EmpleadoScreen } from "./EmpleadoScreen";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../../actions/ui";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { empleadosStartLoad } from "../../../actions/empleado";

export const EmpleadoList = () => {
  const dispatch = useDispatch();
  const { empleados, loading } = useSelector((state) => state.empleado);
  const { visibleForm } = useSelector((state) => state.ui);
  const handleLoad = () => dispatch(empleadosStartLoad());
  useEffect(() => {
    handleLoad();
  }, []);
  const handleNuevo = () => {
    dispatch(uiShowForm());
  };
  return visibleForm ? (
    <EmpleadoScreen />
  ) : (
    <PageHeaderUi
      title="Empleados"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="actualizar"
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleLoad}
        />,
        <ButtonUi
          key="Nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
        />,
      ]}
    >
      <EmpleadoTable data={empleados} loading={loading} />
    </PageHeaderUi>
  );
};
export default EmpleadoList;
