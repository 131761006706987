import React from "react";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Form } from "antd";
import dayjs from "dayjs";
import { RutaProduccionDetalleTableItem } from "./RutaProduccionDetalleTableItem";

const itemDefault = {
  id: new Date().getTime(),
  tarea: null,
  maquina: null,
  es_terciarizado: false,
};
export const RutaProduccionDetalleTable = () => {
  return (
    <Form.List
      name="detallerutaproduccion"
      rules={[
        {
          validator: async (_, value) => {
            if (!value || value < 1)
              return Promise.reject(
                new Error(
                  "Ingrese al menos un item en el detalle de la ruta producción"
                )
              );
            const tareas = new Set(value.map((obj) => obj.tarea));
            return tareas.size === value.length
              ? true
              : Promise.reject(new Error("Existen tareas duplicadas"));
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <RutaProduccionDetalleTableItem
            data={fields}
            handleDelete={remove}
            title={() => (
              <ButtonUi
                typeUi={TipoButtonConstants.AgregarTable}
                onClick={() => add({ ...itemDefault, id: dayjs().valueOf() })}
              />
            )}
          />
          <Form.Item className="ui-errorlist">
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
