import React, { useState } from "react";
import { TableSearchBasicUi } from "../../ui/TableSearchBasicUi";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { monedaSetActive, monedaStartDelete } from "../../../actions/moneda";
import { useDispatch } from "react-redux";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
const columns = ({ filteredColumn, handleEditar, handleEliminar }) => [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
    filteredValue: [filteredColumn],
    onFilter: (value, record) =>
      String(record?.nombre).toLowerCase().includes(value.toLowerCase()) ||
      String(record?.prefijo).toLowerCase().includes(value.toLowerCase()) ||
      String(record?.prefijosunat).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Prefijo",
    dataIndex: "prefijo",
    key: "prefijo",
    render: (text, record) => (
      <>
        {text} - {record?.prefijosunat}
      </>
    ),
  },

  {
    title: "Por defecto",
    dataIndex: "pordefecto",
    key: "pordefecto",
    render: (text, record) => (
      <>
        {text ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            SI
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="error">
            NO
          </Tag>
        )}
      </>
    ),
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => (
      <Space wrap>
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          size="small"
          onClick={() => handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          size="small"
          onClick={() => handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const MonedaTable = ({ data, loading, handleOpenModal }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const handleEditar = (record) => {
    dispatch(monedaSetActive(record));
    handleOpenModal();
  };
  const handleEliminar = (record) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la moneda seleccionado?",
      `${record.prefijosunat}-${record.nombre}`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(monedaStartDelete(record));
        }
      }
    );
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({
        filteredColumn: searchText,
        handleEditar,
        handleEliminar,
      })}
      colSpanSeach={12}
      rowKey="id"
      bordered
      data={data}
      onSearch={onSearch}
      loading={loading}
      size="small"
      sizeSearch="small"
    />
  );
};
