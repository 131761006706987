import { types } from "../types/typesAuth";

const initialState = {
  activeUsuario: null,
  activeEmpleado: null,
  usuarios: [],
  checking: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authLogin:
      return {
        ...state,
        activeUsuario: action.payload,
      };
    case types.authUsuarioChecking:
      return {
        ...state,
        checking: action.payload,
      };
    case types.authUsuarioLogout:
      return {
        ...initialState,
      };
    case types.authEmpleadoSetActive:
      return {
        ...state,
        activeEmpleado: action.payload,
      };
    case types.authUsuariosLoaded:
      return { ...state, usuarios: action.payload };
    case types.authUsuarioAdded:
      return {
        ...state,
        usuarios: [action.payload, ...state.usuarios],
      };
    case types.authUsuarioSetActive:
      return {
        ...state,
        activeUsuario: action.payload,
      };
    case types.authUsuarioClearActive:
      return {
        ...state,
        activeUsuario: null,
      };
    case types.authUsuarioUpdated:
      return {
        ...state,
        usuarios: state.usuarios.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.authUsuarioDeleted:
      return {
        ...state,
        usuarios: state.usuarios.filter((e) => e.id !== action.payload.id),
        activeUsuario: null,
      };

    default:
      return state;
  }
};
