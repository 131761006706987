import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesInventario";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { uiShowLoading } from "./ui";

const key = "msginventario";
const routes = {
  inventario: "inventario/",
  movimientoentrada: "movimientoentrada/",
  movimientosalida: "movimientosalida/",
  movimientotraslado: "movimientotraslado/",
  comprobantemovimiento: "comprobantemovimiento/",
};

export const productosInventarioStartLoading = (empleado, almacen) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.get(routes.inventario, {
        params: { empleado, almacen },
      });
      const body = resp.data;
      dispatch(productosInventarioLoaded(body));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const movimientoEntradaStartAddNew = (movimiento) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.post(
        routes.movimientoentrada,
        movimiento
      );
      const { msg, inventarios } = resp.data;
      const inventariosentrada_New = inventarios.filter(
        (x) => x.IsNewInventario === true
      );
      const inventariosentrada_Updated = inventarios.filter(
        (x) => x.IsNewInventario === false
      );
      if (inventariosentrada_New.length > 0) {
        dispatch(inventariosInserted(inventariosentrada_New));
      } else {
        dispatch(inventariosUpdated(inventariosentrada_Updated));
      }
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const movimientoTrasladoStartInserted = (movimiento) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.post("movimientotraslado/", movimiento);
      const { msg, inventariossalida, inventariosentrada } = resp.data;
      const inventariosentrada_New = inventariosentrada.filter(
        (x) => x.IsNewInventario === true
      );
      const inventariosentrada_Updated = inventariosentrada.filter(
        (x) => x.IsNewInventario === false
      );

      if (inventariosentrada_New.length > 0) {
        dispatch(inventariosInserted(inventariosentrada_New));
      } else {
        dispatch(inventariosUpdated(inventariosentrada_Updated));
      }
      dispatch(inventariosUpdated(inventariossalida));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
      dispatch(inventarioClearActive());
    }
  };
};
export const movimientoSalidaStartAdd = (movimiento) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        "inventario/movimientosalida",
        movimiento
      );
      const { msg, inventarios } = resp.data;
      dispatch(inventariosUpdated(inventarios));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const movimientoRemoverStartAdd = (movimiento) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        "inventario/movimientoremover",
        movimiento
      );
      const { msg, inventarios } = resp.data;
      dispatch(inventariosUpdated(inventarios));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};

const productosInventarioLoaded = (productos) => ({
  type: types.productosInventarioLoaded,
  payload: productos,
});
const inventariosInserted = (inventarios) => ({
  type: types.inventariosInserted,
  payload: inventarios,
});
const inventariosUpdated = (inventarios) => ({
  type: types.inventariosUpdated,
  payload: inventarios,
});
export const inventarioSetActive = (inventario) => ({
  type: types.inventarioSetActive,
  payload: inventario,
});
export const inventarioClearActive = () => ({
  type: types.inventarioClearActive,
});
export const movimientoSetActive = (movimiento) => ({
  type: types.movimientoSetActive,
  payload: movimiento,
});
export const movimientoClearActive = () => ({
  type: types.movimientoClearActive,
});
export const showModalIngreso = (visible = true) => ({
  type: types.inventarioShowModalIngreso,
  payload: visible,
});
export const showModalEgreso = (visible = true) => ({
  type: types.inventarioShowModalEgreso,
  payload: visible,
});
export const showModalRemove = (visible = true) => ({
  type: types.inventarioShowModalRemover,
  payload: visible,
});

export const showFormInputMovements = (visible = true) => ({
  type: types.inventarioShowFormInput,
  payload: visible,
});
export const showFormOutputMovements = (visible = true) => ({
  type: types.inventarioShowFormOutput,
  payload: visible,
});
export const showFormRemoveMovements = (visible = true) => ({
  type: types.inventarioShowFormRemove,
  payload: visible,
});
export const showFormTransferMovements = (visible = true) => ({
  type: types.inventarioShowFormTransfer,
  payload: visible,
});
export const showModalDetalle = (visible = true) => ({
  type: types.movimientoShowModalDetalle,
  payload: visible,
});
export const setQuantitySelectSeries = (quantity = 0) => ({
  type: types.inventarioSetQuantitySelectSeries,
  payload: quantity,
});

export const movimientosTrasladoStartLoading = (fechaemision) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.movimientotraslado, {
        params: { fechaemision },
      });
      const body = resp.data;
      dispatch(comprobantesMovimientoLoaded(body));
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(errors);
    }
  };
};
export const movimientoTrasladoInventarioStartInserted = (movimiento) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.movimientotraslado,
        movimiento
      );
      const { msg, comprobante_salida, comprobante_entrada } = resp.data;
      dispatch(movimientoTrasladoAdded(comprobante_salida));
      dispatch(movimientoTrasladoAdded(comprobante_entrada));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const movimientoTrasladoAdded = (comprobante) => ({
  type: types.comprobanteMovimientoInserted,
  payload: comprobante,
});

export const comprobanteMovimientoStartLoading = (
  tipofiltro,
  tipocomprobantemovimiento = null,
  fechaemision = null,
  almacen = null,
  comprobante = null
) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.get(routes.comprobantemovimiento, {
        params: {
          tipofiltro,
          tipocomprobantemovimiento,
          fechaemision,
          almacen,
          comprobante,
        },
      });
      const datos = resp.data;
      dispatch(comprobantesMovimientoLoaded(datos));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const comprobanteMovimientoStartAnulado = (comprobante) => {
  return async (dispatch) => {
    const msgload = messageLoading("Anulando...");
    try {
      const resp = await axiosConToken.get(
        `${routes.comprobantemovimiento}anular_comprobantemovimiento`,
        { params: { comprobantemovimiento: comprobante } }
      );
      const { msg, comprobantemovimiento } = resp.data;
      dispatch(comprobanteMovimientoUpdated(comprobantemovimiento));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const comprobanteMovimientoEntradaStartAdd = (comprobante) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.post(
        routes.movimientoentrada,
        comprobante
      );
      const { msg, comprobantemovimiento } = resp.data;
      dispatch(comprobanteMovimientoInserted(comprobantemovimiento));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const comprobanteMovimientoSalidaStartAdd = (comprobante) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.movimientosalida,
        comprobante
      );
      const { msg, comprobantemovimiento } = resp.data;
      dispatch(comprobanteMovimientoInserted(comprobantemovimiento));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const comprobantesMovimientoInitialize = () => ({
  type: types.comprobantesMovimientoInitialize,
});
const comprobantesMovimientoLoaded = (comprobantes) => ({
  type: types.comprobantesMovimientoLoaded,
  payload: comprobantes,
});
export const comprobanteMovimientoInserted = (comprobante) => ({
  type: types.comprobanteMovimientoInserted,
  payload: comprobante,
});
export const comprobanteMovimientoUpdated = (comprobante) => ({
  type: types.comprobanteMovimientoUpdated,
  payload: comprobante,
});
export const comprobanteMovimientoSetActive = (comprobante) => ({
  type: types.comprobanteMovimientoSetActive,
  payload: comprobante,
});
export const comprobanteMovimientoClearActive = () => ({
  type: types.comprobanteMovimientoClearActive,
});
export const detalleComprobanteMovimientoSetActive = (detalle) => ({
  type: types.detalleComprobanteMovimientoSetActive,
  payload: detalle,
});
export const detalleComprobanteMovimientoClearActive = () => ({
  type: types.detalleComprobanteMovimientoClearActive,
});

//TODO: Implement extra functions
export const get_tipocomprobantemovimiento_not_traslado = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantemovimiento}get_tipocomprobantemovimiento_not_traslado/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

//TODO: Implement extra functions
// ASYNC FUNCTIONS
export const get_inventory_item_by_store_and_name_or_sku = async (
  almacen,
  param
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.inventario}get_inventory_item_by_store_and_name_or_sku`,
      {
        params: { almacen, param },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipo_movimientos_inventarioinicial = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "movimientoentrada/get_tiposmovimientos_inventarioinicial/"
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_items_base = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("movimientoentrada/get_items_base/");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_items_by_itembase = async (item) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "movimientoentrada/get_items_by_itembase/",
      { params: { item } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const searchProduct_by_store_and_name = async (store, name) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.movimientotraslado}search_product_by_store_and_name`,
      {
        params: { store, name },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_almacenes_empledo = async (empleado) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("inventario/get_almacenes_empledo", {
      params: { empleado },
    });
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipomovimientos_by_tipooperacion = async (tipooperacion) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.inventario}get_tipomovimientos_by_tipooperacion`,
      {
        params: { tipooperacion },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_inventory_movements_by_filters = async (
  item,
  start_date,
  end_date,
  almacen
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.inventario}get_inventory_movements_by_filters`,
      {
        params: { item, start_date, end_date, almacen },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_items_select = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("movimientoentrada/get_productos");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const search_items_by_param = async (param) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.inventario}search_items_by_param/`,
      { params: { param } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
