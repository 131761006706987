import React, { useState } from "react";
import { Space, Tag } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { useDispatch } from "react-redux";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import {
  proveedorSetActive,
  proveedorStartDelete,
} from "../../../actions/proveedor";
import { TableSearchBasicUi } from "../../ui/TableSearchBasicUi";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
const columns = (acciones) => [
  {
    title: "N°",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombrecompleto",
    key: "nombrecompleto",
    filteredValue: [acciones?.filteredColumn],
    onFilter: (value, record) =>
      String(record.nombrecompleto)
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      String(record.nrodocumento).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "T.Documento",
    dataIndex: "nombretipodocumento",
    key: "nombretipodocumento",
  },
  {
    title: "N°Documento",
    dataIndex: "nrodocumento",
    key: "nrodocumento",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Telefono",
    dataIndex: "telefono",
    key: "telefono",
  },
  {
    title: "Dirección",
    dataIndex: "direccion",
    key: "direccion",
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
    render: (text, record) => (
      <>
        {text === true ? (
          <Tag icon={<CheckCircleOutlined />} color="green">
            Activo
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="red">
            Inactivo
          </Tag>
        )}
      </>
    ),
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <Space size="middle">
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          onClick={() => acciones.handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          onClick={() => acciones.handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const ProveedorTable = ({ data, loading, handleOpen }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const handleEditar = (data) => {
    dispatch(proveedorSetActive(data));
    handleOpen();
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el cliente seleccionado?",
      `${item.nombrecompleto}`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(proveedorStartDelete(item));
        }
      }
    );
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({
        filteredColumn: searchText,
        handleEditar,
        handleEliminar,
      })}
      rowKey="id"
      bordered
      data={data}
      onSearch={onSearch}
      loading={loading}
      size="small"
    />
  );
};
