import React, { useEffect, useState } from "react";
import { getEmpleados } from "../../../../helpers/loadRRHH";
import { SelectUi } from "../../../ui/SelectUi";

export const EmpleadosSelect = ({ ...props }) => {
  const [empleados, setEmpleados] = useState([]);
  const OnLoadEmpleados = async () => {
    const datos = await getEmpleados();
    setEmpleados(datos);
  };
  useEffect(() => {
    OnLoadEmpleados();
  }, []);
  return <SelectUi data={empleados} {...props} />;
};
