import { Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import { DenominacionMonedaSelect } from "../reusable/DenominacionMonedaSelect";
import { MonedaSelect } from "../MonedaSelect";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";

export const ArqueoCajaForm = () => {
  const form = Form.useFormInstance();
  const { setFieldValue } = form;
  const moneda = Form.useWatch("moneda", form);
  const valormoneda = Form.useWatch("valormoneda", form);
  const handleChangeMoneda = (value, e) => {
    setFieldValue("moneda_nombre", e?.label);
    setFieldValue("denominacionmoneda", null);
    setFieldValue("valormoneda", 0);
    setFieldValue("cantidad", 0);
    setFieldValue("total", 0);
  };
  const handleChangeCantidad = (value) => {
    const total = valormoneda * value;
    setFieldValue("total", total);
  };
  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="moneda_nombre" />
      <FormItemHiddenUi name="denominacionmoneda_imagen" />
      <FormItemHiddenUi name="valormoneda" />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="moneda"
            label="Moneda"
            rules={[{ required: true, message: "La moneda es obligatoria" }]}
          >
            <MonedaSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeMoneda}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="denominacionmoneda"
            label="Denominación"
            rules={[
              {
                required: true,
                message: "La denominación de moneda es obligatoria",
              },
            ]}
          >
            <DenominacionMonedaSelect moneda={moneda} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              {
                type: "number",
                min: 1,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              onChange={handleChangeCantidad}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="total"
            label="Total"
            rules={[
              {
                required: true,
                message: "El total es obligatorio",
              },
              {
                type: "number",
                min: 1,
                message: "Ingrese un número válido",
              },
            ]}
            required={false}
          >
            <InputNumber readOnly style={{ width: "100%" }} precision={2} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
