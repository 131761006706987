import React from "react";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { Col, DatePicker, Form, Input, InputNumber, Row, Space } from "antd";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { TipoDocumentoIdentidadSelect } from "../reusable/TipoDocumentoIdentidadSelect";
import { TipoMovimientoCajaSelect } from "../reusable/TipoMovimientoCajaSelect";
import { NaturalezaPagoSelect } from "../reusable/NaturalezaPagoSelect";
import { ButtonUi } from "../../../../ui/ButtonUi";

export const MovimientoCajaForm = ({ handleOpenModal }) => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={10} xl={8}>
          <Form.Item
            name="fechaemision"
            label="Fecha Emisión"
            rules={[
              { required: true, message: 'La fecha es obligatoria"' },
              { type: "date", message: "Ingrese una fecha validá" },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={DateFormats.frontend}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="tipomovimiento"
            label="Tipo movimiento"
            rules={[
              {
                required: true,
                message: "El tipo de movimiento caja es obligatorio",
              },
            ]}
          >
            <TipoMovimientoCajaSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
          <Space.Compact block>
            <Form.Item
              name="naturalezapago"
              label="Categoria de pago"
              rules={[
                {
                  required: true,
                  message: "El tipo de pago es obligatorio",
                },
              ]}
              required={false}
              style={{ width: "50%" }}
            >
              <NaturalezaPagoSelect placeholder="[-Seleccione-]" />
            </Form.Item>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                onClick={handleOpenModal}
              />
            </Form.Item>
          </Space.Compact>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="tipodocumentoidentidad"
            label="Tipo documento"
            rules={[
              {
                required: true,
                message: "El tipo de documento es obligatorio",
              },
            ]}
            required={false}
          >
            <TipoDocumentoIdentidadSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="nrodocumentoidentidad"
            label="Número documento"
            rules={[
              {
                required: true,
                message: "El número de documento es obligatorio",
              },
            ]}
            required={false}
          >
            <Input placeholder="Ingrese número" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="nombreacreedor"
            label="Acreedor"
            rules={[
              {
                required: true,
                message: "El acreedor es obligatorio",
              },
            ]}
            required={false}
          >
            <Input
              placeholder="Nombre del acreedor"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="monto"
            label="Monto"
            rules={[
              {
                required: true,
                message: "El monto es obligatorio",
              },
            ]}
            required={false}
          >
            <InputNumber
              placeholder="Ingrese número"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="glosa"
            label="Glosa"
            rules={[
              {
                required: true,
                message: "La glosa es obligatorio",
              },
            ]}
            required={false}
          >
            <Input.TextArea placeholder="Ingrese glosa" rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
