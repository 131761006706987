import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ordenProduccionClearActive,
  ordenProduccionStartAdd,
  ordenProduccionStartUpdate,
  showFormOrdenProduccion,
} from "../../../../actions/produccion";
import { FormUi } from "../../../ui/FormUi";
import { OrdenProduccionForm } from "./OrdenProduccionForm";
import dayjs from "dayjs";

const initialValues = (data = {}) => ({
  id: data?.id || 0,
  item: data?.item || null,
  nombre: data?.nombre || null,
  cantidad: parseFloat(data?.cantidad) || null,
  unidadmedida: parseFloat(data?.unidadmedida) || null,
  fechahorainicio: dayjs(data?.fechahorainicio) || dayjs(),
  fechahorafin: dayjs(data?.fechahorafin) || dayjs(),
  estado: data?.estado || null,
});
export const OrdenProduccionScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeOrdenProduccion } = useSelector((state) => state.produccion);
  const handleSubmit = (values) => {
    if (activeOrdenProduccion) dispatch(ordenProduccionStartUpdate(values));
    else dispatch(ordenProduccionStartAdd(values));

    dispatch(ordenProduccionClearActive());
    dispatch(showFormOrdenProduccion(false));
  };
  return (
    <FormUi
      name="FormOrdenProduccion"
      form={form}
      initialValues={initialValues(activeOrdenProduccion)}
      handleSubmit={handleSubmit}
      layout="vertical"
      autoComplete="off"
    >
      <OrdenProduccionForm />
    </FormUi>
  );
};
