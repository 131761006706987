import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  procesoProduccionClearActive,
  showFormProcesoProduccion,
} from "../../../../actions/produccion";
import { CardUi } from "../../../ui/CardUi";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
} from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  EstadoProcesoProduccionConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { TareasProduccionSteps } from "./TareasProduccionSteps";
import { OrdenProduccionSelect } from "../reusable/OrdenProduccionSelect";
import { EstadoProcesoProduccion } from "../reusable/EstadoProcesoProduccion";
import { UnidadMedidaSelect } from "../../reusable/UnidadMedidaSelect";

export const ProcesoProduccionForm = () => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();

  const estado = Form.useWatch("estado", form);
  const detalleprocesoproduccion = Form.useWatch(
    "detalleprocesoproduccion",
    form
  );
  const { activeProcesoProduccion } = useSelector((state) => state.produccion);
  const handleCancel = () => {
    dispatch(procesoProduccionClearActive());
    dispatch(showFormProcesoProduccion(false));
  };
  const OnChangeOrdenProduccion = (value, field) => {
    if (typeof value !== "undefined") {
      form.setFieldValue("detallerutaproduccion", field?.detallerutaproduccion);
    }
  };
  return (
    <CardUi
      title="Proceso Producción"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormProcesoProduccion"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="detallerutaproduccion" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="ordenproduccion"
            label="Orden Produccion"
            rules={[
              {
                required: true,
                message: "La orden de producción es obligatorio",
              },
            ]}
          >
            <OrdenProduccionSelect
              placeholder="[-Seleccione-]"
              onChange={OnChangeOrdenProduccion}
              disabled={
                activeProcesoProduccion && detalleprocesoproduccion?.length > 0
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item
            name="fechahorainicio"
            label="Fecha hora inicio"
            rules={[
              {
                required: true,
                message: "La fecha hora inicio es obligatorio",
              },
            ]}
          >
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item name="fechahorafin" label="Fecha hora fin">
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item
            name="estado"
            label="Estado"
            rules={[
              {
                required: true,
                message: "El estado es obligatorio",
              },
            ]}
            required={false}
          >
            <EstadoProcesoProduccion
              placeholder="[-Seleccione-]"
              disabled={estado === EstadoProcesoProduccionConstants.FINALIZADO}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={4}>
          <Form.Item
            name="cantidadinicial"
            label="Cantidad a producir"
            rules={[
              {
                required: true,
                message: "La cantidad a producir es obligatorio",
              },
            ]}
          >
            <InputNumber
              placeholder="Cantidad"
              precision={2}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={5}>
          <Form.Item
            name="unidadmedida"
            label="Unidad medida"
            rules={[
              {
                required: true,
                message: "La unidad de medida es obligatorio",
              },
            ]}
          >
            <UnidadMedidaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ paddingBottom: "10px" }}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="descripcion"
            label="Descripción"
            rules={[
              {
                required: true,
                message: "El descripción es obligatorio",
              },
            ]}
          >
            <Input.TextArea rows={2} maxLength={200} showCount />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <TareasProduccionSteps />
        </Col>
      </Row>
    </CardUi>
  );
};
