import { Col, Divider, Row, DatePicker, Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TipeFiltroPeriodoConstants,
  TipoButtonConstants,
} from "../../types/typesConstants";
import { ButtonUi } from "../ui/ButtonUi";
import { AlmacenEmpleadoSelect } from "../ventas/reusable/AlmacenEmpleadoSelect";
import { TiposFiltrosSelect } from "./TiposFiltrosSelect";
import {
  MontosTotalesNotaVentaStartLoad,
  MontosTotalesTipoComprobanteStartLoad,
} from "../../actions/dashboard";
import { FormUi } from "../ui/FormUi";
import dayjs from "dayjs";

const initialValues = {
  establecimiento: null,
  periodo: TipeFiltroPeriodoConstants.ALL,
  fechainicio: dayjs(),
  fechafin: dayjs(),
};

export const FilterDashboardForm = () => {
  const [form] = Form.useForm();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [visibleFechaInicio, setVisibleFechaInicio] = useState(false);
  const [visibleFechaFin, setVisibleFechaFin] = useState(false);
  const [tipoFecha, setTipoFecha] = useState("ALL");
  const onChangePeriodo = (value) => {
    if (value === TipeFiltroPeriodoConstants.POR_MES) {
      setVisibleFechaInicio(true);
      setVisibleFechaFin(false);
      setTipoFecha("MES");
    } else if (value === TipeFiltroPeriodoConstants.ENTRE_MES) {
      setVisibleFechaInicio(true);
      setVisibleFechaFin(true);
      setTipoFecha("MES");
    } else if (value === TipeFiltroPeriodoConstants.POR_FECHA) {
      setVisibleFechaInicio(true);
      setVisibleFechaFin(false);
      setTipoFecha("ALL");
    } else if (value === TipeFiltroPeriodoConstants.ENTRE_FECHAS) {
      setVisibleFechaInicio(true);
      setVisibleFechaFin(true);
      setTipoFecha("ALL");
    } else if (
      value === TipeFiltroPeriodoConstants.ALL ||
      value === TipeFiltroPeriodoConstants.ULT_SEMANA
    ) {
      setVisibleFechaInicio(false);
      setVisibleFechaFin(false);
      setTipoFecha("ALL");
    }
  };

  const OnLoadGraphitsDashboard = (filters) => {
    dispatch(
      MontosTotalesNotaVentaStartLoad(
        filters?.establecimiento,
        filters?.fechainicio,
        filters?.fechafin,
        filters?.periodo
      )
    );
    dispatch(
      MontosTotalesTipoComprobanteStartLoad(
        filters?.establecimiento,
        filters?.fechainicio,
        filters?.fechafin,
        filters?.periodo
      )
    );
  };

  const handleSubmit = (values) => {
    const valuesData = {
      ...values,
      fechainicio: dayjs(values?.fechainicio).format("YYYY-MM-DD"),
      fechafin: dayjs(values?.fechafin).format("YYYY-MM-DD"),
    };
    OnLoadGraphitsDashboard(valuesData);
  };
  return (
    <FormUi
      form={form}
      autoComplete="off"
      name="FormFilterDashboard"
      className="ant-advanced-search-form"
      layout="horizontal"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Divider plain className="ant-divider-ui">
        Filtros avanzados
      </Divider>
      <Row gutter={24}>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item
            name="establecimiento"
            label="Establecimiento"
            rules={[
              {
                required: true,
                message: "El establecimiento es obligatorio",
              },
            ]}
          >
            <AlmacenEmpleadoSelect
              empleado={activeEmpleado?.id}
              placeholder="Seleccione establecimiento"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Item name="periodo" label="Periodo">
            <TiposFiltrosSelect
              placeholder="Seleccione periodo"
              onChange={(value) => onChangePeriodo(value)}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} hidden={!visibleFechaInicio}>
          <Form.Item
            name="fechainicio"
            label={tipoFecha === "ALL" ? "Fecha del" : "Mes del"}
          >
            <DatePicker
              format={tipoFecha === "ALL" ? "DD/MM/YYYY" : "MM/YYYY"}
              picker={tipoFecha === "ALL" ? "date" : "month"}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} hidden={!visibleFechaFin}>
          <Form.Item
            name="fechafin"
            label={tipoFecha === "ALL" ? "Fecha al" : "Mes al"}
          >
            <DatePicker
              format={tipoFecha === "ALL" ? "DD/MM/YYYY" : "MM/YYYY"}
              picker={tipoFecha === "ALL" ? "date" : "month"}
            />
          </Form.Item>
        </Col>
        <Col xs={6} sm={6} md={4} lg={2} xl={2}>
          <ButtonUi
            key="submit"
            htmlType="submit"
            typeUi={TipoButtonConstants.Filtrar}
          />
        </Col>
      </Row>
    </FormUi>
  );
};
