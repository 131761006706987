import { Col, Row, Form, Input } from "antd";
import React from "react";
import { FileUploadUi } from "../../ui/FileUploadUi";
import { SoapTiposSelect } from "./reusable/SoapTiposSelect";
import { SoapTiposConstans } from "../../../types/typesConstants";

export const EmpresaSunatTab = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const soaptipo = Form.useWatch("soaptipo", form);
  const certificadosunat = Form.useWatch("certificadosunat", form);
  const keycertificadosunat = Form.useWatch("keycertificadosunat", form);
  const onChangeTipo = (value) => {
    if (value === SoapTiposConstans.produccion) {
      setFieldValue("soapusuario", "");
      setFieldValue("soappassword", "");
    }
  };
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label="Soap Tipo" name="soaptipo">
            <SoapTiposSelect onChange={onChangeTipo} />
          </Form.Item>
        </Col>
      </Row>
      {soaptipo !== "DEMO" && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="SOAP Usuario" name="soapusuario">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="SOAP Password" name="soappassword">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="certificadosunat"
            label="Certificado"
            className="mb-1"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("esemisorelectronico")) {
                    if (
                      value?.length < 1 ||
                      (value &&
                        value[0]?.name.split(".")[1].toUpperCase() !== "PEM")
                    ) {
                      return Promise.reject(
                        new Error("El certificado es obligatorio")
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FileUploadUi
              name="certificadosunat"
              listType="picture"
              accept=".pem"
              filelist={certificadosunat}
              maxCount={1}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="keycertificadosunat"
            label="Key certificado"
            className="mb-1"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("esemisorelectronico")) {
                    if (
                      value?.lenght < 1 ||
                      (value &&
                        value[0]?.name.split(".")[1].toUpperCase() !== "PEM")
                    ) {
                      return Promise.reject(
                        new Error("El certificado es obligatorio")
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FileUploadUi
              name="keycertificadosunat"
              listType="picture"
              maxCount={1}
              filelist={keycertificadosunat}
              accept=".pem"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
