import { Form, Table } from "antd";
import React, { useEffect, useState } from "react";

export const DetalleCotizacionTableSummary = ({ data }) => {
  const form = Form.useFormInstance();
  const { setFieldsValue, getFieldsValue } = form;
  const { montosubtotal, montototal } = getFieldsValue();
  const [sumCantidad, setSumCantidad] = useState(0);

  useEffect(() => {
    let sumCantidad = 0;
    let _montosubtotal = 0;
    let _montoigvtotal = 0;
    let _montototal = 0;
    data?.forEach((e) => {
      sumCantidad += parseFloat(e.cantidad);
      _montosubtotal += parseFloat(e.subtotalventa);
      _montoigvtotal += parseFloat(e.valorigv);
      _montototal += parseFloat(e.totalventa);
    });
    setFieldsValue({
      montosubtotal: _montosubtotal,
      montoigvtotal: _montoigvtotal,
      montototal: _montototal,
    });
    setSumCantidad(parseFloat(sumCantidad).toFixed(2));
    return () => {
      setSumCantidad(0);
    };
  }, [data]);
  return (
    <Table.Summary fixed="bottom">
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={3} align="right">
          <b>TOTALES</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} align="center">
          <b>{sumCantidad}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} align="center"></Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
        <Table.Summary.Cell index={7} align="right">
          <b> {parseFloat(montosubtotal).toFixed(2)}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8} align="right">
          <b> {parseFloat(montototal).toFixed(2)}</b>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};
