import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { DetalleProgramacionTabItem } from "./DetalleProgramacionTabItem";
import dayjs from "dayjs";
const itemDefault = {
  id: new Date().getTime(),
  tipotela: null,
  cantidadinicial: null,
  cantidadfinal: null,
  pesobruto: null,
  pesoneto: null,
  anchotela: null,
  observacion: null,
  estado: false,
  insumosdetalleprogramaciontejido: [],
};
export const DetalleProgramacionTab = ({ fields, handleAdd, handleRemove }) => {
  const [activeKey, setActiveKey] = useState(0);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const _items = fields?.map((field, index) => ({
      label: `Tab ${index + 1}`,
      children: <DetalleProgramacionTabItem field={field} />,
      key: index,
    }));
    setItems(_items);
  }, [fields]);

  const handleChangeTab = (key) => setActiveKey(key);
  const handleAddTab = () => {
    handleAdd({ ...itemDefault, id: dayjs().valueOf() });
    setActiveKey(fields.length);
  };
  const onEdit = (targetKey, action, { handleRemove }) => {
    if (action === "remove") {
      handleRemove(targetKey);
      const newTargetKey = targetKey - 1;
      if (newTargetKey > 0) setActiveKey(newTargetKey);
      else setActiveKey(0);
    } else if (action === "add") {
      handleAddTab();
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} md={24} lg={20}>
          <Tabs
            onChange={handleChangeTab}
            activeKey={activeKey}
            type="editable-card"
            onEdit={(targetKey, action) =>
              onEdit(targetKey, action, { handleRemove })
            }
            items={items}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
};
