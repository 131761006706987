import React, { useEffect, useState } from "react";
import { SearchSelectUI } from "./../../ui/SearchSelectUI";
import { get_solo_almacenes } from "../../../actions/produccion";

export const AlmacenSelect = ({ ...restProps }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_solo_almacenes();
      setData(datos);
    };
    OnLoad();
    return () => {
      setData([]);
    };
  }, []);

  return <SearchSelectUI data={data} {...restProps} />;
};
