import React, { useEffect, useState } from "react";
import { get_almacenes_empledo } from "../../../actions/inventario";
import { SelectUi } from "../../ui/SelectUi";

export const AlmacenesEmpleadoSelect = ({ empleado, ...props }) => {
  const [almacenes, setAlmacenes] = useState([]);
  const OnLoadAlmacenes = async (empleado) => {
    const datos = await get_almacenes_empledo(empleado);
    setAlmacenes(datos);
  };
  useEffect(() => {
    OnLoadAlmacenes(empleado);
  }, [empleado]);
  return <SelectUi options={almacenes} {...props} />;
};
