import { Col, Row, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { uiShowForm } from "../../actions/ui";
import { productoClearActiveProducto } from "../../actions/producto";
import { ProductoGeneralTabContent } from "./ProductoGeneralTabContent";
import { ButtonUi } from "../ui/ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { TabsUi } from "../ui/TabsUi";
import { CardUi } from "../ui/CardUi";
import { ListaPreciosTabContent } from "./ListaPreciosTab/ListaPreciosTabContent";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const items = (props) => {
  const { tabKeyError, activeProducto } = props;
  const labelWithErrorOrNot = (tabKeyError, key, label) => {
    if (tabKeyError === key) {
      label = (
        <span style={{ color: "#ff4d4f" }}>
          <CloseOutlined />
          {label}
        </span>
      );
    }
    return label;
  };
  return [
    {
      key: "general",
      label: labelWithErrorOrNot(tabKeyError, "general", "General"),
      children: <ProductoGeneralTabContent />,
      forceRender: true,
    },
    {
      key: "listaprecios",
      label: labelWithErrorOrNot(
        tabKeyError,
        "listaprecios",
        "Lista de precios y costos"
      ),
      children: <ListaPreciosTabContent />,
      disabled: !activeProducto,
      forceRender: true,
    },
  ];
};

export const ProductoFormScreen = ({ tabKeyError }) => {
  const dispatch = useDispatch();
  const { activeProducto } = useSelector((state) => state.producto);
  const [tabKeyActive, setTabKeyActive] = useState("general");

  useEffect(() => {
    if (tabKeyError !== null) setTabKeyActive(tabKeyError);
  }, [tabKeyError]);

  const OnChangeTab = (tab) => {
    setTabKeyActive(tab);
  };
  const HandleCloseForm = () => {
    dispatch(uiShowForm(false));
    dispatch(productoClearActiveProducto());
  };

  return (
    <>
      <CardUi
        title={
          <Row>
            <Col sm={24} md={12}>
              Item
              {activeProducto ? (
                <small> Editar</small>
              ) : (
                <small> Registrar</small>
              )}
            </Col>
          </Row>
        }
        size="small"
        extra={
          <Space className="site-button-ghost-wrapper" wrap>
            <Tooltip title="Guardar">
              <ButtonUi
                typeUi={TipoButtonConstants.Guardar}
                htmlType="submit"
              />
            </Tooltip>
            <Tooltip title="Salir">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={HandleCloseForm}
              />
            </Tooltip>
          </Space>
        }
      >
        <TabsUi
          data={items({ tabKeyError, activeProducto })}
          activeKey={tabKeyActive}
          type="card"
          onChange={OnChangeTab}
        />
      </CardUi>
    </>
  );
};
