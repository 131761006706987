import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProveedorModal } from "./modal/ProveedorModal";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ProveedorTable } from "./ProveedorTable";
import { proveedoresStartLoad } from "../../../actions/proveedor";

export const ProveedorList = () => {
  const dispatch = useDispatch();
  const { proveedores, loading } = useSelector((state) => state.proveedor);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleNuevo = () => setOpen(true);

  const handleActualizar = () => {
    dispatch(proveedoresStartLoad());
  };
  useEffect(() => {
    dispatch(proveedoresStartLoad());
  }, [dispatch]);

  return (
    <PageHeaderUi
      title="Proveedores"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
          key="Nuevo"
        />,
      ]}
    >
      <ProveedorTable
        data={proveedores}
        loading={loading}
        handleOpen={handleNuevo}
      />
      <ProveedorModal open={open} OnClose={handleClose} />
    </PageHeaderUi>
  );
};
