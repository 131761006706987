import React, { useEffect, useState } from "react";
import { CardUi } from "../../ui/CardUi";
import { ColorTable } from "./ColorTable";
import { useDispatch, useSelector } from "react-redux";
import { ColorModal } from "./ColorModal";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { Space } from "antd";
import { coloresStartLoad } from "../../../actions/color";

export const ColorCard = () => {
  const dispatch = useDispatch();
  const { colores, loading } = useSelector((state) => state.color);
  const [open, setOpen] = useState(false);
  const OnLoad = () => dispatch(coloresStartLoad());
  useEffect(() => {
    OnLoad();
  }, []);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <CardUi
      loading={loading}
      size="small"
      title="Color"
      extra={
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.Actualizar}
            size="small"
            onClick={OnLoad}
          />
          <ButtonUi
            typeUi={TipoButtonConstants.Nuevo}
            size="small"
            onClick={handleOpenModal}
          />
        </Space>
      }
    >
      <ColorTable
        data={colores}
        loading={loading}
        handleOpenModal={handleOpenModal}
      />
      <ColorModal open={open} onCancelModal={handleCloseModal} />
    </CardUi>
  );
};
