import React, { useState } from "react";
import { TableUi } from "../../../ui/TableUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ZoomInOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { detalleComprobanteMovimientoSetActive } from "../../../../actions/inventario";
import { useDispatch } from "react-redux";
import { DetalleLoteSeriePreviewModal } from "../../reusable/DetalleLoteSeriePreviewModal";

const columns_table = (props) => {
  const { handleEditar, handleDelete, handlePreview } = props;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Item",
      dataIndex: "item_nombre",
      key: "item_nombre",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "center",
      render: (text, record, index) => <> {parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "U.Medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Detalle",
      dataIndex: "detalle",
      key: "detalle",
      align: "center",
      render: (text, record, index) => (
        <>
          {record?.manejalotes && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              text="Lotes"
              icon={<ZoomInOutlined />}
              onClick={() => handlePreview(record)}
            />
          )}
          {record?.manejaseries && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              text="Series"
              icon={<ZoomInOutlined />}
              onClick={() => handlePreview(record)}
            />
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record, index) => (
        <>
          <Space wrap>
            <ButtonUi
              typeUi={TipoButtonConstants.EditarTable}
              displayText={false}
              size="small"
              onClick={
                () => handleEditar(record) // remove a friend from the list
              }
            />
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              displayText={false}
              size="small"
              onClick={
                () => handleDelete(index) // remove a friend from the list
              }
            />
          </Space>
        </>
      ),
    },
  ];
};
export const MovimientoSalidaFormTable = ({
  detallecomprobantemovimiento,
  handleDelete,
  OpenModal,
  title,
}) => {
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const [inventario, setInventario] = useState(null);
  const hideModalPreview = () => setOpenPreview(false);
  const handlePreview = (record) => {
    setInventario({
      manejalotes: record?.manejalotes,
      manejaseries: record?.manejaseries,
      series: record?.series,
      lotes: record?.lotes,
    });
    setOpenPreview(true);
  };
  const handleEditar = (data) => {
    dispatch(detalleComprobanteMovimientoSetActive(data));
    OpenModal();
  };
  return (
    <>
      <TableUi
        columns={columns_table({ handleEditar, handleDelete, handlePreview })}
        rowKey="id"
        size="small"
        pagination={false}
        data={detallecomprobantemovimiento}
        title={title}
      />
      <DetalleLoteSeriePreviewModal
        inventario={inventario}
        tipomovimiento="SALIDA"
        open={openPreview}
        onCancelModal={hideModalPreview}
      />
    </>
  );
};
