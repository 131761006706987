import { Space, Tag, Tooltip } from "antd";
import React from "react";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  DateFormats,
  EstadoDetalleProcesoProduccion,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { TableUi } from "../../../ui/TableUi";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  RightCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { detalleProcesoProduccionSetActive } from "../../../../actions/produccion";
import dayjs from "dayjs";

const TagEstado = ({ estado }) => {
  if (estado === EstadoDetalleProcesoProduccion.INICIADO)
    return (
      <Tag color="cyan" icon={<RightCircleOutlined />}>
        INICIADO
      </Tag>
    );
  if (estado === "PROCESO")
    return (
      <Tag color="processing" icon={<SyncOutlined spin />}>
        EN PROCESO
      </Tag>
    );
  if (estado === EstadoDetalleProcesoProduccion.FINALIZADO)
    return (
      <Tag color="success" icon={<CheckCircleOutlined />}>
        FINALIZADO
      </Tag>
    );
  if (estado === "CANCELADO")
    return (
      <Tag color="error" icon={<CloseCircleOutlined />}>
        CANCELADO
      </Tag>
    );
};
const columns = (acciones) => {
  const { handleEditar, handleEliminar, handleFinalizar } = acciones;

  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (text, record, index) => <TagEstado estado={text} />,
    },
    {
      title: "Tarea",
      dataIndex: "tarea_nombre",
      key: "tarea_nombre",
      align: "center",
      render: (text, record) => (
        <>
          {text}
          <h5 style={{ margin: 0 }}>
            {record.es_terciarizado ? (
              <small style={{ color: "#faad14" }}>Terciarizado</small>
            ) : (
              <small style={{ color: "#87d068" }}>Produccion propia</small>
            )}
          </h5>
        </>
      ),
    },
    {
      title: "Maquina",
      dataIndex: "maquina_nombre",
      key: "maquina_nombre",
    },
    {
      title: "Empleado",
      dataIndex: "empleado_nombre",
      key: "empleado_nombre",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor_nombre",
      key: "proveedor_nombre",
    },
    {
      title: "Cant. inicial",
      dataIndex: "cantidadinicial",
      key: "cantidadinicial",
      align: "right",
    },
    {
      title: "U. Medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
      align: "center",
    },
    {
      title: "Cant. final",
      dataIndex: "cantidadfinal",
      key: "cantidadfinal",
      align: "right",
    },
    {
      title: "U. Medida final",
      dataIndex: "unidadmedidafinal_nombre",
      key: "unidadmedidafinal_nombre",
      align: "center",
    },
    {
      title: "Fecha-Hora Inicio",
      dataIndex: "fechahorainicio",
      key: "fechahorainicio",
      render: (value) => (
        <>{dayjs(value).format(DateFormats.DatetimeFrontEnd)}</>
      ),
    },

    {
      title: "Acciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (value, record, index) => (
        <Space wrap>
          <Tooltip title="Editar">
            <ButtonUi
              typeUi={TipoButtonConstants.EditarTable}
              displayText={false}
              size="small"
              onClick={() => handleEditar(record)}
            />
          </Tooltip>
          {record?.estado === EstadoDetalleProcesoProduccion.PROCESO && (
            <Tooltip title="Finalizar tarea">
              <ButtonUi
                typeUi={TipoButtonConstants.CustomButton}
                icon={<PauseCircleOutlined />}
                displayText={false}
                size="small"
                onClick={() => handleFinalizar(record)}
              />
            </Tooltip>
          )}
          {record?.estado !== EstadoDetalleProcesoProduccion.FINALIZADO && (
            <Tooltip title="Eliminar">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                displayText={false}
                size="small"
                onClick={() => handleEliminar(index)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
};
export const ProcesoProduccionDetalleTable = ({
  detalleprocesoproduccion,
  handleDelete,
  title,
  handleOpenDrawerTarea,
  handleOpenDrawerFinalizar,
}) => {
  const dispatch = useDispatch();
  const handleEliminar = (index) => {
    handleDelete(index);
  };
  const handleEditar = (record) => {
    dispatch(detalleProcesoProduccionSetActive(record));
    handleOpenDrawerTarea();
  };
  const handleFinalizar = (record) => {
    dispatch(detalleProcesoProduccionSetActive(record));
    handleOpenDrawerFinalizar();
  };
  return (
    <TableUi
      data={detalleprocesoproduccion}
      columns={columns({ handleEditar, handleEliminar, handleFinalizar })}
      rowKey="id"
      size="small"
      title={title}
    />
  );
};
