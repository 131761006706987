import React, { useEffect, useState } from "react";
import { get_tipos_calculo } from "../../../actions/listaprecio";
import { Radio } from "antd";

export const TipoCalculoRadio = ({ ...restProps }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_tipos_calculo();
      setData(datos);
    };
    OnLoad();

    return () => {
      setData([]);
    };
  }, []);
  return <Radio.Group options={data} {...restProps} />;
};
