import React, { useEffect, useState } from "react";
import { getTipoLocales } from "../../../helpers/loadGenerales";
import { SelectUi } from "../../ui/SelectUi";

export const TipoLocalSelect = ({ ...props }) => {
  const [tiposlocal, setTiposlocal] = useState([]);
  const OnLoadTiposLocal = async () => {
    const datos = await getTipoLocales();
    setTiposlocal(datos);
  };
  useEffect(() => {
    OnLoadTiposLocal();
  }, []);

  return <SelectUi data={tiposlocal} {...props} />;
};
