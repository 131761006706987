import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Form, Space, Switch, Tooltip } from "antd";
import { TareaProduccionSelect } from "../reusable/TareaProduccionSelect";
import { MaquinaSelect } from "../../reusable/MaquinaSelect";
import { SwitchUi } from "../../../ui/SwitchUi";
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
const columns = (props) => {
  const { handleEliminar, detallerutaproduccion } = props;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Tarea producción",
      dataIndex: "tarea",
      key: "tarea",
      render: (text, field, index) => {
        // console.log(field);
        return (
          <>
            <Form.Item
              name={[field.name, "tarea"]}
              rules={[
                {
                  required: true,
                  message: "La tarea de producción es obligatorio",
                },
              ]}
              style={{ marginBottom: 0, width: "100%" }}
              className="classRowFieldTable"
            >
              <TareaProduccionSelect placeholder="[-Seleccione-]" />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "¿Es terciarizado?",
      dataIndex: "es_terciarizado",
      key: "es_terciarizado",
      render: (text, field, index) => {
        return (
          <>
            <Form.Item
              name={[field.name, "es_terciarizado"]}
              style={{ marginBottom: 0, width: "100%" }}
              className="classRowFieldTable"
              valuePropName="checked"
            >
              <SwitchUi />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Maquina",
      dataIndex: "maquina",
      key: "maquina",
      render: (text, field, index) => {
        const es_terciarizado =
          detallerutaproduccion?.[field?.name].es_terciarizado;
        return (
          <>
            <Form.Item
              name={[field.name, "maquina"]}
              style={{ marginBottom: 0, width: "100%" }}
              className="classRowFieldTable"
            >
              <MaquinaSelect
                showSearch
                optionFilterProp="children"
                placeholder="[-Seleccione-]"
                filterOption={filterOption}
                disabled={es_terciarizado}
              />
            </Form.Item>
          </>
        );
      },
    },

    {
      title: "Acciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (value, record, index) => (
        <Space wrap>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              displayText={false}
              size="small"
              onClick={() => handleEliminar(index)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};

export const RutaProduccionDetalleTableItem = ({
  data,
  handleDelete,
  title,
}) => {
  const form = Form.useFormInstance();
  const detallerutaproduccion = Form.useWatch("detallerutaproduccion", form);
  const handleEliminar = (index) => {
    handleDelete(index);
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEliminar, detallerutaproduccion })}
      size="small"
      title={title}
    />
  );
};
