import { Tag } from "antd";
import React from "react";
import { EstadoAperturaCajaConstants } from "../../types/typesConstants";
import {
  CheckCircleOutlined,
  FileDoneOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const TagUi = ({ type, content, icon }) => {
  const createProps = (type) => {
    switch (type) {
      case EstadoAperturaCajaConstants.Abierto: {
        return { icon: <SyncOutlined />, color: "processing" };
      }
      case EstadoAperturaCajaConstants.Arqueado: {
        return { icon: <CheckCircleOutlined />, color: "success" };
      }
      case EstadoAperturaCajaConstants.Cerrado: {
        return { icon: <FileDoneOutlined />, color: "cyan" };
      }
      default: {
        return null;
      }
    }
  };

  return <Tag {...createProps(type)}>{content}</Tag>;
};
