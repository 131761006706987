import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import React from "react";

export const DropdownButtonUi = ({
  items,
  label = "Acciones",
  handleItemClick,
}) => {
  return (
    <Dropdown
      menu={{
        items: items,
        onClick: handleItemClick,
      }}
    >
      <Button>
        <Space>
          {label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
