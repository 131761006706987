import { types } from "../types/typesListaPrecio";

const initialState = {
  listaprecios: [],
  visibleModal: false,
  activeListaprecio: null,
  activeReglaListaprecio: null,
};
export const listaprecioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.listapreciosLoaded:
      return { ...state, listaprecios: action.payload };
    case types.listaprecioShowModalItemDetalle:
      return {
        ...state,
        visibleModal: action.payload,
      };
    case types.listaprecioAdded:
      return {
        ...state,
        listaprecios: [...state.listaprecios, action.payload],
      };
    case types.listaprecioSetActive:
      return {
        ...state,
        activeListaprecio: action.payload,
      };
    case types.listaprecioClearActive:
      return {
        ...state,
        activeListaprecio: null,
        activeReglaListaprecio: null,
      };
    case types.reglaListaprecioSetActive:
      return {
        ...state,
        activeReglaListaprecio: action.payload,
      };
    case types.reglaListaprecioClearActive:
      return {
        ...state,
        activeReglaListaprecio: null,
      };
    case types.listaprecioUpdated:
      return {
        ...state,
        listaprecios: state.listaprecios.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.listaprecioDeleted:
      return {
        ...state,
        listaprecios: state.listaprecios.filter(
          (e) => e.id !== action.payload.id
        ),
        activeListaprecio: null,
      };
    case types.listaprecioLogout:
      return { ...initialState };
    default:
      return state;
  }
};
