export const types = {
  comprobantesLoaded: "[comprobantepago] Comprobantes Pago Loaded",
  comprobantepagoAdded: "[comprobantepago] Comprobante Added",
  comprobantepagoUpdated: "[comprobantepago] Comprobante updated",
  comprobantepagoDeleted: "[comprobantepago] Comprobante deleted",
  comprobantepagoSetActive: "[comprobantepago] Comprobante pago Set Active",
  comprobantepagoClearActive: "[comprobantepago] Comprobante pago Clear Active",
  comprobantepagoXMLCreate: "[comprobantepago] Comprobante XML Create",
  comprobantepagoShowModal: "[ui] Show Modal Comprobante pago",
  comprobanteShowModalOptionPrint:
    "[ui] Show Modal Comprobante Option Print Option",
  cotizacionShowModalItemDetalle:
    "[cotizacion] Show or Close Modal Item Cotizacion Detalle",
  cotizacionesLoaded: "[cotizacion] Cotizaciones Loaded",
  tipomediospagoLoaded: "[tipomediopago] Tipo medios Pago Loaded",
  mediospagoLoaded: "[mediopago] Medios Pago Loaded",

  resumencomprobanteLoaded: "[resumencomprobante] Resumenes Comprobante Loaded",
  resumencomprobanteAdded: "[resumencomprobante] Resumen Comprobante added",
  resumencomprobanteUpdated: "[resumencomprobante] Resumen Comprobante updated",
  resumencomprobanteDeleted: "[resumencomprobante] Resumen Comprobante deleted",
  resumencomprobanteLogout: "[resumencomprobante] Resumen Comprobante Logout",
  resumencomprobanteSetActive:
    "[resumencomprobante] Resumen Comprobante Set Active",
  resumencomprobanteClearActive:
    "[resumencomprobante] Resumen Comprobante Clear Active",
  resumencomprobanteShowModalComprobantes:
    "[ui] Show Modal Comprobantes Selection",
  resumencomprobantesnoenviadosLoaded:
    "[resumencomprobante] Comprobantes No enviados Loaded",
  resumencomprobantesnoenviadosDeleted:
    "[resumencomprobante] Comprobantes no enviados deleted in resumen",
  resumencomprobantesnoenviadosClearLoaded:
    "[resumencomprobante] Comprobantes No enviados Clear Loaded",
};
