import React, { useEffect, useState } from "react";
import { getAlmacenesSelectOptions } from "../../../helpers/loadModulos";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const AlmacenSelect = ({ ...props }) => {
  const [almacenes, setAlmacenes] = useState([]);
  const OnLoadAlmacenes = async () => {
    const datos = await getAlmacenesSelectOptions();
    setAlmacenes(datos);
  };
  useEffect(() => {
    OnLoadAlmacenes();
  }, []);

  return <SearchBasicSelectUI options={almacenes} {...props} />;
};
