import { Result } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { cierreCajaClearActive } from "../../../../actions/caja";

export const ResultadoStep = () => {
  const dispatch = useDispatch();
  const { dataResult } = useSelector((state) => state.venta);
  const handleSalir = () => {
    dispatch(cierreCajaClearActive());
    window.history.back();
  };
  return (
    <>
      <Result
        status={dataResult?.status}
        title={dataResult?.statusText}
        extra={[
          <ButtonUi
            key="exit"
            typeUi={TipoButtonConstants.Salir}
            onClick={handleSalir}
          />,
        ]}
      />
    </>
  );
};
