import { types } from "../types/typesGenerales";

const initialState = {
  monedas: [],
  activeMoneda: null,
  loading: false,
};

export const monedaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.monedasLoaded:
      return { ...state, monedas: action.payload };
    case types.monedaAdded:
      return {
        ...state,
        monedas: [action.payload, ...state.monedas],
      };
    case types.monedaSetActive:
      return {
        ...state,
        activeMoneda: action.payload,
      };
    case types.monedaClearActive:
      return {
        ...state,
        activeMoneda: null,
      };
    case types.monedaUpdated:
      return {
        ...state,
        monedas: state.monedas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.monedaDeleted:
      return {
        ...state,
        monedas: state.monedas.filter((e) => e.id !== action.payload.id),
        activeMoneda: null,
      };
    case types.dataLoading:
      return { ...state, loading: action.payload };
    case types.monedaLogout:
      return { ...initialState };
    default:
      return state;
  }
};
