import { message } from "antd";
import { axiosConToken } from "../helpers/axios";
import { validaMessagesErrors } from "../helpers/validates";
import { types } from "../types/typesDashboard";

const key = "dashboard";
export const MontosTotalesNotaVentaStartLoad = (
  establecimiento,
  fechainicio,
  fechafin,
  periodo
) => {
  return async (dispatch) => {
    const msgload = message.loading("Cargando...");
    try {
      const resp = await axiosConToken.get(
        "dashboard/get_montototal_mediospago_notaventa_by_establecimiento_and_fechas",
        { params: { establecimiento, fechainicio, fechafin, periodo } }
      );
      const data = resp.data;
      dispatch(MontosTotalesNotaVentaLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      msgload();
    }
  };
};
export const MontosTotalesTipoComprobanteStartLoad = (
  establecimiento,
  fechainicio,
  fechafin,
  periodo
) => {
  return async (dispatch) => {
    const msgload = message.loading("Cargando...");
    try {
      const resp = await axiosConToken.get(
        "dashboard/get_montototal_tipocomprobante_by_establecimiento_and_fechas",
        { params: { establecimiento, fechainicio, fechafin, periodo } }
      );
      const data = resp.data;
      dispatch(MontosTotalesTipoComprobanteLoaded(data));
      msgload();
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      msgload();
    }
  };
};

const MontosTotalesNotaVentaLoaded = (data) => ({
  type: types.MontosNotasVentaLoaded,
  payload: data,
});
const MontosTotalesTipoComprobanteLoaded = (data) => ({
  type: types.MontosComprobantesLoaded,
  payload: data,
});
// Functions Async POS
export const get_tiposfiltros_dashboard = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("dashboard/get_tipofiltrosdashboard");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_montototales_tipocomprobante_by_establecimiento_and_fechas =
  async (establecimiento, fechainicio, fechafin, periodo) => {
    let datos = [];
    try {
      const resp = await axiosConToken.get(
        "dashboard/get_montototal_tipocomprobante_by_establecimiento_and_fechas",
        { params: { establecimiento, fechainicio, fechafin, periodo } }
      );
      datos = resp.data;
    } catch (error) {
      validaMessagesErrors(error.response);
    }
    return datos;
  };
