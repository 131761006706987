import React, { useEffect, useState } from "react";
import { SelectUi } from "../../../ui/SelectUi";
import { get_ancho_tela } from "../../../../actions/produccion";

export const AnchoTelaSelect = ({ ...restProps }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const data = await get_ancho_tela();
      setItems(data);
    };
    OnLoad();
    return () => {
      setItems([]);
    };
  }, []);

  return <SelectUi data={items} {...restProps} />;
};
