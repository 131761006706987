import React, { useEffect } from "react";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { UsuarioTable } from "./UsuarioTable";
import { useDispatch, useSelector } from "react-redux";
import { usuariosStartLoad } from "../../../actions/usuario";
import { UsuarioModal } from "./modal/UsuarioModal";
import { uiShowModal } from "../../../actions/ui";

export const UsuarioList = () => {
  const dispatch = useDispatch();
  const handleNuevo = () => {
    dispatch(uiShowModal());
  };
  const { usuarios } = useSelector((state) => state.auth);
  const LoadUsuarios = () => {
    dispatch(usuariosStartLoad());
  };
  useEffect(() => {
    LoadUsuarios();
  }, []);

  return (
    <PageHeaderUi
      title="Usuarios"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          key="Nuevo"
          onClick={handleNuevo}
        />,
      ]}
    >
      <UsuarioTable usuarios={usuarios} />
      <UsuarioModal />
    </PageHeaderUi>
  );
};
export default UsuarioList;
