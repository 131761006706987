import { Button, Space, Steps, theme } from "antd";
import React from "react";
import { ArqueoCajaStep } from "./ArqueoCajaStep";
import { InformacionGeneralStep } from "./InformacionGeneralStep";
import { StepBackwardOutlined, StepForwardOutlined } from "@ant-design/icons";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import { cierreCajaSetCurrentStep } from "../../../../actions/caja";
import { ResultadoStep } from "./ResultadoStep";

const steps = [
  {
    title: "Información general",
    content: <InformacionGeneralStep />,
  },
  {
    title: "Arqueo caja",
    content: <ArqueoCajaStep />,
  },
  {
    title: "Resultado",
    content: <ResultadoStep />,
  },
];

export const CierreCajaSteps = () => {
  const { token } = theme.useToken();
  const { currentStep } = useSelector((state) => state.caja);
  const dispatch = useDispatch();
  const next = () => {
    dispatch(
      cierreCajaSetCurrentStep({
        ...currentStep,
        current: currentStep?.current + 1,
      })
    );
  };
  const prev = () => {
    dispatch(
      cierreCajaSetCurrentStep({
        ...currentStep,
        current: currentStep.current - 1,
      })
    );
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 10,
  };
  return (
    <>
      <Steps current={currentStep?.current} items={items} responsive={true} />
      <div style={contentStyle}>{steps[currentStep?.current].content}</div>

      <div
        style={{
          marginTop: 16,
        }}
      >
        <Space wrap>
          {currentStep?.current < steps.length - 2 && (
            <Button
              type="primary"
              onClick={() => next()}
              icon={<StepForwardOutlined />}
            >
              Next
            </Button>
          )}
          {currentStep?.current > 0 &&
            currentStep?.current < steps.length - 1 && (
              <Button onClick={() => prev()} icon={<StepBackwardOutlined />}>
                Previous
              </Button>
            )}
          {currentStep?.current === steps.length - 2 && (
            <ButtonUi
              typeUi={TipoButtonConstants.Guardar}
              htmlType="submit"
              type="primary"
              form="FormCierreCaja"
              text="Finalizar"
            />
          )}
        </Space>
      </div>
    </>
  );
};
