import { Col, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { uiShowDrawer } from "../../../actions/ui";
import { AperturaCajaDrawer } from "./aperturacaja/AperturaCajaDrawer";
import { CajasTable } from "./CajasTable";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";

export const CajasList = () => {
  const dispatch = useDispatch();
  const handleNuevaAperturaCaja = () => {
    dispatch(uiShowDrawer());
  };
  return (
    <>
      <PageHeaderUi
        title="Cajas"
        subTitle="Listado"
        extra={[
          <ButtonUi
            key="nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleNuevaAperturaCaja}
          />,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <Row>
            <Col sm={32} md={32} xl={24}>
              <CajasTable />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
      <AperturaCajaDrawer />
    </>
  );
};

export default CajasList;
