import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_tipocomprobantes_ingreso } from "../../../actions/compra";

export const TipoComprobanteSelect = ({ ...props }) => {
  const [tipocomprobantes, setTipocomprobantes] = useState([]);
  useEffect(() => {
    const OnLoadTipoComprobantes = async () => {
      const datos = await get_tipocomprobantes_ingreso();
      setTipocomprobantes(datos);
    };
    OnLoadTipoComprobantes();
  }, []);

  return <SelectUi data={tipocomprobantes} {...props} />;
};
