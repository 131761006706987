import React, { useEffect } from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  programacionTintoreriaStartLoading,
  showFormProgramacionTintoreria,
} from "../../../../../actions/produccion";
import { ProgramacionTintoreriaTable } from "./ProgramacionTintoreriaTable";
import { ProgramacionTintoreriaScreen } from "./ProgramacionTintoreriaScreen";
import { ConsolidadoTelaScreen } from "../../consolidado/ConsolidadoTelaScreen";

const ProgramacionTintoteriaListScreen = () => {
  const dispatch = useDispatch();
  const { programacionesTintoreria } = useSelector((state) => state.produccion);
  const loadProgramacionesTintoreria = () => {
    dispatch(programacionTintoreriaStartLoading());
  };
  const handleReLoad = () => {
    loadProgramacionesTintoreria();
  };
  const handleNuevo = () => {
    dispatch(showFormProgramacionTintoreria());
  };

  return (
    <PageHeaderUi
      title="Programación de Tintoreria"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="actualizar"
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleReLoad}
        />,
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ProgramacionTintoreriaTable data={programacionesTintoreria} />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ProgramacionTintoreriaList = () => {
  const dispatch = useDispatch();
  const { visibleFormProgramacionTintoreria, visibleFormConsolidadoTela } =
    useSelector((state) => state.produccion);
  useEffect(() => {
    dispatch(programacionTintoreriaStartLoading());
  }, []);

  if (visibleFormProgramacionTintoreria) {
    return <ProgramacionTintoreriaScreen />;
  } else if (visibleFormConsolidadoTela) {
    return <ConsolidadoTelaScreen />;
  } else {
    return <ProgramacionTintoteriaListScreen />;
  }
};
export default ProgramacionTintoreriaList;
