import React, { useEffect, useState } from "react";
import { get_colores } from "../../../../actions/produccion";
import { SearchBasicSelectUI } from "../../../ui/SearchBasicSelectUI";

export const ColorSelect = ({ ...restProps }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const data = await get_colores();
      setItems(data);
    };
    OnLoad();
    return () => {
      setItems([]);
    };
  }, []);

  return <SearchBasicSelectUI data={items} {...restProps} />;
};
