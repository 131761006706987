import React from "react";
import { useDispatch } from "react-redux";
import {
  ordenProduccionClearActive,
  showFormOrdenProduccion,
} from "../../../../actions/produccion";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { CardUi } from "../../../ui/CardUi";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
} from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { ItemSelect } from "../../reusable/ItemSelect";
import { UnidadMedidaSelect } from "../../reusable/UnidadMedidaSelect";
import { EstadoOrdenProduccion } from "../../reusable/EstadoOrdenProduccion";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
export const OrdenProduccionForm = () => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(ordenProduccionClearActive());
    dispatch(showFormOrdenProduccion(false));
  };
  return (
    <CardUi
      title="Orden Producción"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormOrdenProduccion"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="item"
            label="Item"
            rules={[
              {
                required: true,
                message: "El item es obligatoria",
              },
            ]}
          >
            <ItemSelect
              showSearch
              optionFilterProp="children"
              placeholder="[-Seleccione-]"
              filterOption={filterOption}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item
            name="estado"
            label="Estado"
            rules={[
              {
                required: true,
                message: "El estado es obligatoria",
              },
            ]}
          >
            <EstadoOrdenProduccion placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={12}>
          <Form.Item
            name="nombre"
            label="Descripción"
            rules={[
              {
                required: true,
                message: "El nombre es obligatorio",
              },
            ]}
          >
            <Input maxLength={150} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              {
                required: true,
                message: "La cantidad es obligatoria",
              },
              { type: "number", message: "Ingrese un número válido", min: 1 },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
          <Form.Item
            name="unidadmedida"
            label="Unidad Medida"
            rules={[
              {
                required: true,
                message: "La unidad de medida es obligatoria",
              },
            ]}
          >
            <UnidadMedidaSelect
              showSearch
              optionFilterProp="children"
              placeholder="[-Seleccione-]"
              filterOption={filterOption}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            name="fechahorainicio"
            label="Fecha Hora inicio"
            required={false}
            rules={[
              {
                required: true,
                message: "La fecha hora inicio es obligatoria",
              },
            ]}
          >
            <DatePicker showTime />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item
            name="fechahorafin"
            label="Fecha hora fin"
            required={false}
          >
            <DatePicker showTime />
          </Form.Item>
        </Col>
      </Row>
    </CardUi>
  );
};
