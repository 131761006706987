import { Col, Row, Form, InputNumber, Space, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InventarioConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { AlmacenEmpleadoSelect } from "../../reusable/AlmacenEmpleadoSelect";
import { ProductoSelect } from "../../reusable/ProductoSelect";
import { TipoMovimientoSelect } from "../../reusable/TipoMovimientoSelect";
import { ButtonUi } from "../../../ui/ButtonUi";
import { PlusSquareOutlined } from "@ant-design/icons";
import { showFormInputMovements } from "../../../../actions/inventario";
import { CardUi } from "../../../ui/CardUi";
import { UnidadMedidaSelect } from "../../../generales/unidadesmedida/UnidadMedidaSelect";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";

export const EntradaForm = ({ onOpenModal }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const manejalotes = Form.useWatch("manejalotes", form);
  const manejaseries = Form.useWatch("manejaseries", form);
  const changeProducto = (e, option) => {
    form.setFieldsValue({
      manejalotes: option?.manejalotes,
      manejaseries: option?.manejaseries,
      unidadmedida: option?.unidadmedida,
    });
  };
  const handleAgredarSerie = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOpenModalLotes = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCancel = () => {
    dispatch(showFormInputMovements(false));
  };
  return (
    <CardUi
      title="Ingreso a almacén"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormMovimientoEntrada"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={16} lg={12} xl={12}>
          <Form.Item
            name="item"
            label="Item"
            rules={[{ required: true, message: "El item es obligatorio" }]}
          >
            <ProductoSelect
              placeholder="Seleccione"
              allowClear
              onChange={changeProducto}
            />
          </Form.Item>
        </Col>
        {manejaseries && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.CustomButton}
                icon={<PlusSquareOutlined />}
                text="Agregar series"
                className="ant-btn-info"
                onClick={handleAgredarSerie}
              />
            </Form.Item>
          </Col>
        )}
        {manejalotes && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar lotes"
                className="ant-btn-info"
                onClick={handleOpenModalLotes}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              { type: "number", message: "Ingrese un número válido", min: 1 },
            ]}
          >
            <InputNumber
              name="cantidad"
              placeholder="Ingrese cantidad"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
          <Form.Item
            name="unidadmedida"
            label="Unidad Medida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
          <Form.Item
            name="costo"
            label="Costo"
            rules={[
              { required: true, message: "el costo es obligatorio" },
              { type: "number", message: "Ingrese un número válido", min: 1 },
            ]}
            required={false}
          >
            <InputNumber
              placeholder="Ingrese Costo"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="tipomovimiento"
            label="Movimiento"
            rules={[
              {
                required: true,
                message: "El tipo de movimiento es obligatorio",
              },
            ]}
          >
            <TipoMovimientoSelect
              tipooperacion={InventarioConstants.TipoOperacionEntrada}
              name="tipomovimiento"
              placeholder="Seleccione movimiento"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="almacendestino"
            label="Almacén"
            rules={[{ required: true, message: "El almacén es obligatorio" }]}
          >
            <AlmacenEmpleadoSelect
              empleado={activeEmpleado?.id}
              name="almacendestino"
              placeholder="Seleccione almacén"
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Form.List
            name="series"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (manejaseries) {
                    if (value.length !== getFieldValue("cantidad"))
                      return Promise.reject(
                        new Error(
                          "La cantidad ingresada debe ser igual a la cantidad de despacho"
                        )
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, options, { errors }) => (
              <>
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Form.List
            name="lotes"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (manejalotes) {
                    const suma = value?.reduce(
                      (prev, curr) => Number(prev) + Number(curr?.cantidad),
                      0
                    );
                    if (suma !== getFieldValue("cantidad"))
                      return Promise.reject(
                        new Error(
                          "La cantidad ingresada debe ser igual a la cantidad de despacho"
                        )
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, options, { errors }) => (
              <>
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </CardUi>
  );
};
