import React, { useEffect, useState } from "react";
import { get_empleados_vendedores } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";

export const EmpleadoVendedorSelect = ({ ...props }) => {
  const [empleados, setEmpleados] = useState([]);
  useEffect(() => {
    const OnLoadEmpleados = async () => {
      const datos = await get_empleados_vendedores();
      setEmpleados(datos);
    };
    OnLoadEmpleados();
    return () => {
      setEmpleados([]);
    };
  }, []);
  return <SelectUi data={empleados} {...props} />;
};
