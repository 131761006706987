import React, { useEffect, useState } from "react";
import { get_tipomediospago } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";

export const TipoMedioPagoSelect = ({ ...props }) => {
  const [tipomediospago, setTipomediospago] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const data = await get_tipomediospago();
      setTipomediospago(data);
    };
    OnLoad();
    return () => {
      setTipomediospago([]);
    };
  }, []);

  return <SelectUi data={tipomediospago} {...props} />;
};
