import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmpresasTable } from "./EmpresasTable";
import { uiShowForm } from "../../../actions/ui";
import { ButtonUi } from "../../ui/ButtonUi";
import { empresasStartLoad } from "../../../actions/empresa";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { EmpresaScreen } from "./EmpresaScreen";
import { PageHeaderUi } from "../../ui/PageHeaderUi";

export const EmpresasListScreen = () => {
  const dispatch = useDispatch();
  const { empresas } = useSelector((state) => state.empresa);
  const handleNuevo = () => {
    dispatch(uiShowForm());
  };
  const handleReLoadEmpresas = () => {
    dispatch(empresasStartLoad());
  };
  useEffect(() => {
    dispatch(empresasStartLoad());
  }, [dispatch]);
  return (
    <PageHeaderUi
      title="Empresas"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key={TipoButtonConstants.Actualizar}
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleReLoadEmpresas}
        />,
        <ButtonUi
          key={TipoButtonConstants.Nuevo}
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
        />,
      ]}
    >
      <EmpresasTable data={empresas} />
    </PageHeaderUi>
  );
};

export const EmpresasList = () => {
  const { visibleForm } = useSelector((state) => state.ui);
  if (visibleForm) {
    return <EmpresaScreen />;
  } else {
    return <EmpresasListScreen />;
  }
};
export default EmpresasList;
