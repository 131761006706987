import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DollarCircleFilled,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Table, Tag } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  comprobantepagoSetActive,
  showModalComprobanteOptionPrint,
  ShowModalComprobantePago,
} from "../../../../actions/comprobante";
import { getPdfNotaVenta } from "../../../../helpers/loadVentas";
import { open_pdf_file } from "../../../../helpers/utils";
import { EstadoComprobantePagoConstants } from "../../../../types/typesConstants";
import { toast } from "react-toastify";
const handleMenuOpt = (e, data, acciones) => {
  const { OnHandleGenerarComprobantePago, OnHandlePrintDocument } = acciones;

  switch (e.key) {
    case "COMPROBANTE":
      OnHandleGenerarComprobantePago(data);
      break;
    case "PRINT_DOCUMENT":
      OnHandlePrintDocument(data);
      break;
    // Invoice();
    default:
      break;
  }
};
const menuProps = (data, acciones) => {
  let opts = [
    {
      key: "PRINT_DOCUMENT",
      label: "Opciones Imprimir",
      icon: <MenuUnfoldOutlined />,
    },
  ];
  if (data?.estadocomprobante === EstadoComprobantePagoConstants.EMITIDO)
    opts.splice(0, 0, {
      key: "COMPROBANTE",
      label: "Generar comprobante",
      icon: <FileTextOutlined />,
    });
  opts.push({
    key: "ANULAR",
    label: "Anular",
    icon: <CloseOutlined />,
  });
  const configItems = {
    items: opts,
    onClick: (e) => handleMenuOpt(e, data, acciones),
  };
  return configItems;
};

const BtnGroupTable = ({ data, acciones }) => {
  const { OnHandlePDFComprobante } = acciones;
  return (
    <>
      <Button
        type="primary"
        danger
        size="small"
        onClick={() => {
          OnHandlePDFComprobante(data);
        }}
      >
        <FilePdfOutlined />
      </Button>
      <Dropdown menu={menuProps(data, acciones)} trigger={["click"]}>
        <Button className="ml-2">
          <SettingOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
const columns_table = (acciones) => {
  const { OnHandleMediosPago, OnHandlePDFComprobante } = acciones;
  const TagEstadoComprobante = (comprobante) => {
    switch (comprobante.estadocomprobante) {
      case EstadoComprobantePagoConstants.ACEPTADO:
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
      case EstadoComprobantePagoConstants.RECHAZADO:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
      case EstadoComprobantePagoConstants.CANJEADO:
        return (
          <Tag icon={<SwapOutlined />} color="gold">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
      default:
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="processing">
            {comprobante?.estadocomprobante_nombre}
          </Tag>
        );
    }
  };
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Cliente",
      dataIndex: "cliente_nombre",
      key: "cliente_nombre",
    },
    {
      title: "Nota de Venta",
      dataIndex: "comprobante",
      key: "comprobante",
    },
    {
      title: "Moneda",
      dataIndex: "moneda_nombre",
      key: "moneda_nombre",
    },
    {
      title: "Total",
      dataIndex: "montototal",
      key: "montototal",
    },
    {
      title: "Estado",
      dataIndex: "estadocomprobante",
      key: "estadocomprobante",
      render: (text, record) => <>{TagEstadoComprobante(record)}</>,
    },
    {
      title: "Pagos",
      dataIndex: "detallemediopagos",
      key: "detallemediopagos",
      render: (text, record) => (
        <Button
          type="info"
          size="small"
          onClick={() => {
            OnHandleMediosPago(record);
          }}
        >
          <DollarCircleFilled />
        </Button>
      ),
    },
    {
      title: "Descarga",
      dataIndex: "descarga",
      key: "descarga",
      render: (text, record) => (
        <BtnGroupTable data={record} acciones={acciones} />
      ),
    },
  ];
};
export const NotaVentaTable = ({ comprobantes }) => {
  const dispatch = useDispatch();
  const { activeCajaAbierta } = useSelector((state) => state.venta);
  const OnHandleMediosPago = () => {};
  const OnHandlePDFComprobante = async (comprobante) => {
    const data = await getPdfNotaVenta(comprobante.id);
    open_pdf_file(data, comprobante.comprobante);
  };
  const OnHandlePrintDocument = (notaventa) => {
    dispatch(comprobantepagoSetActive(notaventa));
    dispatch(showModalComprobanteOptionPrint());
  };
  const OnHandleGenerarComprobantePago = (notaventa) => {
    if (activeCajaAbierta) {
      dispatch(comprobantepagoSetActive(notaventa));
      dispatch(ShowModalComprobantePago());
    } else {
      toast.error(
        "Aperture o seleccione una caja, para emitir el comprobante de la nota de venta"
      );
    }
  };
  return (
    <Table
      columns={columns_table({
        OnHandleMediosPago,
        OnHandlePDFComprobante,
        OnHandleGenerarComprobantePago,
        OnHandlePrintDocument,
      })}
      rowKey="id"
      dataSource={comprobantes}
      size="small"
    />
  );
};
