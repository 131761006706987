import React, { useEffect, useState } from "react";
import { get_categorias } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";
const valueAllItem = {
  value: "",
  label: "Todas las categorias",
};
export const CategoryItemSelect = ({ ...props }) => {
  const [categorias, setCategorias] = useState([]);
  useEffect(() => {
    const OnLoadCategorias = async () => {
      const data = await get_categorias();
      setCategorias([valueAllItem, ...data]);
    };
    OnLoadCategorias();
    return () => {
      setCategorias([]);
    };
  }, []);

  return <SelectUi data={categorias} {...props} />;
};
