import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { get_condicionespago } from "../../../actions/compra";

export const CondicionPagoSelect = ({ ...props }) => {
  const [condicionespago, setCondicionespago] = useState([]);
  const OnLoadData = async () => {
    const datos = await get_condicionespago();
    setCondicionespago(datos);
  };
  useEffect(() => {
    OnLoadData();
  }, []);

  return <Select options={condicionespago} {...props} />;
};
