import { Col, Form, Input, InputNumber, Modal, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { TipoCalculoRadio } from "./TipoCalculoRadio";
import { useSelector } from "react-redux";
import { AplicarSobreSelect } from "./AplicarSobreSelect";
import { TypePriceCalculationConstants } from "../../../types/typesConstants";
import dayjs from "dayjs";

const initialValues = (data = {}) => ({
  id: Number(data?.id || dayjs().valueOf()),
  tipocalculo: data?.tipocalculo || TypePriceCalculationConstants.Monto,
  tipocalculo_nombre: data?.tipocalculo_nombre || "MONTO",
  monto: parseFloat(data?.monto || 0),
  aplicar: data?.aplicar || "ALL",
  aplicar_nombre: data?.aplicar_nombre || "TODOS LOS PRODUCTOS",
  categoriaitem: data?.categoriaitem || null,
  item: data?.item || null,
  cantidadminima: parseFloat(data?.cantidadminima || 0),
});

// const initialValues = {
//   id: Number(dayjs().valueOf()),
//   tipocalculo: TypePriceCalculationConstants.Monto,
//   tipocalculo_nombre: "MONTO",
//   monto: 0,
//   aplicar: "ALL",
//   aplicar_nombre: "TODOS LOS PRODUCTOS",
//   categoriaitem: null,
//   item: null,
//   cantidadminima: 0,
// };

const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

export const ListaPrecioDetailModal = ({ open, onCancel }) => {
  const forminstance = Form.useFormInstance();
  const [form] = Form.useForm(forminstance);
  const tipocalculo = Form.useWatch("tipocalculo", form);
  const { activeReglaListaprecio } = useSelector((state) => state.listaprecio);

  useResetFormOnCloseModal({
    form,
    open,
  });
  useEffect(() => {
    if (activeReglaListaprecio === null) {
      form.setFieldsValue({ ...initialValues() });
    } else {
      form.setFieldsValue({ ...activeReglaListaprecio });
    }
  }, [activeReglaListaprecio]);

  const onOk = () => {
    form.submit();
  };
  const OnChangeTipoCalculo = (e) => {
    const tipocalculo_nombre = e.nativeEvent?.target?.labels?.[0]?.outerText;
    form.setFieldValue("tipocalculo_nombre", tipocalculo_nombre);
  };
  const OnChangeAplicarSobre = (e, children) => {
    form.setFieldValue("aplicar_nombre", children?.label);
  };

  return (
    <Modal
      title="Configuración regla de precio"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      centered
      maskClosable={false}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        name="reglaForm"
        initialValues={initialValues(activeReglaListaprecio)}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="id" hidden={true}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="tipocalculo_nombre" hidden={true}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="aplicar_nombre" hidden={true}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="tipocalculo"
              label="Tipo calculo"
              required={false}
              rules={[
                {
                  required: true,
                  message: "El tipo de calculo es obligatorio",
                },
              ]}
            >
              <TipoCalculoRadio onChange={(e) => OnChangeTipoCalculo(e)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="monto"
              label="Valor"
              rules={[
                {
                  required: true,
                  message: "El monto es obligatorio",
                },
                {
                  type: "number",
                  min: 1,
                  message: "El monto debe ser mayor a 0",
                },
              ]}
            >
              <InputNumber
                min={0}
                addonBefore={
                  tipocalculo === TypePriceCalculationConstants.Monto
                    ? "S/"
                    : null
                }
                addonAfter={
                  tipocalculo === TypePriceCalculationConstants.Porcentaje
                    ? "%"
                    : null
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="aplicar"
              label="Aplicar sobre"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Aplicar sobre es obligatorio",
                },
              ]}
            >
              <AplicarSobreSelect onChange={OnChangeAplicarSobre} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cantidadminima"
              label="Cantidad minima"
              rules={[
                {
                  required: true,
                  message: "La cantidad minima es obligatoria",
                },
                {
                  type: "number",
                  min: 1,
                  message: "La cantidad minima debe ser mayor a 0",
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
