import { axiosConToken } from "../helpers/axios";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { types } from "../types/typesAuth";

const key = "msgusuarios";
export const usuariosStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get("users");
      const data = resp.data;
      dispatch(usuariosLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const usuarioStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post("users/", data);
      const { msg, usuario } = resp.data;
      dispatch(usuarioAdded(usuario));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const usuarioStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.patch(`users/${data.id}/`, data);
      const { msg, usuario } = resp.data;
      dispatch(usuarioUpdated(usuario));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const usuarioStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`users/${data.id}/`);
      const { msg } = resp.data;
      dispatch(usuarioDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const usuariosLoaded = (usuarios) => ({
  type: types.authUsuariosLoaded,
  payload: usuarios,
});
const usuarioAdded = (usuario) => ({
  type: types.authUsuarioAdded,
  payload: usuario,
});
const usuarioUpdated = (usuario) => ({
  type: types.authUsuarioUpdated,
  payload: usuario,
});
const usuarioDeleted = (usuario) => ({
  type: types.authUsuarioDeleted,
  payload: usuario,
});
export const usuarioSetActive = (usuario) => ({
  type: types.authUsuarioSetActive,
  payload: usuario,
});
export const usuarioClearActive = () => ({
  type: types.authUsuarioClearActive,
});
