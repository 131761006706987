import { types } from "../types/typesGenerales";

const initialState = {
  empresas: [],
  activeEmpresa: null,
};
export const empresaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.empresaLoaded:
      return { ...state, empresas: action.payload };
    case types.empresaAdded:
      return {
        ...state,
        empresas: [...state.empresas, action.payload],
      };
    case types.empresaSetActive:
      return {
        ...state,
        activeEmpresa: action.payload,
      };
    case types.empresaClearActive:
      return {
        ...state,
        activeEmpresa: null,
      };
    case types.empresaUpdated:
      return {
        ...state,
        empresas: state.empresas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.empresaDeleted:
      return {
        ...state,
        empresas: state.empresas.filter((e) => e.id !== action.payload.id),
        activeEmpresa: null,
      };
    case types.empresaLogout:
      return { ...initialState };
    default:
      return state;
  }
};
