import React from "react";
import { Form, Tag, Tooltip } from "antd";
import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { TableUi } from "../../../../../ui/TableUi";

const columns_table = (acciones) => {
  const { handleSelect } = acciones;
  return [
    {
      title: "",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => (
        <>
          <Tooltip title="Seleccionar">
            <ButtonUi
              typeUi={TipoButtonConstants.Seleccionar}
              size="small"
              onClick={() => handleSelect(record)}
              displayText={false}
              ghost
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: "Cant. Rollos",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },

    {
      title: "Peso (KG)",
      dataIndex: "pesoneto",
      key: "pesoneto",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (text, record, index) =>
        text === true ? (
          <Tag icon={<CheckOutlined />} color="success">
            Cumplió
          </Tag>
        ) : (
          <Tag icon={<ClockCircleOutlined />} color="processing">
            En proceso
          </Tag>
        ),
    },
  ];
};
export const SearchFormProgramacionTejidoTable = ({ data }) => {
  const form = Form.useFormInstance();
  const handleSelect = (data) => {
    form.setFieldValue("detalleprogramaciontejido", data);
    form.submit();
  };
  return (
    <TableUi
      columns={columns_table({
        handleSelect,
      })}
      rowKey="id"
      dataSource={data}
      size="small"
    />
  );
};
