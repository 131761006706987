import { Descriptions, InputNumber, Form, Input } from "antd";
import React from "react";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";

export const PosCarritoContentItem = ({
  form,
  field,
  handleAdd,
  handleRemove,
}) => {
  const { setFieldValue, getFieldValue } = form;
  const { manejalotes, manejaseries } = getFieldValue([
    "detallecomprobante",
    field.name,
  ]);
  const readOnlyFieldLotesOrSeries = !manejalotes && !manejaseries;
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const onChangeCantidad = (e, field) => {
    const item = detallecomprobante[field.name];
    const total = parseFloat(e * item?.precioventa);
    setFieldValue(["detallecomprobante", field.name, "totalventa"], total);
  };
  const OnChangePrecioVenta = (e, field) => {
    const item = detallecomprobante[field.name];
    const total = parseFloat(e * item?.cantidad);
    setFieldValue(["detallecomprobante", field.name, "totalventa"], total);
  };

  return (
    <>
      <Descriptions column={{ xxl: 6, xl: 6, lg: 6, md: 6, sm: 2, xs: 1 }}>
        <Descriptions.Item
          style={{ width: "5%", paddingBottom: "2px" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "prefijounidadmedida"]}
            style={{ marginBottom: 0 }}
          >
            <Input
              size="small"
              bordered={false}
              readOnly={true}
              style={{ fontSize: "10px" }}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ width: "10%", paddingBottom: "2px" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "cantidad"]}
            rules={[
              { required: true, message: "La cantidad es obligatoria" },
              {
                type: "number",
                message: "Ingrese un número",
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              size="small"
              style={{ fontSize: 12, marginBottom: 0, width: "80%" }}
              onChange={(value) => onChangeCantidad(value, field)}
              min={1}
              precision={2}
              readOnly={!readOnlyFieldLotesOrSeries}
              bordered={readOnlyFieldLotesOrSeries}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ width: "30%", paddingBottom: "2px" }}
          contentStyle={{ width: "100%", display: "inline" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "nombre"]}
            style={{ marginBottom: 0 }}
          >
            <Input
              size="small"
              bordered={false}
              readOnly={true}
              style={{ fontSize: "10px" }}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ width: "10%", paddingBottom: "2px" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "precioventa"]}
            rules={[
              { required: true, message: "El precio de venta es obligatorio" },
              {
                type: "number",
                message: "Ingrese un número",
              },
            ]}
          >
            <InputNumber
              size="small"
              style={{ fontSize: 12, marginBottom: 0, width: "90%" }}
              onChange={(value) => OnChangePrecioVenta(value, field)}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ width: "10%", paddingBottom: "2px" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "totalventa"]}
            rules={[
              { required: true, message: "El total es obligatorio" },
              {
                type: "number",
                message: "Ingrese un número",
              },
            ]}
          >
            <InputNumber
              size="small"
              style={{ fontSize: 12, marginBottom: 0, width: "90%" }}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{
            width: "5%",
            paddingBottom: "2px",
            paddingTop: "5px",
            paddingLeft: "5px",
          }}
          className="poscarritocontent__item"
        >
          <ButtonUi
            typeUi={TipoButtonConstants.EliminarTable}
            displayText={false}
            onClick={() => handleRemove(field.name)}
            size="small"
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
