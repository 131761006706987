import { message } from "antd";
const MessageSuccess = (messageApi, content, duration, messageKey) => {
  messageApi.success({
    content: content,
    duration: duration,
    key: messageKey,
    onClick: () => messageApi.destroy(messageKey),
    onClose: () => messageApi.destroy(messageKey),
  });
};
const MessageError = (content, duration, messageKey) => {
  message.error({
    content: content,
    duration: duration,
    key: messageKey,
    onClick: () => message.destroy(messageKey),
  });
};
const ShowCatchError = (error) => {
  const resp = error.response;
  let errorMessage = "";
  if (!resp) {
    errorMessage = `**Network/Server error: ${resp}`;
    message.error(errorMessage);
  } else {
    if (resp.status !== 401) {
      errorMessage = `Comuniquese con el administrador: ${resp.status} - ${error.message}`;
      message.error(errorMessage);
    }
  }
};
const DisplayMessageErrors = (errors, keyError, keymsg = null) => {
  for (let [key, value] of Object.entries(errors)) {
    if (typeof value === "object") {
      DisplayMessageErrors(value, key, keymsg);
    } else {
      if (keymsg) {
        return message.error(`${keyError} - ${errors}`, 1.5, keymsg);
      } else {
        return message.error(`${keyError} - ${errors}`);
      }
    }
  }
};
const validaMessagesErrors = (errors, keymsg = null) => {
  const errorsResponse = errors?.response;
  if (errorsResponse !== undefined) {
    if (errorsResponse?.status === 500) {
      MessageError(errorsResponse.statusText, 1.5, keymsg);
    } else if (errorsResponse.status === 400) {
      const dataErrors = errorsResponse?.data;
      const keyError = Object.keys(dataErrors)[0];
      const valueError = Object.values(dataErrors)[0];
      if (typeof valueError === "object") {
        DisplayMessageErrors(dataErrors, keyError);
      } else {
        return message.error(`${valueError}`);
      }
    } else {
      message.error(errorsResponse.statusText, 1.5);
    }
  } else {
    message.error(errors.message, 1.5);
  }
};

export { MessageSuccess, validaMessagesErrors, ShowCatchError };
