import React, { useEffect, useState } from "react";
import { Col, Form, Row, Statistic, Avatar } from "antd";
import { CardUi } from "../../../ui/CardUi";
import { useSelector } from "react-redux";
import { CierreCajaSteps } from "./CierreCajaSteps";
import {
  DollarOutlined,
  MinusSquareOutlined,
  NumberOutlined,
  PlusSquareOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { PaymentMethodType } from "../../../../types/typesConstants";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
const initialMontos = {
  montoinicial: 0,
  ingresos: 0,
  egresos: 0,
  montototalventas: 0,
  montoteorico: 0,
};
export const CierreCajaForm = () => {
  const form = Form.useFormInstance();
  const { activeCajaAbierta } = useSelector((state) => state.caja);
  const [montosCaja, setMontosCaja] = useState(initialMontos);

  useEffect(() => {
    if (activeCajaAbierta) {
      let monto_ingresos = 0;
      let monto_egresos = 0;
      activeCajaAbierta?.movimientoscaja?.forEach((item) => {
        if (item.tipomovimiento === "IG")
          monto_ingresos += parseFloat(item.monto);
        if (item.tipomovimiento === "EG")
          monto_egresos += parseFloat(item.monto);
      });
      const qs_filter = activeCajaAbierta?.montototal_mediospago?.filter(
        (item) => item.tipomediopago === PaymentMethodType.Efectivo
      );
      const montototal_ventas = qs_filter?.[0]?.monto || 0;

      const _montoteorico =
        parseFloat(activeCajaAbierta?.montoinicial) +
        monto_ingresos +
        montototal_ventas -
        monto_egresos;
      setMontosCaja({
        montoinicial: parseFloat(activeCajaAbierta?.montoinicial),
        ingresos: monto_ingresos,
        egresos: monto_egresos,
        montoteorico: parseFloat(_montoteorico),
        montototalventas: parseFloat(montototal_ventas),
      });
      form.setFieldValue("montoteorico", parseFloat(_montoteorico));
    }
  }, []);

  return (
    <>
      <FormItemHiddenUi name="montoteorico" />
      <Row
        gutter={[{ xs: 8, sm: 10, md: 10, lg: 12 }, 16]}
        style={{ paddingBottom: 16 }}
      >
        <Col xs={24} sm={12} md={8} lg={5} xl={5}>
          <CardUi bordered={false}>
            <Statistic
              title="Total Efectivo"
              value={montosCaja?.montototalventas || 0}
              prefix={
                <Avatar
                  style={{
                    backgroundColor: "#124076",
                  }}
                  icon={<ShopOutlined />}
                />
              }
              precision={2}
            />
          </CardUi>
        </Col>
        <Col xs={24} sm={12} md={8} lg={5} xl={4}>
          <CardUi bordered={false}>
            <Statistic
              title="Ingresos"
              value={montosCaja?.ingresos || 0}
              prefix={
                <Avatar
                  style={{
                    backgroundColor: "#87d068",
                  }}
                  icon={<PlusSquareOutlined />}
                />
              }
              precision={2}
            />
          </CardUi>
        </Col>
        <Col xs={24} sm={12} md={8} lg={5} xl={4}>
          <CardUi bordered={false}>
            <Statistic
              title="Egresos"
              value={montosCaja?.egresos || 0}
              prefix={
                <Avatar
                  style={{
                    backgroundColor: "#E72929",
                  }}
                  icon={<MinusSquareOutlined />}
                />
              }
              precision={2}
            />
          </CardUi>
        </Col>
        <Col xs={24} sm={12} md={8} lg={5} xl={5}>
          <CardUi bordered={false}>
            <Statistic
              title="Monto inicial"
              value={montosCaja?.montoinicial || 0}
              prefix={
                <Avatar
                  style={{
                    backgroundColor: "#64CCC5",
                  }}
                  icon={<NumberOutlined />}
                />
              }
              precision={2}
            />
          </CardUi>
        </Col>
        <Col xs={24} sm={12} md={8} lg={5} xl={5}>
          <CardUi bordered={false} style={{ backgroundColor: "#F6F4EB" }}>
            <Statistic
              title="Total en caja"
              value={montosCaja?.montoteorico || 0}
              prefix={
                <Avatar
                  style={{
                    backgroundColor: "#64CCC5",
                  }}
                  icon={<DollarOutlined />}
                />
              }
              precision={2}
            />
          </CardUi>
        </Col>
      </Row>
      <CierreCajaSteps />
    </>
  );
};
