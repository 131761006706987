import { Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  procesoProduccionStartAdd,
  procesoProduccionStartUpdate,
  showFormProcesoProduccion,
} from "../../../../actions/produccion";
import { ProcesoProduccionForm } from "./ProcesoProduccionForm";
import { FormUi } from "../../../ui/FormUi";
import dayjs from "dayjs";
import {
  DateFormats,
  EstadoDetalleProcesoProduccion,
} from "../../../../types/typesConstants";

const initialValues = (data = {}) => ({
  id: data?.id || 0,
  nombre: data?.nombre || null,
  fechahorainicio: dayjs(data?.fechahorainicio) || dayjs(),
  fechahorafin: dayjs(data?.fechahorafin) || null,
  detalleprocesoproduccion: data?.detalleprocesoproduccion || [],
  ordenproduccion: data?.ordenproduccion || null,
  detallerutaproduccion: data?.detallerutaproduccion || [],
  unidadmedida: data?.unidadmedida || null,
  cantidadinicial: data?.cantidadinicial || null,
  estado: data?.estado || null,
  descripcion: data?.descripcion || null,
});
export const ProcesoProduccionScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeProcesoProduccion } = useSelector((state) => state.produccion);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleSubmit = (values) => {
    values = {
      ...values,
      fechahorainicio: dayjs(values?.fechahorainicio).format(
        DateFormats.DatetimeBackEnd
      ),
      fechahorafin:
        values?.fechahorafin !== null
          ? dayjs(values?.fechahorafin).format(DateFormats.DatetimeBackEnd)
          : values?.fechahorafin,
    };
    // console.log(values);
    // console.log(valuesData);
    if (activeProcesoProduccion) {
      dispatch(procesoProduccionStartUpdate(values));
    } else dispatch(procesoProduccionStartAdd(values));
    dispatch(showFormProcesoProduccion(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormProcesoProduccion } = forms;
        const detalleprocesoproduccion =
          FormProcesoProduccion.getFieldValue("detalleprocesoproduccion") || [];
        if (name === "FormTareaProduccion") {
          values = {
            ...values,
            estado: EstadoDetalleProcesoProduccion.INICIADO,
            estado_nombre: EstadoDetalleProcesoProduccion.INICIADO,
            fechahorainicio: dayjs(values?.fechahorainicio).format(
              DateFormats.DatetimeBackEnd
            ),
          };

          FormProcesoProduccion.setFieldsValue({
            detalleprocesoproduccion: [...detalleprocesoproduccion, values],
          });
        }
        if (name === "FormFinalizarTareaProduccion") {
          values = {
            ...values,
            estado: EstadoDetalleProcesoProduccion.FINALIZADO,
            estado_nombre: EstadoDetalleProcesoProduccion.FINALIZADO,
            fechahorafin: dayjs(values?.fechahorafin).format(
              DateFormats.DatetimeBackEnd
            ),
          };
          FormProcesoProduccion.setFieldsValue({
            detalleprocesoproduccion: detalleprocesoproduccion.map((e) =>
              e.id === values.id ? values : e
            ),
          });
        }
      }}
    >
      <FormUi
        name="FormProcesoProduccion"
        form={form}
        initialValues={initialValues(activeProcesoProduccion)}
        handleSubmit={handleSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <ProcesoProduccionForm onOpenModal={showModal} />
      </FormUi>
    </Form.Provider>
  );
};
