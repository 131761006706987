import React from "react";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import {
  productoSetActive,
  productoStartChangeStatusEnabled,
  productoStartDelete,
  productoStartDuplicate,
  showModalListaPrecios,
} from "../../actions/producto";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { uiShowForm } from "../../actions/ui";
import { showDeleteConfirm } from "../../helpers/confirmModal";
import { ButtonUi } from "../ui/ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { TagStatusUi } from "../ui/TagStatusUi";
import { TableUi } from "../ui/TableUi";

const itemsOptionsTable = (field) => {
  return [
    {
      label: "Duplicar",
      key: "duplicar",
      icon: <CopyOutlined />,
    },
    {
      label: field?.estahabilitado === true ? "Inhabilitar" : "Habilitar",
      key: "change_status",
      icon:
        field?.estahabilitado === true ? (
          <CloseCircleOutlined />
        ) : (
          <CheckCircleOutlined />
        ),
    },
  ];
};
const columns = ({
  handleEditarListaPrecios,
  handleEditarProducto,
  handleDeleteProducto,
  handleMenuItemClick,
}) => [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
  },
  {
    title: "Tipo",
    dataIndex: "tipoitem_nombre",
    key: "tipoitem_nombre",
  },
  {
    title: "Precio",
    dataIndex: "precioventa",
    key: "precioventa",
    render: (value, record) => (
      <>
        <Space>
          {value}
          <Tooltip title="Lista de precios">
            <Button
              type="dashed"
              icon={<MoneyCollectOutlined />}
              onClick={() => handleEditarListaPrecios(record)}
            />
          </Tooltip>
        </Space>
      </>
    ),
  },
  {
    title: "Costo",
    dataIndex: "costo",
    key: "costo",
  },
  {
    title: "Stock",
    dataIndex: "stockactual",
    key: "stockactual",
  },
  {
    title: "Stock Min.",
    dataIndex: "stockminimo",
    key: "stockminimo",
  },
  {
    title: "¿Es inventariado?",
    dataIndex: "esinventariado",
    key: "esinventariado",
    render: (value) => <TagStatusUi status={value} />,
    align: "center",
  },
  {
    title: "¿Se elabora?",
    dataIndex: "esfabricado",
    key: "esfabricado",
    render: (value) => <TagStatusUi status={value} />,
    align: "center",
  },
  {
    title: "",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <>
        <Space size="middle">
          <Tooltip title="Editar">
            <ButtonUi
              typeUi={TipoButtonConstants.EditarTable}
              onClick={() => handleEditarProducto(record)}
              displayText={false}
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleDeleteProducto(record)}
              displayText={false}
              ghost
            />
          </Tooltip>
          <Dropdown
            menu={{
              items: itemsOptionsTable(record),
              onClick: (e) => handleMenuItemClick(e, record),
            }}
            placement="bottomRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <ButtonUi
              typeUi={TipoButtonConstants.OpcionesTable}
              displayText={false}
            />
          </Dropdown>
        </Space>
      </>
    ),
  },
];
export const ProductosTable = ({ productos, loading }) => {
  const dispatch = useDispatch();
  const handleEditarProducto = (producto) => {
    dispatch(productoSetActive(producto));
    dispatch(uiShowForm(true));
  };
  const handleDeleteProducto = (producto) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el producto?",
      producto.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(productoStartDelete(producto));
        }
      }
    );
  };

  const handleEditarListaPrecios = (record) => {
    dispatch(productoSetActive(record));
    dispatch(showModalListaPrecios());
  };
  const handleMenuItemClick = (e, field) => {
    switch (e.key) {
      case "duplicar":
        dispatch(productoStartDuplicate(field?.id));
        break;
      case "change_status":
        dispatch(productoStartChangeStatusEnabled(field?.id));
        break;
      default:
        break;
    }
  };

  return (
    <TableUi
      data={productos}
      columns={columns({
        handleEditarListaPrecios,
        handleEditarProducto,
        handleDeleteProducto,
        handleMenuItemClick,
      })}
      rowKey="id"
      size="small"
      loading={loading}
      rowClassName={(record) =>
        record?.estahabilitado === false ? "red_color" : ""
      }
    />
  );
};
