import React, { useEffect, useRef } from "react";
import { Form } from "antd";
import { DetalleSalidaSerieForm } from "./DetalleSalidaSerieForm";
import { DetalleSalidaLoteForm } from "./DetalleSalidaLoteForm";
import { ModalUi } from "../../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { FormUi } from "../../../../ui/FormUi";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";

const initialValues = { producto: null, cantidad: null, lotes: [], series: [] };
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const DetalleSalidaModal = ({ producto, cantidad, open, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        producto,
        cantidad: cantidad,
        lotes: producto?.lotes?.map((obj) => ({
          ...obj,
          cantidad_ingresada: obj.cantidad_ingresada
            ? obj.cantidad_ingresada
            : null,
        })),
        series: producto?.series?.map((item) => ({
          ...item,
          selected: item.selected ? item.selected : false,
        })),
      });
    }
  }, [open]);

  const handleSave = () => {
    onCancel();
  };
  return (
    <ModalUi
      title="Detalle producto por lote/serie"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      width={{ sm: "40%", md: "60%" }}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormDetalleLoteSerieSalida"
        />,
      ]}
    >
      <FormUi
        name="FormDetalleLoteSerieSalida"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <FormItemHiddenUi name="producto" />
        <FormItemHiddenUi name="cantidad" />
        {producto?.manejalotes && <DetalleSalidaLoteForm cantidad={cantidad} />}
        {producto?.manejaseries && (
          <DetalleSalidaSerieForm cantidad={cantidad} openModal={open} />
        )}
      </FormUi>
    </ModalUi>
  );
};
