import React, { useEffect, useState } from "react";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { RutaProduccionTable } from "./RutaProduccionTable";
import { useDispatch, useSelector } from "react-redux";
import { RutaProduccionScreen } from "./RutaProduccionScreen";
import {
  rutaProduccionStartLoad,
  showFormRutaProduccion,
} from "../../../../actions/produccion";

export const RutaProduccionListScreen = () => {
  const dispatch = useDispatch();
  const { rutasproduccion } = useSelector((state) => state.produccion);
  useEffect(() => {
    dispatch(rutaProduccionStartLoad());
  }, [dispatch]);
  const handleNuevo = () => {
    dispatch(showFormRutaProduccion(true));
  };
  const handleActualizar = () => {
    dispatch(rutaProduccionStartLoad());
  };
  return (
    <PageHeaderUi
      title="Rutas producción"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
          key="Nuevo"
        />,
      ]}
    >
      <RutaProduccionTable data={rutasproduccion} />
    </PageHeaderUi>
  );
};
export const RutaProduccionList = () => {
  const { visibleFormRutaProduccion } = useSelector(
    (state) => state.produccion
  );
  if (visibleFormRutaProduccion) {
    return <RutaProduccionScreen />;
  } else {
    return <RutaProduccionListScreen />;
  }
};
export default RutaProduccionList;
