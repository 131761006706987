import React from "react";
import { SearchFormUi } from "../../ui/SearchFormUi";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { AlmacenesEmpleadoSelect } from "../reusable/AlmacenesEmpleadoSelect";
import { useDispatch, useSelector } from "react-redux";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { comprobanteMovimientoStartLoading } from "../../../actions/inventario";
import { SelectUi } from "../../ui/SelectUi";
import { TipoComprobanteMovimientoSelect } from "./TipoComprobanteMovimientoSelect";
import dayjs from "dayjs";

const initialValues = {
  tipofiltro: "TIPOCOMP",
  tipocomprobantemovimiento: null,
  fechaemision: dayjs(),
  almacen: null,
  comprobante: null,
};
const _tipofiltro = [
  {
    value: "TIPOCOMP",
    label: "TIPO COMPROBANTE",
  },
  {
    value: "FECHAEMI",
    label: "FECHA EMISION",
  },
  {
    value: "ALMACEN",
    label: "ALMACEN",
  },
  {
    value: "COMPROBAN",
    label: "COMPROBANTE",
  },
];
export const MovimientoSearchForm = () => {
  const [form] = Form.useForm();
  const tipofiltro = Form.useWatch("tipofiltro", form);
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const handleSubmit = (value) => {
    const _fechaemision = value?.fechaemision
      ? dayjs(value?.fechaemision).format(DateFormats.backend)
      : null;
    dispatch(
      comprobanteMovimientoStartLoading(
        value?.tipofiltro,
        value?.tipocomprobantemovimiento,
        _fechaemision,
        value?.almacen,
        value?.comprobante
      )
    );
  };
  return (
    <SearchFormUi
      name="FormSearchMovimientos"
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={8} xl={8}>
          <Form.Item name="tipofiltro" label="Buscar por">
            <SelectUi data={_tipofiltro} />
          </Form.Item>
        </Col>
        {tipofiltro === "TIPOCOMP" && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="tipocomprobantemovimiento"
              label="Tipo comprobante"
            >
              <TipoComprobanteMovimientoSelect
                placeholder="[-TODOS-]"
                allowClear
              />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === "ALMACEN" && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item name="almacen" label="Almacén">
              <AlmacenesEmpleadoSelect
                placeholder="[-TODOS-]"
                allowClear
                empleado={activeEmpleado?.id}
              />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === "FECHAEMI" && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="fechaemision"
              label="Fecha emisión"
              rules={[
                {
                  required: tipofiltro === "FECHAEMI" ? true : false,
                  message: "La fecha de emisión es obligatorio",
                },
              ]}
            >
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === "COMPROBAN" && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="comprobante"
              label="Comprobante"
              rules={[
                {
                  required: tipofiltro === "COMPROBAN" ? true : false,
                  message: "El comprobante es obligatorio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={8} md={12} lg={6} xl={6}>
          <Form.Item>
            <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
          </Form.Item>
        </Col>
      </Row>
    </SearchFormUi>
  );
};
