import { Col, Row, Form, Input } from "antd";
import React from "react";
import { DepartamentoSelect } from "../../ubigeos/DepartamentoSelect";
import { ProvinciaSelect } from "../../ubigeos/ProvinciaSelect";
import { DistritoSelect } from "../../ubigeos/DistritoSelect";
import { SwitchUi } from "../../../ui/SwitchUi";

export const ProveedorDireccionTabContent = () => {
  const form = Form.useFormInstance();
  const departamento = Form.useWatch("departamento", form);
  const provincia = Form.useWatch("provincia", form);

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item name="direccion" label="Dirección" className="mb-1">
            <Input placeholder="Ingrese dirección" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item
            label="¿Está activo?"
            name="state"
            className="mb-1"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Departamento"
            name="departamento"
            rules={[
              {
                required: true,
                message: "El departamento de la dirección es obligatorio",
              },
            ]}
          >
            <DepartamentoSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Provincia"
            name="provincia"
            rules={[
              {
                required: true,
                message: "La provincia de la dirección es obligatorio",
              },
            ]}
          >
            <ProvinciaSelect
              departamento={departamento}
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label="Distrito"
            name="ubigeodireccion"
            rules={[
              {
                required: true,
                message: "El distrito de la dirección es obligatorio",
              },
            ]}
          >
            <DistritoSelect
              provincia={provincia}
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="telefono" label="Telefono" className="mb-1">
            <Input placeholder="Ingrese telefono" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="email" label="Correo electrónico" className="mb-1">
            <Input placeholder="Ingrese correo electrónico" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
