import { Flex, Form, Image, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { messageError } from "../../helpers/messageConfig";
import { to_Base64 } from "../../helpers/utils";

const is_valid_image_file = (file, typeAccept) => {
  const isFormatValid = typeAccept.includes(file.type);
  if (!isFormatValid)
    messageError(`Solo puede subir archivos validos ${typeAccept}`);
  return isFormatValid;
};

export const ImageUploadUi2 = ({
  name,
  imageList,
  imageLength = 1,
  typeAccept = ["image/jpeg", "image/jpg", "image/png"],
}) => {
  const form = Form.useFormInstance();
  const { setFieldValue } = form;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState(imageList);

  useEffect(() => {
    setFileList(imageList);
  }, [imageList]);

  const propsUpload = {
    listType: "picture-card",
    accept: typeAccept,
    maxCount: imageLength,

    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFieldValue(name, newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: ({ file, fileList: newFileList }) => {
      if (file?.status !== "removed") {
        const is_valida = is_valid_image_file(file, typeAccept);
        if (is_valida) {
          setFieldValue(name, newFileList);
          setFileList(newFileList);
        }
      }
    },
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await to_Base64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    },
    fileList,
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <Flex gap="middle" justify="center" wrap>
      <Upload {...propsUpload}>
        {fileList?.length >= imageLength ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </Flex>
  );
};
