import React from "react";
import { PageHeaderUi } from "../ui/PageHeaderUi";
import { Col, Row } from "antd";
import { MonedaCard } from "./monedas/MonedaCard";
import { ColorCard } from "./colores/ColorCard";

export const GeneralesList = () => {
  return (
    <PageHeaderUi title="Configuraciones Generales" subTitle="Listado">
      <div style={{ overflow: "hidden" }}>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <MonedaCard />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <ColorCard />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};
