import { Descriptions, Form, Input } from "antd";
import React, { useEffect } from "react";

const fnCalculosTotales = (detallescomprobantepago) => {
  const productoscarrito = detallescomprobantepago || [];
  //Calculos totales
  let SumTotal = 0;
  productoscarrito?.forEach((item) => {
    SumTotal += parseFloat(item.totalventa);
  });
  SumTotal = parseFloat(SumTotal).toFixed(2);
  const SumTotalIGV = parseFloat(0.18 * SumTotal).toFixed(2);
  const SumGravada = parseFloat(SumTotal - SumTotalIGV).toFixed(2);
  return { SumGravada, SumTotalIGV, SumTotal };
};
export const PosCarritoFooter = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const prefijo = Form.useWatch("prefijo", form);
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const { setFieldValue } = form;

  // TODO: Calculo de los totales del comprobante de venta
  useEffect(() => {
    const { SumGravada, SumTotalIGV, SumTotal } =
      fnCalculosTotales(detallecomprobante);
    setFieldValue("montosubtotal", parseFloat(SumGravada));
    setFieldValue("montoigv", parseFloat(SumTotalIGV));
    setFieldValue("montototal", parseFloat(SumTotal));
  }, [detallecomprobante, setFieldValue]);

  return (
    <>
      <div className="pos__carrito__footer">
        <Descriptions column={1} size="small">
          <Descriptions.Item
            label="SUB-TOTAL"
            labelStyle={{
              width: "70%",
              justifyContent: "right",
              fontWeight: "bold",
              alignItems: "center",
            }}
            contentStyle={{ justifyContent: "center", fontWeight: "bold" }}
            style={{ paddingBottom: 2 }}
          >
            <Form.Item name="montosubtotal" style={{ marginBottom: 0 }}>
              <Input
                bordered={false}
                readOnly={false}
                prefix={prefijo || "S/"}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            label="IGV"
            labelStyle={{
              width: "70%",
              justifyContent: "right",
              fontWeight: "bold",
              alignItems: "center",
            }}
            contentStyle={{ justifyContent: "center", fontWeight: "bold" }}
            style={{ paddingBottom: 2 }}
          >
            <Form.Item name="montoigv" style={{ marginBottom: 0 }}>
              <Input
                bordered={false}
                readOnly={false}
                prefix={prefijo || "S/"}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            label="TOTAL"
            labelStyle={{
              width: "70%",
              justifyContent: "right",
              fontWeight: "bold",
              alignItems: "center",
            }}
            contentStyle={{ justifyContent: "center", fontWeight: "bold" }}
            style={{ paddingBottom: 2 }}
          >
            <Form.Item name="montototal" style={{ marginBottom: 0 }}>
              <Input
                bordered={false}
                readOnly={false}
                prefix={prefijo || "S/"}
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};
