import { types } from "../types/typesVenta";

export const productocarritoSetActive = (producto) => ({
  type: types.productocarritoSetActive,
  payload: producto,
});
export const productocarritoClearActive = () => ({
  type: types.productocarritoClearActive,
});
export const productostockSetActive = (producto) => ({
  type: types.productostockSetActive,
  payload: producto,
});
export const productostockClearActive = () => ({
  type: types.productostockClearActive,
});
export const ShowModalMediosPago = (status = true) => ({
  type: types.showModalMediosPago,
  payload: status,
});
export const ShowModalComprobanteResult = (status = true) => ({
  type: types.showModalComprobanteResult,
  payload: status,
});
export const ShowModalStockProductos = (status = true) => ({
  type: types.showModalStock,
  payload: status,
});
export const puntoventaSetResult = (result) => ({
  type: types.puntoventaSetResult,
  payload: result,
});
export const montosMediosPagoLoaded = (montosTotales) => ({
  type: types.montosMediosPagoLoaded,
  payload: montosTotales,
});
export const puntoventaSetCurrentStep = (
  currentStep = { previewsForm: [], current: 0 }
) => ({
  type: types.puntoventaSetCurrentStep,
  payload: currentStep,
});

export const puntoventaLogout = () => ({
  type: types.puntoventaLogout,
});
