import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_estados } from "../../../actions/produccion";

export const EstadoOrdenProduccion = ({ ...props }) => {
  const [estados, setEstados] = useState([]);
  const OnLoadDatos = async () => {
    const datos = await get_estados();
    setEstados(datos);
  };
  useEffect(() => {
    OnLoadDatos();
  }, []);

  return <SelectUi data={estados} {...props} />;
};
