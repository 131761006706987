import React, { useEffect, useState } from "react";
import { getDistritos } from "../../../helpers/loadGenerales";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const DistritoSelect = ({ provincia, ...props }) => {
  const [distritos, setDistritos] = useState([]);
  const OnLoadDistritos = async (provincia) => {
    const datos = await getDistritos(provincia);
    setDistritos(datos);
  };
  useEffect(() => {
    OnLoadDistritos(provincia);
  }, [provincia]);
  return <SearchBasicSelectUI data={distritos} {...props} />;
};
