import React, { useEffect, useState } from "react";
import { TableUi } from "../../../ui/TableUi";
import { get_comprobantespago_by_aperturacaja } from "../../../../actions/caja";
import dayjs from "dayjs";

const columns = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Fecha-Hora",
    dataIndex: "fechahoracreacion",
    key: "fechahoracreacion",
    render: (text, record, index) => (
      <>{dayjs(text).format("DD/MM/YYYY, h:mm:ss A")}</>
    ),
  },
  {
    title: "Tipo comprobante",
    dataIndex: "tipocomprobante_nombre",
    key: "tipocomprobante_nombre",
  },
  {
    title: "Comprobante",
    dataIndex: "comprobante",
    key: "comprobante",
  },
  {
    title: "Moneda",
    dataIndex: "moneda_prefijo",
    key: "moneda_prefijo",
  },
  {
    title: "SubTotal",
    dataIndex: "montosubtotal",
    key: "montosubtotal",
  },
  {
    title: "IGV",
    dataIndex: "montoigvtotal",
    key: "montoigvtotal",
  },
  {
    title: "Total",
    dataIndex: "montototal",
    key: "montototal",
  },
  {
    title: "Forma pago",
    dataIndex: "formapago_nombre",
    key: "formapago_nombre",
  },
];

export const ComprobantesPagoTable = ({ aperturacaja }) => {
  const [comprobantespago, setComprobantespago] = useState([]);
  useEffect(() => {
    const OnLoadComprobantesPago = async (aperturacaja) => {
      const datos = await get_comprobantespago_by_aperturacaja(aperturacaja);
      setComprobantespago(datos);
    };
    OnLoadComprobantesPago(aperturacaja);
    return () => {
      setComprobantespago([]);
    };
  }, []);

  return (
    <TableUi
      rowKey="id"
      size="small"
      columns={columns}
      data={comprobantespago}
    />
  );
};
