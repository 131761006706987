import { message, Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PosCarritoContentItem } from "./PosCarritoContentItem";
import { productocarritoClearActive } from "../../../../actions/venta";

export const PosCarritoContent = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  let { activeProductoCarrito } = useSelector((state) => state.venta);
  const dispatch = useDispatch();
  useEffect(() => {
    let productoscarrito = detallecomprobante || [];
    if (activeProductoCarrito) {
      const bExiste = productoscarrito?.find(
        (item) => item?.id === activeProductoCarrito?.id
      );
      if (!bExiste) {
        productoscarrito = [...productoscarrito, activeProductoCarrito];
        setFieldValue("detallecomprobante", productoscarrito);
        message.success("Producto agregado", 1.5);
        dispatch(productocarritoClearActive());
      }
    }
  }, [activeProductoCarrito]);
  return (
    <>
      <Form.List
        name="detallecomprobante"
        style={{ marginBottom: 0 }}
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error(
                    "Ingrese al menos 1 item en el detalle del comprobante de pago"
                  )
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field) => (
                <PosCarritoContentItem
                  key={field.key}
                  form={form}
                  field={field}
                  handleAdd={add}
                  handleRemove={remove}
                />
              ))}
              <Form.Item>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
