import { Modal } from "antd";
import React from "react";

export const ModalUi = ({ children, ...props }) => {
  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      aria-modal="true"
      {...props}
    >
      {children}
    </Modal>
  );
};
