import React from "react";
import { TableUi } from "../../ui/TableUi";
import { Dropdown, Space, Tag, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { useDispatch } from "react-redux";
import {
  listaprecioSetActive,
  listaprecioStartDelete,
} from "../../../actions/listaprecio";
import { uiShowForm } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ActionsDropdownUi } from "../../ui/ActionsDropdownUi";

const itemsOptionsTable = [
  {
    label: "Editar",
    key: "editar",
    icon: <EditOutlined />,
  },
  {
    label: "Duplicar",
    key: "duplicar",
    icon: <CopyOutlined />,
  },
  {
    label: "Eliminar",
    key: "eliminar",
    icon: <DeleteOutlined />,
  },
];
const columns = (acciones) => {
  const { handleMenuItemClick } = acciones;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Nombre Emisión",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Por defecto",
      dataIndex: "pordefecto",
      key: "pordefecto",
      render: (text, record) => (
        <Tag
          icon={text && <CheckCircleOutlined />}
          color={text ? "success" : "default"}
        >
          {text ? "Si" : "No"}
        </Tag>
      ),
    },

    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Space size="middle">
          <ActionsDropdownUi
            menu={{
              items: itemsOptionsTable,
              onClick: (e) => handleMenuItemClick(e, record),
            }}
          />
        </Space>
      ),
    },
  ];
};
export const ListaPrecioTable = ({ listaprecios }) => {
  const dispatch = useDispatch();
  const handleEditar = (item) => {
    dispatch(listaprecioSetActive(item));
    dispatch(uiShowForm());
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la lista de precios?",
      item.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(listaprecioStartDelete(item));
        }
      }
    );
  };
  const handleMenuItemClick = (e, record) => {
    switch (e.key) {
      case "editar":
        handleEditar(record);
        break;
      case "eliminar":
        handleEliminar(record);
        break;
      default:
        break;
    }
  };
  return (
    <TableUi
      columns={columns({
        handleMenuItemClick,
      })}
      data={listaprecios}
      rowKey="id"
      size="small"
    />
  );
};
