import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { Space } from "antd";
import { useDispatch } from "react-redux";
import { showDeleteConfirm } from "./../../../../helpers/confirmModal";
import {
  maquinaSetActive,
  maquinaStartDelete,
} from "../../../../actions/produccion";

const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Fecha Adquisición",
      dataIndex: "fechaadquisicion",
      key: "fechaadquisicion",
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <Space wrap>
          <ButtonUi
            key="Editar"
            typeUi={TipoButtonConstants.EditarTable}
            onClick={() => handleEditar(record)}
          />
          <ButtonUi
            key="Eliminar"
            typeUi={TipoButtonConstants.EliminarTable}
            onClick={() => handleEliminar(record)}
          />
        </Space>
      ),
    },
  ];
};
export const MaquinaTable = ({ data, onOpenModal }) => {
  const dispatch = useDispatch();
  const handleEditar = (record) => {
    dispatch(maquinaSetActive(record));
    onOpenModal();
  };
  const handleEliminar = (record) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la maquina?",
      record.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(maquinaStartDelete(record));
        }
      }
    );
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEditar, handleEliminar })}
      rowKey="id"
      size="small"
    />
  );
};
