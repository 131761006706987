import { Col, DatePicker, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { SearchOutlined } from "@ant-design/icons";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { FormUi } from "../../ui/FormUi";
import { useDispatch } from "react-redux";
import { comprobantecompraStartLoad } from "../../../actions/compra";
const initialValues = {
  tipofiltro: "FEMI",
  proveedor: null,
  fechaemision: dayjs(),
  fechavencimiento: dayjs(),
  comprobante: null,
};
const OptionsBusqueda = [
  { value: "FEMI", label: "Fecha Emisión" },
  { value: "FEVE", label: "Fecha Vencimiento" },
  { value: "PROV", label: "Proveedor" },
  { value: "COCO", label: "Comprobante" },
];
export const ComprobanteCompraSearchList = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const tipofiltro = Form.useWatch("tipofiltro", form);

  useEffect(() => {
    dispatch(comprobantecompraStartLoad());
  }, []);

  const handleSubmit = (values) => {
    const {
      tipofiltro,
      fechaemision,
      fechavencimiento,
      proveedor,
      comprobante,
    } = values;
    dispatch(
      comprobantecompraStartLoad(
        tipofiltro,
        dayjs(fechaemision).format(DateFormats.backend),
        dayjs(fechavencimiento).format(DateFormats.backend),
        proveedor,
        comprobante
      )
    );
  };
  return (
    <FormUi
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      form={form}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item name="tipofiltro" label="Filtrar por">
            <SelectUi placeholder="Filtrar por" data={OptionsBusqueda} />
          </Form.Item>
        </Col>
        {tipofiltro === "PROV" && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="proveedor">
              <Input
                placeholder="Ingrese ruc o nombre de proveedor"
                allowClear
                addonAfter={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === "FEMI" && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="fechaemision">
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === "FEVE" && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="fechavencimiento">
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === "COCO" && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="comprobante">
              <Input
                placeholder="Ingrese comprobante"
                allowClear
                addonAfter={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi htmlType="submit" typeUi={TipoButtonConstants.Filtrar} />
        </Col>
      </Row>
    </FormUi>
  );
};
