import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { DetalleLoteForm } from "./DetalleLoteForm";
import { Form } from "antd";
import { FormUi } from "../../../ui/FormUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
const initialValues = {
  lotes: [],
};
export const DetalleLoteModal = ({ open, lotes, onCancelModal, cantidad }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });
  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        lotes: lotes?.map((obj) => ({
          ...obj,
          cantidad_ingresada: obj.cantidad_ingresada
            ? obj.cantidad_ingresada
            : null,
        })),
      });
    }
  }, [open]);
  const handleSave = () => {
    onCancelModal();
  };
  return (
    <ModalUi
      title="Detalle producto por lote"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancelModal}
      width="70%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormLote"
        />,
      ]}
    >
      <FormUi
        name="FormLote"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <DetalleLoteForm cantidad={cantidad} />
      </FormUi>
    </ModalUi>
  );
};
