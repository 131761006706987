import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import React from "react";

export const SwitchUi = ({ ...props }) => {
  return (
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      {...props}
    />
  );
};
