import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Upload } from "antd";
import React, { useState } from "react";

const is_valid_file = (file) => {
  const file_type = file.name.split(".")[1];
  const isPEM = file_type === "pem" || file.type === "PEM";

  if (!isPEM) {
    message.error("Solo puede subir archivos tipo PEM!");
  }
  return isPEM;
};
export const FileUploadUi = ({ name, filelist, ...props }) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue, validateFields } = form;
  const [fileList, setFileList] = useState(filelist);
  const propsUpload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFieldValue(name, newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: ({ file, fileList: newFileList }) => {
      if (file?.status !== "removed") {
        const is_valida = is_valid_file(file);
        if (is_valida) {
          setFileList(newFileList);
          setFieldValue(name, newFileList);
          validateFields([name]);
        }
      }
    },
    fileList,
  };
  return (
    <Upload {...props} {...propsUpload}>
      <Button icon={<UploadOutlined />}>Subir archivo</Button>
    </Upload>
  );
};
