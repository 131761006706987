import React from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { Col, Row } from "antd";
import { TableUi } from "../../../ui/TableUi";
import { useSelector } from "react-redux";

const columns_serie = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Serie",
    dataIndex: "numero_serie",
    key: "numero_serie",
  },
  {
    title: "Fecha fabricación",
    dataIndex: "fecha_fabricacion",
    key: "fecha_fabricacion",
  },
];
const columns_lote = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Lote",
    dataIndex: "numero_lote",
    key: "numero_lote",
  },
  {
    title: "Stock",
    dataIndex: "stockactual",
    key: "stockactual",
    align: "center",
    render: (text, record, index) => <> {parseFloat(text).toFixed(2)}</>,
  },
  {
    title: "Fecha fabricación",
    dataIndex: "fecha_fabricacion",
    key: "fecha_fabricacion",
    align: "center",
  },
  {
    title: "Fecha vencimiento",
    dataIndex: "fecha_vencimiento",
    key: "fecha_vencimiento",
    align: "center",
  },
  {
    title: "Cant. Ingresada",
    dataIndex: "cantidad",
    key: "cantidad",
    align: "center",
    render: (text, record, index) => <> {parseFloat(text).toFixed(2)}</>,
  },
];
export const DetalleLoteSeriePreviewModal = ({ open, onCancelModal }) => {
  const { activeDetalleComprobanteMovimiento } = useSelector(
    (state) => state.inventario
  );
  return (
    <ModalUi
      title={
        activeDetalleComprobanteMovimiento?.manejaseries
          ? "Series"
          : activeDetalleComprobanteMovimiento?.manejalotes
          ? "Lotes"
          : ""
      }
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancelModal}
      width="40%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
      ]}
      forceRender
    >
      {activeDetalleComprobanteMovimiento?.manejaseries && (
        <Row>
          <Col span="20">
            <TableUi
              data={activeDetalleComprobanteMovimiento?.series}
              columns={columns_serie}
              size="small"
              key="id"
            />
          </Col>
        </Row>
      )}
      {activeDetalleComprobanteMovimiento?.manejalotes && (
        <Row>
          <Col span="24">
            <TableUi
              data={activeDetalleComprobanteMovimiento?.lotes}
              columns={columns_lote}
              size="small"
              key="id"
            />
          </Col>
        </Row>
      )}
    </ModalUi>
  );
};
