import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { get_mediospago } from "../../../actions/compra";

export const MedioPagoSelect = ({ condicionpago, ...props }) => {
  const [mediospago, setMediospago] = useState([]);
  const OnLoadData = async (condicionpago) => {
    const datos = await get_mediospago(condicionpago);
    setMediospago(datos);
  };
  useEffect(() => {
    OnLoadData(condicionpago);
  }, [condicionpago]);

  return <Select options={mediospago} {...props} />;
};
