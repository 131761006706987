import { Table } from "antd";
import React from "react";

export const TableUi = ({ columns, data, ...props }) => {
  return (
    <Table
      className="table-ui"
      columns={columns}
      dataSource={data}
      bordered
      scroll={{ x: 550 }}
      {...props}
    />
  );
};
