import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { get_inventory_item_by_store_and_name_or_sku } from "../../../actions/inventario";

export const ItemInventarioDropdown = () => {
  const form = Form.useFormInstance();
  const almacen = Form.useWatch("almacen", form);
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await get_inventory_item_by_store_and_name_or_sku(
        almacen,
        value
      );
      const options = data.map((item) => ({
        label: item?.label,
        key: item?.value,
      }));
      setItems(options);
      setItemsData(data);
    }
  };
  const onClick = ({ key }) => {
    const itemSelect = itemsData?.find((x) => x.value === parseInt(key));
    form.setFieldsValue({
      item_nombre: itemSelect?.label,
      inventario: key,
      manejalotes: itemSelect?.manejalotes,
      manejaseries: itemSelect?.manejaseries,
      series: itemSelect?.series,
      lotes: itemSelect?.lotes,
      unidadmedida: itemSelect?.unidadmedida,
      unidadmedida_nombre: itemSelect?.unidadmedida_nombre,
    });
  };

  const handleChangeSearch = () => {
    form.setFieldsValue({
      inventario: null,
      manejalotes: null,
      manejaseries: null,
      series: null,
      lotes: null,
      unidadmedida: null,
      unidadmedida_nombre: null,
    });
  };
  return (
    <Dropdown menu={{ items, onClick }}>
      <Form.Item name="item_nombre">
        <Input.Search
          placeholder="Buscar producto / servicio"
          allowClear
          enterButton
          onSearch={handleSearch}
          onChange={handleChangeSearch}
        />
      </Form.Item>
    </Dropdown>
  );
};
