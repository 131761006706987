import { Col, Divider, Form, InputNumber, Row } from "antd";

import React, { useState } from "react";
import { AlmacenSelect } from "../../reusable/AlmacenSelect";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { DetalleSerieModal } from "./DetalleSerieModal";
import { DetalleLoteModal } from "./DetalleLoteModal";
import { UnidadMedidaSelect } from "../../../generales/unidadesmedida/UnidadMedidaSelect";
import { ProductoSearchSelect } from "../../reusable/ProductoSearchSelect";

export const ItemDetalleForm = () => {
  const form = Form.useFormInstance();
  const cantidad = Form.useWatch("cantidad", form);
  const manejalotes = Form.useWatch("manejalotes", form);
  const manejaseries = Form.useWatch("manejaseries", form);
  const almacen = Form.useWatch("almacen", form);
  const onChangeAlmacen = (e, field) => {
    form.setFieldsValue({ almacen: e, almacen_nombre: field.label });
  };
  const [open, setOpen] = useState(false);
  const [openLoteModal, setOpenLoteModal] = useState(false);

  const hideModal = () => {
    setOpen(false);
  };
  const hideLoteModal = () => {
    setOpenLoteModal(false);
  };
  const handleShowModal = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleShowLoteModal = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        setOpenLoteModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="almacen_nombre" />
      <FormItemHiddenUi name="unidadmedida_nombre" />
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <FormItemHiddenUi name="inventario" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="almacen"
            label="Almacén"
            rules={[{ required: true, message: "El almacén es obligatorio" }]}
          >
            <AlmacenSelect
              placeholder="[-Selecione-]"
              onChange={onChangeAlmacen}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item name="item_nombre" label="Producto">
            <ProductoSearchSelect almacen={almacen} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item label=" ">
            {manejaseries && (
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar series"
                onClick={handleShowModal}
              />
            )}
            {manejalotes && (
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar lotes"
                onClick={handleShowLoteModal}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: "5px 0" }} />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item name="stockactual" label="Stock Actual">
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              placeholder="[-Seleccione-]"
              readOnly
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              {
                type: "number",
                min: 0.01,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} precision={2} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            name="unidadmedida"
            label="Unidad medida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect
              placeholder="[-Seleccione-]"
              // onChange={onChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
      </Row>

      {manejaseries && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="series"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (manejaseries) {
                      const cantSeleccionada = value?.filter(
                        (item) => item.selected === true
                      ).length;
                      if (cantSeleccionada !== getFieldValue("cantidad"))
                        return Promise.reject(
                          new Error(
                            "Las series deben ser igual a la cantidad ingresada"
                          )
                        );
                    }
                  },
                }),
              ]}
            >
              {(fields, options, { errors }) => (
                <>
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      {manejalotes && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="lotes"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (manejalotes) {
                      const suma = value?.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr?.cantidad_ingresada),
                        0
                      );
                      if (suma !== getFieldValue("cantidad"))
                        return Promise.reject(
                          new Error(
                            "La cantidad de los lotes deben ser igual a la cantidad ingresada"
                          )
                        );
                    }
                  },
                }),
              ]}
            >
              {(fields, options, { errors }) => (
                <>
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      <DetalleSerieModal
        open={open}
        onCancelModal={hideModal}
        cantidad={cantidad}
        series={form.getFieldValue("series")}
      />
      <DetalleLoteModal
        open={openLoteModal}
        onCancelModal={hideLoteModal}
        cantidad={cantidad}
        lotes={form.getFieldValue("lotes")}
      />
    </>
  );
};
