import React, { useEffect, useState } from "react";
import { getTurnos } from "../../helpers/loadConfiguraciones";
import { SelectUi } from "../ui/SelectUi";

export const TurnoSelect = ({ ...props }) => {
  const [turnos, setTurnos] = useState([]);
  const OnLoadTurnos = async () => {
    const datos = await getTurnos();
    setTurnos(datos);
  };
  useEffect(() => {
    OnLoadTurnos();
  }, []);

  return <SelectUi data={turnos} {...props} />;
};
