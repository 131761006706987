import React, { useEffect, useState } from "react";
import { get_soap_tipos } from "../../../../actions/empresa";
import { SelectUi } from "../../../ui/SelectUi";

export const SoapTiposSelect = ({ ...props }) => {
  const [soaptypes, setSoaptypes] = useState([]);
  const OnLoadData = async () => {
    const datos = await get_soap_tipos();
    setSoaptypes(datos);
  };

  useEffect(() => {
    OnLoadData();
  }, []);
  return <SelectUi data={soaptypes} {...props} />;
};
