import { message } from "antd";
import { axiosConToken } from "./axios";
import { validaMessagesErrors } from "./validates";

const getTurnos = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("configuracion/getTurnos");
    datos = resp.data;
  } catch (error) {
    console.log(error.response.data);
    message.error(error.response.data);
  }
  return datos;
};
const getTiposDocumentoIdentidad = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "configuracion/getTiposDocumentoIdentidad"
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
const getTiposClientes = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("configuracion/getTiposClientes");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export { getTurnos, getTiposDocumentoIdentidad, getTiposClientes };
