import { Col, Row, Form, Input, InputNumber, Space, Tooltip } from "antd";
import React from "react";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { CardUi } from "../../../ui/CardUi";
import { useDispatch } from "react-redux";
import { showFormRemoveMovements } from "../../../../actions/inventario";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { UnidadMedidaSelect } from "../../../generales/unidadesmedida/UnidadMedidaSelect";

export const RemoveForm = ({ onOpenModal }) => {
  const form = Form.useFormInstance();

  const dispatch = useDispatch();
  const manejaseries = Form.useWatch("manejaseries", form);
  const manejalotes = Form.useWatch("manejalotes", form);

  const handleOpenModalSeries = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOpenModalLotes = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancel = () => {
    dispatch(showFormRemoveMovements(false));
  };
  return (
    <CardUi
      title="Remover item del almacén"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormMovimietoRetirarAlmacen"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="inventario" />
      <FormItemHiddenUi name="almacenorigen" />
      <FormItemHiddenUi name="almacendestino" />
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="nombreproducto" label="Producto">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item name="stockactual" label="Stock Actual">
            <InputNumber
              placeholder="Ingrese cantidad"
              readOnly
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="nombrealmacen"
            label="Almacén"
            rules={[{ required: true, message: "Seleccione el almacén" }]}
          >
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              {
                type: "number",
                min: 0.01,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese cantidad"
              style={{ width: "100%" }}
              precision={2}
              min={0.01}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <Form.Item
            name="unidadmedida"
            label="Unidad medida"
            rules={[
              { required: true, message: "Seleccione la unidad de medida" },
            ]}
          >
            <UnidadMedidaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        {manejaseries && (
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar series"
                className="ant-btn-info"
                onClick={handleOpenModalSeries}
              />
            </Form.Item>
          </Col>
        )}
        {manejalotes && (
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item label=" ">
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar lotes"
                className="ant-btn-info"
                onClick={handleOpenModalLotes}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Form.List
            name="series"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (manejaseries) {
                    const cantSeleccionada = getFieldValue("series")?.filter(
                      (item) => item.selected === true
                    ).length;
                    if (cantSeleccionada !== getFieldValue("cantidad"))
                      return Promise.reject(
                        new Error(
                          "La cantidad ingresada debe ser igual a la cantidad de despacho"
                        )
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, options, { errors }) => (
              <>
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={16}>
          <Form.List
            name="lotes"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (manejalotes) {
                    const suma = value?.reduce(
                      (prev, curr) =>
                        Number(prev) + Number(curr?.cantidad_ingresada),
                      0
                    );
                    if (suma !== getFieldValue("cantidad"))
                      return Promise.reject(
                        new Error(
                          "La cantidad ingresada debe ser igual a la cantidad de despacho"
                        )
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, options, { errors }) => (
              <>
                <Form.Item className="ui-errorlist">
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </CardUi>
  );
};
