import React, { useState } from "react";
import { CardUi } from "../../../ui/CardUi";
import { Col, Divider, Form, Input, Row, Space, Switch, Tooltip } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  InventarioConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { AlmacenEmpleadoSelect } from "../../reusable/AlmacenEmpleadoSelect";
import { useSelector } from "react-redux";
import { MovimientoEntradaFormTable } from "./MovimientoEntradaFormTable";
import { ItemDetalleModal } from "./modal/ItemDetalleModal";
import { TipoMovimientoSelect } from "../../reusable/TipoMovimientoSelect";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";

export const MovimientoEntradaForm = ({ HandleCloseForm }) => {
  const form = Form.useFormInstance();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const detallescomprobantemovimiento = Form.useWatch(
    "detallescomprobantemovimiento",
    form
  );
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <>
      <CardUi
        title="Movimiento Entrada"
        size="small"
        extra={
          <Space wrap>
            <ButtonUi typeUi={TipoButtonConstants.Guardar} htmlType="submit" />
            <Tooltip title="Salir">
              <ButtonUi
                typeUi={TipoButtonConstants.Salir}
                onClick={HandleCloseForm}
              />
            </Tooltip>
          </Space>
        }
      >
        <FormItemHiddenUi name="id" />
        <FormItemHiddenUi name="comprobantecompra" />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              name="almacenorigen"
              label="Almacén origen"
              rules={[
                { required: true, message: "Seleccione almacen de origen" },
              ]}
            >
              <AlmacenEmpleadoSelect
                empleado={activeEmpleado?.id}
                placeholder="Seleccione almacén origen"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              name="tipomovimiento"
              label="Tipo movimiento"
              rules={[
                {
                  required: true,
                  message: "Seleccione tipo movimiento entrada",
                },
              ]}
            >
              <TipoMovimientoSelect
                tipooperacion={InventarioConstants.TipoOperacionEntrada}
                placeholder="[-Seleccione-]"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Space>
              <Form.Item name="tieneobservacion" valuePropName="checked">
                <Switch />
              </Form.Item>
              Añadir observación
            </Space>
          </Col>
        </Row>
        {tieneobservacion && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item name="observacion" className="mb-1">
                <Input.TextArea
                  rows={2}
                  placeholder="Ingrese Observación"
                  maxLength={150}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Divider orientation="left" style={{ margin: "6px 0px" }}>
          <b>Detalle de items de inventario</b>
        </Divider>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="detallescomprobantemovimiento"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (value < 1) {
                      return Promise.reject(
                        new Error(
                          "Ingrese al menos un item en el detalle de movimiento"
                        )
                      );
                    }
                    const notienelotes = getFieldValue(
                      "detallescomprobantemovimiento"
                    ).some((x) => x.manejalotes && !x.lotes);
                    const notieneseries = getFieldValue(
                      "detallescomprobantemovimiento"
                    ).some((x) => x.manejaseries && !x.series);
                    if (notienelotes) {
                      return Promise.reject(
                        new Error(
                          "Falta registrar los lotes para un item en el detalle de movimiento"
                        )
                      );
                    }
                    if (notieneseries) {
                      return Promise.reject(
                        new Error(
                          "Falta registrar los lotes para un item en el detalle de movimiento"
                        )
                      );
                    }
                    // if()
                  },
                }),
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <MovimientoEntradaFormTable
                    detallecomprobantemovimiento={detallescomprobantemovimiento}
                    handleDelete={remove}
                    OpenModal={handleOpenModal}
                    title={() => (
                      <ButtonUi
                        typeUi={TipoButtonConstants.AgregarTable}
                        onClick={() => handleOpenModal()}
                      />
                    )}
                  />
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </CardUi>
      <ItemDetalleModal open={open} onCancelModal={handleCloseModal} />
    </>
  );
};
