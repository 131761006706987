import React, { useEffect, useState } from "react";
import { getAlmacenesEmpleadoSelect } from "../../../helpers/loadVentas";
import { SelectUi } from "../../ui/SelectUi";
import { Form } from "antd";

export const AlmacenEmpleadoSelect = ({ empleado, ...props }) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);

  const { setFieldValue } = form;
  const [almacenes, setAlmacenes] = useState([]);

  useEffect(() => {
    const OnLoadAlmacenes = async (empleado) => {
      const datos = await getAlmacenesEmpleadoSelect(empleado);
      setAlmacenes(datos);
      setFieldValue("cantidad_almacenes", datos.length);
      if (datos.length === 1) {
        setFieldValue("establecimiento", datos[0]?.value);
      }
    };
    OnLoadAlmacenes(empleado);
  }, [empleado, props?.name, setFieldValue]);

  return <SelectUi data={almacenes} {...props} />;
};
