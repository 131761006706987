import { axiosConToken } from "./axios";
import { message } from "antd";

const getUnidadesMedidaSelectOptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("unidadesmedida/");
    datos = resp.data;
  } catch (error) {
    console.log(error);
  }
  return datos;
};
const getCategoriasSelectOptions = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("categoriasproducto/");
    datos = resp.data;
  } catch (error) {
    console.log(error);
  }
  return datos;
};
const getSubCategoriasSelectOptions = async (CategoriaId) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `subcategoriasproducto/getSubCategoriasByCategoryId/${CategoriaId}`
    );
    datos = resp.data;
  } catch (error) {
    console.log(error);
  }
  return datos;
};
const getMarcasProducto = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("marcasproducto/");
    datos = resp.data;
  } catch (error) {
    console.log(error);
  }
  return datos;
};
const getTipoLocales = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("locales/getTiposLocalesSelect");
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
const getLocales = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("locales/getLocalesSelect");
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
const getMonedas = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("monedas/");
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
const getDepartamentos = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("ubigeos/getDepartamentosSelect");
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
const getProvincias = async (departamento) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("ubigeos/getProvinciasSelect", {
      params: { departamento },
    });
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
const getDistritos = async (provincia) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("ubigeos/getDistritosSelect", {
      params: { provincia },
    });
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
const getDatosPersona = async (tipodocumento, nrodocumento) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("persona/getPersonaSearch", {
      params: { tipodocumento, nrodocumento },
    });
    datos = resp.data;
  } catch (error) {
    message.error(error);
  }
  return datos;
};
export {
  getUnidadesMedidaSelectOptions,
  getCategoriasSelectOptions,
  getSubCategoriasSelectOptions,
  getMarcasProducto,
  getTipoLocales,
  getLocales,
  getMonedas,
  getDepartamentos,
  getProvincias,
  getDistritos,
  getDatosPersona,
};
