import React, { useEffect, useState } from "react";
import { get_tipocomprobantemovimiento_not_traslado } from "../../../actions/inventario";
import { SelectUi } from "../../ui/SelectUi";

export const TipoComprobanteMovimientoSelect = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_tipocomprobantemovimiento_not_traslado();
      setOptions(datos);
    };
    OnLoad();
  }, []);

  return <SelectUi data={options} {...props} />;
};
