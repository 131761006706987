import { Col, DatePicker, Form, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NotasVentaStartLoad } from "../../../../actions/comprobante";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
const { Option } = Select;

const initialValues = {
  tipofiltro: "1",
  fechaemision: dayjs(),
  cliente: "",
};
// const validationSchema = Yup.object().shape({
//   tipofiltro: Yup.string().required("El tipo de filtro es obligatorio"),
//   fechaemision: Yup.string().when("tipofiltro", {
//     is: "1",
//     then: (schema) => schema.required("La Fecha de emisión es obligatorio"),
//   }),
//   cliente: Yup.string().when("tipofiltro", {
//     is: "2",
//     then: (schema) => schema.required("El cliente es obligatorio"),
//   }),
// });
export const NotaVentaSeachFormTable = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    const { tipofiltro, fechaemision, cliente } = values;
    const _fechaemision = dayjs(fechaemision).format("YYYY-MM-DD");
    dispatch(NotasVentaStartLoad(tipofiltro, _fechaemision, cliente));
  };
  useEffect(() => {
    dispatch(NotasVentaStartLoad("", "", ""));
  }, [dispatch]);

  return (
    <Form
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      form={form}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item name="tipofiltro" label="Filtrar por">
            <Select name="tipofiltro" placeholder="Filtrar por">
              <Option key="1" value="1">
                Fecha de Emisión
              </Option>
              <Option key="2" value="2">
                Cliente
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <Form.Item name="fechaemision">
            <DatePicker name="fechaemision" format={"DD-MM-YYYY"} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
    </Form>
  );
};
