export const types = {
  cotizacionesLoaded: "[cotizacion] Cotizaciones Loaded",
  cotizacionShowModalItemDetalle:
    "[cotizacion] Show or Close Modal Item Cotizacion Detalle",
  cotizacionSetActive: "[cotizacion] cotizacion Set Active",
  cotizacionClearActive: "[cotizacion] cotizacion Clear Active",
  cotizacionAdded: "[cotizacion] cotizacion added",
  cotizacionUpdated: "[cotizacion] cotizacion updated",
  cotizacionDeleted: "[cotizacion] cotizacion deleted",
  cotizacionLogout: "[cotizacion] cotizacion logout",
};
