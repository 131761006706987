import { types } from "../types/typesGuiaRemision";

const initialState = {
  guiasRemisiones: [],
  activeGuiaRemision: null,
  showModalProductos: false,
  visibleForm: false,
  visibleModal: false,
  almacen: null,
  itemDetalleActive: null,
};
export const guiaremisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.guiaremisionesLoaded:
      return { ...state, guiasRemisiones: action.payload };
    case types.guiaremisionAdded:
      return {
        ...state,
        guiasRemisiones: [action.payload, ...state.guiasRemisiones],
      };
    case types.guiaremisionSetActive:
      return {
        ...state,
        activeGuiaRemision: action.payload,
      };
    case types.guiaremisionClearActive:
      return {
        ...state,
        activeGuiaRemision: null,
        almacen: null,
      };
    case types.guiaremisionUpdated:
      return {
        ...state,
        guiasRemisiones: state.guiasRemisiones.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.guiaremisionDeleted:
      return {
        ...state,
        guiasRemisiones: state.guiasRemisiones.filter(
          (e) => e.id !== action.payload.id
        ),
        activeGuiaRemision: null,
      };
    case types.guiaremisionShowForm:
      return { ...state, visibleForm: action.payload };
    case types.guiaremisionShowModal:
      return { ...state, visibleModal: action.payload };
    case types.guiaremisionSetAlmacen:
      return { ...state, almacen: action.payload };
    // case types.guiaremisionShowModalComprobantes:
    //   return {
    //     ...state,
    //     showModalProductos: action.payload,
    //   };
    case types.guiaremisionLogout:
      return { ...initialState };
    default:
      return state;
  }
};
