import React, { useEffect, useState } from "react";
import { SelectUi } from "../../../../ui/SelectUi";
import { Avatar, Col, Row } from "antd";

export const DetalleRutaProduccionSelect = ({
  detallerutaproduccion,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const options = detallerutaproduccion?.map((item) => ({
      value: item.id,
      label: (
        <Row>
          <Col flex={4}>{item.tarea_nombre}</Col>
          <Col flex={1}>
            {item.es_terciarizado ? (
              <Avatar
                size="small"
                style={{
                  backgroundColor: "#faad14",
                }}
              >
                T
              </Avatar>
            ) : (
              <Avatar
                size="small"
                style={{
                  backgroundColor: "#87d068",
                }}
              >
                P
              </Avatar>
            )}
          </Col>
        </Row>
      ),
      es_terciarizado: item.es_terciarizado,
      tarea_nombre: item.tarea_nombre,
    }));
    setOptions(options);
  }, [detallerutaproduccion]);

  return <SelectUi data={options} {...props} />;
};
