import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { Space, Tag } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch } from "react-redux";
import {
  movimientoCajaSetActive,
  movimientoCajaStartDelete,
} from "../../../../actions/caja";
import { showDeleteConfirm } from "../../../../helpers/confirmModal";

const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Tipo documento",
      dataIndex: "tipodocumentoidentidad_nombre",
      key: "tipodocumentoidentidad_nombre",
    },
    {
      title: "Número documento",
      dataIndex: "nrodocumentoidentidad",
      key: "nrodocumentoidentidad",
    },
    {
      title: "Nombre acreedor",
      dataIndex: "nombreacreedor",
      key: "nombreacreedor",
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
    },
    {
      title: "Glosa - Descripción",
      dataIndex: "glosa",
      key: "glosa",
    },
    {
      title: "Categoria",
      dataIndex: "naturalezapago_nombre",
      key: "naturalezapago_nombre",
    },
    {
      title: "Tipo movimiento",
      dataIndex: "tipomovimiento_nombre",
      key: "tipomovimiento_nombre",
      render: (text, record) => {
        return record?.tipomovimiento === "IG" ? (
          <Tag color="blue"> {text} </Tag>
        ) : (
          <Tag color="red"> {text} </Tag>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.EditarTable}
            displayText={false}
            onClick={() => handleEditar(record)}
          />
          <ButtonUi
            typeUi={TipoButtonConstants.EliminarTable}
            displayText={false}
            onClick={() => handleEliminar(record)}
          />
          {/* <Button
            type="default"
            onClick={() => handleReporteArqueoCaja(record)}
          >
            <FileExcelOutlined style={{ color: "#65B741" }} />
            Reporte arqueo caja
          </Button> */}
        </Space>
      ),
    },
  ];
};

export const MovimientosCajaTable = ({ handleOpen, movimientoscaja }) => {
  const dispatch = useDispatch();
  const handleEditar = (data) => {
    dispatch(movimientoCajaSetActive(data));
    handleOpen();
  };
  const handleEliminar = (data) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el movimiento de caja?",
      data.glosa,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(movimientoCajaStartDelete(data));
        }
      }
    );
  };
  return (
    <TableUi
      columns={columns({
        handleEditar,
        handleEliminar,
      })}
      data={movimientoscaja}
      rowKey="id"
      size="small"
    />
  );
};
