import React, { useEffect, useState } from "react";
import { getClientes } from "../../../helpers/loadVentas";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const ClienteSelect = ({ name, ...props }) => {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const OnLoadClientes = async () => {
      const datos = await getClientes();
      setClientes(datos);
    };
    OnLoadClientes();
  }, []);

  return <SearchBasicSelectUI data={clientes} {...props} />;
};
