import { SearchOutlined } from "@ant-design/icons";
import { Col, DatePicker, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { cotizacionesStartLoad } from "../../../actions/cotizacion";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { SelectUi } from "../../ui/SelectUi";
import { FormUi } from "../../ui/FormUi";

const initialValues = {
  tipofiltro: "CLIE",
  cliente: "",
  fechaemision: dayjs(),
  fechaentrega: dayjs(),
  vendedor: "",
};
const optionsSearch = [
  {
    value: "CLIE",
    label: "CLIENTE",
  },
  {
    value: "FEMI",
    label: "FECHA EMISIÓN",
  },
  {
    value: "FENT",
    label: "FECHA ENTREGA",
  },
  {
    value: "VEND",
    label: "VENDEDOR",
  },
];
export const CotizacionSearchList = () => {
  const [form] = Form.useForm();
  const tipofiltro = Form.useWatch("tipofiltro", form);
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    const fechaemision = dayjs(values?.fechaemision).format(
      DateFormats.backend
    );
    const fechaentrega = dayjs(values?.fechaentrega).format(
      DateFormats.backend
    );
    const valuesData = { ...values, fechaemision, fechaentrega };
    dispatch(cotizacionesStartLoad(valuesData));
  };
  useEffect(() => {
    dispatch(cotizacionesStartLoad(initialValues));
  }, [dispatch]);
  return (
    <Row>
      <Col sm={32} md={32} xl={24}>
        <FormUi
          name="advanced_search"
          className="ant-advanced-search-form"
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          form={form}
        >
          <Row>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <Form.Item name="tipofiltro" label="Filtrar por">
                <SelectUi data={optionsSearch} placeholder="Filtrar por" />
              </Form.Item>
            </Col>
            {tipofiltro === "CLIE" && (
              <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
                <Form.Item name="cliente">
                  <Input
                    name="cliente"
                    placeholder="Ingrese nombre de cliente"
                    allowClear
                    addonAfter={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
            )}
            {tipofiltro === "FEMI" && (
              <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
                <Form.Item
                  name="fechaemision"
                  rules={[
                    {
                      required: tipofiltro === "FEMI" ? true : false,
                      message: "La Fecha de emisión es obligatorio",
                    },
                  ]}
                >
                  <DatePicker format={DateFormats.frontend} />
                </Form.Item>
              </Col>
            )}
            {tipofiltro === "FENT" && (
              <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
                <Form.Item
                  name="fechaentrega"
                  rules={[
                    {
                      required: tipofiltro === "FENT" ? true : false,
                      message: "La Fecha de entrega es obligatorio",
                    },
                  ]}
                >
                  <DatePicker format={DateFormats.frontend} />
                </Form.Item>
              </Col>
            )}
            {tipofiltro === "VEND" && (
              <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
                <Form.Item name="vendedor">
                  <Input
                    name="vendedor"
                    placeholder="Ingrese vendedor"
                    allowClear
                    addonAfter={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
              <ButtonUi
                htmlType="submit"
                typeUi={TipoButtonConstants.Filtrar}
              />
            </Col>
          </Row>
        </FormUi>
      </Col>
    </Row>
  );
};
