import { types } from "../types/typesUi";

export const uiCollapsedSidebar = (collapsed) => ({
  type: types.uiCollapsedSidebar,
  payload: collapsed,
});

export const uiShowForm = (status = true) => ({
  type: types.uiShowForm,
  payload: status,
});

export const uiShowDrawer = (status = true) => ({
  type: types.uiShowDrawer,
  payload: status,
});
export const uiShowModal = (status = true) => ({
  type: types.uiShowModal,
  payload: status,
});
export const uiShowLoading = (status = true) => ({
  type: types.uiLoading,
  payload: status,
});
