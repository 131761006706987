import React, { useEffect, useState } from "react";
import { get_proveedores_selectoptions } from "../../../actions/producto";
import { SelectUi } from "../../ui/SelectUi";

export const ProveedorSelect = ({ ...props }) => {
  const [proveedores, setProveedores] = useState([]);
  const ProveedoresLoad = async () => {
    const datos = await get_proveedores_selectoptions();
    setProveedores(datos);
  };
  useEffect(() => {
    ProveedoresLoad();
  }, []);

  return <SelectUi data={proveedores} {...props} />;
};
