import React, { useState } from "react";
import { TableUi } from "../../ui/TableUi";
import { Space, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { DetalleLoteSeriePreviewModal } from "../../inventario/reusable/DetalleLoteSeriePreviewModal";
import { useDispatch } from "react-redux";
import { ComprobanteCompraTableSummary } from "./ComprobanteCompraTableSummary";
import { detallecomprobantecompraSetActive } from "../../../actions/compra";

const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;

  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "30%",
    },
    {
      title: "Unidad",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "center",
      render: (value) => <>{parseFloat(value).toFixed(2)}</>,
    },
    {
      title: "Precio unitario",
      dataIndex: "preciounitario",
      key: "preciounitario",
      align: "right",
      render: (value) => <>{parseFloat(value).toFixed(4)}</>,
    },
    {
      title: "Descuento",
      dataIndex: "descuentos",
      key: "descuentos",
      align: "right",
      render: (value) => <>{parseFloat(value).toFixed(4)}</>,
    },
    {
      title: "Total",
      dataIndex: "valorcompra",
      key: "valorcompra",
      align: "right",
      render: (value) => <>{parseFloat(value).toFixed(4)}</>,
    },
    {
      title: "Acciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (value, record, index) => (
        <Space wrap>
          <Tooltip title="Editar">
            <ButtonUi
              typeUi={TipoButtonConstants.EditarTable}
              displayText={false}
              size="small"
              onClick={() => handleEditar(record)}
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              displayText={false}
              size="small"
              onClick={() => handleEliminar(index)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};

export const ComprobanteCompraFormTable = ({
  detallescomprobantecompra,
  handleDelete,
  handleOpenModal,
}) => {
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const [inventario, setInventario] = useState(null);
  const handleEliminar = (index) => {
    handleDelete(index);
  };

  const hideModalPreview = () => setOpenPreview(false);
  const handlePreview = (record) => {
    setInventario(record);
    setOpenPreview(true);
  };
  const handleEditar = (record) => {
    dispatch(detallecomprobantecompraSetActive(record));
    handleOpenModal();
  };
  return (
    <>
      <TableUi
        data={detallescomprobantecompra}
        columns={columns({ handlePreview, handleEditar, handleEliminar })}
        rowKey="id"
        size="small"
        summary={(data) => <ComprobanteCompraTableSummary data={data} />}
      />
      <DetalleLoteSeriePreviewModal
        inventario={inventario}
        open={openPreview}
        onCancelModal={hideModalPreview}
      />
    </>
  );
};
