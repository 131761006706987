import React from "react";
import { PageHeader } from "@ant-design/pro-layout";
export const PageHeaderUi = ({ children, ...props }) => {
  return (
    <PageHeader
      className="site-page-header"
      onBack={() => window.history.back()}
      {...props}
    >
      {children}
    </PageHeader>
  );
};
