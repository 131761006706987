import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TipoButtonConstants } from "../../types/typesConstants";
import { ButtonUi } from "../ui/ButtonUi";
import { AlmacenesEmpleadoSelect } from "./reusable/AlmacenesEmpleadoSelect";
import { productosInventarioStartLoading } from "../../actions/inventario";

import { Col, Form, Row } from "antd";
import { SearchFormUi } from "../ui/SearchFormUi";
const initialValues = { almacen: null };

export const InventarioSeachForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);

  const handleSubmit = (value) => {
    const { almacen } = value;
    dispatch(productosInventarioStartLoading(activeEmpleado?.id, almacen));
  };

  return (
    <SearchFormUi
      name="FormSearchInventario"
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={8} xl={8}>
          <Form.Item name="almacen" label="Almacén">
            <AlmacenesEmpleadoSelect
              placeholder="[-TODOS-]"
              allowClear
              empleado={activeEmpleado?.id}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8} md={12} lg={6} xl={6}>
          <Form.Item>
            <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
          </Form.Item>
        </Col>
      </Row>
    </SearchFormUi>
  );
};
