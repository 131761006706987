import React from "react";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({ user, children }) => {
  const isAllowed = !!user;
  let redirectTo = "/";
  if (isAllowed) {
    const lastPath = localStorage.getItem("lastPath") || null;
    if (lastPath) redirectTo = lastPath;
    return <Navigate to={redirectTo} />;
  }
  return children;
};
