import { Col, Row, Space, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { localSetActive, localStartDelete } from "../../../actions/local";
import { uiShowModal } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { TableUi } from "../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
const { Search } = Input;
const columns = (props) => [
  {
    title: "N°",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Descripción",
    dataIndex: "nombre",
    key: "nombre",
    filteredValue: [props?.filteredColumn],
    onFilter: (value, record) =>
      String(record.nombre).toLowerCase().includes(value.toLowerCase()) ||
      String(record.nombretipolocal)
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      String(record.direccion).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Tipo Local",
    dataIndex: "nombretipolocal",
    key: "nombretipolocal",
  },
  {
    title: "Dirección",
    dataIndex: "direccion",
    key: "direccion",
  },
  {
    title: "Telefono/Movil",
    dataIndex: "telefono",
    key: "telefono",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <Space size="middle">
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          displayText={false}
          onClick={() => props?.handleEditar(record)}
        />
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          displayText={false}
          onClick={() => props?.handleEliminar(record)}
        />
      </Space>
    ),
  },
];
export const LocalesTable = ({ locales }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const handleEditar = (item) => {
    dispatch(localSetActive(item));
    dispatch(uiShowModal());
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el local seleccionado?",
      item.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(localStartDelete(item));
        }
      }
    );
  };
  const onSearch = (value) => {
    setSearchText(value);
  };

  return (
    <TableUi
      columns={columns({
        filteredColumn: searchText,
        handleEditar,
        handleEliminar,
      })}
      data={locales}
      rowKey="id"
      size="small"
      title={() => (
        <Row justify="end">
          <Col span={4}>
            <Search
              placeholder="Buscar"
              allowClear
              onSearch={onSearch}
              enterButton
            />
          </Col>
        </Row>
      )}
    />
  );
};
