import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { TipoDocumentoPersonaConstants } from "../../types/typesConstants";
import { getDatosPersona } from "../../helpers/loadGenerales";
import { messageInfo } from "../../helpers/messageConfig";

export const DocumentoSearchInputUi = ({ tipodocumento, ...props }) => {
  const form = Form.useFormInstance();
  const [maxlength, setMaxlength] = useState(25);
  const { setFieldValue, setFieldsValue } = form;
  const ChangeTipoDocumento = (tipodocumento) => {
    const itipodocumento = parseInt(tipodocumento);
    switch (itipodocumento) {
      case parseInt(TipoDocumentoPersonaConstants.Ruc):
        setMaxlength(11);
        break;
      case parseInt(TipoDocumentoPersonaConstants.Dni):
        setMaxlength(8);
        break;
      default:
        setMaxlength(25);
        break;
    }
  };
  useEffect(() => {
    ChangeTipoDocumento(tipodocumento);
  }, [tipodocumento]);

  const clearFieldsSearch = () => {
    setFieldsValue({
      nombrecompleto: null,
      nombres: null,
      apepaterno: null,
      apematerno: null,
    });
  };
  const onChangePersona = (e) => {
    setFieldValue("nrodocumento", e.target.value);
  };
  const searchPersona = async (numerodocumento, e) => {
    e.preventDefault();
    if (!!tipodocumento && !!numerodocumento) {
      const datosPersona = await getDatosPersona(
        tipodocumento,
        numerodocumento
      );
      if (datosPersona) {
        const itipodocumento = parseInt(tipodocumento);
        switch (itipodocumento) {
          case parseInt(TipoDocumentoPersonaConstants.Ruc):
            setFieldsValue({
              nombrecompleto: datosPersona?.nombre,
              nombres: null,
              apepaterno: null,
              apematerno: null,
            });
            break;
          case parseInt(TipoDocumentoPersonaConstants.Dni):
            setFieldsValue({
              nombrecompleto: datosPersona?.nombre,
              nombres: datosPersona?.nombres,
              apepaterno: datosPersona?.apellidoPaterno,
              apematerno: datosPersona?.apellidoMaterno,
            });
            break;
          default:
            clearFieldsSearch();
            break;
        }
      } else messageInfo("No existe datos con el filtro ingresado");
    } else messageInfo("Seleccione o ingrese el tipo y número de documento");
  };
  return (
    <Input.Search
      allowClear
      showCount
      maxLength={maxlength}
      enterButton
      onSearch={searchPersona}
      onChange={onChangePersona}
      {...props}
    />
  );
};
