import { types } from "../types/typesProducto";
const initialState = {
  productos: [],
  loadingOp: false,
  activeProducto: null,
  modalVariantesProducto: false,
  atributoProductoActive: null,
  varianteProductoActive: null,
  activeProductoPreview: null,
  visibleModalListaPrecios: false,
};

export const productoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.productoStatusLoading:
      return {
        ...state,
        loadingOp: action.payload,
      };
    case types.productoLoaded:
      return {
        ...state,
        productos: action.payload,
      };
    case types.productoAddNew:
      return {
        ...state,
        productos: [action.payload, ...state.productos],
      };
    case types.productoSetActive:
      return {
        ...state,
        activeProducto: action.payload,
      };
    case types.productoPreviewSave:
      return {
        ...state,
        activeProductoPreview: action.payload,
      };
    case types.productoClearActive:
      return {
        ...state,
        activeProducto: null,
        activeProductoPreview: null,
      };
    case types.productoUpdated:
      return {
        ...state,
        productos: state.productos.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.productoDeleted:
      return {
        ...state,
        productos: state.productos.filter((e) => e.id !== action.payload.id),
        activeProducto: null,
      };
    case types.productoShowModalListaPrecios:
      return {
        ...state,
        visibleModalListaPrecios: action.payload,
      };
    case types.productoAtributoSetActive:
      return {
        ...state,
        atributoProductoActive: action.payload,
      };
    case types.productoAtributoClearActive:
      return {
        ...state,
        atributoProductoActive: null,
      };
    case types.productoVarianteSetActive:
      return {
        ...state,
        varianteProductoActive: action.payload,
      };
    case types.productoVarianteClearActive:
      return {
        ...state,
        varianteProductoActive: null,
      };
    default:
      return state;
  }
};
