import { Form } from "antd";
import React, { useState } from "react";
import { DetalleProgramacionTab } from "./DetalleProgramacionTab";
import { UnorderedListOutlined } from "@ant-design/icons";
import { CardUi } from "../../../../ui/CardUi";
import { SearchFormProgramacionTejidoModal } from "./modal/SearchFormProgramacionTejidoModal";

export const DetalleProgramacionContent = () => {
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <>
      <Form.List
        name="detalleprogramaciontintoreria"
        rules={[
          {
            validator: async (_, value) => {
              if (!value || value.length < 1)
                return Promise.reject(
                  new Error(
                    "Se debe ingresar al menos 1 item en el detalle de programación de tintoreria"
                  )
                );
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <CardUi
              title={
                <span>
                  <UnorderedListOutlined /> Detalle programación tintoreria
                </span>
              }
              size="small"
              type="inner"
            >
              <DetalleProgramacionTab
                fields={fields}
                handleAdd={add}
                handleRemove={remove}
                handleOpenModal={handleOpenModal}
              />
            </CardUi>
          </>
        )}
      </Form.List>
      <SearchFormProgramacionTejidoModal
        open={open}
        onCancel={handleCloseModal}
      />
    </>
  );
};
