import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Dropdown, Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  cotizacionSetActive,
  cotizacionStartDelete,
} from "../../../actions/cotizacion";
import { uiShowForm } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { get_pdf_cotizacion } from "../../../helpers/loadVentas";
import { open_pdf_file } from "../../../helpers/utils";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TableUi } from "../../ui/TableUi";

const handleMenuOpt = (e, record, acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  switch (e.key) {
    case "edit":
      handleEditar(record);
      break;
    case "delete":
      handleEliminar(record);
      break;
    default:
      break;
  }
};

const MenuOptions = [
  {
    label: "Editar",
    key: "edit",
    icon: <EditOutlined />,
  },
  {
    label: "Eliminar",
    key: "delete",
    icon: <DeleteOutlined />,
  },
];
const columns = (acciones) => {
  const { handleReportePDF } = acciones;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Vendedor",
      dataIndex: "vendedor_nombre",
      key: "vendedor_nombre",
    },
    {
      title: "Cliente",
      dataIndex: "cliente_nombre",
      key: "cliente_nombre",
    },
    {
      title: "Cotización",
      dataIndex: "numero",
      key: "numero",
    },
    {
      title: "Moneda",
      dataIndex: "moneda_nombre",
      key: "moneda_nombre",
    },
    {
      title: "SubTotal",
      dataIndex: "montosubtotal",
      key: "montosubtotal",
    },
    {
      title: "IGV",
      dataIndex: "montoigvtotal",
      key: "montoigvtotal",
    },
    {
      title: "Total",
      dataIndex: "montototal",
      key: "montototal",
    },
    {
      title: "Estado",
      dataIndex: "estado_nombre",
      key: "estado_nombre",
    },
    {
      title: "PDF",
      dataIndex: "pdf",
      key: "pdf",
      render: (text, record) => (
        <ButtonUi
          typeUi={TipoButtonConstants.CustomButton}
          size="small"
          danger
          onClick={() => handleReportePDF(record)}
          text={<FilePdfOutlined />}
        />
      ),
    },

    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Dropdown
          menu={{
            items: MenuOptions,
            onClick: (e) => handleMenuOpt(e, record, acciones),
          }}
          trigger={["click"]}
        >
          <Button onClick={(e) => e.preventDefault()} size="middle">
            Acciones <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];
};
export const CotizacionTable = ({ cotizaciones }) => {
  const dispatch = useDispatch();
  const handleEditar = (data) => {
    dispatch(cotizacionSetActive(data));
    dispatch(uiShowForm());
  };
  const handleEliminar = (data) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la cotización seleccionada?",
      data.numero,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(cotizacionStartDelete(data));
        }
      }
    );
  };
  const handleReportePDF = async (data) => {
    const pdf = await get_pdf_cotizacion(data?.id);
    open_pdf_file(pdf, data?.numero);
  };
  return (
    <TableUi
      columns={columns({
        handleEditar,
        handleEliminar,
        handleReportePDF,
      })}
      dataSource={cotizaciones}
      rowKey="id"
      size="small"
    />
  );
};
