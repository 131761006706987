import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const routes = {
  proveedores: "proveedores/",
};

export const proveedoresStartLoad = () => {
  return async (dispatch) => {
    dispatch(proveedorLoading());
    try {
      const resp = await axiosConToken.get(routes.proveedores);
      const data = resp.data;
      dispatch(proveedoresLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    } finally {
      dispatch(proveedorLoading(false));
    }
  };
};
export const proveedorStartAdd = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.proveedores, data);
      const { msg, proveedor } = resp.data;
      dispatch(proveedorAdded(proveedor));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const proveedorStartUpdate = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(`proveedores/${data.id}/`, data);
      const { msg, proveedor } = resp.data;
      dispatch(proveedorUpdated(proveedor));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const proveedorStartDelete = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.proveedores}${data.id}/`
      );
      const { msg, proveedor } = resp.data;
      dispatch(proveedorUpdated(proveedor));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
const proveedoresLoaded = (proveedores) => ({
  type: types.proveedorLoaded,
  payload: proveedores,
});
const proveedorAdded = (proveedor) => ({
  type: types.proveedorAdded,
  payload: proveedor,
});
const proveedorUpdated = (proveedor) => ({
  type: types.proveedorUpdated,
  payload: proveedor,
});
const proveedorDeleted = (proveedor) => ({
  type: types.proveedorDeleted,
  payload: proveedor,
});
export const proveedorSetActive = (proveedor) => ({
  type: types.proveedorSetActive,
  payload: proveedor,
});
export const proveedorClearActive = () => ({
  type: types.proveedorClearActive,
});
export const proveedorLoading = (loading = true) => ({
  type: types.proveedorLoading,
  payload: loading,
});
//TODO: Implement extra functions
// ASYNC FUNCTIONS
export const get_proveedores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.proveedores}get_proveedores/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const search_proveedores_by_name_or_code = async (param) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.proveedores}search_proveedores_by_name_or_code/`,
      { params: { param } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
