import { Tag } from "antd";
import React from "react";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

export const TagStatusUi = ({ status, ...props }) => {
  if (status) {
    return (
      <Tag color="success" icon={<CheckCircleOutlined />} {...props}>
        SI
      </Tag>
    );
  } else {
    return (
      <Tag color="default" icon={<MinusCircleOutlined />} {...props}>
        NO
      </Tag>
    );
  }
};
