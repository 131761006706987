import { Col, Descriptions, Form, Row } from "antd";
import React from "react";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ListaPreciosTabContentItem } from "./ListaPreciosTabContentItem";
import dayjs from "dayjs";
import { ListaPreciosTabContentTable } from "./ListaPreciosTabContentTable";
import { useSelector } from "react-redux";
const itemDefault = {
  id: 0,
  listaprecio: null,
  precio: null,
  moneda: null,
};
export const ListaPreciosTabContent = () => {
  const { activeProducto } = useSelector((state) => state.producto);
  return (
    <Row>
      <Col span={20}>
        <Form.List
          name="itemlistaprecios"
          rules={[
            {
              validator: async (_, value) => {
                if (activeProducto !== null) {
                  // Editar producto
                  if (!value || value.length < 1)
                    return Promise.reject(
                      new Error(
                        "Ingrese al menos un item en la lista de precios"
                      )
                    );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <ListaPreciosTabContentTable
                data={fields}
                handleDelete={remove}
                title={() => (
                  <ButtonUi
                    typeUi={TipoButtonConstants.AgregarTable}
                    onClick={() =>
                      add({ ...itemDefault, id: dayjs().valueOf() })
                    }
                  />
                )}
              />
              <Form.Item className="ui-errorlist">
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};
