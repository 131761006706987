import { MailOutlined } from "@ant-design/icons";
import { Alert, Col, Input, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  comprobantespagoSendEmail,
  get_pdf_boleta_venta,
  get_pdf_factura_venta,
  get_pdf_nota_venta,
} from "../../../../actions/comprobante";
import {
  TipoComprobanteSunatConstants,
  TypeSizeReportConstants,
} from "../../../../types/typesConstants";
import { ReportDefaultLayoutUi } from "../../../ui/ReportDefaultLayoutUi";
import { validateEmail } from "../../../../helpers/utils";
import { ButtonsChangeSteps } from "../ButtonsChangeSteps";
import { toast } from "react-toastify";

const { Search } = Input;
export const PuntoVentaResultStep = ({ stepsLength }) => {
  const dispatch = useDispatch();
  const { activeComprobantePago } = useSelector(
    (state) => state.comprobantepago
  );
  const { dataResult } = useSelector((state) => state.venta);
  const [filePDF, setFilePDF] = useState(null);
  const [sizeType, setSizeType] = useState(TypeSizeReportConstants.R_80);
  const file_comprobante_pago = async (
    comprobante,
    size_type = TypeSizeReportConstants.R_A4
  ) => {
    let file = null;
    switch (comprobante.tipocomprobantesunat) {
      case TipoComprobanteSunatConstants.Boleta:
        file = await get_pdf_boleta_venta(comprobante?.id, size_type);
        break;
      case TipoComprobanteSunatConstants.Factura:
        file = await get_pdf_factura_venta(comprobante?.id, size_type);
        break;
      case TipoComprobanteSunatConstants.NotaVenta:
        file = await get_pdf_nota_venta(comprobante?.id, size_type);
        break;
      default:
        file = null;
        break;
    }
    if (file !== null) {
      const fileURL = URL.createObjectURL(file);
      setFilePDF(fileURL);
    }
  };
  const OnHandleSendEmail = (email_receiver) => {
    const valid = validateEmail(email_receiver);
    if (valid) {
      dispatch(
        comprobantespagoSendEmail(activeComprobantePago?.id, email_receiver)
      );
    } else {
      toast.error("Ingrese un email válido");
    }
  };
  const handleChangeFormat = (e) => {
    const size_type = e?.target?.value;
    setSizeType(size_type);
  };
  useEffect(() => {
    if (activeComprobantePago) {
      file_comprobante_pago(activeComprobantePago, sizeType);
    }
  }, [activeComprobantePago, sizeType]);
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={16} lg={16} xl={16} style={{ height: 600 }}>
          {filePDF && <ReportDefaultLayoutUi fileUrl={filePDF} theme="dark" />}
        </Col>
        <Col xs={24} md={8} lg={8} xl={8}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Alert
                message={dataResult?.msg}
                type={dataResult?.status}
                showIcon
              />
            </Col>
            <Col span={24}>
              <Space>
                <label>Formato reporte</label>
                <Radio.Group
                  value={sizeType}
                  buttonStyle="solid"
                  onChange={handleChangeFormat}
                >
                  <Radio.Button value={TypeSizeReportConstants.R_80}>
                    80 mm
                  </Radio.Button>
                  <Radio.Button value={TypeSizeReportConstants.R_A4}>
                    {TypeSizeReportConstants.R_A4.valueOf()}
                  </Radio.Button>
                </Radio.Group>
              </Space>
            </Col>
            <Col span={24}>
              <Search
                enterButton={
                  <>
                    <MailOutlined />
                    {" Enviar"}
                  </>
                }
                style={{ width: "90%" }}
                onSearch={OnHandleSendEmail}
              />
            </Col>
          </Row>
          <ButtonsChangeSteps stepsLength={stepsLength} />
        </Col>
      </Row>
    </>
  );
};
