import React, { useEffect, useState } from "react";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { MaquinaTable } from "./MaquinaTable";
import { MaquinaModal } from "./MaquinaModal";
import {
  maquinaStartAdd,
  maquinaStartLoad,
  maquinaStartUpdate,
} from "../../../../actions/produccion";
import dayjs from "dayjs";

export const MaquinaList = () => {
  const dispatch = useDispatch();
  const { maquinas, activeMaquina } = useSelector((state) => state.produccion);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const handleActualizar = () => {
    dispatch(maquinaStartLoad());
  };
  useEffect(() => {
    dispatch(maquinaStartLoad());
  }, [dispatch]);
  return (
    <PageHeaderUi
      title="Maquinas"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={showModal}
          key="Nuevo"
        />,
      ]}
    >
      <MaquinaTable data={maquinas} onOpenModal={showModal} />
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "MaquinaForm") {
            values = {
              ...values,
              fechaadquisicion: dayjs(values?.fechaadquisicion).format(
                "YYYY-MM-DD"
              ),
            };
            if (activeMaquina) dispatch(maquinaStartUpdate(values));
            else dispatch(maquinaStartAdd(values));

            hideModal();
          }
        }}
      >
        <MaquinaModal open={open} onCancelModal={hideModal} />
      </Form.Provider>
    </PageHeaderUi>
  );
};
export default MaquinaList;
