import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showFormInputMovements,
  showFormOutputMovements,
} from "../../actions/inventario";
import { InventarioSearch } from "./InventarioSearch";
import { RemoveModal } from "./modal/RemoveModal";
import { PageHeaderUi } from "../ui/PageHeaderUi";
import { ButtonUi } from "../ui/ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { EntradaScreen } from "./movimientos/entrada/EntradaScreen";
import { SalidaScreen } from "./movimientos/salida/SalidaScreen";
import { TrasladoScreen } from "./movimientos/traslado/TrasladoScreen";
import { RemoveScreen } from "./movimientos/remover/RemoveScreen";

const InventarioProductoListScreen = () => {
  const dispatch = useDispatch();
  const handleNuevoIngreso = () => {
    dispatch(showFormInputMovements());
  };
  const handleNuevaSalida = () => {
    dispatch(showFormOutputMovements());
  };
  return (
    <PageHeaderUi
      title="Existencias"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="ingreso"
          typeUi={TipoButtonConstants.Agregar}
          text="Ingreso"
          onClick={handleNuevoIngreso}
        />,
        <ButtonUi
          key="salida"
          typeUi={TipoButtonConstants.Disminuir}
          text="Salida"
          onClick={handleNuevaSalida}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <InventarioSearch />
      </div>
      <RemoveModal />
    </PageHeaderUi>
  );
};

export const InventarioProductoList = () => {
  const {
    visibleFormInput,
    visibleFormOutput,
    visibleFormTransfer,
    visibleFormRemove,
  } = useSelector((state) => state.inventario);
  if (visibleFormInput) {
    return <EntradaScreen />;
  } else if (visibleFormOutput) {
    return <SalidaScreen />;
  } else if (visibleFormTransfer) {
    return <TrasladoScreen />;
  } else if (visibleFormRemove) {
    return <RemoveScreen />;
  } else {
    return <InventarioProductoListScreen />;
  }
};

export default InventarioProductoList;
