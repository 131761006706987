import { Form } from "antd";
import React from "react";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TerminalCajeroDetalleTableItem } from "./TerminalCajeroDetalleTableItem";
import dayjs from "dayjs";

const itemDefault = {
  id: new Date().getTime(),
  tipooperacion: null,
  tipoemision: null,
  tipocomprobante: null,
  seriecomprobante: "",
  nrocomprobanteactual: "",
  tipocomprobantepordefecto: false,
};

export const TerminalCajeroDetalleTable = ({ ...props }) => {
  return (
    <>
      <Form.List
        name="detalleterminal"
        rules={[
          {
            validator: async (_, value) => {
              // if (activeProducto !== null) {
              // Editar producto
              if (!value || value.length < 1)
                return Promise.reject(
                  new Error(
                    "Se debe ingresar al menos 1 tipo y número de comprobante"
                  )
                );
              // }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <TerminalCajeroDetalleTableItem
              data={fields}
              handleDelete={remove}
              title={() => (
                <ButtonUi
                  typeUi={TipoButtonConstants.AgregarTable}
                  onClick={() => add({ ...itemDefault, id: dayjs().valueOf() })}
                />
              )}
            />
            <Form.Item className="ui-errorlist">
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
