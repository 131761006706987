import React from "react";
import { FormUi } from "../../../ui/FormUi";
import { useDispatch, useSelector } from "react-redux";
import { RutaProduccionForm } from "./RutaProduccionForm";
import { Form } from "antd";
import {
  rutaProduccionStartAdd,
  rutaProduccionStartUpdate,
  showFormRutaProduccion,
} from "../../../../actions/produccion";

const initialValues = (data = {}) => ({
  id: data?.id || 0,
  nombre: data?.nombre || null,
  detallerutaproduccion: data?.detallerutaproduccion || [],
});
export const RutaProduccionScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeRutaProduccion } = useSelector((state) => state.produccion);
  const handleSubmit = (values) => {
    if (activeRutaProduccion) {
      dispatch(rutaProduccionStartUpdate(values));
    } else dispatch(rutaProduccionStartAdd(values));
    dispatch(showFormRutaProduccion(false));
  };
  return (
    <FormUi
      name="FormRutaProduccion"
      form={form}
      initialValues={initialValues(activeRutaProduccion)}
      handleSubmit={handleSubmit}
      layout="vertical"
      autoComplete="off"
    >
      <RutaProduccionForm />
    </FormUi>
  );
};
