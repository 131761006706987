import React from "react";
import { useDispatch } from "react-redux";
import {
  procesoProduccionSetActive,
  procesoProduccionStartDelete,
  showFormProcesoProduccion,
} from "../../../../actions/produccion";
import { showDeleteConfirm } from "../../../../helpers/confirmModal";
import { Space, Tag } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  DateFormats,
  EstadoDetalleProcesoProduccion,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { TableUi } from "../../../ui/TableUi";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RightCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
const TagEstado = ({ estado }) => {
  if (estado === EstadoDetalleProcesoProduccion.INICIADO)
    return (
      <Tag color="cyan" icon={<RightCircleOutlined />}>
        INICIADO
      </Tag>
    );
  if (estado === "PROCESO")
    return (
      <Tag color="processing" icon={<SyncOutlined spin />}>
        EN PROCESO
      </Tag>
    );
  if (estado === EstadoDetalleProcesoProduccion.FINALIZADO)
    return (
      <Tag color="success" icon={<CheckCircleOutlined />}>
        FINALIZADO
      </Tag>
    );
  if (estado === "CANCELADO")
    return (
      <Tag color="error" icon={<CloseCircleOutlined />}>
        CANCELADO
      </Tag>
    );
};
const columns = (acciones) => {
  const { handleEditar, handleEliminar } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Orden Producción",
      dataIndex: "ordenproduccion_nombre",
      key: "ordenproduccion_nombre",
    },
    {
      title: "Fecha-Hora Inicio",
      dataIndex: "fechahorainicio",
      key: "fechahorainicio",
      render: (value) => (
        <>{dayjs(value).format(DateFormats.DatetimeFrontEnd)}</>
      ),
    },
    {
      title: "Fecha-Hora Fin",
      dataIndex: "fechahorafin",
      key: "fechahorafin",
      render: (value) => (
        <>{dayjs(value).format(DateFormats.DatetimeFrontEnd)}</>
      ),
    },
    {
      title: "Cantidad a producir",
      dataIndex: "cantidadinicial",
      key: "cantidadinicial",
    },
    {
      title: "Unidad medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (text, record, index) => <TagEstado estado={text} />,
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <Space wrap>
          <ButtonUi
            key="Editar"
            displayText={false}
            typeUi={TipoButtonConstants.EditarTable}
            onClick={() => handleEditar(record)}
          />
          <ButtonUi
            key="Eliminar"
            displayText={false}
            typeUi={TipoButtonConstants.EliminarTable}
            onClick={() => handleEliminar(record)}
          />
        </Space>
      ),
    },
  ];
};
export const ProcesoProduccionTable = ({ data }) => {
  const dispatch = useDispatch();
  const handleEditar = (record) => {
    dispatch(procesoProduccionSetActive(record));
    dispatch(showFormProcesoProduccion(true));
  };
  const handleEliminar = (record) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el proceso de programación?",
      record.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(procesoProduccionStartDelete(record));
        }
      }
    );
  };
  return (
    <TableUi
      data={data}
      columns={columns({ handleEditar, handleEliminar })}
      rowKey="id"
      size="small"
    />
  );
};
