import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detalleArqueoCajaClearActive } from "../../../../../actions/caja";
import { ModalUi } from "../../../../ui/ModalUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { ArqueoCajaForm } from "./ArqueoCajaForm";
import dayjs from "dayjs";
import { Form } from "antd";
import { FormUi } from "../../../../ui/FormUi";
const useResetFormOnCloseModal = ({ form, open, activeDetalleArqueoCaja }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

const initialValues = {
  id: dayjs().valueOf(),
  moneda: null,
  moneda_nombre: null,
  denominacionmoneda: null,
  denominacionmoneda_imagen: null,
  valormoneda: null,
  cantidad: null,
  total: null,
};
export const ArqueoCajaModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();
  const { activeDetalleArqueoCaja } = useSelector((state) => state.venta);
  useResetFormOnCloseModal({ form, open, activeDetalleArqueoCaja });
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      if (activeDetalleArqueoCaja) {
        form.setFieldsValue({ ...activeDetalleArqueoCaja });
      } else {
        form.setFieldsValue({ ...initialValues, id: new dayjs().valueOf() });
      }
    }
  }, [open]);

  const handleSubmit = () => {
    dispatch(detalleArqueoCajaClearActive());
    // onCancelModal();
  };
  return (
    <ModalUi
      title="Arqueo de caja"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancelModal}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          htmlType="submit"
          form="FormArqueocaja"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormArqueocaja"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <ArqueoCajaForm />
      </FormUi>
    </ModalUi>
  );
};
