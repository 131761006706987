import React from "react";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";

export const MaquinaForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item name="nombre" label="Nombre">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item name="descripcion" label="Descripción">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="fechaadquisicion" label="Fecha Adquisición">
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
