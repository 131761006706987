import { Select } from "antd";
import React from "react";

export const SearchBasicSelectUI = ({ data, ...restProps }) => {
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={data}
      {...restProps}
    />
  );
};
