import React, { useEffect, useState } from "react";
import { getMonedas } from "../../../helpers/loadVentas";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const MonedaSelect = ({ ...props }) => {
  const [monedas, SetMonedas] = useState([]);
  const OnLoadMonedas = async () => {
    const datos = await getMonedas();
    const Options = datos?.map((x) => ({
      value: x.id,
      label: x?.nombre,
      prefijo: x?.prefijosunat,
    }));
    SetMonedas(Options);
  };
  useEffect(() => {
    OnLoadMonedas();
  }, []);

  return <SearchBasicSelectUI data={monedas} {...props} />;
};
