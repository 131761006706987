import React, { useEffect, useState } from "react";
import { SelectUi } from "../../../ui/SelectUi";
import { get_tareasproduccion } from "../../../../actions/produccion";

export const TareaProduccionSelect = ({ ...props }) => {
  const [tareasproduccion, setTareasproduccion] = useState([]);
  const OnLoadData = async () => {
    const datos = await get_tareasproduccion();
    setTareasproduccion(datos);
  };
  useEffect(() => {
    OnLoadData();
  }, []);
  return <SelectUi data={tareasproduccion} {...props} />;
};
