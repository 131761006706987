import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cotizacionesStartLoad } from "../../../actions/cotizacion";
import { uiShowForm } from "../../../actions/ui";
import { CotizacionScreen } from "./CotizacionScreen";
import { CotizacionSearchList } from "./CotizacionSearchList";
import { CotizacionTable } from "./CotizacionTable";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";

const CotizacionListScreen = () => {
  const dispatch = useDispatch();
  const { cotizaciones } = useSelector((state) => state.cotizacion);
  const handleNuevo = () => {
    dispatch(uiShowForm());
  };
  const handleLoadCotizacion = () => {
    dispatch(cotizacionesStartLoad());
  };
  return (
    <>
      <PageHeaderUi
        title="Cotizaciones"
        subTitle="Listado"
        extra={[
          <ButtonUi
            key="actualizar"
            typeUi={TipoButtonConstants.Actualizar}
            onClick={handleLoadCotizacion}
          />,
          <ButtonUi
            key="nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleNuevo}
          />,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <CotizacionSearchList />
          <Row>
            <Col sm={32} md={32} xl={24}>
              <CotizacionTable cotizaciones={cotizaciones} />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
    </>
  );
};

export const CotizacionList = () => {
  const { visibleForm } = useSelector((state) => state.ui);
  if (visibleForm) {
    return <CotizacionScreen />;
  } else {
    return <CotizacionListScreen />;
  }
};
export default CotizacionList;
