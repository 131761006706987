import { types } from "../types/typesVenta";

const initialState = {
  terminalescajero: [],
  activeTerminalcajero: null,
  cajas: [],
  activeCaja: null,

  activeProductoCarrito: null,
  visibleModalMediosPago: false,
  visibleModalComprobanteResult: false,
  visbleModalStock: false,
  activeArqueoCaja: null,

  activeProductoStock: null,
  currentStep: { previewsForm: [], current: 0 },
  dataResult: null,
};

export const ventaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.terminalcajeroLoaded:
      return { ...state, terminalescajero: action.payload };
    case types.terminalcajeroAdded:
      return {
        ...state,
        terminalescajero: [action.payload, ...state.terminalescajero],
      };
    case types.terminalcajeroSetActive:
      return {
        ...state,
        activeTerminalcajero: action.payload,
      };
    case types.terminalcajeroClearActive:
      return {
        ...state,
        activeTerminalcajero: null,
      };
    case types.terminalcajeroUpdated:
      return {
        ...state,
        terminalescajero: state.terminalescajero.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.terminalcajeroDeleted:
      return {
        ...state,
        terminalescajero: state.terminalescajero.filter(
          (e) => e.id !== action.payload.id
        ),
        activeTerminalcajero: null,
      };

    case types.cajaLoaded:
      return { ...state, cajas: action.payload };
    case types.cajaAdded:
      return {
        ...state,
        cajas: [action.payload, ...state.cajas],
      };
    case types.cajaSetActive:
      return {
        ...state,
        activeCaja: action.payload,
      };
    case types.cajaClearActive:
      return {
        ...state,
        activeCaja: null,
      };
    case types.cajaUpdated:
      return {
        ...state,
        cajas: state.cajas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.cajaDeleted:
      return {
        ...state,
        cajas: state.cajas.filter((e) => e.id !== action.payload.id),
        activeCaja: null,
      };

    case types.arqueoCajaSetActive:
      return {
        ...state,
        activeArqueoCaja: action.payload,
      };

    case types.cierreCajaSetResult:
      return {
        ...state,
        dataResult: action.payload,
      };

    case types.productocarritoSetActive:
      return {
        ...state,
        activeProductoCarrito: action.payload,
      };
    case types.productocarritoClearActive:
      return {
        ...state,
        activeProductoCarrito: null,
      };
    case types.showModalMediosPago:
      return {
        ...state,
        visibleModalMediosPago: action.payload,
      };
    case types.showModalComprobanteResult:
      return {
        ...state,
        visibleModalComprobanteResult: action.payload,
      };
    case types.showModalStock:
      return {
        ...state,
        visbleModalStock: action.payload,
      };
    case types.productostockSetActive:
      return {
        ...state,
        activeProductoStock: action.payload,
      };
    case types.productostockClearActive:
      return {
        ...state,
        activeProductoStock: null,
      };
    case types.puntoventaSetCurrentStep:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.puntoventaSetResult:
      return {
        ...state,
        dataResult: action.payload,
      };
    case types.puntoventaLogout:
      return {
        ...state,
        currentStep: { previewsForm: [], current: 0 },
        activeProductoCarrito: null,
        dataResult: null,
      };
    default:
      return state;
  }
};
