import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  empleadoClearActive,
  empleadoStartAdd,
  empleadoStartUpdate,
} from "../../../actions/empleado";
import { uiShowForm } from "../../../actions/ui";

import { FormUi } from "../../ui/FormUi";
import { Card, Form, Space, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { EmpleadoForm } from "./EmpleadoForm";
import dayjs from "dayjs";
import { to_Base64 } from "../../../helpers/utils";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  nombres: data?.nombres || null,
  apepaterno: data?.apepaterno || null,
  apematerno: data?.apematerno || null,
  tipodocumento: data?.tipodocumento || null,
  nrodocumento: data?.nrodocumento || null,
  fechanacimiento: dayjs(data?.fechanacimiento) || null,
  direccion: data?.direccion || null,
  email: data?.email || null,
  telefono: data?.telefono || null,
  locales: data?.locales || [],
  tieneaccesosistema: data?.tieneaccesosistema || false,
  esarqueadorcaja: data?.esarqueadorcaja || false,
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  state: data?.state || true,
  usuario: data?.usuario || null,
  image: data?.image || [],
  generarusuario: data?.usuario ? false : true,
});
export const EmpleadoScreen = () => {
  const dispatch = useDispatch();
  const { activeEmpleado: activeEmpleadoAuth } = useSelector(
    (state) => state.auth
  );
  const { activeEmpleado } = useSelector((state) => state.empleado);
  const [form] = Form.useForm();
  const handleSubmit = async (values) => {
    let image = values?.image?.[0]?.url || "";
    if (values?.image?.[0]?.originFileObj !== undefined)
      image = await to_Base64(values?.image?.[0]?.originFileObj);

    values = {
      ...values,
      fechanacimiento: dayjs(values.fechanacimiento).format(
        DateFormats.backend
      ),
      image: image,
      empresa: activeEmpleadoAuth?.empresa?.id,
    };

    if (activeEmpleado) dispatch(empleadoStartUpdate(values));
    else dispatch(empleadoStartAdd(values));
    HandleCloseForm();
  };
  const HandleCloseForm = () => {
    dispatch(empleadoClearActive());
    dispatch(uiShowForm(false));
  };
  return (
    <FormUi
      form={form}
      autoComplete="off"
      key="FormEmpleado"
      layout="vertical"
      handleSubmit={handleSubmit}
      initialValues={initialValues(activeEmpleado)}
    >
      <Card
        title={
          <>
            Empleado
            {activeEmpleado ? (
              <small> Editar</small>
            ) : (
              <small> Registrar</small>
            )}
          </>
        }
        size="small"
        extra={
          <Space wrap>
            <ButtonUi typeUi={TipoButtonConstants.Guardar} htmlType="submit">
              Guardar
            </ButtonUi>
            <Tooltip title="Salir">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={HandleCloseForm}
              />
            </Tooltip>
          </Space>
        }
      >
        <EmpleadoForm />
      </Card>
    </FormUi>
  );
};
