import React, { useEffect, useState } from "react";
import { Form, Radio } from "antd";
import { get_tipocomprobantepagos_puntoventa_by_terminalcajero } from "../../../actions/comprobante";
import { TipoComprobanteSunatConstants } from "../../../types/typesConstants";

export const TipoComprobanteRadio = ({ terminalcajero, ...props }) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { setFieldValue } = form;
  const [tiposcomprobantes, setTiposcomprobantes] = useState([]);

  useEffect(() => {
    const OnLoadTipoComprobantesTerminal = async (terminalcajero) => {
      const datos = await get_tipocomprobantepagos_puntoventa_by_terminalcajero(
        terminalcajero
      );
      setTiposcomprobantes(datos);
      const OptionDefault =
        datos.filter((x) => x.pordefecto === true)[0] || datos?.[0];
      setFieldValue("tipocomprobante", OptionDefault?.value);
      // setFieldValue("tipodocumentosunat", OptionDefault?.tipodocumentosunat);
    };
    OnLoadTipoComprobantesTerminal(terminalcajero);
  }, [terminalcajero]);
  useEffect(() => {
    const tipocomprobante = tiposcomprobantes.filter(
      (x) => x.value === props?.value
    )[0];
    setFieldValue("tipodocumentosunat", tipocomprobante?.tipodocumentosunat);
    if (
      tipocomprobante?.tipodocumentosunat.toString() !==
      TipoComprobanteSunatConstants.Factura
    ) {
      setFieldValue("cliente", 0);
    }
  }, [props?.value]);

  return (
    <Radio.Group
      options={tiposcomprobantes}
      buttonStyle="solid"
      optionType="button"
      {...props}
    />
  );
};
