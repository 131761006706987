import { Form } from "antd";
import React from "react";

export const FormSearchUi = ({
  initialValues = {},
  handleSubmit,
  ...props
}) => {
  return (
    <Form
      initialValues={initialValues}
      onFinish={handleSubmit}
      className="ant-advanced-search-form"
      {...props}
    />
  );
};
