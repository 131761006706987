import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormUi } from "../../../ui/FormUi";
import { MovimientoSalidaForm } from "./MovimientoSalidaForm";
import {
  comprobanteMovimientoClearActive,
  comprobanteMovimientoSalidaStartAdd,
  showFormOutputMovements,
} from "../../../../actions/inventario";
import { messageError } from "../../../../helpers/messageConfig";

const initialValues = (data) => ({
  id: data?.id || null,
  tipocomprobantemovimiento: data?.tipocomprobantemovimiento || null,
  seriecomprobantemovimiento: data?.seriecomprobantemovimiento || null,
  nrocomprobantemovimiento: data?.nrocomprobantemovimiento || null,
  tieneobservacion: data?.length > 0 ? true : false,
  observacion: data?.observacion || null,
  almacendestino: data?.almacendestino || null,
  almacenorigen: data?.almacenorigen || null,
  tipomovimiento: data?.tipomovimiento || null,
  comprobantecompra: data?.comprobantecompra || null,
  detallescomprobantemovimiento: data?.detallescomprobantemovimiento || [],
});
export const MovimientoSalidaScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeComprobanteMovimiento, activeDetalleComprobanteMovimiento } =
    useSelector((state) => state.inventario);
  const handleSubmit = (values) => {
    const valuesData = {
      ...values,
      almacendestino: values?.almacenorigen,
      empresa: activeEmpleado?.empresa?.id,
      detallescomprobantemovimiento: values?.detallescomprobantemovimiento?.map(
        (item) => ({
          ...item,
          lotes: item?.lotes?.map((x) => ({
            ...x,
            cantidad: x.cantidad_ingresada,
          })),
        })
      ),
    };
    if (!activeComprobanteMovimiento?.id && !values?.id) {
      //Nuevo
      dispatch(comprobanteMovimientoSalidaStartAdd(valuesData));
    }
    handleCloseForm();
  };
  const handleCloseForm = () => {
    dispatch(comprobanteMovimientoClearActive());
    dispatch(showFormOutputMovements(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormMovimientoSalida, ItemDetalleForm } = forms;
        if (name === "ItemDetalleForm") {
          const detallescomprobantemovimiento =
            FormMovimientoSalida.getFieldValue(
              "detallescomprobantemovimiento"
            ) || [];
          const _existe = detallescomprobantemovimiento?.filter(
            (x) => x.inventario === values?.inventario
          )?.length;
          if (activeDetalleComprobanteMovimiento) {
            FormMovimientoSalida.setFieldsValue({
              detallescomprobantemovimiento: detallescomprobantemovimiento.map(
                (e) => (e.id === values?.id ? values : e)
              ),
            });
          } else {
            if (_existe < 1)
              FormMovimientoSalida.setFieldsValue({
                detallescomprobantemovimiento: [
                  ...detallescomprobantemovimiento,
                  values,
                ],
              });
            else messageError("el item ya existe en el detalle del movimiento");
          }
        }
        if (name === "FormDetalleLoteSerieSalida") {
          const valuesLotes = values?.lotes?.filter(
            (e) => e.cantidad_ingresada !== null
          );
          const series = values?.series?.filter(
            (item) => item.selected === true
          );
          ItemDetalleForm.setFieldsValue({ series, lotes: valuesLotes });
        }
      }}
    >
      <FormUi
        name="FormMovimientoSalida"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeComprobanteMovimiento)}
        handleSubmit={handleSubmit}
      >
        <MovimientoSalidaForm handleCloseForm={handleCloseForm} />
      </FormUi>
    </Form.Provider>
  );
};
