import { types } from "../types/typesGenerales";

const initialState = {
  proveedores: [],
  activeProveedor: null,
  loading: false,
};
export const proveedorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.proveedorLoaded:
      return { ...state, proveedores: action.payload };
    case types.proveedorAdded:
      return {
        ...state,
        proveedores: [...state.proveedores, action.payload],
      };
    case types.proveedorSetActive:
      return {
        ...state,
        activeProveedor: action.payload,
      };
    case types.proveedorClearActive:
      return {
        ...state,
        activeProveedor: null,
      };
    case types.proveedorUpdated:
      return {
        ...state,
        proveedores: state.proveedores.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.proveedorDeleted:
      return {
        ...state,
        proveedores: state.proveedores.filter(
          (e) => e.id !== action.payload.id
        ),
        activeProveedor: null,
      };
    case types.proveedorLogout:
      return { ...initialState };
    case types.proveedorLoading:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
