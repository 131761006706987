import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
const baseUrl = process.env.REACT_APP_API_URL;
const baseSunatConsulta = process.env.REACT_APP_SUNAT_CON_URL;

const setAuthTokens = (tokens) => {
  localStorage.setItem("authTokens", tokens);
};
const getAuthTokens = () => {
  const tokens = localStorage.getItem("authTokens")
    ? JSON.parse(localStorage.getItem("authTokens"))
    : null;
  return tokens;
};

const axiosSinToken = axios.create({
  baseURL: `${baseUrl}/`,
  timeout: 4000,
  headers: { "Content-Type": "application/json" },
});

const axiosConToken = axios.create({
  baseURL: `${baseUrl}/`,
  timeout: 4000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosConToken.interceptors.request.use(
  async (req) => {
    let authTokens = getAuthTokens();
    if (authTokens) {
      req.headers.Authorization = `Bearer ${authTokens?.access}`;
    }
    const user = jwt_decode(authTokens?.access);

    const CurrentDate = dayjs().unix();
    const isExpired = dayjs(user.exp).diff(CurrentDate) < 1;
    if (isExpired) {
      const response = await axiosSinToken.post("auth/token/refresh/", {
        refresh: authTokens?.refresh,
      });

      authTokens = JSON.stringify(response.data);
      setAuthTokens(authTokens);
      req.headers.Authorization = `Bearer ${response.data?.access}`;
    }
    return req;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
  }
);

export { axiosSinToken, axiosConToken };
