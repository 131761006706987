import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import { EmpleadoSelect } from "../../../reusable/EmpleadoSelect";
import { ProveedorSelect } from "../../../reusable/ProveedorSelect";
import { DetalleRutaProduccionSelect } from "./DetalleRutaProduccionSelect";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { UnidadMedidaSelect } from "../../../reusable/UnidadMedidaSelect";

export const TareaProduccionForm = ({ detallerutaproduccion }) => {
  const form = Form.useFormInstance();
  const es_terciarizado = Form.useWatch("es_terciarizado", form);

  const handleChangeDetalle = (value, field) => {
    form.setFieldsValue({
      tarea_nombre: field?.tarea_nombre,
      es_terciarizado: field?.es_terciarizado,
    });
  };
  const handleChangeProveedor = (value, field) => {
    form.setFieldsValue({ proveedor_nombre: field?.label });
  };
  const handleChangeUnidadMedida = (value, field) => {
    form.setFieldsValue({ unidadmedida_nombre: field?.prefijo });
  };
  const handleChangeEmpleado = (_, field) =>
    form.setFieldsValue({ empleado_nombre: field?.label });
  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="maquina" />
      <FormItemHiddenUi name="tarea_nombre" />
      <FormItemHiddenUi name="proveedor_nombre" />
      <FormItemHiddenUi name="unidadmedida_nombre" />
      <FormItemHiddenUi name="empleado_nombre" />
      <FormItemHiddenUi name="es_terciarizado" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item
            name="detallerutaproduccion"
            label="Tarea producción"
            rules={[
              {
                required: true,
                message: "La tarea es obligatorio",
              },
            ]}
            required={false}
          >
            <DetalleRutaProduccionSelect
              detallerutaproduccion={detallerutaproduccion}
              placeholder="[-Seleccione-]"
              onChange={handleChangeDetalle}
            />
          </Form.Item>
        </Col>
      </Row>
      {!es_terciarizado && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <Form.Item name="maquina_nombre" label="Maquina">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <Form.Item
              name="empleado"
              label="Empleado"
              rules={[
                {
                  required: true,
                  message: "El empleado es obligatorio",
                },
              ]}
              required={false}
            >
              <EmpleadoSelect
                placeholder="[-Seleccione-]"
                onChange={handleChangeEmpleado}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {es_terciarizado && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Form.Item
              name="proveedor"
              label="Proveedor"
              rules={[
                {
                  required: es_terciarizado,
                  message: "El proveedor es obligatorio",
                },
              ]}
              required={false}
            >
              <ProveedorSelect
                placeholder="[-Seleccione-]"
                allowClear
                onChange={handleChangeProveedor}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="cantidadinicial"
            label="Cantidad inicial"
            rules={[
              {
                required: true,
                message: "La cantidad inicial es obligatoria",
              },
            ]}
            required={false}
          >
            <InputNumber
              placeholder="Cantidad inicial"
              precision={2}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="unidadmedida"
            label="Unidad medida"
            rules={[
              {
                required: true,
                message: "La unidad de medida es obligatoria",
              },
            ]}
            required={false}
          >
            <UnidadMedidaSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
              onChange={handleChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="fechahorainicio"
            label="Fecha hora inicio"
            rules={[
              {
                required: true,
                message: "La fecha hora inicio es obligatoria",
              },
            ]}
            required={false}
          >
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
