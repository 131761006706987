import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesRRHH";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msgempleado";
const routes = {
  empleados: "empleados/",
};

export const empleadosStartLoad = () => {
  return async (dispatch) => {
    dispatch(empleadoLoading());
    try {
      const resp = await axiosConToken.get(`${routes.empleados}`);
      const data = resp.data;
      dispatch(empleadosLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(empleadoLoading(false));
    }
  };
};
export const empleadoStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(`${routes.empleados}`, data);
      const { msg, empleado } = resp.data;
      dispatch(empleadoAdded(empleado));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const empleadoStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.empleados}${data.id}/`,
        data
      );
      const { msg, empleado } = resp.data;
      dispatch(empleadoUpdated(empleado));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const empleadoStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`${routes.empleados}${data.id}/`);
      const { msg } = resp.data;
      dispatch(empleadoDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const empleadosLoaded = (empleados) => ({
  type: types.empleadoLoaded,
  payload: empleados,
});
const empleadoAdded = (empleado) => ({
  type: types.empleadoAdded,
  payload: empleado,
});
const empleadoUpdated = (empleado) => ({
  type: types.empleadoUpdated,
  payload: empleado,
});
const empleadoDeleted = (empleado) => ({
  type: types.empleadoDeleted,
  payload: empleado,
});
const empleadoLoading = (loading = true) => ({
  type: types.empleadoLoading,
  payload: loading,
});
export const empleadoSetActive = (empleado) => ({
  type: types.empleadoSetActive,
  payload: empleado,
});
export const empleadoClearActive = () => ({ type: types.empleadoClearActive });

export const get_users_actives = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("users/get_users_actives");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipodocumentosidentidad = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.empleados}get_tipodocumentosidentidad`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
