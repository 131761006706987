import { Form, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resumenComprobanteShowModalComprobantes,
  resumencomprobanteStartAdd,
} from "../../../../actions/resumencomprobante";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { ComprobantesModalForm } from "./ComprobantesModalForm";
import dayjs from "dayjs";
import { FormUi } from "../../../ui/FormUi";
const initialValues = {
  fechareferencia: Date.now(),
  detalleresumencomprobante: [],
};
// const validationSchema = Yup.object().shape({
//   fechareferencia: Yup.string()
//     .required("La Fecha de referencia es obligatoria")
//     .nullable(),
//   detalleresumencomprobante: Yup.array()
//     .min(1, "Se requiere al menos 1 comprobante")
//     .required("Se requiere comprobantes para el resumen"),
// });

export const ComprobantesModal = () => {
  const [form] = Form;
  const dispatch = useDispatch();
  const { activeResumen, showModalComprobantes } = useSelector(
    (state) => state.resumencomprobante
  );
  const handleCancel = () => {
    dispatch(resumenComprobanteShowModalComprobantes(false));
  };

  const handleSave = (values) => {
    const detalle = values.detalleresumencomprobante.map((item) => {
      return { comprobante: item.id };
    });
    const _values = {
      fechareferencia: dayjs(values?.fechareferencia).format("YYYY-MM-DD"),
      detalleresumencomprobante: detalle,
    };
    if (!activeResumen) {
      // TODO: Save Resumen
      dispatch(resumencomprobanteStartAdd(_values));
      dispatch(resumenComprobanteShowModalComprobantes(false));
    }
  };

  return (
    <Modal
      title="Listado comprobantes no enviados"
      centered
      maskClosable={false}
      destroyOnClose
      open={showModalComprobantes}
      onOk={handleSave}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          key="submit"
          htmlType="submit"
          form="FormComprobantesResumen"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
    >
      <FormUi
        form={form}
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <ComprobantesModalForm />
      </FormUi>
    </Modal>
  );
};
