import React, { useEffect } from "react";
import { CardUi } from "../../../../ui/CardUi";
import {
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
} from "antd";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  programacionTejidoClearActive,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { ProveedorSelect } from "../../../reusable/ProveedorSelect";
import { DetalleProgramacionContent } from "./DetalleProgramacionContent";
import { EstadoProgramacionTag } from "../../reusable/EstadoProgramacionTag";
import { SwitchUi } from "./../../../../ui/SwitchUi";

const items = ({ cantidadtotal, pesobrutototal, pesonetototal }) => [
  {
    key: "cantidadtotal",
    label: "Cantidad",
    children: <>{parseFloat(cantidadtotal).toFixed(2)}</>,
  },
  {
    key: "pesobrutototal",
    label: "Peso bruto total(KG)",
    children: <>{parseFloat(pesobrutototal).toFixed(2)}</>,
  },
  {
    key: "pesonetototal",
    label: "Peso neto total(KG)",
    children: <>{parseFloat(pesonetototal).toFixed(2)}</>,
  },
];

export const ProgramacionTejidoForm = () => {
  const form = Form.useFormInstance();
  const detalleprogramaciontejido = Form.useWatch(
    "detalleprogramaciontejido",
    form
  );
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const cantidadtotal = Form.useWatch("cantidadtotal", form);
  const pesobrutototal = Form.useWatch("pesobrutototal", form);
  const pesonetototal = Form.useWatch("pesonetototal", form);
  const dispatch = useDispatch();
  const { activeProgramacionTejido } = useSelector((state) => state.produccion);

  useEffect(() => {
    let pesobrutototal = 0;
    let pesonetototal = 0;
    let cantidadtotal = 0;
    detalleprogramaciontejido?.forEach((x) => {
      pesobrutototal += parseFloat(x?.pesobruto) || 0;
      pesonetototal += parseFloat(x?.pesoneto) || 0;
      cantidadtotal += parseFloat(x?.cantidad) || 0;
    });

    form.setFieldsValue({
      pesobrutototal,
      pesonetototal,
      cantidadtotal,
    });
  }, [detalleprogramaciontejido]);

  const handleCancel = () => {
    dispatch(programacionTejidoClearActive());
    dispatch(showFormProgramacionTejido(false));
  };
  return (
    <>
      <CardUi
        title={
          <Space wrap>
            <span>Programación tejido</span>
            <EstadoProgramacionTag
              estado={
                activeProgramacionTejido
                  ? activeProgramacionTejido?.estado
                  : "nuevo"
              }
              estado_nombre={
                activeProgramacionTejido
                  ? "Estado : " + activeProgramacionTejido?.nombreestado
                  : "Estado : NUEVO"
              }
            />
          </Space>
        }
        size="small"
        extra={[
          <Space className="site-button-ghost-wrapper" wrap key="Opts">
            <Tooltip title="Salir" key="1">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={handleCancel}
              />
            </Tooltip>
            <Tooltip title="Guardar" key="2">
              <ButtonUi
                htmlType="submit"
                form="FormProgramacionTejido"
                typeUi={TipoButtonConstants.Guardar}
              />
            </Tooltip>
          </Space>,
        ]}
      >
        <FormItemHiddenUi name="id" />
        <FormItemHiddenUi name="cantidadtotal" />
        <FormItemHiddenUi name="pesobrutototal" />
        <FormItemHiddenUi name="pesonetototal" />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="proveedor" label="Proveedor">
                  <ProveedorSelect placeholder="Seleccione proveedor" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Descriptions
                  layout="vertical"
                  size="small"
                  items={items({
                    cantidadtotal,
                    pesobrutototal,
                    pesonetototal,
                  })}
                  colon={false}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="tieneobservacion"
                  label="Añadir observación"
                  valuePropName="checked"
                >
                  <SwitchUi />
                </Form.Item>
              </Col>
              {tieneobservacion && (
                <Col span={24}>
                  <Form.Item name="observacion">
                    <Input.TextArea
                      placeholder="Ingrese observaciones"
                      style={{ width: "100%" }}
                      showCount
                      maxLength={250}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <DetalleProgramacionContent />
          </Col>
        </Row>
      </CardUi>
    </>
  );
};
