import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { FormUi } from "../../../../ui/FormUi";
import { Form } from "antd";
import dayjs from "dayjs";
import { TareaProduccionForm } from "./TareaProduccionForm";
import { useDispatch, useSelector } from "react-redux";
import { tareaProduccionClearActive } from "../../../../../actions/produccion";
const initialValues = {
  id: new dayjs().valueOf(),
  nombre: null,
  descripcion: null,
};
const useResetFormOnCloseModal = ({ form, open, activeTareaProduccion }) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(tareaProduccionClearActive());
      form.resetFields();
    } else {
      //Abrir Modal
      if (activeTareaProduccion) {
        form.setFieldsValue({
          ...activeTareaProduccion,
        });
      } else {
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const TareaProduccionModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();
  const { activeTareaProduccion } = useSelector((state) => state.produccion);
  useResetFormOnCloseModal({ form, open, activeTareaProduccion });
  return (
    <ModalUi
      title="Tarea producción"
      open={open}
      onCancel={onCancelModal}
      width="40%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="TareaProduccionForm"
        />,
      ]}
    >
      <FormUi
        name="TareaProduccionForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
      >
        <TareaProduccionForm />
      </FormUi>
    </ModalUi>
  );
};
