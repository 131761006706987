import React, { useEffect } from "react";
import { Layout, Menu, Button, Space, Row, Col } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  UserOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import "./AdminLayout.scss";
import { useDispatch, useSelector } from "react-redux";
import { authStartLogout } from "../../actions/auth";
import { uiCollapsedSidebar } from "../../actions/ui";
import { UserMenu } from "./UserMenu";
import { showConfirm } from "../../helpers/confirmModal";
import { useNavigate } from "react-router-dom";

const items_nav_right = ({ onClickMessage, activeUsuario }) => [
  {
    key: "user",
    label: (
      <>
        {activeUsuario?.first_name} <UserMenu />
      </>
    ),
    disabled: false,
  },
  {
    key: "settings",
    label: (
      <Button
        ghost
        shape="circle"
        type="dashed"
        icon={<ControlOutlined />}
        onClick={onClickMessage}
      />
    ),
  },
];

export const HeaderLayout = () => {
  const { Header } = Layout;
  const dispatch = useDispatch();
  const { activeUsuario } = useSelector((state) => state.auth);
  const { collapsedSidebar } = useSelector((state) => state.ui);

  const onCollapse = () => {
    dispatch(uiCollapsedSidebar(!collapsedSidebar));
  };

  const handleLogout = () => {
    dispatch(authStartLogout());
  };
  const onClickMessage = () => {
    showConfirm("Estas de acuerdo con la prueba", "", (isConfirmed) => {
      if (isConfirmed) {
        showConfirm("Estas de acuerdo con la prueba 2", "", (isConfirmed2) => {
          console.log(isConfirmed2);
        });
      }
    });
    console.log("Luego del confirmar");
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <b>Hola,</b>
            <span> Edison</span>
          </div>
          <h6>ed@gmail.com</h6>
        </div>
      </Menu.Item>
      <Menu.Item key="1" icon={<UserOutlined />}>
        Ver Perfíl
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" icon={<PoweroffOutlined />} onClick={handleLogout}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header>
        <Row>
          <Col flex="auto">
            <Space>
              <span className="btnToogleSider" onClick={onCollapse}>
                {collapsedSidebar ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined />
                )}
              </span>
            </Space>
          </Col>
          <Col flex="0 1 300px">
            <Menu
              theme="dark"
              mode="horizontal"
              className="header-menu-right"
              selectable={false}
              items={items_nav_right({ onClickMessage, activeUsuario })}
              // items={items_nav_right}
            />
          </Col>
        </Row>

        {/* <span className="btnToogleSider" onClick={onCollapse}>
        {collapsedSidebar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </span> */}

        {/* <div className="right-menu">
        <UserMenu />
        {/* <Avatar
          size={40}
          src={"https://joeschmoe.io/api/v1/random"}
          style={{ backgroundColor: "#FFFFFF" }}
        />
        <Dropdown
          menu={menu}
          overlayStyle={{ paddingTop: "15px" }}
          icon={<UserOutlined />}
        >
          <Button type="link">
            <Space>
              <b> {activeEmpleado?.persona?.nombrecompleto}</b>
            </Space>
          </Button>
        </Dropdown> */}
        {/* </div> */}
      </Header>
    </>
  );
};
