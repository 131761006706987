import { Col, Row, Space } from "antd";
import React from "react";
import { CardUi } from "../../../ui/CardUi";
import { MediosPagoTable } from "./MediosPagoTable";
import { ComprobantesPagoTable } from "./ComprobantesPagoTable";
import { useSelector } from "react-redux";

export const InformacionGeneralStep = () => {
  const { activeCajaAbierta } = useSelector((state) => state.caja);
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={16} md={12} lg={8} xl={8}>
          <Space direction="vertical" size="small" style={{ display: "flex" }}>
            <CardUi title="Medios pago" size="small">
              <MediosPagoTable />
            </CardUi>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <CardUi title="Comprobantes pago" size="small">
            <ComprobantesPagoTable aperturacaja={activeCajaAbierta?.id} />
          </CardUi>
        </Col>
      </Row>
    </>
  );
};
