import React, { useState } from "react";
import { TableUi } from "../../ui/TableUi";
import {
  PaymentMethodType,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { Form, Input, InputNumber, Space, Tooltip } from "antd";
import { MedioPagoSelect } from "../reusable/MedioPagoSelect";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
const itempagos = {
  id: new Date().valueOf(),
  mediopago: null,
  referencia: null,
  monto: null,
  requiredreferencia: false,
};
const columns = (props) => {
  const { handleDelete, handleAdd, onChangeMedioPago, condicionpago } = props;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Medio pago",
      dataIndex: "mediopago",
      key: "mediopago",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "mediopago"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "El medio de pago es obligatorio",
            },
          ]}
        >
          <MedioPagoSelect
            condicionpago={condicionpago}
            name={["mediospago", field.name, "mediopago"]}
            onChange={(value, option) =>
              onChangeMedioPago(value, option, field.name)
            }
          />
        </Form.Item>
      ),
      width: "30%",
    },
    {
      title: "Referencia",
      dataIndex: "referencia",
      key: "referencia",
      render: (text, field, i) => (
        <>
          <Form.Item
            name={[field.name, "referencia"]}
            className="classRowFieldTable"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (
                    getFieldValue([
                      "mediospago",
                      field.name,
                      "requiredreferencia",
                    ]) &&
                    value === null
                  )
                    return Promise.reject(new Error("Este campo es requerido"));
                },
              }),
            ]}
          >
            <Input placeholder="Referencia" />
          </Form.Item>
          <FormItemHiddenUi name={[field.name, "requiredreferencia"]} />
        </>
      ),
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "monto"]}
          className="classRowFieldTable"
          rules={[
            {
              required: true,
              message: "El monto es obligatorio",
            },
          ]}
        >
          <InputNumber
            placeholder="Ingrese monto"
            style={{ width: "100%" }}
            precision={2}
          />
        </Form.Item>
      ),
    },

    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, field) => (
        <Space size="small">
          <Tooltip title="Agregar">
            <ButtonUi
              typeUi={TipoButtonConstants.AgregarTable}
              onClick={() => handleAdd(itempagos)}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleDelete(field)}
              displayText={false}
              ghost
              size="small"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};

export const ContadoMediosPagoTable = ({ data, Add, Remove }) => {
  const form = Form.useFormInstance();
  const condicionpago = Form.useWatch("condicionpago", form);

  const handleAdd = (data) => {
    Add(data);
  };
  const handleDelete = (data) => {
    Remove(data?.name);
  };
  const onChangeMedioPago = (value, option, fieldname) => {
    const { tipomediopago } = option;
    let requiredReferencia = false;
    if (tipomediopago === PaymentMethodType.Efectivo)
      requiredReferencia = false;
    else requiredReferencia = true;
    form.setFieldValue(
      ["mediospago", fieldname, "requiredreferencia"],
      requiredReferencia
    );
  };
  return (
    <TableUi
      data={data}
      columns={columns({
        handleAdd,
        handleDelete,
        onChangeMedioPago,
        condicionpago,
      })}
      size="small"
    />
  );
};
