import { DatePicker, Descriptions, Form, InputNumber, Space } from "antd";

import React from "react";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import dayjs from "dayjs";

export const ListaPrecioContentItem = ({ field, handleAdd, handleRemove }) => {
  return (
    <>
      <Form.Item noStyle>
        <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }}>
          <Descriptions.Item style={{ paddingBottom: "2px" }}>
            <Form.Item
              {...field}
              name={[field.name, "fechavalida"]}
              rules={[{ required: true, message: "La fecha es obligatorio" }]}
              style={{ marginBottom: 0 }}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: "2px" }}>
            <Form.Item
              {...field}
              name={[field.name, "precio"]}
              rules={[
                { required: true, message: "El precio es obligatorio" },
                {
                  type: "number",
                  message: "Ingrese un número",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: "100%" }} min={1} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item style={{ paddingBottom: "2px" }}>
            <Space>
              <ButtonUi
                typeUi={TipoButtonConstants.AgregarTable}
                displayText={false}
                onClick={() =>
                  handleAdd({
                    id: dayjs().valueOf(),
                    fechavalida: dayjs(),
                    precio: "",
                  })
                }
              />
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                displayText={false}
                onClick={() => handleRemove(field.name)}
              />
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Form.Item>
    </>
  );
};
