import React, { useState } from "react";
import { Card, Row, Col, Form } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TableUi } from "../../ui/TableUi";
import { CotizacionProductoModal } from "./CotizacionProductoModal";
import { DetalleCotizacionTableSummary } from "./DetalleCotizacionTableSummary";

const columns = (props) => {
  const { remove } = props;
  return [
    {
      title: "N°",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Descripción",
      dataIndex: "item_nombre",
      key: "item_nombre",
    },
    {
      title: "Unidad",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "center",
    },
    {
      title: "V.Unitario",
      dataIndex: "valorunitario",
      key: "valorunitario",
      align: "right",
      render: (value, record) => <>{parseFloat(value).toFixed(2)}</>,
    },
    {
      title: "P.Unitario",
      dataIndex: "preciounitario",
      key: "preciounitario",
      align: "right",
      render: (value, record) => <>{parseFloat(value).toFixed(2)}</>,
    },
    {
      title: "SubTotal",
      dataIndex: "subtotalventa",
      key: "subtotalventa",
      align: "right",
      render: (value, record) => <>{parseFloat(value).toFixed(2)}</>,
    },
    {
      title: "Total",
      dataIndex: "totalventa",
      key: "totalventa",
      align: "right",
      render: (value, record) => <>{parseFloat(value).toFixed(2)}</>,
    },
    {
      title: "Opciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record, index) => (
        <>
          <ButtonUi
            typeUi={TipoButtonConstants.EliminarTable}
            displayText={false}
            onClick={() => remove(index)}
            size="small"
          />
        </>
      ),
    },
  ];
};
export const CotizacionFormDetalle = () => {
  const form = Form.useFormInstance();
  const detallecotizacion = Form.useWatch("detallecotizacion", form);
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <>
      <Card title="Detalle de Bienes/Servicios" size="small" className="mt-2">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.List
              name="detallecotizacion"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value < 1)
                      return Promise.reject(
                        new Error(
                          "Ingrese al menos un item en el detalle de cotización"
                        )
                      );
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <TableUi
                    rowKey="id"
                    size="small"
                    columns={columns({ remove })}
                    data={detallecotizacion}
                    title={() => (
                      <ButtonUi
                        typeUi={TipoButtonConstants.AgregarTable}
                        onClick={handleOpenModal}
                        size="small"
                      />
                    )}
                    summary={() => (
                      <DetalleCotizacionTableSummary data={detallecotizacion} />
                    )}
                  />
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Card>
      <CotizacionProductoModal open={open} handleClose={handleCloseModal} />
    </>
  );
};
