import React, { useEffect, useState } from "react";
import { EstadoComprobantePagoConstants } from "../../../types/typesConstants";
import { SelectUi } from "../../ui/SelectUi";

export const EstadoComprobanteSelect = ({ ...props }) => {
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const OnLoadClientes = () => {
      const _items = EstadoComprobantePagoConstants;
      const _data = [];
      for (const item in _items) {
        _data.push({ value: _items[item], label: item.replace("_", " ") });
      }
      setDatos(_data);
    };
    OnLoadClientes();
  }, []);

  return <SelectUi data={datos} placeholder="Seleccione" {...props} />;
};
