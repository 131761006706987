export const types = {
  cajaLoaded: "[caja] Caja Loaded",
  cajaSetActive: "[caja] Caja Set Active",
  cajaClearActive: "[caja] Caja Clear Active",
  cajaStartAdd: "[caja] Start Caja Add New",
  cajaStartUpdate: "[caja] Start Caja Update",
  cajaStartDelete: "[caja] Start Caja Delete",
  cajaAdded: "[caja] Caja added",
  cajaUpdated: "[caja] Caja updated",
  cajaDeleted: "[caja] Caja deleted",
  cajaLogout: "[caja] Caja logout",
  cajaAbiertaSetActive: "[caja] Caja Abierta Set Active",
  cierreCajaClearActive: "[caja] cierre caja Clear Active",
  cierreCajaSetCurrentStep: "[caja] Cierre caja Set Current Step",
  cierreCajaSetResult: "[caja] cierre caja Set Result",
  detalleArqueoCajaSetActive: "[caja] Detalle arqueo caja Set Active",
  detalleArqueoCajaClearActive: "[caja] Detalle arqueo caja Clear Active",

  movimientoCajaLoaded: "[movimientocaja] Movimientos Caja loaded",
  movimientoCajaSetActive: "[movimientocaja] Movimientos Caja Set Active",
  movimientoCajaClearActive: "[movimientocaja] Movimientos Caja Clear Active",
  movimientoCajaAdded: "[movimientocaja] Movimientos Caja Added",
  movimientoCajaUpdated: "[movimientocaja] Movimientos Caja Updated",
  movimientoCajaDeleted: "[movimientocaja] Movimientos Caja Deleted",
  movimientoCajaLogout: "[movimientocaja] Movimientos Caja Logout",

  naturalezaPagoCajaLoaded: "[naturalezapagocaja] Naturaleza Pago Caja loaded",
  naturalezaPagoCajaSetActive:
    "[naturalezapagocaja] Naturaleza Pago Caja Set Active",
  naturalezaPagoCajaClearActive:
    "[naturalezapagocaja] Naturaleza Pago Caja Clear Active",
  naturalezaPagoCajaAdded: "[naturalezapagocaja] Naturaleza Pago Caja Added",
  naturalezaPagoCajaUpdated:
    "[naturalezapagocaja] Naturaleza Pago Caja Updated",
  naturalezaPagoCajaDeleted:
    "[naturalezapagocaja] Naturaleza Pago Caja Deleted",
  naturalezaPagoCajaLogout: "[naturalezapagocaja] Naturaleza Pago Caja Logout",
  naturalezaPagoCajaSelectUpdate:
    "[naturalezapagocaja] Naturaleza Pago Caja Select Update",
};
