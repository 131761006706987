import { SendOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tooltip, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  inventarioSetActive,
  showFormRemoveMovements,
  showFormTransferMovements,
} from "../../actions/inventario";
import { ButtonUi } from "../ui/ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { InventarioLoteSerieModal } from "./InventarioLoteSerieModal";
const { Search } = Input;

const columns = (acciones) => {
  const {
    filteredColumn,
    handleTransladar,
    handleRemover,
    handleShowModalAdicionales,
  } = acciones;
  return [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
      render: (text, record, i) => <>{i + 1}</>,
    },
    {
      title: "Producto",
      dataIndex: "nombreproducto",
      key: "nombreproducto",
      filteredValue: [filteredColumn],
      // width: "35%",
      onFilter: (value, record) =>
        String(record.nombreproducto)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        String(record.nombrealmacen)
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "Almacén",
      dataIndex: "nombrealmacen",
      key: "nombrealmacen",
    },
    {
      title: "Stock",
      dataIndex: "stockactual",
      key: "stockactual",
    },
    {
      title: "U. Medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Adicionales",
      dataIndex: "adicionales",
      key: "adicionales",
      render: (_, record) => (
        <>
          {record?.manejalotes && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              icon={<ZoomInOutlined />}
              text="Lotes"
              onClick={() => handleShowModalAdicionales(record)}
            />
          )}
          {record?.manejaseries && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              icon={<ZoomInOutlined />}
              text="Series"
              onClick={() => handleShowModalAdicionales(record)}
            />
          )}
        </>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (value, record) => (
        <>
          <Space size="middle">
            <Tooltip title="Trasladar">
              <Button
                htmlType="button"
                type="info"
                ghost
                icon={<SendOutlined />}
                onClick={() => handleTransladar(record)}
              >
                Trasladar
              </Button>
            </Tooltip>
            <Tooltip title="Remover">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                displayText={false}
                onClick={() => handleRemover(record)}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
};

export const InventarioProductoTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [inventario, setInventario] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleTransladar = (values) => {
    dispatch(inventarioSetActive(values));
    dispatch(showFormTransferMovements());
  };

  const handleRemover = (values) => {
    dispatch(inventarioSetActive(values));
    dispatch(showFormRemoveMovements());
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  const handleShowModalAdicionales = (data) => {
    setInventario(data);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  return (
    <>
      <Row justify="end" className="mb-2">
        <Col sm={16} md={12} lg={6}>
          <Search
            placeholder="Buscar"
            allowClear
            onSearch={onSearch}
            enterButton
          />
        </Col>
      </Row>
      <Table
        size="small"
        className="ui-table"
        bordered
        columns={columns({
          filteredColumn: searchText,
          handleTransladar,
          handleRemover,
          handleShowModalAdicionales,
        })}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{ showSizeChanger: true }}
        scroll={{ x: 550 }}
      />
      <InventarioLoteSerieModal
        inventario={inventario}
        open={openModal}
        handleCancelModal={handleCloseModal}
      />
    </>
  );
};
