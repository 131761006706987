import React from "react";
import { FormItemHiddenUi } from "../../../../../ui/FormItemHiddenUi";
import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import { UnidadMedidaSelect } from "../../../../reusable/UnidadMedidaSelect";

export const FinalizarTareaForm = () => {
  const form = Form.useFormInstance();
  const es_terciarizado = Form.useWatch("es_terciarizado", form);
  const handleChangeUnidadMedida = (_, field) => {
    form.setFieldsValue({ unidadmedidafinal_nombre: field?.prefijo });
  };
  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="maquina" />
      <FormItemHiddenUi name="detallerutaproduccion" />
      <FormItemHiddenUi name="proveedor" />
      <FormItemHiddenUi name="unidadmedida" />
      <FormItemHiddenUi name="empleado" />
      <FormItemHiddenUi name="es_terciarizado" />
      <FormItemHiddenUi name="unidadmedidafinal_nombre" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Form.Item name="tarea_nombre" label="Tarea producción">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      {!es_terciarizado && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <Form.Item
              name="maquina_nombre"
              label="Maquina"
              rules={[
                {
                  required: true,
                  message: "La maquina es obligatorio",
                },
              ]}
              required={false}
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <Form.Item name="empleado_nombre" label="Empleado">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      )}
      {es_terciarizado && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Form.Item name="proveedor_nombre" label="Proveedor">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="cantidadinicial" label="Cantidad inicial">
            <InputNumber precision={2} style={{ width: "100%" }} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="unidadmedida_nombre" label="Unidad medida">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="cantidadfinal" label="Cantidad final">
            <InputNumber precision={2} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="unidadmedidafinal"
            label="Unidad medida"
            rules={[
              {
                required: true,
                message: "La unidad de medida es obligatoria",
              },
            ]}
            required={false}
          >
            <UnidadMedidaSelect
              placeholder="[-Seleccione-]"
              style={{ width: "100%" }}
              onChange={handleChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="fechahorainicio" label="Fecha hora inicio">
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item name="fechahorafin" label="Fecha hora final">
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
