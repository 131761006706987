import { Form } from "antd";
import React from "react";

export const SearchFormUi = ({
  initialValues = {},
  validationSchema = null,
  handleSubmit,
  ...props
}) => {
  return (
    <Form
      scrollToFirstError
      className="ant-advanced-search-form"
      initialValues={initialValues}
      onFinish={handleSubmit}
      {...props}
    ></Form>
  );
};
