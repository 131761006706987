import { Space } from "antd";
import React from "react";
import { TableUi } from "../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { TagRowTable } from "../../../helpers/utils";
import { usuarioSetActive, usuarioStartDelete } from "../../../actions/usuario";
import { useDispatch } from "react-redux";
import { uiShowModal } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";

const columns = (acciones) => [
  {
    title: "N°",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Nombres",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Apellidos",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "¿Es Super Usuario?",
    dataIndex: "is_superuser",
    key: "is_superuser",
    render: (text) => <TagRowTable estado={text} />,
  },
  {
    title: "¿Esta activo?",
    dataIndex: "is_active",
    key: "is_active",
    render: (text) => <TagRowTable estado={text} />,
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <Space size="middle">
        <ButtonUi
          typeUi={TipoButtonConstants.EditarTable}
          onClick={() => acciones.handleEditar(record)}
          displayText={false}
        ></ButtonUi>
        <ButtonUi
          typeUi={TipoButtonConstants.EliminarTable}
          onClick={() => acciones.handleEliminar(record)}
          displayText={false}
        ></ButtonUi>
      </Space>
    ),
  },
];
export const UsuarioTable = ({ usuarios }) => {
  const dispatch = useDispatch();
  const handleEditar = (usuario) => {
    dispatch(usuarioSetActive(usuario));
    dispatch(uiShowModal());
  };
  const handleEliminar = (usuario) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el usuario seleccionado?",
      usuario.username,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(usuarioStartDelete(usuario));
        }
      }
    );
  };
  return (
    <TableUi
      data={usuarios}
      columns={columns({ handleEditar, handleEliminar })}
      size="small"
      rowKey="id"
    />
  );
};
