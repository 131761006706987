import { Col, Form, Row, Space } from "antd";
import React from "react";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import {
  StepBackwardOutlined,
  StepForwardOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  puntoventaLogout,
  puntoventaSetCurrentStep,
} from "../../../actions/venta";
import { useNavigate } from "react-router-dom";

export const ButtonsChangeSteps = ({ stepsLength }) => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const { currentStep } = useSelector((state) => state.venta);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const prev = () => {
    dispatch(
      puntoventaSetCurrentStep({
        ...currentStep,
        current: currentStep?.current - 1,
      })
    );
  };
  const handleCancel = () => {
    navigate("");
    dispatch(puntoventaSetCurrentStep({ ...currentStep, current: 0 }));
    form.resetFields();
    dispatch(puntoventaLogout());
  };
  const handleExit = () => {
    dispatch(puntoventaLogout());
    navigate("/");
  };

  return (
    <Row className="mt-2">
      <Col span={12}>
        <Space wrap>
          {currentStep?.current > 0 &&
            currentStep?.current < stepsLength - 1 && (
              <ButtonUi
                typeUi={TipoButtonConstants.CustomButton}
                text={"Anterior"}
                onClick={prev}
                icon={<StepBackwardOutlined />}
              />
            )}
          {currentStep?.current < stepsLength - 2 && (
            <ButtonUi
              typeUi={TipoButtonConstants.CustomButton}
              type="primary"
              text={"Siguiente"}
              icon={<StepForwardOutlined />}
              htmlType="submit"
            />
          )}
          {currentStep?.current === stepsLength - 2 && (
            <ButtonUi typeUi={TipoButtonConstants.Guardar} htmlType="submit" />
          )}
          {currentStep?.current === stepsLength - 1 && (
            <>
              <ButtonUi
                typeUi={TipoButtonConstants.Salir}
                onClick={handleExit}
              />
              <ButtonUi
                typeUi={TipoButtonConstants.CustomButton}
                type="primary"
                text={"Nueva venta"}
                icon={<PlusSquareOutlined />}
                onClick={handleCancel}
              />
            </>
          )}
        </Space>
      </Col>

      <Col span={12}>
        <Space wrap>
          {currentStep?.current < stepsLength - 1 && (
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};
