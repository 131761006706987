import { Col, Descriptions, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { DetalleSalidaLoteTable } from "./DetalleSalidaLoteTable";
const items = (props) => {
  const { cantidad_despacho, sumCantidad } = props;
  return [
    {
      key: "1",
      label: "Cantidad despacho",
      children: parseFloat(cantidad_despacho).toFixed(2),
    },
    {
      key: "2",
      label: "Cantidad ingresada",
      children: parseFloat(sumCantidad).toFixed(2),
    },
  ];
};

export const DetalleSalidaLoteForm = ({ cantidad }) => {
  const form = Form.useFormInstance();
  const lotes = Form.useWatch("lotes", form);
  const [sumCantidad, setSumCantidad] = useState(0);
  useEffect(() => {
    const suma = lotes?.reduce(
      (prev, curr) => Number(prev) + Number(curr?.cantidad_ingresada),
      0
    );
    setSumCantidad(suma);
  }, [lotes]);

  return (
    <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 16 }}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Form.List
          name="lotes"
          rules={[
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (sumCantidad !== cantidad)
                  return Promise.reject(
                    new Error(
                      "La cantidad ingresada debe ser igual a la cantidad de despacho"
                    )
                  );
              },
            }),
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <DetalleSalidaLoteTable data={fields} />
              <Form.Item className="ui-errorlist">
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <Descriptions
          title="Resumen de cantidades"
          bordered
          column={1}
          items={items({ cantidad_despacho: cantidad, sumCantidad })}
          size="middle"
          labelStyle={{ width: "50%" }}
        />
      </Col>
    </Row>
  );
};
