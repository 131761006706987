import React, { useEffect, useState } from "react";
import { SelectUi } from "../../../ui/SelectUi";
import { get_ordenesproduccion } from "../../../../actions/produccion";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
export const OrdenProduccionSelect = ({ ...props }) => {
  const [data, setData] = useState([]);
  const OnLoadData = async () => {
    const datos = await get_ordenesproduccion();
    setData(datos);
  };
  useEffect(() => {
    OnLoadData();
  }, []);

  return (
    <SelectUi
      data={data}
      allowClear
      showSearch
      filterOption={filterOption}
      {...props}
    />
  );
};
