import { Col, Form, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  terminalCajeroStartAdd,
  terminalCajeroStartUpdate,
} from "../../../actions/terminalcajero";
import { uiShowForm } from "../../../actions/ui";
import { TerminalCajeroForm } from "./TerminalCajeroForm";
import { FormUi } from "../../ui/FormUi";

const initialValues = (data = {}) => ({
  id: data?.id || "",
  nombre: data?.nombre || "",
  local: data?.local || null,
  tipoterminalcajero: data?.tipoterminalcajero || null,
  empleados: data?.empleados || [],
  detalleterminal: data?.detalleterminal || [],
});

export const TerminalCajeroScreen = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { activeTerminalcajero } = useSelector((state) => state.venta);
  const handleSubmit = (values) => {
    if (activeTerminalcajero) {
      dispatch(terminalCajeroStartUpdate(values));
    } else {
      dispatch(terminalCajeroStartAdd(values));
    }
    dispatch(uiShowForm(false));
  };
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" xs={32} sm={32} md={24} lg={24} xl={24}>
          <FormUi
            form={form}
            layout="vertical"
            initialValues={initialValues(activeTerminalcajero)}
            handleSubmit={handleSubmit}
            autoComplete="off"
          >
            <TerminalCajeroForm />
          </FormUi>
        </Col>
      </Row>
    </>
  );
};
