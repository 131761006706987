import React from "react";
import { Layout, Breadcrumb } from "antd";

import "./AdminLayout.scss";
import { HeaderLayout } from "./HeaderLayout";
import { Outlet } from "react-router-dom";
import { SiderbarAll } from "./SiderbarAll";

export const AdminLayout = () => {
  const { Content } = Layout;
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <SiderbarAll />
        <Layout>
          <HeaderLayout />
          <Content>
            <Layout style={{ minHeight: "90vh" }}>
              <Layout style={{ padding: "0 24px 24px" }}>
                <Breadcrumb
                  style={{ margin: "16px 0" }}
                  separator=">"
                  items={[
                    { title: "Home" },
                    { title: "List" },
                    { title: "App" },
                  ]}
                />
                <Content className="site-layout-background">
                  <div className="site-layout-content">
                    <Outlet />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
