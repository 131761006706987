import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { getTiposAfectacionProducto } from "../../../actions/producto";

export const TiposAfectionProducto = ({ ...props }) => {
  const [tiposafectacion, setTiposafectacion] = useState([]);
  const TiposAfectacionProductoLoad = async () => {
    const datos = await getTiposAfectacionProducto();
    setTiposafectacion(datos);
  };
  useEffect(() => {
    TiposAfectacionProductoLoad();
  }, []);

  return <SelectUi data={tiposafectacion} {...props} />;
};
