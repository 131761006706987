import React, { useEffect, useState } from "react";
import { get_tipooperaciones } from "../../../actions/terminalcajero";
import { SelectUi } from "../../ui/SelectUi";

export const TipoOperacionSelect = ({ ...props }) => {
  const [tipooperaciones, setTipooperaciones] = useState([]);
  const OnLoadTipoOperaciones = async () => {
    const datos = await get_tipooperaciones();
    setTipooperaciones(datos);
  };
  useEffect(() => {
    OnLoadTipoOperaciones();
  }, []);

  return <SelectUi data={tipooperaciones} {...props} />;
};
