import React, { useEffect, useState } from "react";
import {
  get_naturalezapago,
  naturalezaPagoCajaSelectUpdate,
} from "../../../../../actions/caja";
import { SelectUi } from "../../../../ui/SelectUi";
import { useDispatch, useSelector } from "react-redux";

export const NaturalezaPagoSelect = ({ ...props }) => {
  const dispatch = useDispatch();
  const [naturalezaPago, setNaturalezaPago] = useState([]);
  const { updateSelectNaturalezaPagoCaja } = useSelector((state) => state.caja);
  const OnLoadData = async () => {
    const datos = await get_naturalezapago();
    setNaturalezaPago(datos);
  };
  useEffect(() => {
    OnLoadData();
  }, []);
  useEffect(() => {
    if (updateSelectNaturalezaPagoCaja) {
      OnLoadData();
      dispatch(naturalezaPagoCajaSelectUpdate(false));
    }
    return () => {
      setNaturalezaPago([]);
    };
  }, [updateSelectNaturalezaPagoCaja]);

  return <SelectUi data={naturalezaPago} {...props} />;
};
