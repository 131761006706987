import React, { useEffect, useState } from "react";
import { getCategoriasSelectOptions } from "../../../actions/producto";
import { SelectUi } from "../../ui/SelectUi";

export const CategoriaProductoSelect = ({ ...props }) => {
  const [categorias, setCategorias] = useState([]);
  const CategoriasLoad = async () => {
    const data = await getCategoriasSelectOptions();
    setCategorias(data);
  };
  useEffect(() => {
    CategoriasLoad();
  }, []);

  return <SelectUi data={categorias} {...props} />;
};
