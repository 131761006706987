import React, { useEffect, useState } from "react";
import { get_tipoemisiones } from "../../../actions/terminalcajero";
import { SelectUi } from "../../ui/SelectUi";

export const TipoEmisionSelect = ({ ...props }) => {
  const [tipoemisiones, setTipoemisiones] = useState([]);
  const OnLoadTipoEmisiones = async () => {
    const datos = await get_tipoemisiones();
    setTipoemisiones(datos);
  };
  useEffect(() => {
    OnLoadTipoEmisiones();
  }, []);

  return <SelectUi data={tipoemisiones} {...props} />;
};
