import { types } from "../types/typesCotizacion";

const initialState = {
  cotizaciones: [],
  visibleModal: false,
  activeCotizacion: null,
};
export const cotizacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.cotizacionesLoaded:
      return { ...state, cotizaciones: action.payload };
    case types.cotizacionShowModalItemDetalle:
      return {
        ...state,
        visibleModal: action.payload,
      };
    case types.cotizacionAdded:
      return {
        ...state,
        cotizaciones: [...state.cotizaciones, action.payload],
      };
    case types.cotizacionSetActive:
      return {
        ...state,
        activeCotizacion: action.payload,
      };
    case types.cotizacionClearActive:
      return {
        ...state,
        activeCotizacion: null,
      };
    case types.cotizacionUpdated:
      return {
        ...state,
        cotizaciones: state.cotizaciones.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.cotizacionDeleted:
      return {
        ...state,
        cotizaciones: state.cotizaciones.filter(
          (e) => e.id !== action.payload.id
        ),
        activeCotizacion: null,
      };
    case types.cotizacionLogout:
      return { ...initialState };
    default:
      return state;
  }
};
