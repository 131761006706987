import React, { useEffect, useState } from "react";
import { get_campos_aplicar } from "../../../actions/listaprecio";
import { SelectUi } from "./../../ui/SelectUi";

export const AplicarSobreSelect = ({ ...restProps }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_campos_aplicar();
      setData(datos);
    };
    OnLoad();
    return () => {
      setData([]);
    };
  }, []);

  return <SelectUi options={data} {...restProps} />;
};
