import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminLayout } from "./../layouts/AdminLayout/AdminLayout";
import { useSelector } from "react-redux";
import routes from "./routes";
import { messageError } from "../helpers/messageConfig";

export const AdminRoutes = () => {
  const [routesFilter, setRoutesFilter] = useState([]);
  const { activeUsuario: user, activeEmpleado } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!user?.is_superuser && activeEmpleado) {
      //Si el usuario esta asociado a un empleado
      if (user?.groups?.length > 0) {
        let aRoutesFilter = [];
        const routesFiltrados = routes.filter((x) => x.isLink === true);
        user?.groups.forEach((x) => {
          const valuesData = routesFiltrados.filter((i) =>
            i.roles.includes(x.name)
          );
          const valuesNewAdd = valuesData.filter(
            (x) => !aRoutesFilter.some((item) => item.id === x.id)
          );
          aRoutesFilter = aRoutesFilter.concat(valuesNewAdd);
        });
        setRoutesFilter(
          aRoutesFilter.sort((a, b) => Number(a.id) - Number(b.id))
        );
      } else {
        messageError("El usuario no tiene asociado ningún perfil");
      }
    } else {
      if (activeEmpleado && user?.is_superuser)
        //Es un superusuario y tiene datos de empleado
        setRoutesFilter(routes?.filter((x) => x.isLink === true));
      else {
        //Es un superusuario, pero no tiene datos del empleado
        const initialConfigRoutes = routes?.filter(
          (x) => x?.initialConfig === true && x.isLink === true
        );
        setRoutesFilter(initialConfigRoutes);
      }
    }
  }, []);
  return (
    <>
      <Routes>
        <Route element={<AdminLayout />}>
          {routesFilter.map((route) => (
            <Route
              key={route.to}
              path={route.path}
              element={route.Component && <route.Component />}
            />
          ))}
          <Route
            path="/*"
            element={<Navigate to={routesFilter?.[0]?.to} replace />}
          />
        </Route>
      </Routes>
    </>
  );
};
