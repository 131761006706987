import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../ui/ModalUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { FormUi } from "../../ui/FormUi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Form } from "antd";
import {
  monedaClearActive,
  monedaStartAdd,
  monedaStartUpdate,
} from "../../../actions/moneda";
import { MonedaForm } from "./MonedaForm";

const initialValues = (data = {}) => ({
  id: data?.id || new dayjs().valueOf(),
  nombre: data?.nombre || null,
  prefijo: data?.prefijo || null,
  prefijosunat: data?.prefijosunat || null,
  pordefecto: data?.pordefecto || false,
});
const useResetFormOnCloseModal = ({ form, open, activeMoneda }) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    form.resetFields();
    if (!open && prevOpen) {
      dispatch(monedaClearActive());
    } else {
      //Abrir Modal
      if (activeMoneda) {
        //entro editar
        form.setFieldsValue({
          ...activeMoneda,
        });
      } else {
        //entro nuevo
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const MonedaModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeMoneda } = useSelector((state) => state.moneda);
  useResetFormOnCloseModal({ form, open, activeMoneda });
  const handleSubmit = (values) => {
    console.log(values);
    console.log(activeMoneda);
    if (activeMoneda) dispatch(monedaStartUpdate(values));
    else dispatch(monedaStartAdd(values));
    onCancelModal();
  };
  return (
    <ModalUi
      title={
        <>
          Moneda <small>{activeMoneda ? "Editar" : "Nuevo"}</small>
        </>
      }
      open={open}
      onCancel={onCancelModal}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="MonedaForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="MonedaForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeMoneda)}
        handleSubmit={handleSubmit}
      >
        <MonedaForm />
      </FormUi>
    </ModalUi>
  );
};
