import React, { useEffect, useState } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { get_tipomovimientos_by_tipooperacion } from "../../../actions/inventario";

export const TipoMovimientoSelect = ({ tipooperacion, ...props }) => {
  const [tipomovimientos, setTipomovimientos] = useState([]);
  const OnLoadTipoMovimientos = async (tipooperacion) => {
    const datos = await get_tipomovimientos_by_tipooperacion(tipooperacion);
    setTipomovimientos(datos);
  };
  useEffect(() => {
    OnLoadTipoMovimientos(tipooperacion);
  }, [tipooperacion]);

  return <SelectUi data={tipomovimientos} {...props} />;
};
