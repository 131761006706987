import { types } from "../types/typesDashboard";

const initialState = {
  dataMontosNotasVenta: [],
  dataMontosComprobantes: [],
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MontosNotasVentaLoaded:
      return { ...state, dataMontosNotasVenta: action.payload };
    case types.MontosComprobantesLoaded:
      return { ...state, dataMontosComprobantes: action.payload };
    case types.dashboardLogout:
      return { ...initialState };
    default:
      return state;
  }
};
