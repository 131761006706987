import { Drawer, Form, Space } from "antd";
import React, { useEffect, useRef } from "react";
import { FinalizarTareaForm } from "./FinalizarTareaForm";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../../../types/typesConstants";
import { FormUi } from "../../../../../ui/FormUi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { detalleProcesoProduccionClearActive } from "../../../../../../actions/produccion";

const initialValues = {
  id: dayjs().valueOf(),
  fechahorainicio: dayjs(),
  fechahorafin: dayjs(),
  detallerutaproduccion: null,
  maquina: null,
  empleado: null,
  proveedor: null,
  estado: null,
  cantidadfinal: null,
};
const useResetFormOnCloseModal = ({ form, open, activeTarea }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    } else {
      if (activeTarea) {
        form.setFieldsValue({
          ...activeTarea,
          fechahorainicio: dayjs(activeTarea?.fechahorainicio),
          fechahorafin: dayjs(),
          cantidadfinal: 0,
        });
      } else {
        form.setFieldsValue({ ...initialValues });
      }
    }
  }, [form, prevOpen, open]);
};

export const FinalizarTareaDrawer = ({ open, onCancelDrawer }) => {
  const { activeDetalleProcesoProduccion } = useSelector(
    (state) => state.produccion
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useResetFormOnCloseModal({
    form,
    open,
    activeTarea: activeDetalleProcesoProduccion,
  });
  const handleCancel = () => {
    dispatch(detalleProcesoProduccionClearActive());
    onCancelDrawer();
  };
  const handleSubmit = () => handleCancel();

  return (
    <Drawer
      title={<span>Iniciar tarea de producción</span>}
      maskClosable={false}
      onClose={handleCancel}
      open={open}
      size="large"
      destroyOnClose
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.Cancelar}
            onClick={handleCancel}
          />
          <ButtonUi
            form="FormFinalizarTareaProduccion"
            typeUi={TipoButtonConstants.Guardar}
            htmlType="submit"
          />
        </Space>
      }
      forceRender
    >
      <FormUi
        form={form}
        name="FormFinalizarTareaProduccion"
        layout="vertical"
        autoComplete="off"
        handleSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <FinalizarTareaForm />
      </FormUi>
    </Drawer>
  );
};
