import { Col, DatePicker, Form, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import { get_comprobantesNoEnviados } from "../../../../actions/resumencomprobante";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { ComprobantesModalTable } from "./ComprobantesModalTable";

export const ComprobantesModalForm = () => {
  const [form] = Form.useForm();
  const { values, setFieldValue } = form;
  const handleFilterComprobantes = async (fechareferencia) => {
    if (fechareferencia) {
      const _fechareferencia = dayjs(fechareferencia).format("YYYY-MM-DD");
      const data = await get_comprobantesNoEnviados(_fechareferencia);
      setFieldValue("detalleresumencomprobante", data);
    }
  };
  return (
    <Form
      name="FormComprobantesResumen"
      className="ant-advanced-search-form"
      form={form}
    >
      <Row>
        <Col sm={12} md={8} lg={8}>
          <Form.Item name="fechareferencia" label="Fecha referencia">
            <DatePicker name="fechareferencia" format={"DD-MM-YYYY"} />
          </Form.Item>
        </Col>
        <Col sm={12} md={8} lg={6}>
          <ButtonUi
            typeUi={TipoButtonConstants.Filtrar}
            onClick={() => handleFilterComprobantes(values?.fechareferencia)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="detalleresumencomprobante">
            <ComprobantesModalTable
              name="detalleresumencomprobante"
              data={values?.detalleresumencomprobante}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
