import { Row, Col, Form, Input, Divider, DatePicker } from "antd";
import React from "react";
import { LocalSelect } from "../../generales/locales/LocalSelect";
import { DepartamentoSelect } from "../../generales/ubigeos/DepartamentoSelect";
import { ProvinciaSelect } from "../../generales/ubigeos/ProvinciaSelect";
import { DistritoSelect } from "../../generales/ubigeos/DistritoSelect";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { AdicionalesCard } from "./AdicionalesCard";
import { DateFormats } from "../../../types/typesConstants";
import { DocumentoSearchInputUi } from "../../ui/DocumentoSearchInputUi";
import { TipoDocumentoSelect } from "./reusable/TipoDocumentoSelect";

export const EmpleadoForm = ({ ...props }) => {
  const form = Form.useFormInstance();
  const departamento = Form.useWatch("departamento", form);
  const provincia = Form.useWatch("provincia", form);
  const tipodocumento = Form.useWatch("tipodocumento", form);
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                label="Tipo Documento"
                name="tipodocumento"
                rules={[
                  {
                    required: true,
                    message: "El tipo de documento es obligatorio",
                  },
                ]}
              >
                <TipoDocumentoSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item
                label="N° Documento"
                name="nrodocumento"
                rules={[
                  {
                    required: true,
                    message: "El número de documento es obligatorio",
                  },
                ]}
              >
                <DocumentoSearchInputUi
                  tipodocumento={tipodocumento}
                  placeholder="Ingrese el número de documento"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={16} lg={12} xl={12}>
              <Form.Item
                name="nombres"
                label="Nombre"
                rules={[
                  { required: true, message: "Los nombres son obligatorios" },
                ]}
              >
                <Input placeholder="Ingrese nombre" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Apellido paterno"
                name="apepaterno"
                rules={[
                  {
                    required: true,
                    message: "El apellido paterno es obligatorio",
                  },
                ]}
              >
                <Input placeholder="Ingrese apellido paterno" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Apellido materno"
                name="apematerno"
                rules={[
                  {
                    required: true,
                    message: "El apellido materno es obligatorio",
                  },
                ]}
              >
                <Input placeholder="Ingrese apellido materno" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={16} lg={6} xl={4}>
              <Form.Item
                name="fechanacimiento"
                label="Fecha nacimiento"
                rules={[
                  {
                    required: true,
                    message: "La fecha de nacimiento es obligatorio",
                  },
                ]}
              >
                <DatePicker
                  format={DateFormats.frontend}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col xs={24} sm={24} md={16} lg={12} xl={10}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "El email es obligatorio",
                  },
                  {
                    type: "email",
                    message: "Ingrese un correo electrónico válido",
                  },
                ]}
              >
                <Input placeholder="Ingrese el email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item label="Telefono / Movil" name="telefono">
                <Input
                  name="telefono"
                  placeholder="Ingrese el telefono-movil"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Locales"
                name="locales"
                rules={[
                  {
                    type: "array",
                    min: 1,
                    message: "Seleccione al menos 1 Local",
                  },
                ]}
              >
                <LocalSelect
                  name="locales"
                  allowClear
                  mode="multiple"
                  labelInValue
                  style={{ width: "100%" }}
                  placeholder="[-Selecione Locales-]"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={[8, 16]}>
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <Form.Item
            label="Usuario"
            name="usuario"
            hidden={!tieneaccesosistema}
          >
            <UsuariosSelect
              placeholder="Seleccione usuario"
              disabled={generarusuario}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8} md={6} lg={4} xl={4}>
          <Form.Item
            name="generarusuario"
            valuePropName="checked"
            hidden={!tieneaccesosistema}
          >
            <Checkbox onChange={() => setFieldValue("usuario", null)}>
              ¿Autogenerar usuario?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row> */}
          <Divider orientation="center" style={{ margin: "8px 0" }}>
            Ubicación
          </Divider>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
              <Form.Item
                label="Dirección"
                name="direccion"
                rules={[
                  { required: true, message: "La dirección es obligatoria" },
                ]}
              >
                <Input name="direccion" placeholder="Ingrese dirección" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Departamento"
                name="departamento"
                rules={[
                  { required: true, message: "El departamento es obligatorio" },
                ]}
              >
                <DepartamentoSelect
                  name="departamento"
                  placeholder="[-Seleccione-]"
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Provincia"
                name="provincia"
                rules={[
                  { required: true, message: "La provincia es obligatoria" },
                ]}
              >
                <ProvinciaSelect
                  name="provincia"
                  departamento={departamento}
                  placeholder="[-Seleccione-]"
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Distrito"
                name="ubigeodireccion"
                rules={[
                  { required: true, message: "El distrito es obligatorio" },
                ]}
              >
                <DistritoSelect
                  name="ubigeodireccion"
                  provincia={provincia}
                  placeholder="[-Seleccione-]"
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={7}>
          <Row>
            <Col span={24}>
              <AdicionalesCard />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
