import { SearchOutlined } from "@ant-design/icons";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import { comprobantespagoStartLoad } from "../../../actions/comprobante";
import {
  TipoButtonConstants,
  TipoFiltroBusquedaComprobanteConstants,
} from "../../../types/typesConstants";
import { EstadoComprobanteSelect } from "../reusable/EstadoComprobanteSelect";
import { ButtonUi } from "../../ui/ButtonUi";
import { FormUi } from "../../ui/FormUi";
const { Option } = Select;

const initialValues = {
  tipofiltro: "",
  fechaemision: dayjs(),
  cliente: "",
};

export const ComprobantePagoSearchFormTable = () => {
  const [form] = Form.useForm();
  const tipofiltro = Form.useWatch("tipofiltro", form);
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    const { tipofiltro, fechaemision, cliente, estadocomprobante } = values;
    const _fechaemision = dayjs(fechaemision).format("YYYY-MM-DD");
    dispatch(
      comprobantespagoStartLoad(
        tipofiltro,
        _fechaemision,
        cliente,
        estadocomprobante
      )
    );
  };
  return (
    <FormUi
      name="advanced_search"
      className="ant-advanced-search-form"
      labelCol={{
        span: 5,
      }}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      form={form}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item name="tipofiltro" label="Filtrar por">
            <Select name="tipofiltro" placeholder="Filtrar por" allowClear>
              <Option key="1" value="FEMI">
                Fecha de Emisión
              </Option>
              <Option key="2" value="CLIE">
                Cliente
              </Option>
            </Select>
          </Form.Item>
        </Col>
        {tipofiltro === TipoFiltroBusquedaComprobanteConstants.CLIENTE && (
          <Col sm={12} md={8} lg={4} offset={1}>
            <Form.Item name="cliente">
              <Input
                name="cliente"
                placeholder="Ingrese nombre de cliente"
                allowClear
                addonAfter={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
        )}
        {tipofiltro ===
          TipoFiltroBusquedaComprobanteConstants.FECHA_EMISION && (
          <Col sm={12} md={8} lg={4} offset={1} hidden>
            <Form.Item name="fechaemision">
              <DatePicker name="fechaemision" format={"DD-MM-YYYY"} />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item name="estadocomprobante" label="Estado">
            <EstadoComprobanteSelect name="estadocomprobante" allowClear />
          </Form.Item>
        </Col>
      </Row>
    </FormUi>
  );
};
