import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { get_monedas } from "../../../actions/moneda";

export const MonedasSelect = ({ ...props }) => {
  const [monedas, setMonedas] = useState([]);
  const OnLoadMonedas = async () => {
    const datos = await get_monedas();
    setMonedas(datos);
  };
  useEffect(() => {
    OnLoadMonedas();
  }, []);

  return <Select options={monedas} {...props} />;
};
