import { Form } from "antd";
import React from "react";
import { ListaPrecioContentItem } from "./ListaPrecioContentItem";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import dayjs from "dayjs";

export const ListaPrecioContent = () => {
  return (
    <Form.List
      name="listapreciositem"
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length < 1)
              return Promise.reject(
                new Error("Ingrese al menos un item en la lista de precios")
              );
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map((field) => (
              <ListaPrecioContentItem
                key={field.key}
                field={field}
                handleAdd={add}
                handleRemove={remove}
              />
            ))}
            {fields.length < 1 && (
              <Form.Item>
                <ButtonUi
                  typeUi={TipoButtonConstants.Agregar}
                  onClick={() =>
                    add({
                      id: dayjs().valueOf(),
                      fechavalida: dayjs(),
                      precio: "",
                    })
                  }
                />
              </Form.Item>
            )}
            <Form.Item>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};
