import React, { useState } from "react";
import { FormItemHiddenUi } from "../../../../../ui/FormItemHiddenUi";
import { Col, Form, Row } from "antd";
import { SearchSelectUI } from "../../../../../ui/SearchSelectUI";
import { search_comprobantecompras_by_filter } from "../../../../../../actions/produccion";
import { SearchComprobanteCompraTable } from "./SearchComprobanteCompraTable";

export const SearchComprobanteCompraForm = () => {
  const [comprobantesCompra, setComprobantesCompra] = useState([]);
  const [detalleComprobanteCompra, setDetalleComprobanteCompra] = useState([]);
  const onSearch = async (value) => {
    const data = await search_comprobantecompras_by_filter(value);
    const options = data?.map((x) => ({
      value: x?.id,
      label: x?.comprobante_label,
      detallescomprobantecompra: x?.detallescomprobantecompra,
    }));
    setComprobantesCompra(options);
  };
  const handleChange = (value, field) => {
    setDetalleComprobanteCompra(field?.detallescomprobantecompra);
  };
  return (
    <>
      <FormItemHiddenUi name="detallecomprobantecompra" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            name="programaciontejido"
            label="Buscar por código o descripción"
          >
            <SearchSelectUI
              handleSearch={onSearch}
              onChange={handleChange}
              data={comprobantesCompra}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <SearchComprobanteCompraTable data={detalleComprobanteCompra} />
        </Col>
      </Row>
    </>
  );
};
