import { Avatar, Space, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { get_denominacionesmonedas_by_moneda } from "../../../../../actions/caja";
import { SearchBasicSelectUI } from "../../../../ui/SearchBasicSelectUI";
const { Option } = Select;

export const DenominacionMonedaSelect = ({ moneda, ...props }) => {
  const form = Form.useFormInstance();
  const [denominaciones, setDenominaciones] = useState([]);

  useEffect(() => {
    const OnLoadDenominaciones_by_moneda = async (moneda) => {
      if (moneda !== "") {
        const data = await get_denominacionesmonedas_by_moneda(moneda);
        setDenominaciones(data);
      }
    };
    OnLoadDenominaciones_by_moneda(moneda);
    return () => {
      setDenominaciones([]);
    };
  }, [moneda]);
  const onChange = (value, option) => {
    form.setFieldsValue({
      denominacionmoneda: value,
      denominacionmoneda_imagen: option?.imagen,
      valormoneda: option?.valor,
      cantidad: 0,
      total: 0,
    });
  };

  return (
    <SearchBasicSelectUI
      style={{
        width: "100%",
      }}
      placeholder="[-Seleccione-]"
      {...props}
      onChange={onChange}
    >
      {denominaciones.map((item) => (
        <Option
          key={item.value}
          value={item.value}
          label={item.label}
          valor={item.valor}
          imagen={item.imagen}
        >
          <Space>
            <Avatar
              shape="square"
              src={<img src={item.imagen} alt="avatar" />}
            />
            {item.label}
          </Space>
        </Option>
      ))}
    </SearchBasicSelectUI>
  );
};
