import React, { useEffect, useState } from "react";
import { get_formaspago } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";

export const FormaPagoSelect = ({ ...props }) => {
  const [formaspago, setFormapago] = useState([]);

  useEffect(() => {
    const OnLoadFormasPago = async () => {
      const datos = await get_formaspago();
      setFormapago(datos);
    };
    OnLoadFormasPago();
  }, []);

  return <SelectUi data={formaspago} {...props} />;
};
