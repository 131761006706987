import { message } from "antd";
import { axiosConToken } from "../helpers/axios";
import { validaMessagesErrors } from "../helpers/validates";
import { types } from "../types/typesComprobantePago";

const key = "msgresumenes";

export const resumenesComprobanteStartLoad = () => {
  const msgload = message.loading("Cargando...", key);
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get("resumencomprobante/");
      const data = resp.data;
      dispatch(resumenesComprobanteLoaded(data));
      msgload();
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const resumencomprobanteStartAdd = (resumencomprobante) => {
  return async (dispatch) => {
    const msgload = message.loading("Guardando...", key);
    try {
      const resp = await axiosConToken.post(
        "resumencomprobante/",
        resumencomprobante
      );
      const { msg, resumencomprobante: datanew } = resp.data;
      msgload();
      dispatch(resumenComprobanteAdded(datanew));
      message.success(msg, 1.5, key);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const resumencomprobanteStartUpdate = (resumencomprobante) => {
  return async (dispatch) => {
    const hide = message.loading("Guardando...", key);
    try {
      const resp = await axiosConToken.put(
        `resumencomprobante/${resumencomprobante.id}/`,
        resumencomprobante
      );
      const { msg, resumencomprobante: dataupdate } = resp.data;
      dispatch(resumenComprobanteUpdated(dataupdate));
      hide();
      message.success(msg, 1.5, key);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const resumencomprobanteStartDelete = (resumencomprobante) => {
  return async (dispatch) => {
    const hide = message.loading("Eliminando...", key);
    try {
      const resp = await axiosConToken.delete(
        `resumencomprobante/${resumencomprobante.id}/`
      );
      const { msg } = resp.data;
      dispatch(resumenComprobanteDeleted(resumencomprobante));
      hide();
      message.success(msg, key);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const resumencomprobanteGetStatusSummary = (resumencomprobante) => {
  return async (dispatch) => {
    const hide = message.loading("Verificando...", key);
    try {
      const resp = await axiosConToken.get(
        `resumencomprobante/${resumencomprobante.id}/get_status_summary`
      );
      const { msg, resumencomprobante: resumen } = resp.data;
      dispatch(resumenComprobanteUpdated(resumen));
      hide();
      message.success(msg, 1.5, key);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const resumencomprobanteSendSummary = (resumencomprobante) => {
  return async (dispatch) => {
    const hide = message.loading("Enviando...", key);
    try {
      const resp = await axiosConToken.get(
        `resumencomprobante/${resumencomprobante.id}/send_summary`
      );
      const { msg, resumen } = resp.data;
      dispatch(resumenComprobanteUpdated(resumen));
      hide();
      message.success(msg, 1.5, key);
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
const resumenesComprobanteLoaded = (resumenes) => ({
  type: types.resumencomprobanteLoaded,
  payload: resumenes,
});
const resumenComprobanteAdded = (resumencomprobante) => ({
  type: types.resumencomprobanteAdded,
  payload: resumencomprobante,
});
const resumenComprobanteUpdated = (resumencomprobante) => ({
  type: types.resumencomprobanteUpdated,
  payload: resumencomprobante,
});
const resumenComprobanteDeleted = (resumencomprobante) => ({
  type: types.resumencomprobanteDeleted,
  payload: resumencomprobante,
});
export const resumenComprobanteSetActive = (resumencomprobante) => ({
  type: types.resumencomprobanteSetActive,
  payload: resumencomprobante,
});
export const resumenComprobanteClearActive = () => ({
  type: types.resumencomprobanteClearActive,
});
export const resumenComprobanteShowModalComprobantes = (visible = true) => ({
  type: types.resumencomprobanteShowModalComprobantes,
  payload: visible,
});

//TODO: Async Functions

export const get_comprobantesNoEnviados = async (fechareferencia = null) => {
  const hide = message.loading("Cargando...", key);
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "resumencomprobante/get_comprobantes_no_enviados_by_fechareferencia",
      {
        params: { fechareferencia },
      }
    );
    datos = resp.data;
    hide();
  } catch (error) {
    validaMessagesErrors(error, key);
  }
  return datos;
};

export const file_download_xml_resumencomprobantes = async (resumen) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `resumencomprobante/${resumen}/get_xml_resumencomprobantes`,
      {
        responseType: "blob",
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const file_download_cdr_resumencomprobantes = async (resumen) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `resumencomprobante/${resumen}/get_cdr_resumencomprobantes`,
      {
        responseType: "blob",
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
