import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { OrdenProduccionTable } from "./OrdenProduccionTable";
import { OrdenProduccionScreen } from "./OrdenProduccionScreen";
import {
  ordenProduccionStartLoad,
  showFormOrdenProduccion,
} from "../../../../actions/produccion";

export const OrdenProduccionListScreen = () => {
  const dispatch = useDispatch();
  const { ordenesproduccion } = useSelector((state) => state.produccion);
  useEffect(() => {
    dispatch(ordenProduccionStartLoad());
  }, [dispatch]);
  const handleNuevo = () => {
    dispatch(showFormOrdenProduccion(true));
  };
  const handleActualizar = () => {
    dispatch(ordenProduccionStartLoad());
  };
  return (
    <PageHeaderUi
      title="Orden producción"
      subTitle="Listado"
      extra={[
        <ButtonUi
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleActualizar}
          key="Actualizar"
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
          key="Nuevo"
        />,
      ]}
    >
      <OrdenProduccionTable data={ordenesproduccion} />
    </PageHeaderUi>
  );
};
export const OrdenProduccionList = () => {
  const { visibleFormOrdenProduccion } = useSelector(
    (state) => state.produccion
  );
  if (visibleFormOrdenProduccion) {
    return <OrdenProduccionScreen />;
  } else {
    return <OrdenProduccionListScreen />;
  }
};
export default OrdenProduccionList;
