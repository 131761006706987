import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowModal } from "../../../actions/ui";
import { LocalForm } from "./LocalForm";
import {
  localClearActive,
  localStartAdd,
  localStartUpdate,
} from "../../../actions/local";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";

const initialValues = (data = {}) => ({
  id: data?.id || 0,
  nombre: data?.nombre || "",
  descripcion: data?.descripcion || "",
  direccion: data?.direccion || "",
  departamento: data?.departamento || null,
  provincia: data?.provincia || null,
  ubigeodireccion: data?.ubigeodireccion || null,
  telefono: data?.telefono || "",
  email: data?.email || "",
  tipolocal: data?.tipolocal || null,
});

export const LocalModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { visibleModal } = useSelector((state) => state.ui);
  const { activeLocal } = useSelector((state) => state.local);
  useEffect(() => {
    if (visibleModal) {
      if (activeLocal === null) {
        form.setFieldsValue({ ...initialValues() });
      } else {
        form.setFieldsValue({ ...activeLocal });
      }
    }
  }, [activeLocal, visibleModal]);
  const handleSave = (values) => {
    if (activeLocal) {
      dispatch(localStartUpdate(values));
    } else {
      dispatch(localStartAdd(values));
    }
    handleCancel();
  };
  const handleCancel = () => {
    dispatch(uiShowModal(false));
    dispatch(localClearActive());
  };
  return (
    <Modal
      title={
        <span>
          Establecimiento <small>{activeLocal ? "Editar" : "Nuevo"}</small>
        </span>
      }
      centered
      maskClosable={false}
      destroyOnClose
      open={visibleModal}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <ButtonUi
          typeUi={TipoButtonConstants.Cancelar}
          key="back"
          onClick={handleCancel}
        />,
        <ButtonUi
          typeUi={TipoButtonConstants.Guardar}
          form="FormLocal"
          key="submit"
          htmlType="submit"
        />,
      ]}
      forceRender
    >
      <Form
        form={form}
        name="FormLocal"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSave}
      >
        <LocalForm />
      </Form>
    </Modal>
  );
};
