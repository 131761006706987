import { Form, Space, Tooltip } from "antd";
import React from "react";
import { ButtonUi } from "./ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { CardUi } from "./CardUi";

export const FormBasicRegisterUi = ({
  initialValues = {},
  handleSubmit,
  handleClose,
  title,
  children,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      className="ui-form"
      {...props}
    >
      <CardUi
        title={!title ? "Registro" : title}
        size="small"
        extra={
          <Space wrap>
            <ButtonUi
              typeUi={TipoButtonConstants.Guardar}
              htmlType="submit"
              type="primary"
            />
            <Tooltip title="Salir">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={handleClose}
              />
            </Tooltip>
          </Space>
        }
      >
        {children}
      </CardUi>
    </Form>
  );
};
