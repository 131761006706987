import {
  IdcardOutlined,
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { authStartLogout } from "../../actions/auth";

const items = [
  {
    label: "Ver perfil",
    key: "1",
    icon: <IdcardOutlined />,
  },
  {
    type: "divider",
  },
  {
    label: "Configuraciones de la cuenta",
    key: "2",
    icon: <SettingOutlined />,
  },
  {
    label: "Cerrar sesión",
    key: "cerrarsesion",
    icon: <PoweroffOutlined />,
  },
];

export const UserMenu = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(authStartLogout());
  };
  const OnMenuClick = ({ key }) => {
    switch (key) {
      case "cerrarsesion":
        handleLogout();
        break;
      default:
        break;
    }
  };

  return (
    <Space wrap>
      <Dropdown
        menu={{
          items,
          onClick: OnMenuClick,
        }}
        placement="bottom"
      >
        <Button
          ghost
          shape="circle"
          type="dashed"
          icon={<UserOutlined />}
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
    </Space>
  );
};
