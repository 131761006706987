import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../ui/ModalUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { FormUi } from "../../ui/FormUi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Form } from "antd";
import { ColorForm } from "./ColorForm";
import {
  colorClearActive,
  colorStartAdd,
  colorStartUpdate,
} from "../../../actions/color";

const initialValues = (data = {}) => ({
  id: data?.id || new dayjs().valueOf(),
  nombre: data?.nombre || null,
  codigohexadecimal: data?.codigohexadecimal || null,
});
const useResetFormOnCloseModal = ({ form, open, activeColor }) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    form.resetFields();
    if (!open && prevOpen) {
      dispatch(colorClearActive());
    } else {
      //Abrir Modal
      if (activeColor) {
        //entro editar
        form.setFieldsValue({
          ...activeColor,
        });
      } else {
        //entro nuevo
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const ColorModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeColor } = useSelector((state) => state.color);
  useResetFormOnCloseModal({ form, open, activeColor });
  const handleSubmit = (values) => {
    const colorValue =
      typeof values?.codigohexadecimal === "string"
        ? values?.codigohexadecimal
        : values?.codigohexadecimal?.toHexString();
    values = {
      ...values,
      codigohexadecimal: colorValue,
    };
    if (activeColor) dispatch(colorStartUpdate(values));
    else dispatch(colorStartAdd(values));
    onCancelModal();
  };
  return (
    <ModalUi
      title={
        <>
          Color <small>{activeColor ? "Editar" : "Nuevo"}</small>
        </>
      }
      open={open}
      onCancel={onCancelModal}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="ColorForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="ColorForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeColor)}
        handleSubmit={handleSubmit}
      >
        <ColorForm />
      </FormUi>
    </ModalUi>
  );
};
