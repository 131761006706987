import React, { useEffect, useState } from "react";
import { get_items_select } from "../../../actions/inventario";
import { SelectUi } from "../../ui/SelectUi";

export const ProductoSelect = ({ ...props }) => {
  const [productos, setProductos] = useState([]);
  const OnLoadProductos = async () => {
    const datos = await get_items_select();
    setProductos(datos);
  };
  useEffect(() => {
    OnLoadProductos();
  }, []);
  return (
    <SelectUi
      data={productos}
      allowClear
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      {...props}
    />
  );
};
