import React, { useEffect, useState } from "react";
import { get_empleados } from "../../../actions/produccion";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";

export const EmpleadoSelect = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  const OnLoadDatos = async () => {
    const datos = await get_empleados();
    setOptions(datos);
  };
  useEffect(() => {
    OnLoadDatos();
  }, []);

  return <SearchBasicSelectUI options={options} {...props} />;
};
