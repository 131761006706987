import { Col, Form, Input, Row } from "antd";
import React from "react";
import { SwitchUi } from "../../ui/SwitchUi";
import { CardUi } from "../../ui/CardUi";
import { ListaPrecioSucursalDetalle } from "./ListaPrecioSucursalDetalle";
import { ListaPrecioTabForm } from "./ListaPrecioTabForm";

export const ListaPrecioForm = () => {
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input placeholder="Ingrese nombre" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="pordefecto"
            label="Por defecto"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      <ListaPrecioTabForm />
    </>
  );
};
