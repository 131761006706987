import React, { useEffect, useState } from "react";
import { Col, Descriptions, Row } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { configDataGraphits } from "../../helpers/utils";

ChartJS.register(ArcElement, Tooltip, Legend);
export const ComprobantesCardContent = () => {
  const { dataMontosComprobantes } = useSelector((state) => state.dashboard);

  const [data, setData] = useState(
    configDataGraphits("S/ por tipo de comprobante", [], [])
  );
  const [totalGraphit, setTotalGraphit] = useState(0);
  useEffect(() => {
    let gLabels = [];
    let gData = [];
    let SumTotal = 0;
    dataMontosComprobantes?.map((item) => {
      gLabels.push(item.tipocomprobante);
      gData.push(item.monto);
      SumTotal += parseFloat(item.monto);
    });
    const result = configDataGraphits(
      "S/ por tipo de comprobante",
      gLabels,
      gData
    );
    setData(result);
    setTotalGraphit(SumTotal.toFixed(2));
  }, [dataMontosComprobantes]);

  return (
    <>
      <Row>
        <Col>
          <Doughnut data={data} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Total">
              {`S/ ${totalGraphit}`}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};
