import React from "react";
import { ModalUi } from "../../ui/ModalUi";
import { useDispatch, useSelector } from "react-redux";
import {
  productoClearActiveProducto,
  productoStartUpdate,
  showModalListaPrecios,
} from "../../../actions/producto";

import { ListaPrecioContent } from "./ListaPrecioContent";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import dayjs from "dayjs";
import { Form } from "antd";

const initialValues = (valueInitial) => {
  const valueData = valueInitial?.listapreciositem?.map((item) => ({
    ...item,
    fechavalida: dayjs(item.fechavalida),
  }));

  return {
    listapreciositem: valueData || [],
  };
};

export const ListaPreciosModal = () => {
  const dispatch = useDispatch();
  const { activeProducto, visibleModalListaPrecios } = useSelector(
    (state) => state.producto
  );

  const handleSave = (values) => {
    const listapreciositem = values?.listapreciositem?.map((item) => ({
      ...item,
      fechavalida: dayjs(item.fechavalida).format("YYYY-MM-DD"),
    }));
    const valueData = {
      ...activeProducto,
      atributosvariantes_set: activeProducto?.atributosvariantes,
      imagen: activeProducto?.imagen || "",
      listapreciositem,
    };
    dispatch(productoStartUpdate(valueData));
    handleCancel();
  };
  const handleCancel = () => {
    dispatch(productoClearActiveProducto());
    dispatch(showModalListaPrecios(false));
  };
  return (
    <ModalUi
      title={`Lista de precios: ${activeProducto?.nombre}`}
      open={visibleModalListaPrecios}
      onOk={handleSave}
      onCancel={handleCancel}
      footer={[
        <ButtonUi
          key={TipoButtonConstants.Cancelar}
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          key={TipoButtonConstants.Guardar}
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormListaPrecios"
        />,
      ]}
    >
      <Form
        name="FormListaPrecios"
        initialValues={initialValues(activeProducto)}
        onFinish={handleSave}
        autoComplete="off"
      >
        <ListaPrecioContent />
      </Form>
    </ModalUi>
  );
};
