import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FileTextTwoTone,
  FileZipTwoTone,
  SearchOutlined,
  SendOutlined,
  SettingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Table, Tag } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  file_download_cdr_resumencomprobantes,
  file_download_xml_resumencomprobantes,
  resumencomprobanteGetStatusSummary,
  resumencomprobanteSendSummary,
} from "../../../../actions/resumencomprobante";
import { open_file } from "../../../../helpers/utils";
import { EstadoComprobantePagoConstants } from "../../../../types/typesConstants";

const BtnGroupComprobante = ({ data, acciones }) => {
  const { OnHandleDownloadXML, OnHandleDownloadCDR } = acciones;
  return (
    <>
      <Button
        onClick={() => OnHandleDownloadXML(data)}
        disabled={!data?.resumen_xml_existe}
      >
        <FileTextTwoTone twoToneColor="#0083ff" /> XML
      </Button>
      <Button
        className="ml-1"
        onClick={() => OnHandleDownloadCDR(data)}
        disabled={!data?.resumen_cdr_existe}
      >
        <FileZipTwoTone twoToneColor="#00bb2d" /> CDR
      </Button>
      {(data?.estadoresumen === EstadoComprobantePagoConstants.EMITIDO ||
        data?.estadoresumen === EstadoComprobantePagoConstants.ENVIADO) && (
        <Dropdown menu={MenuOpts(data, acciones)} trigger={["click"]}>
          <Button className="ml-2">
            <SettingOutlined />
          </Button>
        </Dropdown>
      )}
    </>
  );
};
const handleMenuOpt = (e, record, acciones) => {
  const { handleSendSummary, handleGetStatusSummary } = acciones;

  switch (e.key) {
    case "Enviar":
      handleSendSummary(record);
      break;
    case "Consultar":
      handleGetStatusSummary(record);
      break;
    default:
      break;
  }
};
const MenuOpts = (data, acciones) => {
  return (
    <Menu onClick={(e) => handleMenuOpt(e, data, acciones)}>
      {data?.estadoresumen === EstadoComprobantePagoConstants.EMITIDO &&
        data?.resumen_xml_existe === true && (
          <Menu.Item key="Enviar" icon={<SendOutlined />}>
            Enviar
          </Menu.Item>
        )}
      {data?.estadoresumen === EstadoComprobantePagoConstants.ENVIADO && (
        <Menu.Item key="Consultar" icon={<SearchOutlined />}>
          Consultar ticket
        </Menu.Item>
      )}
    </Menu>
  );
};

const columns = (acciones) => {
  const TagEstado = (resumen) => {
    switch (resumen.estadoresumen) {
      case EstadoComprobantePagoConstants.ACEPTADO:
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {resumen?.estadoresumen_nombre}
          </Tag>
        );
      case EstadoComprobantePagoConstants.RECHAZADO:
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {resumen?.estadoresumen_nombre}
          </Tag>
        );
      case EstadoComprobantePagoConstants.ENVIADO:
        return (
          <Tag icon={<SyncOutlined />} color="default">
            {resumen?.estadoresumen_nombre}
          </Tag>
        );
      default:
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="processing">
            {resumen?.estadoresumen_nombre}
          </Tag>
        );
    }
  };
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
    },
    {
      title: "Fecha Referencia",
      dataIndex: "fechareferencia",
      key: "fechareferencia",
    },
    {
      title: "Identificador",
      dataIndex: "nroresumen",
      key: "nroresumen",
    },
    {
      title: "Estado",
      dataIndex: "estadoresumen",
      key: "estadoresumen",
      render: (text, record) => <>{TagEstado(record)}</>,
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      key: "ticket",
    },
    {
      title: "",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <BtnGroupComprobante data={record} acciones={acciones} />
      ),
    },
  ];
};
export const ResumenTable = ({ resumenes }) => {
  const dispatch = useDispatch();
  const OnHandleDownloadXML = async (resumen) => {
    const file = await file_download_xml_resumencomprobantes(resumen?.id);
    let name_file = resumen?.nombre_archivo_sunat + ".xml";
    open_file(file, name_file, "application/xml");
  };
  const OnHandleDownloadCDR = async (resumen) => {
    const file = await file_download_cdr_resumencomprobantes(resumen?.id);
    const { type: type_file } = file;
    let name_file = "R-" + resumen?.nombre_archivo_sunat + ".zip";
    open_file(file, name_file, type_file);
  };
  const handleSendSummary = (resumen) => {
    dispatch(resumencomprobanteSendSummary(resumen));
  };
  const handleGetStatusSummary = (resumen) => {
    dispatch(resumencomprobanteGetStatusSummary(resumen));
  };
  return (
    <Table
      columns={columns({
        OnHandleDownloadXML,
        OnHandleDownloadCDR,
        handleSendSummary,
        handleGetStatusSummary,
      })}
      dataSource={resumenes}
      rowKey="id"
      size="small"
    />
  );
};
