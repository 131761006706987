import { types } from "../types/typesComprobantePago";

const initialState = {
  resumenesComprobante: [],
  activeResumen: null,
  showModalComprobantes: false,
};
export const resumencomprobanteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.resumencomprobanteLoaded:
      return { ...state, resumenesComprobante: action.payload };
    case types.resumencomprobanteAdded:
      return {
        ...state,
        resumenesComprobante: [action.payload, ...state.resumenesComprobante],
      };
    case types.resumencomprobanteSetActive:
      return {
        ...state,
        activeResumen: action.payload,
      };
    case types.resumencomprobanteClearActive:
      return {
        ...state,
        activeResumen: null,
      };
    case types.resumencomprobanteUpdated:
      return {
        ...state,
        resumenesComprobante: state.resumenesComprobante.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.resumencomprobanteDeleted:
      return {
        ...state,
        resumenesComprobante: state.resumenesComprobante.filter(
          (e) => e.id !== action.payload.id
        ),
        activeResumen: null,
      };
    case types.resumencomprobanteShowModalComprobantes:
      return {
        ...state,
        showModalComprobantes: action.payload,
      };
    case types.resumencomprobanteLogout:
      return { ...initialState };
    default:
      return state;
  }
};
