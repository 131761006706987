import React from "react";
import { TableUi } from "../../../ui/TableUi";
import { Form, InputNumber } from "antd";
const columns = (props) => {
  const { form } = props;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Lote",
      dataIndex: "numero_lote",
      key: "numero_lote",
      render: (text, record, index) => (
        <>{form.getFieldValue(["lotes", record?.name, "numero_lote"])}</>
      ),
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (text, record, index) => (
        <>{form.getFieldValue(["lotes", record?.name, "cantidad"])}</>
      ),
    },
    {
      title: "Fecha vencimiento",
      dataIndex: "fecha_vencimiento",
      key: "fecha_vencimiento",
      render: (text, record, index) => (
        <>{form.getFieldValue(["lotes", record?.name, "fecha_vencimiento"])}</>
      ),
    },
    {
      title: "Cantidad ingresada",
      dataIndex: "cantidad_ingresada",
      key: "cantidad_ingresada",
      render: (text, field, i) => (
        <Form.Item
          name={[field.name, "cantidad_ingresada"]}
          className="classRowFieldTable"
        >
          <InputNumber
            placeholder="Ingrese la cantidad"
            style={{ width: "100%" }}
            precision={2}
          />
        </Form.Item>
      ),
    },
  ];
};
export const DetalleSalidaLoteTable = ({ data }) => {
  const form = Form.useFormInstance();
  return <TableUi data={data} columns={columns({ form })} size="small" />;
};
