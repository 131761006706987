import { Button, Col, Row, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../actions/ui";
import { ProductoScreen } from "./ProductoScreen";
import { ProductosTable } from "./ProductosTable";
import { ButtonUi } from "../ui/ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { productoStartLoading } from "../../actions/producto";
import { PageHeaderUi } from "../ui/PageHeaderUi";
import { ListaPreciosModal } from "./listaprecios/ListaPreciosModal";
import { ProductoSearchFormTable } from "./ProductoSearchFormTable";
import { UploadOutlined } from "@ant-design/icons";
import { axiosConToken } from "../../helpers/axios";
import { messageError, messageSuccess } from "../../helpers/messageConfig";

const ProductosListScreen = () => {
  const dispatch = useDispatch();
  const { productos, loadingOp } = useSelector((state) => state.producto);

  const handleNuevo = () => {
    dispatch(uiShowForm(true));
  };
  const handleLoad = () => {
    dispatch(productoStartLoading());
  };
  const [defaultFileList, setDefaultFileList] = useState([]);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("file", file);
    try {
      const res = await axiosConToken.post(
        "productos/import_excel_of_products/",
        fmData,
        config
      );
      onSuccess("Ok");
      messageSuccess(res.data?.msg);
    } catch (err) {
      console.log("error: ", err);
      onError({ err });
    }
  };

  const propsUpload = {
    beforeUpload: (file) => {
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        messageError(`${file.name} no es un archivo excel`);
      }
      return isExcel || Upload.LIST_IGNORE;
    },
    onChange: ({ file, fileList, event }) => {
      setDefaultFileList(fileList);
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  return (
    <>
      <PageHeaderUi
        title="Items"
        subTitle="Listado"
        extra={[
          <Space key="options" wrap>
            <Upload
              key="subirExcel"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              customRequest={uploadImage}
              // onChange={handleOnChange}
              maxCount={1}
              defaultFileList={defaultFileList}
              {...propsUpload}
            >
              <Button icon={<UploadOutlined />}>Importar</Button>
            </Upload>
            <ButtonUi
              key="actualizar"
              typeUi={TipoButtonConstants.Actualizar}
              onClick={handleLoad}
            />
            <ButtonUi
              key="nuevo"
              typeUi={TipoButtonConstants.Nuevo}
              onClick={handleNuevo}
            />
          </Space>,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <ProductoSearchFormTable />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <ProductosTable productos={productos} loading={loadingOp} />
            </Col>
          </Row>
        </div>
        <ListaPreciosModal />
      </PageHeaderUi>
    </>
  );
};
export const ProductosList = () => {
  const dispatch = useDispatch();
  const { visibleForm } = useSelector((state) => state.ui);
  useEffect(() => {
    dispatch(productoStartLoading());
  }, [dispatch]);
  if (visibleForm) {
    return <ProductoScreen />;
  } else {
    return <ProductosListScreen />;
  }
};
export default ProductosList;
