import React, { useEffect } from "react";
import { TableUi } from "../../../ui/TableUi";
import { useDispatch, useSelector } from "react-redux";

const columns = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Tipo medio pago",
    dataIndex: "tipomediopago_nombre",
    key: "tipomediopago_nombre",
  },
  {
    title: "Medio pago",
    dataIndex: "mediopago",
    key: "mediopago",
  },
  {
    title: "Total",
    dataIndex: "monto",
    key: "monto",
    render: (text) => <>{parseFloat(text).toFixed(2)}</>,
  },
];
export const MediosPagoTable = () => {
  const { activeCajaAbierta } = useSelector((state) => state.caja);

  return (
    <TableUi
      rowKey="mediopago"
      size="small"
      columns={columns}
      data={activeCajaAbierta?.montototal_mediospago}
      pagination={false}
      scroll={null}
    />
  );
};
