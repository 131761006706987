import React, { useEffect, useState } from "react";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";
import { TiposAfectacionIGV } from "../../../types/typesConstants";

export const TipoAfectacionIGV = ({ ...restProps }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const OnLoad = () => {
      const datos = TiposAfectacionIGV;
      const groups = Object.groupBy(datos, (item) => item.grupo);
      let data = [];
      for (let [key, value] of Object.entries(groups)) {
        const item = {
          label: key,
          title: key,
          options: value?.map((item) => ({ value: item?.id, ...item })),
        };
        data = [...data, item];
      }
      setOptions(data);
    };
    OnLoad();
    return () => {
      setOptions([]);
    };
  }, []);

  return <SearchBasicSelectUI data={options} {...restProps} />;
};
