import React from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AppRouter } from "./routers/AppRouter";
import "./styles/styles.scss";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <div>
        <ToastContainer position="top-center" />
        <AppRouter />
      </div>
    </Provider>
  );
}

export default App;
