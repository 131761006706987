import React, { useEffect, useState } from "react";
import { get_arqueadorescaja } from "../../../../../actions/caja";
import { SelectUi } from "../../../../ui/SelectUi";

export const ArqueadoresCajaSelect = ({ ...props }) => {
  const [arqueadores, setArqueadores] = useState([]);
  useEffect(() => {
    const OnLoadArqueadoresCaja = async () => {
      const _data = await get_arqueadorescaja();
      setArqueadores(_data);
    };
    OnLoadArqueadoresCaja();
    return () => {
      setArqueadores([]);
    };
  }, []);

  return <SelectUi data={arqueadores} {...props} />;
};
