import { types } from "../types/typesRRHH";

const initialState = {
  empleados: [],
  activeEmpleado: null,
  loading: false,
};
export const empleadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.empleadoLoaded:
      return { ...state, empleados: action.payload };
    case types.empleadoAdded:
      return {
        ...state,
        empleados: [...state.empleados, action.payload],
      };
    case types.empleadoSetActive:
      return {
        ...state,
        activeEmpleado: action.payload,
      };
    case types.empleadoClearActive:
      return {
        ...state,
        activeEmpleado: null,
      };
    case types.empleadoUpdated:
      return {
        ...state,
        empleados: state.empleados.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.empleadoDeleted:
      return {
        ...state,
        empleados: state.empleados.filter((e) => e.id !== action.payload.id),
        activeEmpleado: null,
      };
    case types.empleadoLogout:
      return { ...initialState };
    case types.empleadoLoading:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
