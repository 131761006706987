import React, { useEffect, useState } from "react";
import { get_tipodocumentosidentidad } from "../../../../../actions/caja";
import { SelectUi } from "../../../../ui/SelectUi";

export const TipoDocumentoIdentidadSelect = ({ ...props }) => {
  const [tipoDocumentosIdentidad, setTipoDocumentosIdentidad] = useState([]);

  useEffect(() => {
    const OnLoadData = async () => {
      const datos = await get_tipodocumentosidentidad();
      setTipoDocumentosIdentidad(datos);
    };
    OnLoadData();
  }, []);

  return <SelectUi data={tipoDocumentosIdentidad} {...props} />;
};
