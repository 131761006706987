import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { authStartChecking } from "../actions/auth";
import { LoginScreen } from "../components/auth/LoginScreen";
import { PublicRoute } from "./PublicRoute";
import { Spin } from "antd";
import { PrivateRoute } from "./PrivateRoute";
import { AdminRoutes } from "./AdminRoutes";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { activeUsuario, checking } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(authStartChecking());
  }, []);

  if (checking) {
    return <Spin spinning={checking}>Cargando</Spin>;
  }
  return (
    <Suspense fallback={<Spin size="large">Cargando...</Spin>}>
      <BrowserRouter>
        <Routes>
          <Route
            path="login/*"
            element={
              <PublicRoute user={activeUsuario}>
                <Routes>
                  <Route path="/*" element={<LoginScreen />} />
                </Routes>
              </PublicRoute>
            }
          />
          {/* <Route path="/login" element={<LoginScreen />} /> */}
          <Route
            path="/*"
            element={
              <PrivateRoute isAllowed={!!activeUsuario}>
                {/* {activeUsuario?.is_staff ? <AdminRoutes /> : <UserRoutes />} */}
                <AdminRoutes />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
